import { forward } from 'effector'
import {
  $newsList,
  updateNewsList,
  newsPagination,
} from './units'

$newsList
  .on(updateNewsList, (_, news) => news)

forward({
  from: newsPagination.fetchDataFx.doneData.map((res) => res.items),
  to: updateNewsList,
})
