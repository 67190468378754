import { createDomain, attach } from 'effector'
import { ServiceProviderContract } from '@/dal/contracts.interfaces'
import { getServiceProviderContractReqFx } from '@/dal/contracts'

const d = createDomain('d')

export const $activeTab = d.createStore<string>('general')
export const $serviceProviderContract = d.createStore<ServiceProviderContract|null>(null)

export const setTab = d.createEvent<string>()
export const loadContract = d.createEvent<string>()
export const setContract = d.createEvent<ServiceProviderContract>()

export const getServiceProviderFx = attach({ effect: getServiceProviderContractReqFx })
