import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'

import {
  AccordeonRow,
} from '@/ui'
import { AccordeonHeader } from '@/ui/components/AccordeonRow'

import { SiteRow, SurfaceRow, LocalityRow } from '../parts'

import {
  $sitesListWithParentLinks,
  mounted,
  $selectedObjects,
  toggleSite,
  toggleSurface,
  toggleLocality,
  SurfaceWithParent,
} from '../../model/rentalObjects'

const SurfaceList = ({ items }: {items: SurfaceWithParent[] }) => {
  const selectedObjects = useStore($selectedObjects)

  return (
    <>
      {
        items.map(
          (surface) => (
            <>
              <SurfaceRow
                key={surface.id}
                surface={surface}
                checked={selectedObjects.surfaces.includes(surface.id)}
                onClick={() => toggleSurface(surface)}
              />
              <>
                {
                  surface.children.map((locality) => (
                    <LocalityRow
                      key={locality.id}
                      locality={locality}
                      checked={selectedObjects.localities.includes(locality.id)}
                      onClick={() => toggleLocality(locality)}
                    />
                  ))
                }
              </>
            </>
          ),
        )
      }
    </>
  )
}

export const RentalObjects = () => {
  const sitesList = useStore($sitesListWithParentLinks)
  const selectedObjects = useStore($selectedObjects)

  React.useEffect(() => {
    mounted()
  }, [])

  return (
    <ItemsListWrap>
      {
        sitesList.map((site) => (
          <AccordeonRow
            key={site.id}
            header={(
              <SiteRow
                site={site}
                checked={selectedObjects.sites.includes(site.id)}
                onClick={() => toggleSite(site)}
              />
            )}
          >
            <SurfaceList items={site.children} />
          </AccordeonRow>
        ))
      }
    </ItemsListWrap>
  )
}

const ItemsListWrap = styled.div`
  position: relative;
  width: 100%;
  & ${AccordeonHeader} {
    ${breakpoint('mobile', 'tablet')`
      padding: 20px 25px 20px 15px;
    `}
  }
`

const GridCol = styled.div<{ gridArea: string; vAlign?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  align-items: ${({ vAlign = 'center' }) => vAlign};
  grid-area: ${({ gridArea }) => gridArea};
`
