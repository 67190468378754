import { createDomain, attach } from 'effector'

import {
  TariffPlan,
  fetchTariffPlansListReqFx,
  setAccountTariffReqFx,
  setAccountTypeReqFx,
} from '@/dal'
import { createFilters } from '@/lib/effector-filters'

export const d = createDomain('d')

export const $tariffList = d.store<TariffPlan[]>([])

export const selectTariff = d.event<string>()
export const loadTariff = d.event()

export const filters = createFilters({
  domain: d,
  filters: {
    isStartingPlan: {
      include: true,
      value: true,
    },
  },
})

export const getTariffListFx = attach({
  effect: fetchTariffPlansListReqFx,
  source: filters.$result,
  mapParams: (_, f) => ({ ...f, offset: 0, limit: 99999 }),
})

export const setAccountTariffFx = attach({ effect: setAccountTariffReqFx })
export const setAccountTypeFx = attach({ effect: setAccountTypeReqFx })
