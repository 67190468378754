import { RouterConfig } from '@/lib/declarative-routing'
import { UnderDevelopment } from '@/features/app'
import { SetNewPassword, VerificationEmail } from '@/features/autorization'
import { CompanyCreatePage } from '@/features/company'
import { CreateOwnerProfile } from '@/features/create-owner-profile'
import { ChangeOwnerTariff } from '@/features/change-profile-tariff'
import { ToSPage, GDPRPage } from '@/features/tos-and-gdpr'
import { LocalCounterpartiesListPage } from '@/features/counterparty'
import { OrderPage } from './features/orders'
import { DemandPage } from './features/demands'
import { ConsultationPage, ConsultationBidPage } from './features/consultation'
import { WelcomePage } from './features/welcome'
import { ReportPage } from './features/reports'
import { ProfileSettingsPage } from './features/settings'
import { StartPage } from './features/start-page'
import {
  SitePage,
  SitePageDummy,
  PlanBlock,
  ContractBlock,
  NewsList,
  ContactList,
} from './features/site'
import { CommandPage, NewCommandPage } from './features/commands'
import { ReservationPage, ReservationSearchPage } from './features/reservation'
import {
  SelectEntityType, NewPersonalFile, NewUser, EditMember,
} from './features/new-employee'
import { SiteListPage } from './features/site-creation'
import { ContractPage } from './features/tenant-contract'
import { ServiceProviderPage } from './features/service-provider'
import { InsuranceContractPage } from './features/insurance-contract'

export const routerConfig: RouterConfig = {
  guestFallbackPath: '/home',
  privateFallbackPath: '/',
  routes: [
    {
      path: '/home',
      component: StartPage,
      type: 'private',
    },
    {
      path: '/demands',
      component: UnderDevelopment, // DemandPage,
      type: 'private',
    },
    {
      path: '/orders',
      component: UnderDevelopment, // OrderPage,
      type: 'private',
    },
    {
      path: '/reports',
      component: UnderDevelopment, // ReportPage,
      type: 'private',
    },
    {
      path: '/settings',
      component: ProfileSettingsPage,
      type: 'public',
    },
    /* {
      path: '/arrival',
      component: ApplicationPage,
      type: 'private',
    },
    {
      path: '/arrival/search',
      component: ApplicationSearchPage,
      type: 'private',
    }, */
    {
      path: '/site',
      component: SitePageDummy,
      type: 'private',
    },
    {
      path: '/site/:siteId',
      component: SitePage,
      type: 'private',
      routes: [
        {
          path: '',
          component: NewsList,
          type: 'private',
        },
        {
          path: '/news',
          component: NewsList,
          type: 'private',
        },
        {
          path: '/contacts',
          component: ContactList,
          type: 'private',
        },
        {
          path: '/plan',
          component: PlanBlock,
          type: 'private',
        },
        {
          path: '/contracts',
          component: ContractBlock,
          type: 'private',
        },
      ],
    },
    {
      path: '/commands',
      component: UnderDevelopment, // CommandPage,
      type: 'private',
    },
    {
      path: '/commands/new',
      component: UnderDevelopment, // NewCommandPage,
      type: 'private',
    },
    {
      path: '/reservation',
      component: UnderDevelopment, // ReservationPage,
      type: 'private',
    },
    {
      path: '/reservation/search',
      component: UnderDevelopment, // ReservationSearchPage,
      type: 'private',
    },
    {
      path: '/owner-registration/tariff',
      component: ChangeOwnerTariff,
      type: 'private',
    },
    {
      path: '/owner-registration/profile',
      component: CreateOwnerProfile,
      type: 'private',
    },
    {
      path: '/owner-registration/company',
      component: CompanyCreatePage,
      type: 'private',
    },
    {
      path: '/new-employee',
      component: SelectEntityType,
      type: 'private',
    },
    {
      path: '/new-employee/personal-file',
      component: NewPersonalFile,
      type: 'private',
    },
    {
      path: '/new-employee/user',
      component: NewUser,
      type: 'private',
    },
    {
      path: '/new-employee/member',
      component: EditMember,
      type: 'private',
    },
    {
      path: '/counterparty/list',
      component: LocalCounterpartiesListPage,
      type: 'private',
    },
    {
      path: '/gdpr',
      component: GDPRPage,
      type: 'public',
    },
    {
      path: '/tos',
      component: ToSPage,
      type: 'public',
    },
    {
      path: '/sites',
      component: SiteListPage,
      type: 'private',
    },
    {
      path: '/tenant-contract',
      component: ContractPage,
      type: 'private',
    },
    {
      path: '/reservations',
      component: UnderDevelopment,
      type: 'private',
    },
    {
      path: '/consultations',
      component: UnderDevelopment, // ConsultationPage,
      type: 'private',
    },
    {
      path: '/consultations/bids',
      component: UnderDevelopment, // ConsultationBidPage,
      type: 'private',
    },
    {
      path: '/pre-registration',
      component: UnderDevelopment,
      type: 'private',
    },
    {
      path: '/manage',
      component: UnderDevelopment,
      type: 'private',
    },
    {
      path: '/faq',
      component: UnderDevelopment,
      type: 'private',
    },
    {
      path: '/tenant-contract/:id',
      component: ContractPage,
      type: 'public',
    },
    {
      path: '/service-provider',
      component: ServiceProviderPage,
      type: 'public',
    },
    {
      path: '/service-provider/:id',
      component: ServiceProviderPage,
      type: 'public',
    },
    {
      path: '/insurance-contract',
      component: InsuranceContractPage,
      type: 'public',
    },
    {
      path: '/insurance-contract/:id',
      component: InsuranceContractPage,
      type: 'public',
    },
    {
      path: '/',
      component: WelcomePage,
      type: 'guest',
      routes: [
        {
          path: 'auth/change-password',
          component: SetNewPassword,
          type: 'guest',
        },
        {
          path: 'auth/verify-email',
          component: VerificationEmail,
          type: 'guest',
        },
      ],
    },
  ],
}
