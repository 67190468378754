import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { FirstBlock, NewsList } from '../containers'
import { Goodies } from '../parts'

export const WelcomePage: React.FC = ({ children }) => (
  <>
    <WhiteBlock>
      <WhiteBlockBg />
      <FirstBlock />
      <Goodies />
    </WhiteBlock>
    <NewsList />
    {children}
  </>
)


const WhiteBlock = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    z-index:1;
    margin-top: -68px;
    ${breakpoint('tablet')`
      margin-bottom: -70px;
      padding-bottom: 130px;
    `}
`

const WhiteBlockBg = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    background: white;
    z-index: -1;
    ${breakpoint('tablet')`
      left: calc(-50vw + 692px / 2);
    `}
`
