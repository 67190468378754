import {
  $isCommandCreatedDialogShown,
  $isInfoCommandDialogShown,
  $isValidateCommandDialogShown,
  $isCommandValidatedDialogShown,
  $createCommandFormStep,
  $suppplierId,
  $completedSteps,
} from './state'
import {
  createCommand,
  hideCommandCreatedModal,
  hideCommandInfoDialog,
  showCommandInfoDialog,
  showCommandValidateDialog,
  hideCommandValidateDialog,
  validateCommand,
  hideValidatedCommandDialog,
  setCreateFormStep,
  setSupplierId,
  completeStep,
} from './events'

$isCommandCreatedDialogShown
  .on(createCommand, () => true)
  .on(hideCommandCreatedModal, () => false)

$isInfoCommandDialogShown
  .on(showCommandInfoDialog, () => true)
  .on(hideCommandInfoDialog, () => false)

$isValidateCommandDialogShown
  .on(showCommandValidateDialog, () => true)
  .on(hideCommandValidateDialog, () => false)
  .on(validateCommand, () => false)

$isCommandValidatedDialogShown
  .on(validateCommand, () => true)
  .on(hideValidatedCommandDialog, () => false)

$createCommandFormStep
  .on(setCreateFormStep, (_, stepName) => stepName)

$suppplierId
  .on(setSupplierId, (_, id) => {
    completeStep('supplier')
    return id
  })

$completedSteps
  .on(completeStep, (_, step) => {
    const completedSteps = _
    completedSteps.push(step)
    return completedSteps
  })
