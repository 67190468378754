import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'
import Masonry from 'react-masonry-css'
import { useTranslation } from 'react-i18next'

import { Row, Col, PrimaryButton } from '@/ui'
import { $accountInfo } from '@/features/app'

import {
  SiteCard, NewSite, NewLocality, NewSurface, SiteInfo,
} from '../containers'
import { showModal } from '../../model/new-site'
import { $list, reloadSitesList, fetchSitesListFx } from '../../model/list'

export const SiteListPage = () => {
  const { t } = useTranslation('site-creation')
  const list = useStore($list)
  const accountInfo = useStore($accountInfo)
  const isPending = useStore(fetchSitesListFx.pending)

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    693: 2,
    500: 1,
  }

  React.useEffect(() => {
    reloadSitesList()
  }, [])

  return (
    <>
      <Wrap>
        <Row>
          <Col md="270px" xs="100%">
            <PrimaryButton
              outline
              type="white"
              appendIcon="plus"
              disabled={isPending || list.length >= (accountInfo?.tariff?.maxSites || 0)}
              onClick={showModal}
            >
              {t('addNewSite')}
            </PrimaryButton>
          </Col>
        </Row>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {list.map((site) => (
            <SiteCard key={site.id} site={site} />
          ))}
        </Masonry>
      </Wrap>
      <NewSite />
      <NewSurface />
      <NewLocality />
      <SiteInfo />
      <MasonryStyles />
    </>
  )
}

const Wrap = styled.span`
  margin-bottom: 35px;
  width: 100%;
`

const MasonryStyles = createGlobalStyle`
  .my-masonry-grid {
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
`
