import { createDomain, attach } from 'effector'
import { InsuranceContract } from '@/dal/contracts.interfaces'
import { getInsuranceContractReqFx } from '@/dal/contracts'

const d = createDomain('d')

export const $activeTab = d.createStore<string>('general')
export const $insuranceContract = d.createStore<InsuranceContract|null>(null)

export const setTab = d.createEvent<string>()
export const loadContract = d.createEvent<string>()
export const setContract = d.createEvent<InsuranceContract>()

export const getInsuranceContractFx = attach({ effect: getInsuranceContractReqFx })
