import * as React from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconName } from '@/ui/icons'
import { themeVar, useTheme, ThemedStyledProps } from '../theming'

export type Props = {
  error?: boolean;
  errorText?: string;
  accent?: boolean;
  prependIcon?: IconName;
  appendIcon?: IconName;
  innerIcon?: boolean;
  onPrependIconClick?: () => void;
  onAppendIconClick?: () => void;
  onChangeValue?: (val: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>

export const InputField = ({
  error,
  errorText,
  accent,
  prependIcon,
  appendIcon,
  innerIcon,
  onChangeValue,
  onPrependIconClick,
  onAppendIconClick,
  ...inputProps
}: Props) => {
  const hasError = error || Boolean(errorText)
  const theme = useTheme()

  const iconColors: {[key: string]: string} = {
    primary: theme.inputIconColor,
    warning: theme.inputErrorColor,
  }

  const iconColor = hasError ? iconColors.warning : iconColors.primary

  return (
    <Wrap
      accent={accent}
      hasError={hasError}
      disabled={inputProps.disabled}
    >
      {prependIcon && (
        <IconWrap
          innerIcon={innerIcon}
          pointer={Boolean(onPrependIconClick)}
          onClick={() => { if (onPrependIconClick) onPrependIconClick() }}
        >
          <Icon
            icon={prependIcon}
            fill={iconColor}
            width={15}
          />
        </IconWrap>
      )}
      <StyledInput
        innerIcon={innerIcon}
        appendIcon={Boolean(appendIcon)}
        prependIcon={Boolean(prependIcon)}
        onChange={(e) => (onChangeValue ? onChangeValue(e.currentTarget.value) : {})}
        {...inputProps}
      />

      {appendIcon && (
        <IconWrap
          innerIcon={innerIcon}
          pointer={Boolean(onAppendIconClick)}
          onClick={() => { if (onAppendIconClick) onAppendIconClick() }}
        >
          <Icon
            icon={appendIcon}
            fill={iconColor}
            width={15}
          />
        </IconWrap>
      )}
      {hasError && (
        <ErrorText>{errorText}</ErrorText>
      )}
    </Wrap>
  )
}

type StyledInputWrapProps = {
  hasError?: boolean;
  disabled?: boolean;
  accent?: boolean;
} & ThemedStyledProps
const Wrap = styled.div<StyledInputWrapProps>`
  position: relative;
  width: 100%;
  height: 35px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  font-family: ${themeVar('baseFont')};
  outline: none !important;
  background: white;
  ${({ hasError, theme }) => hasError && css`
    border: 1px solid ${theme.inputErrorColor} !important;
  `}
  ${({ hasError, theme }) => !hasError && css`
    border: 1px solid ${theme.inputBorderColor} !important;
  `}
  ${({ accent, theme }) => accent && css`
    border: 1px solid ${theme.inputIconColor} !important;
  `}
  ${({ disabled }) => disabled && css`
    border: none !important;
    background-image: linear-gradient(-60deg,
      ${themeVar('brandGrayPale2xColor')} 0px,
      ${themeVar('brandGrayPale2xColor')} 15px,
      ${themeVar('brandGrayPale3xColor')} 15px,
      ${themeVar('brandGrayPale3xColor')} 40px,
      ${themeVar('brandGrayPale2xColor')} 40px,
      ${themeVar('brandGrayPale2xColor')} 55px
      );
    background-repeat: repeat-x;
    background-size: 61px;
    })
  `}
`


type StyledInputProps = {
  innerIcon?: boolean;
  prependIcon?: boolean;
  appendIcon?: boolean;
} & ThemedStyledProps

const StyledInput = styled.input<StyledInputProps>`
  padding-left: 12px;
  padding-right: 12px;
  font-family: ${themeVar('baseFont')};
  font-size: 16px;
  height: 33px;
  color: ${themeVar('textColor')};
  border: none;
  border-radius: 4px;
  margin: auto;
  width: 100%;
  background: transparent;
  &:focus{
    border: none;
    outline: none
  }
  ${({ innerIcon, appendIcon }) => Boolean(innerIcon && appendIcon) && css`
    padding-right: 35px;
  `}
  ${({ innerIcon, prependIcon }) => Boolean(innerIcon && prependIcon) && css`
    padding-left: 35px;
  `}
  ${({ disabled }) => disabled && css`
    color: ${themeVar('whiteOpacity')};
  `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
  -moz-appearance: textfield;
`

type StyledIconWrapProps = {
  innerIcon?: boolean;
  pointer?: boolean;
} & ThemedStyledProps

const IconWrap = styled.div<StyledIconWrapProps>`
    margin-right: 10px;
    display: flex;
    align-items: center;
    pointer-events: none;
    ${({ innerIcon }) => innerIcon && css`
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      ${StyledInput} + & {
        left: auto;
        right: 0;
      }
    `}
    ${({ pointer }) => pointer && css`
      cursor: pointer;
      pointer-events: all;
    `}

`

const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
  width: 100%;
`
