import * as React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import {
  InputField,
  PhoneInput,
  Col,
  Row,
  SubTitle,
  AccentText,
  PrimaryButton,
  SmallText,
} from '@/ui'
import { showConfirmModal } from '@/features/app'
import { history } from '@/lib/history'

import {
  mounted,
  reset,
  form,
  useUserData,
  createCompanyFx,
} from '../../model/create'
import { PhoneNumbers } from '../parts'


export const CompanyCreateForm = () => {
  const { t } = useTranslation('company/main')
  const { fields, hasError, submit } = useForm(form)

  const isSaving = useStore(createCompanyFx.pending)

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  const setUserData = () => {
    showConfirmModal({
      title: t('useProfileDataTitle'),
      text: t('useProfileDataText'),
      action: () => useUserData(),
    })
  }

  const back = () => {
    history.push('/owner-registration/tariff')
  }

  React.useEffect(() => {
    mounted()
  }, [])

  return (
    <Card onSubmit={submitForm}>
      <Row align="flex-start">
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                1.
                {' '}
                {t('companyName')}
                <AccentText>*</AccentText>
              </SubTitle>
            </Col>
            <InputField
              value={fields.name.value}
              onChangeValue={fields.name.onChange}
              errorText={fields.name.errorText({
                required: t('requiredFiled'),
              })}
              placeholder={t('companyName')}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                2.
                {' '}
                {t('companyDescription')}
                <AccentText>*</AccentText>
              </SubTitle>
            </Col>
            <InputField
              value={fields.description.value}
              onChangeValue={fields.description.onChange}
              errorText={fields.description.errorText({
                required: t('requiredFiled'),
              })}
              placeholder={t('companyDescription')}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                3.
                {' '}
                {t('companyCode')}
                <AccentText>*</AccentText>
              </SubTitle>
            </Col>
            <InputField
              value={fields.code.value}
              placeholder={t('companyCode')}
              errorText={fields.code.errorText({
                required: t('requiredFiled'),
              })}
              onChangeValue={fields.code.onChange}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                4.
                {' '}
                {t('webSite')}
              </SubTitle>
            </Col>
            <InputField
              value={fields.webSite.value}
              placeholder={t('webSite')}
              errorText={fields.webSite.errorText({
                isURL: t('invalidURL'),
              })}
              onChangeValue={fields.webSite.onChange}
            />
          </Row>
        </Col>
      </Row>
      <Row align="flex-start" colorized>
        <BlockTitle>
          <SubTitle>
            <AccentText>
              {t('officeContactsTitle')}
            </AccentText>
          </SubTitle>
        </BlockTitle>
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                5.
                {' '}
                {t('address')}
                <AccentText>*</AccentText>
              </SubTitle>
            </Col>
            <InputField
              value={fields.address.value}
              onChangeValue={fields.address.onChange}
              errorText={fields.address.errorText({
                required: t('requiredFiled'),
              })}
              placeholder={t('address')}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                6.
                {' '}
                {t('email')}
                <AccentText>*</AccentText>
              </SubTitle>
            </Col>
            <InputField
              value={fields.email.value}
              onChangeValue={fields.email.onChange}
              errorText={fields.email.errorText({
                required: t('requiredFiled'),
              })}
              placeholder={t('email')}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                7.
                {' '}
                {t('fax')}
              </SubTitle>
            </Col>
            <InputField
              value={fields.fax.value}
              onChangeValue={fields.fax.onChange}
              placeholder={t('fax')}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <FieldTitle>
                <SubTitle>
                  8.
                  {' '}
                  {t('phoneNumbers')}
                  <AccentText>*</AccentText>
                </SubTitle>
                <AccentText>
                  <SmallText>
                    {t('default')}
                  </SmallText>
                </AccentText>
              </FieldTitle>
            </Col>
            <PhoneInput
              accent
              value={fields.defaultPhone.value}
              errorText={fields.defaultPhone.errorText({
                required: t('requiredFiled'),
              })}
              placeholder={t('phoneNumber')}
              onChangeValue={fields.defaultPhone.onChange}
            />
          </Row>
        </Col>
        <PhoneNumbers
          phones={fields.phones.value}
          onChange={fields.phones.onChange}
        />
      </Row>
      <Row>
        <Col xs="100%" md="130px">
          <PrimaryButton
            fluid
            outline
            onClick={back}
            prependIcon="leftChevron"
          >
            {t('back')}
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="165px">
          <PrimaryButton
            fluid
            outline
            onClick={setUserData}
            appendIcon="plus"
            disabled={isSaving}
          >
            {t('useMyData')}
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="130px">
          <PrimaryButton
            fluid
            outline
            type="warning"
            appendIcon="close"
            onClick={reset}
          >
            {t('clear')}
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="130px">
          <PrimaryButton
            fluid
            htmlType="submit"
            appendIcon="rightChevron"
          >
            {t('submit')}
          </PrimaryButton>
        </Col>
      </Row>
    </Card>
  )
}

const Card = styled.form`
    width: 100%;
    margin: 25px 0;
    background: white;
    border-radius: 8px;
    padding: 0 40px 20px;
    overflow-x: hidden;
    box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28);
`

const BlockTitle = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const FieldTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
