import * as React from 'react'
import { useStore } from 'effector-react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtil from '@date-io/dayjs'
import { Router } from 'react-router'

import {
  ThemeProvider, NormalizeCss, Fonts, BlurApp,
} from '@/ui'
import { Routes } from '@/lib/declarative-routing'
import { history } from '@/lib/history'
import { $isAuth, $isAuthStateReady } from '@/features/app'

import { routerConfig } from './router-config'
import { MainLayout } from './features/app'
import { Header } from './features/header'
import { MainMenu } from './features/main-menu'
import { AutorizationLayer } from './features/autorization'
import { PreRegistrationLayer } from './features/pre-registration'
import { NewsModalLayer } from './features/news-modal'

export const App = () => {
  const isAppReady = useStore($isAuthStateReady)
  const isAuth = useStore($isAuth)

  if (!isAppReady) {
    return (<div>...loading</div>)
  }

  return (
    <React.Suspense fallback="loading...">
      <MuiPickersUtilsProvider utils={DayJsUtil}>
        <Fonts />
        <NormalizeCss />
        <ThemeProvider>
          <BlurApp>
            <Router history={history}>
              <MainLayout
                header={(
                  <Header />
              )}
              >
                <>
                  <MainMenu />
                  <AutorizationLayer />
                  <PreRegistrationLayer />
                  <NewsModalLayer />
                  <Routes isAuth={isAuth} config={routerConfig} />
                </>
              </MainLayout>
            </Router>
          </BlurApp>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </React.Suspense>
  )
}
