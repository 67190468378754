import { reservation } from './domain'

export const send = reservation.event()
export const hideSendedModal = reservation.event()
export const showInfoDialog = reservation.event()
export const hideInfoDialog = reservation.event()
export const showValidateDialog = reservation.event()
export const hideValidateDialog = reservation.event()
export const validate = reservation.event()
export const hideValidatedDialog = reservation.event()
export const showFilterDialog = reservation.event()
export const hideFilterDialog = reservation.event()
export const filter = reservation.event()
