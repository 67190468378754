import { createDomain } from 'effector'


export const autorization = createDomain('autorization')

export const $isAutorizationDialogShown = autorization.store<boolean>(false)
export const $isChangePasswordDialogShown = autorization.store<boolean>(false)

export const $isThanksModalShown = autorization.store<boolean>(false)
export const $isThanksModalText = autorization.store<string>('')
export const $isNewPasswordDialogShown = autorization.store<boolean>(false)
export const $isSignUpDialogShown = autorization.store<boolean>(false)
export const $isVerificationEmailSuccess = autorization.store<boolean>(false)


export const showAutorizationDialog = autorization.event()
export const hideAutorizationDialog = autorization.event()
export const showChangePasswordDialog = autorization.event()
export const hideChangePasswordDialog = autorization.event()
export const sendChangePasswordMailDialog = autorization.event()
export const showThanksModal = autorization.event<string>('')
export const hideThanksModal = autorization.event()
export const showNewPasswordDialog = autorization.event()
export const hideNewPasswordDialog = autorization.event()
export const hideSignUpDialog = autorization.event()
export const showSignUpDialog = autorization.event()
export const hideVerificationSuccess = autorization.event()
export const showVerificationSuccess = autorization.event()


export const logout = autorization.event<void>()
