import { forward, sample } from 'effector'
import { condition } from 'patronum'

import {
  CreateInsuranceContractParams,
  EditInsuranceContractParams,
  InsuranceContract,
} from '@/dal/contracts.interfaces'
import { convertToEuroCentsCurrency, convertToEuroCurrency } from '@/lib/field-handlers'

import {
  GeneralForm,
  createInsuranceContractFx,
  createContract,
  updateContract,
  editInsuranceContractFx,
  counterpartyAutocomplete,
} from './unit'
import {
  getInsuranceContractFx, $insuranceContract, setTab, setContract,
} from '../main'
import { $selectedObjectsForBackend } from '../rentalObjects/unit'

forward({
  from: getInsuranceContractFx.doneData.map(({ insuranceContract: x, counterpartyWithContact }) => ({
    ...x,
    conterparty: counterpartyWithContact?.conterparty?.id || '',
    guaranteedCapital: x.guaranteedCapital ? convertToEuroCurrency(x.guaranteedCapital) : '',
    termsYears: `${x.termsYears || ''}`,
    termsMonths: `${x.termsMonths || ''}`,
    terminationNoticePeriodMonths: `${x.terminationNoticePeriodMonths || ''}`,
    terminationNoticePeriodYears: `${x.terminationNoticePeriodYears || ''}`,
  })),
  to: GeneralForm.set,
})

forward({
  from: getInsuranceContractFx.fail,
  to: GeneralForm.reset,
})

condition({
  source: GeneralForm.formValidated,
  if: $insuranceContract.map((x) => x === null),
  then: createContract,
  else: updateContract,
})


// counterparty autocomplete
getInsuranceContractFx.doneData.watch((x) => {
  counterpartyAutocomplete.onChange(x.counterpartyWithContact)
})

forward({
  from: counterpartyAutocomplete.onChange.map((x) => (x ? x.conterparty.id : '')),
  to: GeneralForm.fields.conterparty.onChange,
})


sample({
  source: [GeneralForm.$values, $selectedObjectsForBackend],
  clock: createContract,
  target: createInsuranceContractFx,
  fn: ([form, selectedObjects]): CreateInsuranceContractParams => {
    const resultData: CreateInsuranceContractParams = {
      ...form,
      guaranteedCapital: form.guaranteedCapital ? convertToEuroCentsCurrency(form.guaranteedCapital) : undefined,
      termsYears: +form.termsYears || undefined,
      termsMonths: +form.termsMonths || undefined,
      terminationNoticePeriodMonths: +form.terminationNoticePeriodMonths || undefined,
      terminationNoticePeriodYears: +form.terminationNoticePeriodYears || undefined,
      objectsSites: selectedObjects.sites,
      objectsSurfaces: selectedObjects.surfaces,
      objectsLocalities: selectedObjects.localities,
    }
    return { ...resultData }
  },
})

sample({
  source: [GeneralForm.$values, $insuranceContract, $selectedObjectsForBackend],
  clock: updateContract,
  target: editInsuranceContractFx,
  fn: ([form, contract, selectedObjects]): EditInsuranceContractParams => {
    const resultData: EditInsuranceContractParams['data'] = {
      ...form,
      guaranteedCapital: form.guaranteedCapital ? convertToEuroCentsCurrency(form.guaranteedCapital) : undefined,
      termsYears: +form.termsYears || undefined,
      termsMonths: +form.termsMonths || undefined,
      terminationNoticePeriodMonths: +form.terminationNoticePeriodMonths || undefined,
      terminationNoticePeriodYears: +form.terminationNoticePeriodYears || undefined,
      objectsSites: selectedObjects.sites,
      objectsSurfaces: selectedObjects.surfaces,
      objectsLocalities: selectedObjects.localities,
    }
    return { id: (contract as InsuranceContract).id, data: resultData }
  },
})

forward({
  from: [
    editInsuranceContractFx.done.map((_) => 'documents'),
    createInsuranceContractFx.done.map((_) => 'documents'),
  ],
  to: setTab,
})

forward({
  from: createInsuranceContractFx.doneData,
  to: setContract,
})
