import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../../theming'

type Props = {
  notFluid?: boolean;
  align?: 'left' | 'right' | 'center';
} & ThemedStyledProps

export const Link = styled.a<Props>`
  color: ${themeVar('brandOrangeColor')};
  text-decoration: underline;
  text-align: ${({ align = 'left' }) => align};
  ${({ notFluid }) => !notFluid && css`
    width: 100%;
  `}
`
