import { forward, sample } from 'effector'

import { convertToEuroCentsCurrency } from '@/lib/field-handlers'
import { TenantLease } from '@/dal/contracts.interfaces'

import { getTenentLeaseFx, $tenantContract } from '../main'
import {
  $list,
  ContractClausesForm,
  addItem,
  attachItemToTenantLeaseFx,
  createItemFx,
  deleteItem,
  deleteItemFx,
  detachItemFromTenantLeaseFx,
  dropItem,
  loadList,
} from './unit'

$list
  .on(loadList, (_, r) => r)
  .on(addItem, (_, r) => [..._, r])
  .on(dropItem, (_, r) => _.filter((el) => el.id !== r))

forward({
  from: getTenentLeaseFx.doneData.map((r) => r.tenantLease.clauses),
  to: loadList,
})

sample({
  clock: ContractClausesForm.formValidated,
  source: ContractClausesForm.$values,
  fn: (form) => ({
    ...form,
    amount: form.amount ? convertToEuroCentsCurrency(form.amount) : undefined,
    share: form.share ? parseFloat(form.share) : undefined,
  }),
  target: createItemFx,
})

forward({
  from: createItemFx.doneData,
  to: addItem,
})

sample({
  clock: createItemFx.doneData,
  source: $tenantContract,
  fn: (contract, period) => ({
    id: contract?.id as string,
    itemsID: [period.id],
  }),
  target: attachItemToTenantLeaseFx,
})

forward({
  from: attachItemToTenantLeaseFx.done,
  to: ContractClausesForm.reset,
})

sample({
  clock: deleteItem,
  source: $tenantContract,
  fn: (contract, i) => ({ id: (contract as TenantLease).id, items: [i.id] }),
  target: detachItemFromTenantLeaseFx,
})

forward({
  from: [
    detachItemFromTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0] : '')),
    attachItemToTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0]?.id : '')),
    detachItemFromTenantLeaseFx.done.map((r) => (r.params.items ? r.params.items[0] : '')),
  ],
  to: dropItem,
})


forward({
  from: deleteItem.map((i) => ({ id: i.id })),
  to: deleteItemFx,
})
