import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  PrimaryButton,
  Visible,
} from '@/ui'
import { getMock } from '@/features/app'
import { setUserStep, userCompleteStep, showSiteSearchModal } from '../../../model'
import { AccessBySiteTable, AccessBySiteCard } from '../../parts'
import { SiteSearchModal } from './SiteSearchModal'

export const Access = () => {
  const { accessBySite } = getMock().newEmloyee
  const completeForm = () => {
    userCompleteStep('access')
  }

  const siteList = accessBySite.map((item) => (
    <AccessBySiteCard item={item} />
  ))

  return (
    <Wrap>
      <>

        <Row>
          <Col xs="100%" md="180px">
            <PrimaryButton appendIcon="plus" onClick={() => showSiteSearchModal()}>
              add new site
            </PrimaryButton>
          </Col>
        </Row>
        <Visible tablet>
          <Row>
            <AccessBySiteTable items={accessBySite} />
          </Row>
        </Visible>
        <Visible mobile>
          {siteList}
        </Visible>

        <Row>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => setUserStep('user')}
            >
              back
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="250px">
            <PrimaryButton
              fluid
              outline
              type="warning"
              appendIcon="close"
            >
              discard changes
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              appendIcon="rightChevron"
              onClick={() => completeForm()}
            >
              next step
            </PrimaryButton>
          </Col>
        </Row>
        <SiteSearchModal />
      </>
    </Wrap>
  )
}


const Wrap = styled.div`
    padding: 20px 0px 20px 0px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 20px;
    `}
`
