import { createDomain } from 'effector'
import { createForm } from 'effector-forms'
import {
  getMyNotificationSettingsReqFx,
  setMyNotificationSettingsReqFx,
  NotificationType,
} from '@/dal'

export const d = createDomain()

export const getUserNotificationSettingsFx = getMyNotificationSettingsReqFx
export const setUserNotificationSettingsFx = setMyNotificationSettingsReqFx


export const userNotificationSettingsForm = createForm({
  domain: d,
  fields: {
    interventionDemands: {
      init: [] as NotificationType[],
    },
    consultations: {
      init: [] as NotificationType[],
    },
    orders: {
      init: [] as NotificationType[],
    },
  },
})
