import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateSecurityDepositRes } from '@/dal/contracts.interfaces'
import {
  createSecurityDepositReqFx,
  deleteSecurityDepositReqFx,
  attachSecurityDepositToTenantLeaseReqFx,
  detachSecurityDepositFromTenantLeaseReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $depositList = d.createStore<CreateSecurityDepositRes[]>([])

export const loadDeposit = d.createEvent<CreateSecurityDepositRes[]>()
export const addDeposit = d.createEvent<CreateSecurityDepositRes>()
export const deleteDeposit = d.createEvent<CreateSecurityDepositRes>()
export const dropDeposit = d.createEvent<CreateSecurityDepositRes['id']>()

export const DepositForm = createForm({
  domain: d,
  fields: {
    date: {
      init: '',
      rules: [rules.required()],
    },
    amount: {
      init: '' as string,
      rules: [rules.required()],
    },
    amountRent: {
      init: null as null | 1 | 2 | 3 | 6,
    },
    indexedDeposit: {
      init: true,
    },
    lastReviewDate: {
      init: '',
    },
    typeOfGuarantee: {
      init: '',
    },
    remarks: {
      init: '',
    },
  },
})

export const createSecurityDepositFx = attach({ effect: createSecurityDepositReqFx })
export const attachSecurityDepositToTenantLeaseFx = attach({ effect: attachSecurityDepositToTenantLeaseReqFx })
export const deleteSecurityDepositFx = attach({ effect: deleteSecurityDepositReqFx })
export const detachSecurityDepositFromTenantLeaseFx = attach({ effect: detachSecurityDepositFromTenantLeaseReqFx })
