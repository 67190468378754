import * as React from 'react'
import {
  Filter, Row, Col, SortHeader, InputField,
} from '@/ui'

import styled, { css } from 'styled-components'

export const MobileFilter = () => {
  const [search, setSearch] = React.useState<string>('')
  const [tmpSortBy, setTmpSortBy] = React.useState<string | null>('number')
  const [tmpSortDirection, setTmpSortDirection] = React.useState<'ASC'|'DESC'>('ASC')
  const setSort = (name: string) => {
    if (tmpSortBy !== name) {
      setTmpSortBy(name)
      setTmpSortDirection('ASC')
      return
    }
    if (tmpSortDirection === 'ASC') {
      setTmpSortDirection('DESC')
      return
    }
    setTmpSortBy(null)
  }

  return (
    <Wrap>
      <Filter count={36}>
        <>
          <Col width={1} noPadding vAlign="flex-start" align="center">
            <SortWrap>
              <SortHeader
                direction={tmpSortBy === 'group' ? tmpSortDirection : undefined}
                onChange={() => setSort('group')}
              >
                Group name
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'module' ? tmpSortDirection : undefined}
                onChange={() => setSort('module')}
              >
                Module
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'description' ? tmpSortDirection : undefined}
                onChange={() => setSort('description')}
              >
                Description
              </SortHeader>
            </SortWrap>
          </Col>
          <Col width="100%" noPadding noMargin>
            <InputField
              innerIcon
              appendIcon="lense"
              value={search}
              onChangeValue={setSearch}
            />
          </Col>
        </>
      </Filter>
      <Row>
        <Col width={1} noMargin noPadding>
          <ListActions noMargin>
            • Select all
          </ListActions>
        </Col>
        <Col width={1} noMargin noPadding>
          <ListActions>
            ○ Deselect all
          </ListActions>
        </Col>
        <Col width={1} noMargin noPadding>
          <ListActions>
            ☓ Delete selected
          </ListActions>
        </Col>
      </Row>
    </Wrap>
  )
}

const SortWrap = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: column;
justify-content: space-between;
padding-bottom: 6px;
align-items: flex-start;
`

const ListActions = styled.button<{noMargin?: boolean}>`
  border: none;
  width:100%;
  display: flex;
  background: transparent;
  color: white;
  text-align: center;
  justify-content: center;
  ${({ noMargin }) => !noMargin && css`
    margin-left: 1px;
  `}
  padding: 4px;
  outline: none;
  cursor: pointer;
`

const Wrap = styled.div`
	margin-bottom: 15px;
`
