import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { resetPasswordReqFx } from '@/dal'
import { rules } from '@/lib/validators'
import { CustomError } from '@/lib/errors'

const resetPasswordDomain = createDomain()

export const resetPasswordForm = createForm({
  domain: resetPasswordDomain,
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.isEmail()],
    },
  },
})

export const $serverError = resetPasswordDomain.store<CustomError | null>(null)

export const setServerError = resetPasswordDomain.event<CustomError>()
export const resetServerError = resetPasswordDomain.event<void>()
export const closePasswordResetModal = resetPasswordDomain.event<void>()

export const resetPasswordFx = attach({ effect: resetPasswordReqFx })
