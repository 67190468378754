
import { quickUUID } from '@/lib/random'
import { attachWrapper } from '@/lib/effector-extra'
import {
  Method, requestFx,
} from './rest-api'
import {
  WithFilters,
  PaginationResult,
  PaginationParams,
  Title,
  UserSettings,
} from './common.interfaces'
import {
  EditUserNotificationParams,
  SetUserSettingsParams,
  UserNotification,
} from './user-settings.interfaces'

export const titleMock = (): Title => ({
  id: quickUUID(),
  language: 'en',
  name: 'Mr.',
})

export const titleListMock = (): PaginationResult<Title> => ({
  count: 35,
  items: new Array(10).fill(titleMock()),
})

/** @deprecated */
export const getUsersSettingsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: (WithFilters & PaginationParams) | PaginationParams) => ({
    method: Method.post,
    url: '/user/list',
    body: p,
  }),
  mapResult: ({ params, result }): PaginationResult<UserSettings> => result.data,
  mapError: ({ error }) => new Error(),
})

/** @deprecated */
export const getUserSettingsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/user/get',
  }),
  mapResult: ({ result }): UserSettings => result.data,
  mapError: () => new Error(),
})

/** @deprecated */
export const setUserSettingsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (userSetting: SetUserSettingsParams) => ({
    method: Method.post,
    url: '/user/edit',
    body: userSetting,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: () => new Error(),
})


export const getMyNotificationSettingsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/notification/getMy',
  }),
  mapResult: ({ result }): UserNotification => result.data,
  mapError: () => new Error(),
})

export const setMyNotificationSettingsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: EditUserNotificationParams) => ({
    method: Method.post,
    url: '/notification/editOrAddMy',
    body: p,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: () => new Error(),
})


export const getTitleListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (params: WithFilters) => ({
    method: Method.post,
    url: '/title/list',
    body: {
      offset: 0,
      limit: 9999,
      ...params,
    },
  }),
  mapResult: ({ result }): PaginationResult<Title> => result.data,
  mapError: () => new Error(),
})
