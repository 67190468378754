import { sample, forward } from 'effector'
import {
  showModal,
  $isShowModal,
  hideModal,
  passwordForm,
  setNewPasswordFx,
  $serverError,
  resetServerError,
  setServerError,
} from './units'

$isShowModal
  .on(showModal, () => true)
  .reset(hideModal)

$serverError
  .on(setServerError, (_, error) => error)
  .reset(resetServerError)

forward({
  from: hideModal,
  to: [passwordForm.reset, resetServerError],
})

forward({
  from: setNewPasswordFx.failData,
  to: setServerError,
})

sample({
  source: passwordForm.$values,
  clock: passwordForm.formValidated,
  fn: ({ password, oldPassword }) => ({ newPassword: password, oldPassword }),
  target: setNewPasswordFx,
})
