import * as React from 'react'
import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'
import { AccentText, SmallText } from './typo'

type Props = {
  fluid?: boolean;
  errorText?: string;
  onChangeValue: (val: string) => void;
} & React.InputHTMLAttributes<HTMLTextAreaElement>

export const InputTextArea = ({
  fluid, errorText, onChangeValue, ...inputProps
}: Props) => {
  const hasError = Boolean(errorText)

  return (
    <Wrap fluid={Boolean(fluid)}>
      <StyledInput
        hasError={hasError}
        onChange={(e) => onChangeValue(e.currentTarget.value)}
        {...inputProps}
      />
      <ErrorArea>
        {hasError && (
        <ErrorText>{errorText}</ErrorText>
        )}
        {inputProps.maxLength && (
        <AccentText>
          <SmallText>
            Up to
            {' '}
            {inputProps.value?.toString().length}
            /
            {inputProps.maxLength}
            {' '}
            symbols
          </SmallText>
        </AccentText>
        )}
      </ErrorArea>
    </Wrap>
  )
}

const Wrap = styled.div<{fluid: boolean}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${({ fluid }) => fluid && css`
    height: 100%;
  `};
  font-family: ${themeVar('baseFont')};
`

type StyledInputProps = {
  hasError?: boolean;
} & ThemedStyledProps

const StyledInput = styled.textarea<StyledInputProps>`
  padding: 12px;
  margin-bottom: 4px;
  font-family: ${themeVar('baseFont')};
  font-size: 16px;
  color: ${themeVar('textColor')};
  min-height: 73px;
  max-height: 250px;
  width: 100%;
  resize: vertical;
  border-radius: 4px;
  outline: none !important;
  ${({ hasError, theme }) => hasError && css`
    border: 1px solid ${theme.inputErrorColor} !important;
  `};
  ${({ hasError, theme }) => !hasError && css`
    border: 1px solid ${theme.inputBorderColor} !important;
  `};
`

const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
`

const ErrorArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
