import { forward, sample, combine } from 'effector'

import { history } from '@/lib/history'
import { $lang } from '@/features/app'

import { Language } from '@/dal/common.interfaces'
import {
  resetPasswordForm,
  $serverError,
  setServerError,
  resetServerError,
  closePasswordResetModal,
  resetPasswordFx,
} from './units'

$serverError
  .on(setServerError, (_, error) => error)
  .reset(resetServerError)

const a = sample({
  source: resetPasswordForm.$values,
  /*   combine(
    resetPasswordForm.$values,
    $lang,
    (form, lang) => ({
      email: form.email,
      language: lang,
    }),
  ), */
  clock: resetPasswordForm.formValidated,
  target: resetPasswordFx,
})

forward({
  from: resetPasswordFx.failData,
  to: setServerError,
})

resetPasswordFx.done.watch(() => {
  closePasswordResetModal()
  resetServerError()
  resetPasswordForm.reset()
})
