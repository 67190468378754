import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PrimaryButton,
  Row,
  Col,
  Visible,
  Icon,
  Heading,
  ThemedStyledProps,
} from '@/ui'
import { logout } from '@/features/autorization'

export const FirstBlock = () => {
  const { t } = useTranslation('start-page')
  const [titleText, setTitleText] = React.useState<string>('Site')

  return (
    <Wrap>
      {/* <H>Site</H> */}
      <Row>
        <ButtonsCol xs="100%" md="40%" noMargin>
          <>
            <ButtonWrapLink to="/site">
              <PrimaryButton
                fluid
                size="extra large"
                outline
                appendIcon="rightChevron"
                prependIcon="Site"
                onHover={() => { setTitleText(t('site')) }}
              >
                {t('site')}
              </PrimaryButton>
            </ButtonWrapLink>
            <ButtonWrapLink to="/demands">
              <PrimaryButton
                fluid
                size="extra large"
                outline
                appendIcon="rightChevron"
                prependIcon="Demands"
                onHover={() => { setTitleText(t('demands')) }}
              >
                {t('demands')}
              </PrimaryButton>
            </ButtonWrapLink>
            {/* <ButtonWrapLink to="/orders"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="Orders" */}
            {/*     onHover={() => { setTitleText(t('orders')) }} */}
            {/*   > */}
            {/*     {t('orders')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
            {/* <ButtonWrapLink to="/consultations"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="consultation" */}
            {/*     onHover={() => { setTitleText(t('consultations')) }} */}
            {/*   > */}
            {/*     {t('consultations')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
            {/* <ButtonWrapLink to="/reservations"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="consultation" */}
            {/*     onHover={() => { setTitleText(t('reservations')) }} */}
            {/*   > */}
            {/*     {t('reservations')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
            {/* <ButtonWrapLink to="/pre-registration"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="consultation" */}
            {/*     onHover={() => { setTitleText(t('pre-registration')) }} */}
            {/*   > */}
            {/*     {t('pre-registration')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
            {/* <ButtonWrapLink to="/manage"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="Manage" */}
            {/*     onHover={() => { setTitleText(t('manage')) }} */}
            {/*   > */}
            {/*     {t('manage')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
            {/* <ButtonWrapLink to="/faq"> */}
            {/*   <PrimaryButton */}
            {/*     fluid */}
            {/*     size="extra large" */}
            {/*     outline */}
            {/*     appendIcon="rightChevron" */}
            {/*     prependIcon="faq" */}
            {/*     onHover={() => { setTitleText(t('faq')) }} */}
            {/*   > */}
            {/*     {t('faq')} */}
            {/*   </PrimaryButton> */}
            {/* </ButtonWrapLink> */}
          </>
        </ButtonsCol>
        <Col xs="100%" md="60%" noMargin vAlign="flex-start">
          <Visible tablet fluid>
            <Col xs="100%" noMargin>
              <Row>
                <Title>
                  {titleText}
                </Title>
                <Text>
                  {t('text')}
                </Text>
                <Icon icon="character6" />
              </Row>
            </Col>
          </Visible>
        </Col>

      </Row>
      <BottomRow>
        <BottonButtonsCol xs="100%" md="40%" noMargin>
          <PrimaryButton
            fluid
            type="warning"
            size="extra large"
            prependIcon="logout"
            appendIcon="emptyIcon"
            outline
            onClick={logout}
          >
            {t('logOut')}
          </PrimaryButton>
        </BottonButtonsCol>
        <BottonButtonsCol xs="100%" md="40%" noMargin>
          <ButtonWrapLink
            woMargin
            to="/settings"
          >
            <PrimaryButton
              fluid
              type="gray"
              size="extra large"
              outline
              prependIcon="settings"
              appendIcon="rightChevron"
            >
              {t('settings')}
            </PrimaryButton>
          </ButtonWrapLink>
        </BottonButtonsCol>
      </BottomRow>
    </Wrap>
  )
}

const Wrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28); 
    margin-bottom: 40px;
    margin-top: 25px;
    padding: 20px; 
    ${breakpoint('mobile', 'tablet')`
      padding: 18px; 
      margin-bottom: 10px;
      margin-top: 20px;
    `}
    padding-top: 0;
`

const Map = styled.div`
  margin: 0 -20px 40px;
  width: calc(100% + 40px);
  ${breakpoint('mobile', 'tablet')`
    margin: -20px -18px 20px ;
    width: calc(100% + 36px);
  `}
  
  height: 260px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`

const ButtonsCol = styled(Col)`
  align-items: flex-start;
  align-content: flex-start;
  padding-right: 0 !important;
`

const ButtonWrapLink = styled(Link)<ThemedStyledProps & {woMargin?: boolean}>`
  display: block;
  width: 100%;
  margin-bottom: 15px;
  ${(p) => p.woMargin && css`
    margin-bottom: 0;
  `}
`

const BottonButtonsCol = styled(Col)`
  margin-top: 0 !important;
  margin-bottom: 15px;
  padding-right: 0 !important;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`

const H = styled(Heading)`
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
`
