import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../../theming'

type ColumnProps = {
  width?: number | string;
  xs?: number | string;
  md?: number | string;
  lg?: number | string;
  color?: 'warning' | 'gray' | 'success' | 'primary' | string;
  big?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  vAlign?: 'flex-start' | 'center' | 'flex-end';
  order?: number;
  name?: string;
  tab?: boolean;
  iconColor?: 'warning' | 'gray' | 'success' | 'primary' | string;
  children?: React.ReactChild;
  noPadding?: boolean;
  noMargin?: boolean;
  onClick?: () => void;
}


export const Col = ({
  name, children, onClick, ...props
}: ColumnProps) => (
  <StyledCol {...props} onClick={onClick}>
    {name && (
      <StyledName>{name}</StyledName>
    )}
    {children}
  </StyledCol>
)

type StyledColumnProps = {
  width?: number | string;
  xs?: number | string;
  md?: number | string;
  lg?: number | string;
  color?: 'warning' | 'gray' | 'success' | 'primary' | string;
  big?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  vAlign?: 'flex-start' | 'center' | 'flex-end';
  order?: number;
  tab?: boolean;
  iconColor?: 'warning' | 'gray' | 'success' | 'primary' | string;
  noPadding?: boolean;
  noMargin?: boolean;
} & ThemedStyledProps

function getColor(color: string) {
  switch (color) {
    case 'warning':
      return themeVar('dataTableColColorWarning')
    case 'gray':
      return themeVar('dataTableColColorGray')
    case 'success':
      return themeVar('dataTableColColorSuccess')
    case 'primary':
      return themeVar('dataTableColColorPrimary')
    default:
      break
  }
}
const StyledCol = styled.div<StyledColumnProps>`
width: 100%;
${({ width }) => (Number.isInteger(width) && css`
  flex: ${width};
  `) || css`
  width: ${width};
  `
}
${({ xs }) => (Number.isInteger(xs) && css`
    ${breakpoint('mobile')`
      flex: ${xs};
    `}
  `) || css`
    ${breakpoint('mobile')`
      width: ${xs};
    `}
  `
}
${({ md }) => (Number.isInteger(md) && css`
    ${breakpoint('tablet')`
      flex: ${md};
    `}
  `) || css`
    ${breakpoint('tablet')`
      width: ${md};
    `}
  `
}
${({ lg }) => (Number.isInteger(lg) && css`
    ${breakpoint('desktop')`
      flex: ${lg};
    `}
  `) || css`
    ${breakpoint('desktop')`
      width: ${lg};
    `}
  `
}


fill: ${themeVar('dataTableColColorPrimary')};
${({ color = '' }) => css`
  color: ${getColor(color)};
  fill: ${getColor(color)};
`}
${({ iconColor = '' }) => css`
  fill: ${getColor(iconColor)};
`}
${({ big }) => big && css`
    text-transform: uppercase;
    font-weight: 600;
`}
position: relative;
display: flex;
flex-wrap: wrap;
justify-content: ${({ align = 'center' }) => align};
align-items: ${({ vAlign = 'center' }) => vAlign};
${({ vAlign }) => vAlign === 'flex-start' && css`
  margin-bottom: auto;
`}
${({ order }) => order && css`
    order: ${order};
`}
${({ noPadding }) => noPadding && css`
    padding: 0 !important;
    margin: 0 !important;
`}

${({ tab, color = '' }) => tab && css`
    margin-left: -${themeVar('dataTableRowPaddingMobile')};
    padding: 10px 20px 10px ${themeVar('dataTableRowPaddingMobile')};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${themeVar('dataTableLabelColor')};
    fill: ${themeVar('dataTableLabelColor')};
    background: ${getColor(color)};
`}
&:not(:last-of-type){
    padding-right: 15px;
}
${({ noMargin }) => !noMargin && breakpoint('tablet')`
  &:first-child{
    margin-left: 22px;
  }
`}
${breakpoint('mobile', 'tablet')`
  margin-top: 15px;
  padding-right: 15px;
`}
`

const StyledName = styled.div`
  color: ${themeVar('dataTableColColorGray')};
  width: 100%;
  margin-bottom: 6px;
  ${breakpoint('mobile')`
    font-size: 10px;
    line-height: 10px;
  `}
`
