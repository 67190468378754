import * as React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'
import { breakpoint } from 'styled-components-breakpoint'

import { Surface } from '@/dal'
import {
  AccentText,
  Checkbox,
  themeVar,
} from '@/ui'

import { StyledSubTitle } from './components'
import { SurfaceWithParent } from '../../../model/rentalObjects'

interface Props {
  surface: SurfaceWithParent;
  checked: boolean;
  onClick: () => void;
}

export const SurfaceRow = (props: Props) => {
  const { surface, checked, onClick } = props

  return (
    <RowWrap>
      <Checkbox
        contrastMode
        checked={checked}
        onChange={() => onClick()}
      />

      <StyledSubTitle>
        {surface.name}
      </StyledSubTitle>

      {
        surface.areaSurface && (
          <StyledSubTitle>
            <AccentText>
              {surface.areaSurface}
              {' '}
              m
              <sup>2</sup>
            </AccentText>
          </StyledSubTitle>
        )
      }
    </RowWrap>
  )
}

const RowWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 61px;
  padding: 6px 34px 6px 44px;
  grid-column-gap: 20px;
  align-items: center;
  background: ${themeVar('brandOrangePale2xColor')};
  
  ${breakpoint('mobile', 'tablet')`
    padding: 11px 25px 11px 27px;
    grid-column-gap: 7px;
  `}
`
