import * as React from 'react'
import styled, { css } from 'styled-components'

import { SortOrder } from '@/lib/effector-sort'

import { Icon } from '../../icons'
import { themeVar, ThemedStyledProps } from '../../theming'

type SortProps = {
    direction?: SortOrder;
    onChange?: () => void;
    children: React.ReactChild;
  }


export const SortHeader = ({
  children, direction, onChange = () => {},
}: SortProps) => (
  <Sort onClick={() => onChange()}>
    {children}
    {direction && (
      <IconWrap sortDirection={direction === 'ASC'}>
        <Icon icon="sort" />
      </IconWrap>
    )}
  </Sort>
)

const Sort = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
`
const IconWrap = styled.div<{ sortDirection: boolean } & ThemedStyledProps>`
    ${({ sortDirection }) => sortDirection && css`
        transform: rotate3d(1,0,0, 180deg);
    `}
    transition: transform .3s;
    display: flex;
    align-items: center;
    margin-left: 5px;
    fill: ${themeVar('dataTableSortIconColor')};
`
