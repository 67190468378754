import { createDomain } from 'effector'
import {
  TenantLease, ServiceProviderContract, InsuranceContract,
} from '@/dal/contracts.interfaces'
import { getTenentLeaseListReqFx, getServiceProviderListReqFx, getInsuranceListReqFx } from '@/dal/contracts'
import { createPagination } from '@/lib/effector-pagination'
import { createFilters } from '@/lib/effector-filters'

const siteManageDomain = createDomain()

export const reset = siteManageDomain.event<void>()
export const mounted = siteManageDomain.event<void>()

export const $leasesList = siteManageDomain.store<TenantLease[]>([])
export const resetLeases = siteManageDomain.event<void>()

export const $serviceProviderList = siteManageDomain.store<ServiceProviderContract[]>([])
export const resetServiceProvider = siteManageDomain.event<void>()

export const $insuranceContractList = siteManageDomain.store<InsuranceContract[]>([])
export const resetInsurance = siteManageDomain.event<void>()

export const $showContractDetailsModal = siteManageDomain.store(false)
export const showContractDetailsModal = siteManageDomain.event<void>()
export const hideContractDetailsModal = siteManageDomain.event<void>()

export const $showLeasesDetailsModal = siteManageDomain.store(false)
export const showLeasesDetailsModal = siteManageDomain.event<void>()
export const hideLeasesDetailsModal = siteManageDomain.event<void>()

export const $selectedLeases = siteManageDomain.store<(TenantLease | null)>(null)

siteManageDomain.onCreateStore(($store) => {
  $store.reset(reset)
})

export const leases = createPagination({
  domain: siteManageDomain,
  effect: getTenentLeaseListReqFx,
  perPage: 10,
})

export const serviceProvider = createPagination({
  domain: siteManageDomain,
  effect: getServiceProviderListReqFx,
  perPage: 10,
})

export const insurance = createPagination({
  domain: siteManageDomain,
  effect: getInsuranceListReqFx,
  perPage: 10,
})
