import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'

type Props = {
  colorized?: boolean;
  orange?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  size?: 'small' | 'regular';
  smallText?: boolean;
} & ThemedStyledProps

export const Row = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  justify-content: ${({ align = 'center' }) => align};
  padding: ${({ size = 'regular' }) => (size === 'regular' ? '16px 0' : '4px 0')};
  ${({ smallText }) => smallText && css`
    font-size: 12px;
  `}
  ${({ colorized }) => colorized && css`
    z-index:1;
    &:after{
      position: absolute;
      width: 300%;
      top: 0;
      height: 100%;
      z-index: -1;
      left: -100%;
      background: ${themeVar('rowSecondBg')};
      content: '';
    }
    &:nth-child(2n+1){
      &:after{
        background: ${themeVar('rowMainbg')};
      }
    }
  `}
  ${({ colorized, orange }) => colorized && orange && css`
    & * {
      color: white;
    }
    &:after{
      background: transparent;
    }
    &:nth-child(2n+1){
      &:after{
        background: rgba(255,255,255, 0.3);
      }
    }
  `}
  
`
