import { attachWrapper } from '@/lib/effector-extra'
import { createCustomError } from '@/lib/errors'
import { requestFx, Method } from '@/dal/rest-api'
import { quickUUID } from '@/lib/random'

import { UnknownError, UserNotFound } from './common-errors'
import {
  CounterpartyProfile,
  FetchCounterpartyParams,
  FetchCounterpartyRes,
  FetchCounterpartyListParams,
  FetchCounterpartyListRes,
  CreateCounterpartyParams,
  CreateCounterpartyRes,
  CreateLocalCounterpartyContactParams,
  CreateLocalCounterpartyContactRes,
  CreateAllCounterpartyContactsParams,
  CreateAllCounterpartyContactsRes,
  FetchCounterpartyWithContactsListParams,
  FetchCounterpartyWithContactsListRes,
  FetchLocalCounterpartyContactListParams,
  FetchLocalCounterpartyContactListRes,
} from './counterparty.interfaces'

const USER_NOT_FOUND = 404
const CREATE_COUNTERPARTY_ERROR = 400
const EMAIL_ALREADY_EXISTS = 2011
const WRONG_DATA = 2012
const ALREADY_ADDED = 2013
const LOCAL_CONTACT_EXISTS = 2022

const EmailAlreadyExists = createCustomError('EmailAlreadyExists')
const WrongData = createCustomError('WrongData')
const AlreadyAdded = createCustomError('AlreadyAdded')
const LocalContactExists = createCustomError('LocalContactExists')

const counterpartyMock = (): CounterpartyProfile => ({
  id: quickUUID(),
  email: 'test@example.com',
  type: 'service-provider',
  companyName: 'Google inc',
  companyCode: '123321',
  contact: {
    address: 'moscow ul. lenina, 123',
    phones: ['111-222-33-44', '999-888-77-66'],
    defaultPhone: '111-222-33-44',
    fax: '999-888-77-66',
    firstName: 'Vasilij',
    lastName: 'Mashina',
    webSite: 'https://google.com',
  },
})

export const fetchCounterpartyReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCounterpartyParams) => ({
    method: Method.post,
    url: '/conterparty/get',
    body: p,
  }),
  mapResult: ({ result }): FetchCounterpartyRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === USER_NOT_FOUND) {
      return new UserNotFound()
    }
    return new UnknownError()
  },
})

export const fetchCounterpartyListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCounterpartyListParams) => ({
    method: Method.post,
    url: '/conterparty/list',
    body: p,
  }),
  mapResult: ({ result }): FetchCounterpartyListRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const createCounterpartyReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateCounterpartyParams) => ({
    method: Method.post,
    url: '/conterparty/add',
    body: p,
  }),
  mapResult: ({ result }): CreateCounterpartyRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === CREATE_COUNTERPARTY_ERROR) {
      switch (error.response?.data.code) {
        case ALREADY_ADDED:
          return new AlreadyAdded()
        case EMAIL_ALREADY_EXISTS:
          return new EmailAlreadyExists()
        case WRONG_DATA:
          return new WrongData(error.response?.data.message)
        default:
      }
    }
    return new UnknownError()
  },
})

export const createLocalCounterpartyContactReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateLocalCounterpartyContactParams) => ({
    method: Method.post,
    url: '/conterparty/create-local-contact',
    body: p,
  }),
  mapResult: ({ result }): CreateLocalCounterpartyContactRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === CREATE_COUNTERPARTY_ERROR) {
      switch (error.response?.data.code) {
        case ALREADY_ADDED:
          return new AlreadyAdded()
        case WRONG_DATA:
          return new WrongData(error.response?.data.message)
        default:
      }
    }
    return new UnknownError()
  },
})

export const createAllCounterpartyContactsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateAllCounterpartyContactsParams) => ({
    method: Method.post,
    url: '/conterparty/refreshContacts',
    body: p,
  }),
  mapResult: ({ result }): CreateAllCounterpartyContactsRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === CREATE_COUNTERPARTY_ERROR) {
      switch (error.response?.data.code) {
        case LOCAL_CONTACT_EXISTS:
          return new LocalContactExists()
        default:
      }
    }
    return new UnknownError()
  },
})

export const fetchCounterpartyWithContactsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCounterpartyWithContactsListParams) => ({
    method: Method.post,
    url: '/conterparty/conterpartiesWithLocalContactsList',
    body: p,
  }),
  mapResult: ({ result }): FetchCounterpartyWithContactsListRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const fetchLocalCounterpartyContactsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchLocalCounterpartyContactListParams) => ({
    method: Method.post,
    url: '/localConterpartyContact/list',
    body: p,
  }),
  mapResult: ({ result }): FetchLocalCounterpartyContactListRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})
