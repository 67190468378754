import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { CustomError } from '@/lib/errors'
import { rules } from '@/lib/validators'
import { loginReqFx, fetchMyUserReqFx } from '@/dal'

export const signinFormDomain = createDomain()

export const signinForm = createForm({
  domain: signinFormDomain,
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.isEmail()],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const $serverError = signinFormDomain.store<CustomError | null>(null)

export const setServerError = signinFormDomain.event<CustomError>()
export const resetServerError = signinFormDomain.event<void>()
export const closeSigninModal = signinFormDomain.event<void>()

export const signinFx = attach({ effect: loginReqFx })
export const fetchMyUserFx = attach({ effect: fetchMyUserReqFx })
