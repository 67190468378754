import * as React from 'react'

import { handleFloatInput, handleIntInput } from '@/lib/field-handlers'

import { InputField, Props as InputProps } from './InputField'

interface Props extends InputProps {
  isFloat?: boolean;
  decimalLength?: number;
}

export const NumberInput = (props: Props) => {
  const onChangeValue = (val: string) => {
    if (typeof props.onChangeValue === 'function') {
      if (props.isFloat) {
        return props.onChangeValue(handleFloatInput(val))
      }
      return props.onChangeValue(handleIntInput(val))
    }
  }

  return (
    <InputField
      {...props}
      type="tel"
      onChangeValue={onChangeValue}
    />
  )
}
