import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import {
  TenantLeaseType,
  DespecialisationIndemnityPaymentTerms,
  TerminationModality,
  Taxation,
  PaymentTerms,
} from '@/dal/contracts.interfaces'
import { FetchCounterpartyWithContactsListParams, fetchCounterpartyWithContactsListReqFx } from '@/dal'
import { setTenantLeasePaymentAndSpecialClausesReqFx } from '@/dal/contracts'
import { createAutocomplete } from '@/lib/effector-autocomplete'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const paymentToAutocomplete = createAutocomplete({
  domain: d,
  fields: ['companyName'],
  initValue: '',
  effect: attach({
    effect: fetchCounterpartyWithContactsListReqFx,
    mapParams: (x) => ({
      ...x,
      filters: [
        {
          include: true,
          key: 'localConterpartyContact',
          value: 'have',
        },
        ...x.filters,
      ],
    } as FetchCounterpartyWithContactsListParams),
  }),
})

export const PaymentForm = createForm({
  domain: d,
  fields: {
    type: {
      init: 'lease' as TenantLeaseType,
    },
    paymentPeriod: {
      init: 1 as 1 | 2 | 3 | 6 | 12 | 18,
    },
    joiningFee: {
      init: false,
    },
    joiningFeeAmount: {
      init: '' as string,
      rules: (value, { joiningFee }) => (joiningFee ? [rules.required()] : []),
    },
    despecialisationIndemnity: {
      init: false,
    },
    despecialisationIndemnityAmount: {
      init: '' as string,
      rules: (value, { despecialisationIndemnity }) => (despecialisationIndemnity ? [rules.required()] : []),
    },
    despecialisationIndemnityPaymentTerms: {
      init: 'check' as DespecialisationIndemnityPaymentTerms,
    },
    despecialisationIndemnityPaymentToMyOrganization: {
      init: true as boolean,
    },
    paymentTo: {
      init: '' as string,
    },
    taxation: {
      init: '' as Taxation | null,
    },
    maximumRent: {
      init: false,
    },
    paymentTerms: {
      init: '' as PaymentTerms | null,
    },
    paymentAmount: {
      init: '' as string,
      rules: (value, { type }) => (type ? [rules.required()] : []),
    },
    transferOfBusiness: {
      init: false,
    },
    transferOfBusinessAmount: {
      init: '' as string,
      rules: (value, { transferOfBusiness }) => (transferOfBusiness ? [rules.required()] : []),
    },
    repurchasingOfShares: {
      init: true,
    },
    repurchasingOfSharesAmount: {
      init: '' as string,
      rules: (value, { repurchasingOfShares }) => (repurchasingOfShares ? [rules.required()] : []),
    },
    estimatedRent: {
      init: '' as string,
    },
    budgetedRent: {
      init: '' as string,
    },
    estimatedCosts: {
      init: '' as string,
    },
    budgetedCosts: {
      init: '' as string,
    },
    penalty: {
      init: '',
    },
    typeOfAssignment: {
      init: '',
    },
    subLettingConditions: {
      init: '',
    },
    work: {
      init: '',
    },
    specialClauses: {
      init: '',
    },
    terminationModality: {
      init: 'deed-of-the-bailiff' as TerminationModality,
    },
    private: {
      init: false,
    },
    coOwnership: {
      init: false,
    },
  },
})

PaymentForm.fields.paymentTo.$value.watch(console.log)
export const setTenantLeasePaymentAndSpecialClausesFx = attach({ effect: setTenantLeasePaymentAndSpecialClausesReqFx })
