import {
  $isCreateOrderDialogShown,
  $isOrderCreatedDialogShown,
  $isInfoOrderDialogShown,
  $isAdminReportInfoDialogShown,
} from './state'
import {
  showCreateOrderDialog,
  hideCreateOrderDialog,
  createOrder,
  hideOrderCreatedModal,
  hideOrderInfoDialog,
  showOrderInfoDialog,
  hideAdminReportInfoDialog,
  showAdminReportInfoDialog,
} from './events'


$isCreateOrderDialogShown
  .on(showCreateOrderDialog, () => true)
  .on(hideCreateOrderDialog, () => false)
  .on(createOrder, () => false)

$isOrderCreatedDialogShown
  .on(createOrder, () => true)
  .on(hideOrderCreatedModal, () => false)

$isInfoOrderDialogShown
  .on(showOrderInfoDialog, () => true)
  .on(hideOrderInfoDialog, () => false)

$isAdminReportInfoDialogShown
  .on(showAdminReportInfoDialog, () => true)
  .on(hideAdminReportInfoDialog, () => false)
