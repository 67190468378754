import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import {
  Checkbox, SmallText, themeVar, ThemedStyledProps,
} from '@/ui'

import { GridCol, StyledSubTitle } from './components'
import { LocalityWithParent } from '../../../model/rentalObjects'

interface Props {
  locality: LocalityWithParent;
  checked: boolean;
  onClick: () => void;
}

export const LocalityRow = (props: Props) => {
  const { locality, checked, onClick } = props

  return (
    <LocalityRowWrap key={locality.id} checked={checked}>
      <GridCol gridArea="checkBox">
        <Checkbox
          contrastMode
          checked={checked}
          onChange={() => onClick()}
        />
      </GridCol>
      <GridCol gridArea="name">
        <StyledSubTitle>
          {locality.name}
        </StyledSubTitle>
      </GridCol>
      <GridCol gridArea="description">
        <SmallText>
          {locality.description}
        </SmallText>
      </GridCol>
      <GridCol gridArea="square">
        {
          locality.areaSurface && (
            <StyledSubTitle>
              {locality.areaSurface}
              {' '}
              m
              <sup>2</sup>
            </StyledSubTitle>
          )
        }
      </GridCol>
    </LocalityRowWrap>
  )
}

const LocalityRowWrap = styled.div<ThemedStyledProps & { checked?: boolean}>`
  padding: 15px 34px 15px 66px;
  background: ${themeVar('brandGrayPale3xColor')};
  border-bottom: 1px solid ${themeVar('brandOrangePale2xColor')};
  display: grid;
  width: 100%;
  grid-template: 
    "checkBox name square" 1fr
    "checkBox description square" 1fr / 30px 1fr 60px;
  grid-gap: 5px;
  ${(p) => p.checked && css`
    ${breakpoint('mobile', 'tablet')` 
      border-color: #FB8C5D !important;
      box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28);
    `}
  `}
  ${breakpoint('mobile', 'tablet')`
    background: white;
    width: auto;
    padding: 15px;
    margin: 15px;
    border: 2px solid ${themeVar('brandOrangePale2xColor')};
    border-radius: 4px;
    grid-template: 
        "checkBox name square" 1fr
        "description description description" 1fr / 30px 1fr 60px;
  `}

`
