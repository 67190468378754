import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CustomError } from '@/lib/errors'
import { rules } from '@/lib/validators'
import { setProfilePasswordReqFx } from '@/dal'

const d = createDomain('d')

export const $isShowModal = d.store<boolean>(false)
export const $serverError = d.store<CustomError | null>(null)

export const setServerError = d.event<CustomError>()
export const resetServerError = d.event<void>()

export const showModal = d.event<void>()
export const hideModal = d.event<void>()


export const passwordForm = createForm({
  domain: d,
  fields: {
    oldPassword: {
      init: '',
      rules: [rules.required()],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
    repeatPassword: {
      init: '',
      rules: [rules.required(), rules.isConfirmed('password')],
    },
  },
})

export const setNewPasswordFx = attach({
  effect: setProfilePasswordReqFx,
})
