import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  AccentText,
  Modal,
  PrimaryButton,
  InputField,
  Heading,
  Text,
  Col,
  Row,
  WrapField,
} from '@/ui'
import styled from 'styled-components'

import { useQuery } from '@/lib/use-query'

import {
  $serverError,
  $modalShown,
  setToken,
  setNewPasswordForm,
  setNewPasswordFx,
  closeModal,
  showModal,
} from '../../model/set-new-password'


export const SetNewPassword = () => {
  const { t } = useTranslation('autorization/translation')

  const token = useQuery().get('token')
  const isModalShown = useStore($modalShown)
  const serverError = useStore($serverError)
  const { submit, fields, hasError } = useForm(setNewPasswordForm)

  const pending = useStore(setNewPasswordFx.pending)
  const formHasError = hasError()

  React.useEffect(() => {
    if (token) {
      setToken(token)
      setImmediate(() => {
        showModal()
      })
    }
  }, [])

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Modal
      show={isModalShown}
      onClose={() => closeModal()}
    >
      <ContentWrap onSubmit={onSubmit}>
        <Row>
          <Col noPadding width="100%">
            <WrapField>
              <Heading>{t('changePassword.title')}</Heading>
            </WrapField>
          </Col>
          <Col noPadding width="100%">
            <Text align="center" style={{ fontWeight: 500 }}>
              {t('changePassword.description')}
            </Text>
          </Col>
        </Row>
        {serverError && (
          <Col noPadding width="100%">
            <WrapField>
              <Text align="center">
                <AccentText warning>
                  {t(`errors.${serverError}`)}
                </AccentText>
              </Text>
            </WrapField>
          </Col>
        )}
        <Col noPadding width="80%">
          <WrapField>
            <InputField
              type="password"
              placeholder={t('newPassword')}
              errorText={fields.password.errorText({
                required: 'Field is required',
              })}
              value={fields.password.value}
              onChangeValue={fields.password.onChange}
            />
          </WrapField>
        </Col>
        <Col noPadding width="80%">
          <WrapField>
            <InputField
              type="password"
              placeholder={t('repeatNewPassword')}
              errorText={fields.repeatPassword.errorText({
                isConfirmed: t('passwordNotConfirmed'),
                required: t('requiredFiled'),
              })}
              value={fields.repeatPassword.value}
              onChangeValue={fields.repeatPassword.onChange}
            />
          </WrapField>
        </Col>
        <Row>
          <Col noPadding width="80%">
            <PrimaryButton
              fluid
              htmlType="submit"
              disabled={formHasError || pending}
            >
              {t('send')}
            </PrimaryButton>
          </Col>
        </Row>
      </ContentWrap>
    </Modal>
  )
}


const ContentWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
