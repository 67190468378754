import * as React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import { Card, PrimaryButton, Row } from '@/ui'

import {
  $isCreationModalOpen,
  closeCreationModal,
  openCreationModal,
} from '../../model/create-local-counterparty'
import { CreateLocalCounterpartyModal } from '../containers'


export const LocalCounterpartiesListPage = () => {
  const { t } = useTranslation('site-creation')
  const isCreationModalOpen = useStore($isCreationModalOpen)

  return (
    <>
      <Row>
        <Title>
          {t('counterparty')}
        </Title>
      </Row>

      <Wrap>
        <Row align="center">
          List is under development
        </Row>
        <Row align="center">
          <PrimaryButton onClick={() => openCreationModal()}>
            {t('addCounterparty')}
          </PrimaryButton>
        </Row>
      </Wrap>

      <CreateLocalCounterpartyModal />
    </>
  )
}

const Wrap = styled(Card)`
  margin: 0 0 40px;
  padding: 30px 30px 10px;
`

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;
  color: white;
`
