import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'
import {
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  InputDateTime,
  InputTextArea,
} from '@/ui'
import { EndorsementForm } from '../../model/endorsements'

export const AddEndorsementForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields, submit, reset } = useForm(EndorsementForm)

  return (
    <BlockWrap>
      <Col md="100%" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('description')}
            <sup>*</sup>
          </SubTitle>
          <InputTextArea
            value={fields.description.value}
            onChangeValue={fields.description.onChange}
            errorText={fields.description.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" noMargin>
        <>
          <Col md="100%" xs="100%" align="flex-start" vAlign="flex-start" noMargin noPadding>
            <>
              <SubTitle>
                2.
                {' '}
                {t('effectiveDate')}
              </SubTitle>
              <InputDateTime
                fill
                onlyDate
                value={fields.effectiveDate.value}
                onChange={(val) => fields.effectiveDate.onChange(val || '')}
              />
            </>
          </Col>
          <Col md="100%" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                3.
                {' '}
                {t('dateOfSigning')}
              </SubTitle>
              <InputDateTime
                fill
                onlyDate
                value={fields.dateOfSigning.value}
                onChange={(val) => fields.dateOfSigning.onChange(val || '')}
              />
            </>
          </Col>
        </>
      </Col>
      <Col md="calc(100% / 3 * 2)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('content')}
          </SubTitle>
          <InputTextArea
            value={fields.content.value}
            onChangeValue={fields.content.onChange}
          />
        </>
      </Col>
      <Col width="100%" noMargin noPadding>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
              onClick={reset}
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
              onClick={submit}
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
  & [class*="ErrorText"] {
    color: white !important;
  }
`
