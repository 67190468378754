import * as React from 'react'
import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'

type Props = {
  unit?: string;
  min: number;
  max: number;
  value: [number, number];
  outputModificator?: (val: number) => React.ReactChild;
  onChange: (val: [number, number]) => void;
}

export const RangeSliderField = ({
  unit, min, max, value, onChange, outputModificator,
}: Props) => {
  const offsetInPercent = ((value[0] - min) / (max - min)) * 100
  const valueInPercent = (((value[1] - min) / (max - min)) * 100) - offsetInPercent

  const setValue = (positionX: number, targetWidth: number, offset: number) => {
    const newValue = Math.round(((positionX - offset) / targetWidth) * (max - min) + min)
    if (newValue <= Math.floor(((value[1] - value[0]) / 2 + value[0]))) {
      onChange([newValue, value[1]])
    } else {
      onChange([value[0], newValue])
    }
  }

  return (
    <Wrap
      onMouseUp={(e) => setValue(
        e.clientX,
        e.currentTarget.clientWidth,
        e.currentTarget.getBoundingClientRect().left,
      )}
      onTouchMove={(e) => setValue(
        e.touches[0].clientX,
        e.currentTarget.clientWidth,
        e.currentTarget.getBoundingClientRect().left,
      )}
    >
      <InputLine>
        <InputLineFilled width={valueInPercent} offset={offsetInPercent}>
          <InputPointerStart>
            <PointerValue>
              {outputModificator
                ? outputModificator(value[0])
                : (
                  <>
                    {value[0]}
                    {unit}
                  </>
                )}
            </PointerValue>
          </InputPointerStart>
          <InputPointerEnd>
            <PointerValue>
              {outputModificator
                ? outputModificator(value[1])
                : (
                  <>
                    {value[1]}
                    {unit}
                  </>
                )}
            </PointerValue>
          </InputPointerEnd>
        </InputLineFilled>

      </InputLine>
      <InputRange>
        <div>
          {outputModificator
            ? outputModificator(min)
            : (
              <>
                {min}
                {unit}
              </>
            )}
        </div>
        <div>
          {outputModificator
            ? outputModificator(max)
            : (
              <>
                {max}
                {unit}
              </>
            )}
        </div>
      </InputRange>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  height: 34px;
  cursor: pointer;
  padding-top: 18px;
  font-family: ${themeVar('baseFont')};
`

const InputLine = styled.div<ThemedStyledProps>`
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 3px;
  pointer-events: none;
  background: ${themeVar('inputSliderMainColor')};
  border-radius: 3px;
`

const InputLineFilled = styled.div<ThemedStyledProps & {width: number; offset: number}>`
  display: block;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  transition: all .2s;
  ${({ width }) => css`
        width: ${width}%;
  `}
  ${({ offset }) => css`
    left: ${offset}%;
  `}
  height: 3px;
  background: ${themeVar('inputSliderFilledColor')};
  border-radius: 3px;
`

const InputPointerEnd = styled.div<ThemedStyledProps>`
  display: block;
  position: absolute;
  bottom: -50%;
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  right: 0;
  background: ${themeVar('inputSliderPointer')};
`

const InputPointerStart = styled.div<ThemedStyledProps>`
  display: block;
  position: absolute;
  bottom: -50%;
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  left: 0;
  background: ${themeVar('inputSliderPointer')};
`
const PointerValue = styled.div<ThemedStyledProps>`
  display: block;
  position: absolute;
  bottom: 100%;
  font-size: 12px;
  color: ${themeVar('inputSliderPointerText')};
`

const InputRange = styled.div<ThemedStyledProps>`
  display: flex;
  width: 100%;
  padding-top: 5px;
  flex-wrap: wrap;
  font-size: 8px;
  justify-content: space-between;
  color: ${themeVar('inputSliderRangeText')};
`
