import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'
import { useInView } from 'react-intersection-observer'

import { NewsArticle } from '@/dal'
import {
  Col,
  PrimaryButton,
  Title,
  AccentText,
  InputField,
  InputDateTime,
  Row,
  Icon,
  themeVar,
  SubTitle,
} from '@/ui'
import { showModal, setNewsArticleData } from '@/features/news-modal'

import { NewsItem } from '../parts'
import {
  $newsList,
  $isSearchActive,
  $searchValue,
  $searchDate,
  $currentSearchValue,
  $searchNewsList,
  endSearch,
  updateSearchValue,
  updateSearchDate,
  startSearch,
  loadNextPage,
  loadNextSearchPage,
} from '../../model/news/units'

export const NewsList = () => {
  const newsList = useStore($newsList)
  const isSearchActive = useStore($isSearchActive)
  const searchValue = useStore($searchValue)
  const searchDate = useStore($searchDate)
  const currentSearchValue = useStore($currentSearchValue)
  const searchNewsList = useStore($searchNewsList)

  const { ref, inView } = useInView({
    threshold: 1,
  })

  React.useEffect(() => {
    if (inView) {
      isSearchActive ? loadNextSearchPage() : loadNextPage()
    }
  }, [inView])

  const [showBackToTop, setShowBackToTop] = React.useState(false)

  function showCondition() {
    setShowBackToTop(window.scrollY > 100)
  }

  /* React.useEffect(() => {
    window.addEventListener('scroll', () => showCondition(), true)
    return () => {
      window.removeEventListener('scroll', () => showCondition(), true)
    }
  }, []) */

  const openNewsArticleModal = (article: NewsArticle) => {
    setNewsArticleData(article)
    showModal()
  }

  const newsItemList = (isSearchActive ? searchNewsList : newsList).map(
    (el) => (
      <Col width="100%" md="50%" key={el.id} noMargin noPadding vAlign="flex-start">
        <NewsItem
          author={el.postedBy.authorName}
          date={el.createdAt}
          text={el.snippet}
          title={el.title}
          more={() => openNewsArticleModal(el)}
        />
      </Col>
    ),
  )

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }


  return (
    <Wrap>

      <SearchRow>
        {isSearchActive && (
          <>
            <Col md="25%" xs="40%" noMargin noPadding align="flex-start">
              <PrimaryButton
                onClick={() => endSearch()}
                prependIcon="leftChevron"
                outline
              >
                Back
              </PrimaryButton>
            </Col>
            <Col md="25%" xs="60%" noMargin noPadding>
              <Title>
                <AccentText>
                  Results by “
                  {currentSearchValue}
                  ”
                </AccentText>
              </Title>
            </Col>
          </>
        )}
        <Col width="100%" md="25%" noMargin>
          <InputDateTime
            onlyDate
            value={searchDate}
            onChange={updateSearchDate}
          />
        </Col>
        <Col width="100%" md={isSearchActive ? '25%' : '50%'} noMargin noPadding>
          <InputField
            value={searchValue}
            placeholder="Searching by word, title etc."
            onChangeValue={updateSearchValue}
            appendIcon="lense"
            innerIcon
            onAppendIconClick={() => startSearch()}
          />
        </Col>
      </SearchRow>

      <h3 style={{ textAlign: 'center', width: '100%' }}>News module is under development</h3>

      {newsItemList}

      <NextPageLoader ref={ref}> </NextPageLoader>
      <ScrollToTopBox
        show={showBackToTop}
        onClick={scrollToTop}
      >
        <>
          <Icon icon="arrow" />
          <SubTitle>back to the top</SubTitle>
        </>
      </ScrollToTopBox>
    </Wrap>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px;
    overflow: visible;
    position: relative;
    ${breakpoint('mobile', 'tablet')`
        padding: 30px 25px;
        margin: 35px 0px;
    `}
`

const SearchRow = styled(Row)`
  padding-left: 20px;
  padding-right: 20px;
`

const NextPageLoader = styled.div`
  width: 100%;
  height: 1px;
`

const ScrollToTopBox = styled.div<{show?: boolean}>`
  align-self: flex-start;
  max-width: 270px;
  width: 100%;
  margin: auto;
  margin-bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: 15px 35px 10px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  fill: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  & ${SubTitle} {
    width: 100%;
    text-align: center;
  }
  ${({ show }) => show && css`
    opacity: 1;
    pointer-events: all;
  `}
`
