import { createDomain } from 'effector'
import { AxiosError, AxiosResponse } from 'axios'

export enum Method {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export type Request = {
  method: Method;
  url: string;
  headers?: any;
  accessToken?: string;
  query?: {
    [key: string]: string;
  };
  fake?: {success: boolean};
  body?: object | FormData;
}

export const restApi = createDomain('rest-api')

export const resetAuthState = restApi.event<void>()

export const requestFx = restApi.effect<Request, AxiosResponse<any>, AxiosError>()
export const authServiceRequestFx = restApi.effect<Request, AxiosResponse<any>, AxiosError>()
