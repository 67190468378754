import * as React from 'react'

import { InputField, Props } from './InputField'

export const PhoneInput = (props: Props) => {
  const onChangeValue = (val: string) => {
    if (typeof props.onChangeValue === 'function') {
      props.onChangeValue(val.replace(/[^0-9-()+ ]/gu, ''))
    }
  }

  return (
    <InputField {...props} onChangeValue={onChangeValue} />
  )
}
