import { forward, sample } from 'effector'
import { InsuranceContract } from '@/dal/contracts.interfaces'
import { getInsuranceContractFx, $insuranceContract } from '../main'
import {
  $list,
  DocumentForm,
  addItem,
  attachItemFx,
  createItemFx,
  deleteItem,
  deleteItemFx,
  detachItemFx,
  dropItem,
  loadList,
} from './unit'

$list
  .on(loadList, (_, r) => r)
  .on(addItem, (_, r) => [..._, r])
  .on(dropItem, (_, r) => _.filter((el) => el.id !== r))

forward({
  from: getInsuranceContractFx.doneData.map((r) => r.insuranceContract.documents),
  to: loadList,
})

sample({
  clock: DocumentForm.formValidated,
  source: DocumentForm.$values,
  fn: (form) => ({ ...form }),
  target: createItemFx,
})

forward({
  from: createItemFx.doneData,
  to: addItem,
})

sample({
  clock: createItemFx.doneData,
  source: $insuranceContract,
  fn: (contract, period) => ({
    id: contract?.id as string,
    itemsID: [period.id],
  }),
  target: attachItemFx,
})

forward({
  from: attachItemFx.done,
  to: DocumentForm.reset,
})

sample({
  clock: deleteItem,
  source: $insuranceContract,
  fn: (contract, i) => ({ id: (contract as InsuranceContract).id, items: [i.id] }),
  target: detachItemFx,
})

forward({
  from: [
    detachItemFx.fail.map((r) => (r.params.items ? r.params.items[0] : '')),
    attachItemFx.fail.map((r) => (r.params.items ? r.params.items[0].id : '')),
    detachItemFx.done.map((r) => (r.params.items ? r.params.items[0] : '')),
  ],
  to: dropItem,
})


forward({
  from: deleteItem.map((i) => ({ id: i.id })),
  to: deleteItemFx,
})
