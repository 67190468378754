import { createCustomError } from '@/lib/errors'
import { attachWrapper } from '@/lib/effector-extra'
import { quickUUID } from '@/lib/random'

import { Method, requestFx } from './rest-api'
import {
  Entity,
  Location,
  WithFilters,
  PaginationResult,
  PaginationParams,
} from './common.interfaces'

export const entityMock = (): Entity => ({
  id: quickUUID(),
  name: 'Entity 1',
})

export const entitiesListMock = (): PaginationResult<Entity> => ({
  count: 10,
  items: new Array(10).fill(null).map(() => entityMock()),
})

export const locationMock = (): Location => ({
  id: quickUUID(),
  name: 'Entity 1',
  state: 'State',
  type: 'Type',
})

export const locationsListMock = (): PaginationResult<Location> => ({
  count: 10,
  items: new Array(10).fill(null).map(() => locationMock()),
})

export const getFilteredEntitiesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: (WithFilters & PaginationParams) | PaginationParams) => ({
    method: Method.post,
    url: '/entity/list',
    body: p,
  }),
  mapResult: ({ params, result }): PaginationResult<Entity> => result.data,
  mapError: ({ error }) => new Error(),
})

export const getLocationsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: (WithFilters & PaginationParams) | PaginationParams) => ({
    method: Method.post,
    url: '/location/list',
    body: p,
  }),
  mapResult: ({ params, result }): PaginationResult<Location> => result.data,
  mapError: ({ error }) => new Error(),
})
