import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../theming'

type StyledVisibleProps = {
    mobile?: boolean;
    tablet?: boolean;
    fluid?: boolean;
  } & ThemedStyledProps

export const Visible = styled.div<StyledVisibleProps>`
display: none;
flex-wrap: inherit;
position: inherit;
width: inherit;
${({ mobile }) => mobile && css`
    ${breakpoint('mobile', 'tablet')`
        display: inherit;
    `}
`}
${({ tablet }) => tablet && css`
    ${breakpoint('tablet')`
        display: inherit;
    `}
`}

${({ fluid = false }) => fluid && css`
  width: 100%;
`}
`
