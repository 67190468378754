import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar } from '../theming'
import { IconName, Icon } from '../icons'

type PropsType<Value> = {
    items: Item<Value>[];
    value: Value;
    onItemClick: (item: Item<Value>) => void;
    checkedSteps?: Value[];
    checkedIcon?: IconName;
}

type Item<Value> = {
    name: string;
    value: Value;
    disabled?: boolean;
}

export function TabControl<Value>({
  items, value, onItemClick, checkedSteps = [], checkedIcon,
}: PropsType<Value>) {
  const chechedItem = (item: Value) => checkedSteps.includes(item)

  const listItems = items.map((item) => (
    <Item
      key={`${item.value}tabItem`}
      onClick={() => onItemClick(item)}
      active={item.value === value}
      checked={chechedItem(item.value)}
      disabled={Boolean(item.disabled)}
    >
      {item.name}
      {checkedIcon && chechedItem(item.value) && (
        <ItemIcon
          icon={checkedIcon}
        />
      )}
    </Item>
  ))

  return (
    <Wrap>
      {listItems}
    </Wrap>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    overflow-x: auto;
    position: relative;
    padding-bottom: 1px;
    ${breakpoint('tablet')`
      justify-content: space-around;
    `}
    &::after{
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      bottom: 1px;
      background: ${themeVar('brandGrayColor')};
      height: 1px;
      z-index: 1;
      opacity: 0.25;
    }
`

type ItemProps = {
  active: boolean;
  checked?: boolean;
  disabled: boolean;
}

const Item = styled.div<ItemProps>`
    user-select: none;
    position: relative;
    display: flex;
    color: ${themeVar('brandGrayColor')};
    font-size: 16px;
    cursor: pointer;
    padding: 10px 10px;
    transition: all .1s ease-in-out;
    align-items: center;
    white-space: nowrap;

    &::after{
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      bottom: -1px;
      border-radius: 5px;
      background: ${themeVar('brandOrangeColor')};
      height: 3px;
      z-index: 2;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }
    ${({ checked }) => checked && css`
      color: ${themeVar('brandOrangeColor')};
      fill: ${themeVar('brandOrangeColor')};
    `}
    ${({ active }) => active && css`
      color: black;
      font-weight: bold;
      &::after{
        opacity: 1;
      }
    `}
    
    &:hover{
      color: black;
    }

    ${({ disabled }) => disabled && css`
      color: ${themeVar('brandGrayColor')};
      fill: ${themeVar('brandGrayColor')};
      cursor: unset;
      pointer-events: none;
    `}
`
const ItemIcon = styled(Icon)`
  margin-left: 5px;
`
