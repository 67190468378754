import { attachWrapper } from '@/lib/effector-extra'
import { createCustomError } from '@/lib/errors'
import { quickUUID } from '@/lib/random'

import { authServiceRequestFx, Method } from './rest-api'
import { UserNotFound, UnknownError } from './common-errors'
import {
  FetchMyAccountParams,
  FetchMyAccountRes,
  SetAccountTariffParams,
  SetAccountTariffRes,
  SetAccountTypeParams,
  SetAccountTypeRes,
} from './account.interfaces'

const ACCOUNT_ERROR = 404
const USER_IS_NOT_AN_OWNER = 2007
const USER_DONT_HAVE_AN_ACCOUNT = 2008
const ACCOUNT_TYPE_MISMATCH = 2017
const ACCOUNT_TYPE_ALREADY_SET = 2018

const UserIsNotAnOwner = createCustomError('UserIsNotAnOwner')
const UserDontHaveAnAccount = createCustomError('UserDontHaveAnAccount')
const AccountTypeMismatch = createCustomError('AccountTypeMismatch')
const AccountTypeAlreadySet = createCustomError('AccountTypeAlreadySet')

export const fetchMyAccountReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: FetchMyAccountParams) => ({
    method: Method.post,
    url: '/get-my-account',
  }),
  mapResult: ({ result }): FetchMyAccountRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === ACCOUNT_ERROR) {
      switch (error.response?.data.code) {
        case USER_IS_NOT_AN_OWNER:
          return new UserIsNotAnOwner()
        case USER_DONT_HAVE_AN_ACCOUNT:
          return new UserDontHaveAnAccount()
        default:
      }
    }
    return new UnknownError()
  },
})

export const setAccountTypeReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: SetAccountTypeParams) => ({
    method: Method.post,
    url: '/set-account-type',
    body: p,
  }),
  mapResult: ({ result }): SetAccountTypeRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === ACCOUNT_ERROR) {
      switch (error.response?.data.code) {
        case USER_IS_NOT_AN_OWNER:
          return new UserIsNotAnOwner()
        case USER_DONT_HAVE_AN_ACCOUNT:
          return new UserDontHaveAnAccount()
        default:
      }
    }
    return new UnknownError()
  },
})

export const setAccountTariffReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: SetAccountTariffParams) => ({
    method: Method.post,
    url: '/set-tariff-plan',
    body: p,
  }),
  mapResult: ({ result }): SetAccountTariffRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === ACCOUNT_ERROR) {
      switch (error.response?.data.code) {
        case USER_IS_NOT_AN_OWNER:
          return new UserIsNotAnOwner()
        case USER_DONT_HAVE_AN_ACCOUNT:
          return new UserDontHaveAnAccount()
        case ACCOUNT_TYPE_MISMATCH:
          return new AccountTypeMismatch()
        default:
      }
    }
    return new UnknownError()
  },
})
