import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

export const PlanBlock = () => (
  <Wrap />
)

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    ${breakpoint('mobile', 'tablet')`
        padding: 30px 25px;
        margin: 35px 6px;
    `}
`
