import * as React from 'react'
import styled from 'styled-components'
import { themeVar } from '@/ui/theming'
import { Icon } from '../icons'
import { PrimaryButton } from './PrimaryButton'


type Props = {
  files: File[];
  onFile: (file: File) => void;
  onDelete: (index: number) => void;
}

export const FilePicker = ({ files, onFile, onDelete }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const clickUploadHadnler = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const uploadHandler = (filesList: FileList | null) => {
    if (!filesList) return null
    onFile(filesList[0])
    if (inputRef.current) {
      // TODO: typehack!
      inputRef.current.value = null as unknown as string
    }
  }

  return (
    <Wrap>
      <FilesWrap>
        {files.map((file, index) => (
          <FileRow key={file.size.toString()}>
            <Filename>{file.name}</Filename>
            <IconWrap onClick={() => onDelete(index)}>
              <Icon icon="close" />
            </IconWrap>
          </FileRow>
        ))}
      </FilesWrap>
      <HiddenInput
        ref={inputRef}
        type="file"
        onChange={(e) => uploadHandler(e.currentTarget.files)}
      />
      <PrimaryButton
        fluid
        type="gray"
        outline
        appendIcon="upload"
        onClick={clickUploadHadnler}
      >
        Upload File
      </PrimaryButton>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
`

const FileRow = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Filename = styled.div`
  font-family: ${themeVar('baseFont')};
  font-weight: 400;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const FilesWrap = styled.div`
  width: 100%;
  padding-bottom: 12px;
`

const IconWrap = styled.div`
  padding-left: 8px;
  margin-left: auto;
  cursor: pointer;
  svg {
    width: 10px;
    fill: ${themeVar('filePickerDeleteIconColor')};
  }
`

const HiddenInput = styled.input`
  display: none;
`
