import { command } from './domain'

export const createCommand = command.event()
export const hideCommandCreatedModal = command.event()
export const showCommandInfoDialog = command.event()
export const hideCommandInfoDialog = command.event()
export const showCommandValidateDialog = command.event()
export const hideCommandValidateDialog = command.event()
export const validateCommand = command.event()
export const hideValidatedCommandDialog = command.event()
export const setCreateFormStep = command.event<string>()
export const setSupplierId = command.event<number| null>()
export const completeStep = command.event<'supplier'|'items'|'order'>()
