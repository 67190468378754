import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { themeVar } from '@/ui/theming'
import { Icon } from '@/ui'

import { onToggleMenu, $isMenuOpen } from '../../model'

export const HamburgerButton = () => {
  const isMenuOpen = useStore($isMenuOpen)

  return (
    <Button onClick={() => onToggleMenu()}>
      {
        isMenuOpen
          ? <ButtonIcon icon="close" />
          : <ButtonIcon icon="menu" />
      }
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  outline: none;
  cursor: pointer;
  ${breakpoint('tablet')`
    width: 40px;
    height: 40px;
  `}
`

const ButtonIcon = styled(Icon)`
  width: 18px;
  fill: ${themeVar('hamburgerButtonColor')};
`
