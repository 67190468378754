import { attachWrapper } from '@/lib/effector-extra'

import { authServiceRequestFx, Method } from './rest-api'
import { UnknownError } from './common-errors'
import {
  FetchTariffPlansListParams,
  FetchTariffPlansListRes,
} from './tariff-plans.interfaces'


export const fetchTariffPlansListReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: FetchTariffPlansListParams) => ({
    method: Method.post,
    url: '/tariffs-list',
    body: p,
  }),
  mapResult: ({ result }): FetchTariffPlansListRes => result.data,
  mapError: () => new UnknownError(),
})
