import { attachWrapper } from '@/lib/effector-extra'
import { quickUUID } from '@/lib/random'
import { ResultDataFilter } from '@/lib/effector-filters'

import { requestFx, Method } from './rest-api'

import {
  PaginationResult,
  PaginationParams,
  WithFilters,
  WithSorting,
  Comment,
  Project,
} from './common.interfaces'
import {
  AcceptDemandParams,
  CreateDemandComment,
  CreateDemandParams,
  DeclineDemandParams,
  DeleteDemandParams,
  Demand,
  DemandCategory,
  MarkAsSeenDemandParams,
} from './demands.interfaces'

export const fetchDemandsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: PaginationParams & WithFilters & WithSorting) => {
    const filters: ResultDataFilter[] = []
    p.filters.forEach((f) => {
      if (Array.isArray(f.value)) {
        f.value.forEach((x) => {
          filters.push({
            value: x,
            key: f.key,
            include: f.include,
          })
        })
      } else {
        filters.push(f)
      }
    })

    return {
      method: Method.post,
      url: '/demand/list',
      body: { ...p, filters },
    }
  },
  mapResult: ({ result }): PaginationResult<Demand> => result.data,
  mapError: () => new Error(),
})

export const deleteDemandReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteDemandParams) => ({
    method: Method.delete,
    url: '/demand/delete',
    body: p,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: () => new Error(),
})

export const createDemandReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateDemandParams) => {
    // fixme: квикфикс, чтобы можно было создать деманд. надо сказать бэку, чтобы при создании поле comments было стрингом, а не массивом стрингов
    const comments = [p.comments]
    return {
      method: Method.post,
      url: '/demand/add',
      body: {
        ...p,
        comments,
      },
    }
  },
  mapResult: ({ result }): Demand => result.data,
  mapError: () => new Error(),
})

export const createDemandCommentReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateDemandComment) => ({
    method: Method.post,
    url: '/demand/comment',
    body: p,
  }),
  mapResult: ({ result }): Comment => result.data,
  mapError: () => new Error(),
})


export const fetchDemandsCategoriesReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/demandCategory/list',
    body: {
      offset: 0,
      limit: 9999,
    },
  }),
  mapResult: ({ result }): { items: DemandCategory[] } => result.data,
  mapError: () => new Error(),
})

export const fetchDemandsProjectsReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/demandProject/list',
    body: {
      offset: 0,
      limit: 9999,
    },
  }),
  mapResult: ({ result }): { items: Project[] } => result.data,
  mapError: () => new Error(),
})

export const markAsSeenDemandReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: MarkAsSeenDemandParams) => ({
    method: Method.post,
    url: '/demand/markAsSeen',
    body: p,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: () => new Error(),
})

export const acceptDemandReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AcceptDemandParams) => ({
    method: Method.post,
    url: '/demand/accept',
    body: p,
  }),
  mapResult: ({ result }): Demand => result.data,
  mapError: () => new Error(),
})

export const declineDemandReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeclineDemandParams) => ({
    method: Method.post,
    url: '/demand/decline',
    body: p,
  }),
  mapResult: ({ result }): Demand => result.data,
  mapError: () => new Error(),
})
