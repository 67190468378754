import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { Card, ThemedStyledProps } from '@/ui'

interface LinkProps {
  blank?: boolean;
  href: string;
}

export const Link: React.FC<LinkProps> = ({ children, blank, href }) => {
  const options = blank ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  return (
    <StyledLink
      href={href}
      {...options}
    >
      {children}
    </StyledLink>
  )
}

export const Wrap = styled(Card)`
  border-radius: 8px;
  padding: 0 39px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  & b {
    font-weight: 600 !important;
  }
  ${breakpoint('mobile', 'tablet')`
    padding: 0 20px 20px;
    margin: 20px 10px;
  `}
`

export const Title = styled.h2`
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 20px;
  margin-top: 40px;
  color: black;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  ${breakpoint('mobile', 'tablet')`
    font-size: 14px;
    line-height: 14px;
  `}
`

export const Subtitle = styled.h3`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: black;
  font-weight: 600;
`

export const Text = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #3D3939;
`

export const StyledLink = styled.a`
  color: #FB8C5D !important;
  text-decoration: underline !important;
`

export const Ul = styled.ul`
  list-style-type: none;
  padding-left: 28px;
  ${breakpoint('mobile', 'tablet')`
    padding-left: 16px;
  `}
`

export const Li = styled.li<ThemedStyledProps & {noMarker?: boolean}>`
  position: relative;
  margin: 16px 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #3D3939;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    top: 5px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #FB8C5D;
    ${(p) => p.noMarker && css`
      background-color: transparent;
    `}
  
`
