import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { rules } from '@/lib/validators'
import {
  LocalityAssignment,
  LocalityType,
  LocalityLoad,
  Surface,
  createLocalityReqFx,
  attachLocalityToSurfaceReqFx,
} from '@/dal'

export const d = createDomain('d')

export const $isShowModal = d.store(false)
export const $selectedSurfaceId = d.store<Surface['id'] | null>(null)

export const showModal = d.event<Surface['id']>()
export const hideModal = d.event()

export const newLocality = createForm({
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    description: {
      init: '',
    },
    assigment: {
      init: '' as LocalityAssignment,
    },
    type: {
      init: '' as LocalityType,
    },
    availibility: {
      init: '',
    },
    typeOfLoad: {
      init: '' as LocalityLoad,
    },
    surface: {
      init: '',
    },
    perimetr: {
      init: '',
    },
    volume: {
      init: '',
    },
  },
})

export const createLocalityFx = attach({ effect: createLocalityReqFx })
export const attachLocalityToSurfaceFx = attach({ effect: attachLocalityToSurfaceReqFx })
