import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Icon } from '../icons'
import { useTheme, themeVar } from '../theming'

type Props = {
    header: React.ReactChild;
    children?: React.ReactChild;
    colorized?: boolean;
    dark?: boolean;
}

export const AccordeonRow = ({
  header, children, colorized, dark,
}: Props) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState<boolean>(false)
  const content = React.useRef<HTMLDivElement>(null)
  const contentHeight = content.current !== null ? content.current.clientHeight : 0
  return (
    <AccordeonRowWrap
      colorized={Boolean(colorized)}
      dark={Boolean(dark)}
    >
      <AccordeonHeader
        onClick={() => setOpen(!open)}
        colorized={Boolean(colorized)}
        dark={Boolean(dark)}
      >
        <HeaderContent>
          {header}
        </HeaderContent>
        <OpenIndicator open={open} icon="arrow" fill={theme.brandOrangeColor} />
      </AccordeonHeader>
      <AccordeonHiddenContent className={open ? 'open' : 'close'} open={open}>
        <AccordeonHiddenContentWrap ref={content} open={open}>
          {children}
        </AccordeonHiddenContentWrap>
      </AccordeonHiddenContent>
    </AccordeonRowWrap>
  )
}

const AccordeonRowWrap = styled.div<{colorized: boolean;dark: boolean}>`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    ${({ colorized }) => colorized && css`
        background: ${themeVar('brandColor')};
        border-radius: 8px;
    `}
    ${({ colorized, dark }) => colorized && dark && css`
        background: ${themeVar('brandOrangeColor')};
    `}
`

export const AccordeonHeader = styled.div<{colorized: boolean;dark: boolean}>`
    display: flex;
    width: 100%;
    padding: 22px 30px;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid ${themeVar('brandOrangePale2xColor')};
    ${({ colorized }) => colorized && css`
        color: white;
        border-bottom: 1px solid ${themeVar('brandColor')};
        & svg {
            fill: white;
        }
    `}
    ${({ colorized, dark }) => colorized && dark && css`
    color: white;
        border-bottom: 1px solid ${themeVar('brandOrangeColor')};
    `}
`

const HeaderContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
`


const OpenIndicator = styled(Icon)<{open: boolean}>`
    display: flex;
    width: 10px;
    transition: all .3s ease-in-out;
    ${({ open }) => !open && css`
        transform: rotate3d(0, 0, 1, 180deg);
    `}
    margin-left: 30px;
    
`

const AccordeonHiddenContent = styled.div<{open: boolean }>`
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 5px 5px rgba(251, 140, 93, 0.1) inset;
    &.open{}
    &.close{
        display: none;
    }
    
`

const AccordeonHiddenContentWrap = styled.div<{open: boolean;}>`
    position: relative;
    width: 100%;
    ${({ open }) => !open && css`
        pointer-events: none;
    `}
`
