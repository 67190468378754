import { createDomain, attach } from 'effector'
import {
  getFilteredSitesListReqFx,
  getSiteReqFx,
  Site,
} from '@/dal'

export const site = createDomain('site')

export const $activeTab = site.store<string>('')
export const $sitesList = site.store<Site[]>([])
export const $selectedSiteId = site.store<string>('')
export const $selectedSite = site.store<Site | null>(null)

export const $sitesListForSelect = $sitesList.map((list) => list.map((x) => ({ text: x.name, value: x.id })))

export const setSitesList = site.event<Site[]>()
export const setSiteId = site.event<string>()
export const setSiteData = site.event<Site>()
export const setActiveTab = site.event<string>()
export const clearActiveTab = site.event()
export const clearActiveSite = site.event()
export const setSiteIdAndTab = site.event<{siteId: string; activeTab: string}>()

export const fetchSitesListFx = attach({
  effect: getFilteredSitesListReqFx,
  mapParams: (p: void) => ({
    offset: 0,
    limit: 9999,
  }),
})
export const fetchSiteFx = getSiteReqFx
