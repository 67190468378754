import * as React from 'react'
import {
  Row,
  SortHeader,
  Col,
  Checkbox,
  InputField,
  themeVar,
  AccordeonRow,
  Visible,
  useTheme,
  Icon,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'


export const MemberList = () => {
  const theme = useTheme()
  const dataList = getMock().newEmloyee.membersList
  const [tmpSortBy, setTmpSortBy] = React.useState('number')
  const [search, setSearch] = React.useState('')
  const [tmpSortDirection, setTmpSortDirection] = React.useState<'ASC'|'DESC'>('ASC')
  const setSort = (name: string) => {
    if (tmpSortBy !== name) {
      setTmpSortBy(name)
      setTmpSortDirection('ASC')
      return
    }
    if (tmpSortDirection === 'ASC') {
      setTmpSortDirection('DESC')
      return
    }
    setTmpSortBy('')
  }
  const [selectAll, setSelectAll] = React.useState(false)


  function subcategoryList(members: typeof dataList[0]['members']) {
    return members.map((item) => (
      <ItemRow>
        <Visible tablet>
          <Col md={2}>
            {item.name}
          </Col>
          <Col md={1} big>
            {item.jobTitle}
          </Col>
          <Col md={2}>
            {item.address}
          </Col>
          <Col md={2}>
            {item.phone}
          </Col>
          <Col md={2}>
            {item.email}
          </Col>
          <Col md={1} big color={item.online ? 'success' : 'primary'}>
            {item.online ? 'online' : 'offline'}
          </Col>
          <Col md={1} iconColor="primary">
            <Icon width="15" icon="close" />
          </Col>
        </Visible>
        <Visible mobile>
          <Row>
            <Col xs="90%">
              <>
                <Col name="Name" xs="50%" align="flex-start">
                  {item.name}
                </Col>
                <Col xs={1} big color={item.online ? 'success' : 'primary'} align="flex-start">
                  {item.online ? 'online' : 'offline'}
                </Col>
                <Col xs="30px" iconColor="primary">
                  <Icon width="15" icon="close" />
                </Col>
                <Col xs="50%" name="Job title" big align="flex-start">
                  {item.jobTitle}
                </Col>
                <Col xs="50%" name="Address" align="flex-start">
                  {item.address}
                </Col>
                <Col xs="50%" name="Phone" align="flex-start">
                  {item.phone}
                </Col>
                <Col xs="50%" name="Email" align="flex-start">
                  {item.email}
                </Col>
              </>
            </Col>
          </Row>
        </Visible>
      </ItemRow>
    ))
  }

  const categoryHeader = (item: typeof dataList[0]) => (
    <CategoryHead>
      <Col width="30px">
        <Checkbox
          mini
          checked={selectAll}
          onChange={(val) => setSelectAll(val)}
        />
      </Col>
      <Col xs={4} md={9}>
        <>
          <Col xs="100%" big md={2} align="flex-start">
            {item.group}
          </Col>
          <Col xs="100%" md={2} align="flex-start" color="primary" big>
            {item.module}
          </Col>
          <Col xs="100%" md={4} align="flex-start">
            {item.description}
          </Col>
        </>
      </Col>
    </CategoryHead>
  )
  const categoryList = dataList.map((item) => (
    <AccordeonRow header={categoryHeader(item)}>
      <>
        {subcategoryList(item.members)}
        <AddMember>
          <AddMemberText>
            add new user
          </AddMemberText>
          <Icon width="20" icon="plus" />
        </AddMember>
      </>
    </AccordeonRow>
  ))

  return (
    <ItemsListWrap>
      <AddGroup>
        <AddMemberText>
          add new group
        </AddMemberText>
        <Icon width="20" icon="plus" />
      </AddGroup>
      <Visible tablet>
        <Row>
          <Col width="30px">
            <Checkbox
              mini
              checked={selectAll}
              onChange={(val) => setSelectAll(val)}
            />
          </Col>
          <Col width={1} align="flex-start">
            <SortHeader
              direction={tmpSortBy === 'group name' ? tmpSortDirection : undefined}
              onChange={() => setSort('group name')}
            >
              Group name
            </SortHeader>
          </Col>
          <Col width={1} align="flex-start">
            <SortHeader
              direction={tmpSortBy === 'modules' ? tmpSortDirection : undefined}
              onChange={() => setSort('modules')}
            >
              Modules
            </SortHeader>
          </Col>
          <Col width={1} align="flex-start">
            <SortHeader
              direction={tmpSortBy === 'description ' ? tmpSortDirection : undefined}
              onChange={() => setSort('description')}
            >
              Description
            </SortHeader>
          </Col>
          <Col width={3} align="flex-end">
            <InputField
              innerIcon
              appendIcon="lense"
              value={search}
              onChangeValue={setSearch}
            />
          </Col>
        </Row>

        <Row>
          <Col width={1} noMargin noPadding>
            <ListActions noMargin>
              • Select all
            </ListActions>
          </Col>
          <Col width={1} noMargin noPadding>
            <ListActions>
              ○ Deselect all
            </ListActions>
          </Col>
          <Col width={1} noMargin noPadding>
            <ListActions>
              ☓ Delete selected
            </ListActions>
          </Col>
        </Row>
      </Visible>
      {categoryList}
      <Row>
        <Col xs="100%" md="150px">
          <PrimaryButton
            fluid
            outline
            type="gray"
            prependIcon="leftChevron"
            onClick={() => {}}
          >
            back
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="250px">
          <PrimaryButton
            fluid
            outline
            type="warning"
            appendIcon="close"
          >
            discard changes
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="150px">
          <PrimaryButton
            fluid
            appendIcon="rightChevron"
            onClick={() => {}}
          >
            validate
          </PrimaryButton>
        </Col>
      </Row>
    </ItemsListWrap>
  )
}

const ItemsListWrap = styled.div`
  position: relative;
  ${breakpoint('mobile', 'tablet')`
    padding-bottom: 300px;
    
  `}
`

const ListActions = styled.button<{noMargin?: boolean}>`
  border: none;
  width:100%;
  display: flex;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  text-align: center;
  justify-content: center;
  ${({ noMargin }) => !noMargin && css`
    margin-left: 1px;
  `}
  padding: 4px;
  outline: none;
  cursor: pointer;
`

const ItemRow = styled(Row)`
  background: ${themeVar('brandOrangePale2xColor')};
  font-size: 12px;
`

const CategoryHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;
`
const AddMember = styled.button`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: center;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  fill: white;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
`

const AddMemberText = styled.span`
  margin: auto 15px;
`

const AddGroup = styled(AddMember)`
  background: white;
  color: ${themeVar('brandOrangeColor')};
  fill: ${themeVar('brandOrangeColor')};
  border-bottom: 1px solid ${themeVar('brandOrangePale2xColor')};
`
