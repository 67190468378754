import * as React from 'react'
import styled from 'styled-components'

import { Card } from '@/ui'

import {
  Wrap,
  Title,
  Subtitle,
  Text,
  Ul,
  Li,
  Link,
} from '../parts'

export const TosFR = () => (
  <Wrap>
    <Title>
      ARTICLE 1 : OBJET
    </Title>
    <Text>
      Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement juridique des modalités de
      mise à disposition des services du site
      {' '}
      <Link href="http://www.snouki.fr" blank>http://www.snouki.fr</Link>
      et leur utilisation par « l'Utilisateur ».
    </Text>
    <Text>
      Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur souhaitant accéder au site.
      L’accès au site par l’Utilisateur signifie son acceptation des présentes conditions générales d’utilisation.
    </Text>
    <Text>
      Éventuellement:
    </Text>
    <Ul>
      <Li>
        En cas de non-acceptation des conditions générales d'utilisation, l'Utilisateur se doit de renoncer à l'accès
        des services proposés par le site.
      </Li>
      <Li>
        SNOUKI se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes conditions
        générales d'utilisation.
      </Li>
    </Ul>


    <Title>
      ARTICLE 2 : MENTIONS LÉGALES
    </Title>
    <Subtitle>
      1. La politique de confidentialité chez Snouki
    </Subtitle>
    <Text>
      La politique de confidentialité concerne l’ensemble des pratiques liée à l’utilisation de vos données personnelles
      sur le site Snouki Cette politique s’applique également à l’ensemble des applications, supports et opérations
      de communication émis par Snouki susceptibles de faire appel à des données personnelles.
    </Text>

    <Subtitle>
      2. Les données collectées
    </Subtitle>
    <Ul>
      <Li noMarker>
        <Subtitle>
          Les interactions sur
          {' '}
          <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
        </Subtitle>

        <Text>
          Dans le cadre d’une inscription sur www.snouki.fr, d’une inscription aux alertes newsletters ou d’une
          demande effectuée via le formulaire d’ouverture de compte, www.snouki.fr collecte des données vous concernant.
        </Text>
        <Text>
          Il peut s’agir de votre nom, votre prénom, votre civilité, votre adresse, votre code postal, la ville dans
          laquelle vous habitez, votre adresse e-mail. En fonction de votre interaction avec le site www.snouki.fr
          certaines de ces données peuvent se montrer obligatoires à fournir tandis que d’autres sont facultatives.
          Ces informations peuvent être mises à jour par vos soins dans votre espace personnel et peuvent être combinées
          pour vous proposer une offre plus adaptée.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Les alertes newsletters Snouki
        </Subtitle>
        <Text>
          Les différentes communications de Snouki que vous pouvez recevoir par email sont tracées. Ce traçage nous
          permet de savoir si vous avez réceptionné, ouvert et éventuellement interagi avec le contenu transmis.
          Si vous ne souhaitez pas communiquer d’information via les e-mails Snouki, vous avez la possibilité de
          vous désabonner en modifiant vos préférences d’alertes.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Publicité sur internet
        </Subtitle>
        <Text>
          Snouki peut être amené à effectuer de la publicité sur internet. Ces publicités apparaissent dans la mesure
          où votre navigation a été ciblée par nos différentes campagnes. Ce ciblage peut s’effectuer d’un point de
          vue géographique mais également si le site sur lequel la publicité apparait présente un intérêt potentiel
          connexe à l’activité de Snouki. Un traçage peut être effectué suite à une visite provenant d’une publicité
          sur internet par l’intermédiaire d’une balise de traçage, un script de programmation permettant de mesurer
          le comportement d’un internaute.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Réseaux sociaux
        </Subtitle>
        <Text>
          Snouki dispose d’une présence sur Facebook ainsi que sur d’autres réseaux sociaux. Par l’intermédiaire de
          boutons, il est possible de suivre @Snouki sur twitter ainsi que « d’aimer » Snouki sur Facebook. Les
          informations suggérées par ces actions peuvent éventuellement être utilisées par le réseau social
          concerné. Consulter leur politique de confidentialité est donc nécessaire pour connaitre en détail
          les conséquences éventuelles.
        </Text>
        <Text>
          Par ailleurs, il est possible de s’inscrire sur Snouki par l’intermédiaire d’un compte Facebook.
          Les informations collectées grâce à cette opération peuvent être votre nom, votre prénom, votre
          adresse e-mail, votre photo de profil, la ville dans laquelle vous vivez et votre date de naissance.
          Ces mises à jour dans votre compte et peuvent être utilisées et combinées par Snouki dans le but de vous
          proposer l'offre la plus adaptée.
        </Text>
      </Li>
    </Ul>

    <Subtitle>
      3. Modifications de vos données
    </Subtitle>
    <Text>
      Conformément à la loi informatique et liberté (Certifiée RGPD), vous êtes libre à tout moment de modifier ou
      supprimer vos données personnelles. Ceci peut s’effectuer en vous connectant à votre compte sur
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      Notre service client reste également à votre joignable depuis notre formulaire de contact. Bien que vous soyez
      désinscrit de nos alertes e-mails, vos données peuvent être conservées.
    </Text>

    <Subtitle>
      4. Modification de la politique de confidentialité
    </Subtitle>
    <Text>
      La présente politique de confidentialité peut être modifiée à tout moment. Toute modification significative
      vous sera communiquée. En cas de changement, le fait que vous continuiez à utiliser les fonctionnalités du
      site
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      équivaut à l’acceptation de cette dernière.
    </Text>

    <Subtitle>
      5. Utilisation des données collectés
    </Subtitle>
    <Text>
      Snouki utilise les données collectées à plusieurs fins:
    </Text>
    <Ul>
      <Li>
        Vous communiquer l’offre la plus adaptée possible en fonction des éléments en notre possession
      </Li>
      <Li>
        Améliorer nos contenus afin de vous proposer la meilleure navigation possible sur le site
        {' '}
        <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      </Li>
      <Li>
        Répondre le mieux possible aux questions que vous nous poseriez (en fonction de votre magasin par exemple)
      </Li>
    </Ul>

    <Subtitle>
      6. Cookies
    </Subtitle>
    <Text>
      Votre navigation sur
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      peut donner lieu à l’utilisation de cookies. Il s’agit de petits volumes
      installés par votre navigateur sur votre disque dur. Une fois reconnus, ils permettent de nous informer sur
      les caractéristiques de votre visite sur notre site. Par défaut, la plupart des navigateurs acceptent les cookies.
      Toutefois, il vous est possible de modifier ce choix pour que votre visite ne soit pas comptabilisée dans nos
      outils de mesures d’audience. Le cas échéant, cela ne gêne pas la navigation sur le site et l’ensemble des
      fonctionnalités de ce dernier restent accessibles. Vous trouverez plus d'informations sur le site de la CNIL.
    </Text>
    <Text>
      Snouki est amené à utiliser 3 types de cookies:
    </Text>
    <Ul>
      <Li>
        <b>Les cookies de session ou de préférences: </b>
        ces cookies sont indispensables à la navigation et au bon fonctionnement du site. Par conséquent,
        vous ne pouvez-vous opposer à leur utilisation
      </Li>
      <Li>
        <b>Les cookies de mesure d'audience: </b>
        ces cookies permettent de suivre votre navigation sur le site à des fins statistiques et pour permettre à
        Snouki de mieux connaitre son audience pour améliorer son service, vous pouvez vous opposer à leur utilisation.
      </Li>
      <Li>
        <b>Les cookies de tierce partie: </b>
        ces cookies sont déposés par des partenaires dans le cadre de partenariats commerciaux ou publicitaires.
        Vous pouvez vous opposer à leur utilisation.
      </Li>
    </Ul>


    <Title>
      ARTICLE 3 : DÉFINITIONS
    </Title>
    <Text>
      La présente clause a pour objet de définir les différents termes essentiels des conditions générales
      d’utilisations:
    </Text>
    <Ul>
      <Li>
        Utilisateur : ce terme désigne toute personne qui utilise le site ou l'un des services proposés par le site.
      </Li>
      <Li>
        Contenu utilisateur : ce sont les données transmises par l'Utilisateur au sein du site.
      </Li>
      <Li>
        Membre : l'Utilisateur devient membre lorsqu'il est identifié sur le site.
      </Li>
      <Li>
        Identifiant et mot de passe : c'est l'ensemble des informations nécessaires à l'identification d'un Utilisateur
        sur le site. L'identifiant et le mot de passe permettent à l'Utilisateur d'accéder à des services
        réservés aux membres du site. Le mot de passe est confidentiel.
      </Li>
    </Ul>

    <Title>
      ARTICLE 4 : ACCÈS AUX SERVICES
    </Title>
    <Text>
      Le site permet à l'Utilisateur un accès notamment aux informations suivantes:
    </Text>
    <Ul>
      <Li>
        Implantations géographiques sur un site donné
      </Li>
      <Li>
        Sociétés de rattachement
      </Li>
      <Li>
        Intervenants sur un site donnée
      </Li>
      <Li>
        Contrats (bail, assurance, électricité …)
      </Li>
      <Li>
        ...
      </Li>
    </Ul>
    <Text>
      Le site est accessible sans surcoût en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
      supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.)
      sont à sa charge.
    </Text>
    <Text>
      L’Utilisateur non membre n'a pas accès aux services réservés aux membres. Pour cela, il doit s'identifier à l'aide
      de son identifiant et de son mot de passe.
    </Text>
    <Text>
      Tout événement dû à un cas de force majeure, ou toute cause extérieure au périmètre d’intervention
      de &lt;Nom_Utilisateur&gt;, ayant notamment pour conséquence un dysfonctionnement du réseau ou du serveur n'engage pas
      la responsabilité de &lt;Nom_Utilisateur&gt;.
    </Text>
    <Text>
      L'accès aux services du site peut à tout moment faire l'objet d'une interruption, d'une suspension, d'une
      modification sans préavis pour une maintenance. Dans ce cas, l'Utilisateur s'oblige à ne réclamer aucune
      indemnisation suite à l'interruption, à la suspension ou à la modification du service.
    </Text>


    <Title>
      ARTICLE 5 : PROPRIÉTÉ INTELLECTUELLE
    </Title>
    <Text>
      Les marques, logos, signes et tout autre contenu du site font l'objet d'une protection par le Code de la
      propriété intellectuelle et plus particulièrement par le droit d'auteur.
    </Text>
    <Text>
      L'Utilisateur sollicite l'autorisation préalable du site pour toute reproduction,
      publication, copie des différents contenus.
    </Text>
    <Text>
      L'Utilisateur s'engage à une utilisation des contenus du site dans un cadre strictement privé.
      Une utilisation des contenus à des fins autres notamment commerciales est strictement interdite.
    </Text>


    <Title>
      ARTICLE 6 : DONNÉES PERSONNELLES
    </Title>
    <Text>
      Les informations demandées à l’inscription au site sont nécessaires et obligatoires pour la création du compte
      de l'Utilisateur. En particulier, l'adresse électronique pourra être utilisée par le site pour l'administration,
      la gestion et l'animation du service.
    </Text>
    <Text>
      Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le
      respect de la RGPD entrée en vigueur le 25 mai. L’Utilisateur dispose d’un droit de consentement.
    </Text>
    <Text>
      L'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses
      données personnelles. L'Utilisateur exerce ce droit:
    </Text>
    <Ul>
      <Li>
        par mail à
        {' '}
        <Link href="mailto:contact@snouki.fr">contact@snouki.fr</Link>
      </Li>
    </Ul>


    <Title>
      ARTICLE 7 : RESPONSABILITÉ ET FORCE MAJEURE
    </Title>
    <Text>
      Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, pour les données qui ne
      sont pas directement produites par &lt;Nom_Utilisateur&gt;, le site se réserve la faculté d'une non-garantie de
      la fiabilité des sources. Ces informations données sur le site le sont à titre purement informatif. Ainsi,
      l'Utilisateur assume seul l'entière responsabilité de l'utilisation de ces informations et contenus du
      présent site.
    </Text>
    <Text>
      L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que
      soit sa forme, est interdite.
    </Text>
    <Text>
      L'Utilisateur assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline
      toute responsabilité.
    </Text>
    <Text>
      La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable
      d'un tiers ou du fait d’un intervenant extérieur à &lt;Nom_Utilisateur&gt;.
    </Text>


    <Title>
      ARTICLE 8 : ÉVOLUTION DES CONDITIONS GÉNÉRALES D’UTILISATION
    </Title>
    <Text>
      Le site se réserve à tout moment le droit de modifier les clauses stipulées dans les présentes Conditions
      Générales d’Utilisation.
    </Text>


    <Title>
      ARTICLE 9 : DURÉE
    </Title>
    <Text>
      La durée des présentes Conditions Générales d’Utilisation est définie par l’accord qui lie SNOUKI et ses
      utilisateurs. Les présentes Conditions Générales d’Utilisation produisent leurs effets à l'égard
      de l'Utilisateur à compter de l'utilisation du service.
    </Text>


    <Title>
      ARTICLE 10 : DROIT APPLICABLE ET JURIDICTION COMPÉTENTE
    </Title>
    <Text>
      La législation française s'applique aux présentes Conditions Générales d’Utilisation.
    </Text>
  </Wrap>
)
