import * as React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'

export const UnderDevelopment = () => (
  <Wrap>
    <h3>Page is under development</h3>
  </Wrap>
)

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
`
