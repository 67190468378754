import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  InputField,
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  InputTextArea,
} from '@/ui'
import { SignatoryForm } from '../../model/signature'

export const AddSignatureForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields, submit, reset } = useForm(SignatoryForm)

  return (
    <BlockWrap>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('contractSignatory')}
            <sup>*</sup>
          </SubTitle>
          <InputField
            value={fields.signatory.value}
            onChangeValue={fields.signatory.onChange}
            errorText={fields.signatory.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3 * 2)" xs="100%" align="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('remarks')}
          </SubTitle>
          <InputTextArea
            value={fields.remarks.value}
            onChangeValue={fields.remarks.onChange}
          />
        </>
      </Col>
      <Col width="100%" noPadding noMargin>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
              onClick={reset}
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
              onClick={submit}
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
  & [class*="ErrorText"] {
    color: white !important;
  }
`
