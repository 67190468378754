import * as React from 'react'
import {
  Col,
  Row,
  Checkbox,
  themeVar,
  InputNumber,
  AccentText,
  Visible,
} from '@/ui'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

export type ItemI = {
  price: number;
  name: string;
  description: string;
  img: string;
}

type Props = {
    item: ItemI;
}

export function ItemCard({ item }: Props) {
  const [count, setCount] = React.useState<number>(0)
  const [summ, setSumm] = React.useState<number>(count * item.price)
  React.useEffect(() => {
    setSumm(count * item.price)
  }, [count])
  return (
    <ItemWrap>
      <Visible tablet>
        <Col width="40px">
          <Checkbox
            checked={false}
            onChange={() => {}}
          />
        </Col>
        <Col width="60px">
          <ItemImage src={item.img} alt={item.name} />
        </Col>
        <Col width={6}>
          <>
            <Col width="100%" align="flex-start" noMargin>
              <ItemName>
                {item.name}
              </ItemName>
            </Col>
            <Col width="100%" align="flex-start">
              <ItemDescription>
                {item.description}
              </ItemDescription>
            </Col>
          </>
        </Col>
        <Col width={2}>
          <InputNumber
            value={count}
            onChangeValue={(val) => setCount(val)}
          />
        </Col>
        <Col width={1}>
          <ItemPrice>
            {item.price}
            $
          </ItemPrice>
        </Col>
        <Col width={1}>
          <SummaryPrice>
            <AccentText>
              {summ}
              $
            </AccentText>
          </SummaryPrice>
        </Col>
      </Visible>
      <Visible mobile>
        <Row>
          <Col width="40px" noMargin>
            <Checkbox
              checked={false}
              onChange={() => {}}
            />
          </Col>
          <Col width={2} align="flex-start">
            <ItemName>
              {item.name}
            </ItemName>
          </Col>
          <Col width={1}>
            <ItemPrice>
              <AccentText>
                {item.price}
                $
              </AccentText>
            </ItemPrice>
          </Col>
        </Row>
        <Row>
          <Col width={1} noMargin>
            <ItemImage src={item.img} alt={item.name} />
          </Col>
        </Row>
        <Row>
          <Col width={1} align="flex-start" noMargin>
            <ItemDescription>
              {item.description}
            </ItemDescription>
          </Col>
        </Row>
        <Row>
          <Col width="50%" noMargin>
            <InputNumber
              value={count}
              onChangeValue={(val) => setCount(val)}
            />
          </Col>
        </Row>
        <Col width="100%" align="center" noMargin>
          <SummaryPrice>
            total:
            {' '}
            {summ}
            $
          </SummaryPrice>
        </Col>
      </Visible>
    </ItemWrap>
  )
}

const ItemWrap = styled(Row)`
    padding-left: 40px;
    border-bottom: 1px solid ${themeVar('brandOrangePale2xColor')};
    ${breakpoint('mobile', 'tablet')`
      padding: 0px 25px;
      margin: 0px 25px 25px;
      width: auto;
      border-radius: 4px;
      border: 1px solid ${themeVar('brandOrangePale2xColor')};
    `}
    ${breakpoint('mobile', 'tablet')`
      &:focus, &:hover{
        border: 1px solid ${themeVar('brandOrangeColor')};
        box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28);
      }
    `}
`

const ItemName = styled.span`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4px;
  ${breakpoint('mobile', 'tablet')`
    font-size: 14px;
  `}
`

const ItemDescription = styled.span`
  font-size: 10px;
`
const ItemImage = styled.img`
  width: 100%;
`

const ItemPrice = styled.span`
  font-size: 12px;
  ${breakpoint('mobile', 'tablet')`
    font-size: 16px;
  `}
`


const SummaryPrice = styled.span`
  font-size: 12px;
  font-weight: 300;
  ${breakpoint('mobile', 'tablet')`
    font-size: 16px;
    width: 100%;
    padding: 13px 25px;
    color: white;
    background: ${themeVar('brandOrangeColor')};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 60%;
    text-align: center;
    text-transform: uppercase;
  `}
`
