import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateContractDocumentRes } from '@/dal/contracts.interfaces'
import {
  createContractDocumentReqFx,
  deleteContractDocumentReqFx,
  attachContractDocumentToTenantLeaseReqFx,
  detachContractDocumentFromTenantLeaseReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateContractDocumentRes[]>([])

export const loadList = d.createEvent<CreateContractDocumentRes[]>()
export const addItem = d.createEvent<CreateContractDocumentRes>()
export const deleteItem = d.createEvent<CreateContractDocumentRes>()
export const dropItem = d.createEvent<CreateContractDocumentRes['id']>()

export const DocumentForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    version: {
      init: '',
    },
    validityEndDate: {
      init: '',
    },
    documentType: {
      init: '',
    },
    active: {
      init: true,
    },
    description: {
      init: '',
    },
    content: {
      init: {} as Document,
      rules: [rules.required()],
    },
  },
})

export const createItemFx = attach({ effect: createContractDocumentReqFx })
export const deleteItemFx = attach({ effect: deleteContractDocumentReqFx })
export const attachItemToTenantLeaseFx = attach({ effect: attachContractDocumentToTenantLeaseReqFx })
export const detachItemFromTenantLeaseFx = attach({ effect: detachContractDocumentFromTenantLeaseReqFx })
