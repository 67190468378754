import { forward } from 'effector'
import { history } from '@/lib/history'
import {
  $activeTab, setTab, $serviceProviderContract, getServiceProviderFx, loadContract, setContract,
} from './unit'

$activeTab
  .on(setTab, (_, r) => r)


$serviceProviderContract
  .on(getServiceProviderFx.doneData, (_, r) => r.serviceProviderContract)
  .on(setContract, (_, r) => r)
  .reset(getServiceProviderFx.fail)

forward({
  from: loadContract.map((val) => ({ id: val })),
  to: getServiceProviderFx,
})

getServiceProviderFx.fail.watch(() => {
  history.push('/service-provider')
})

setContract.watch((contract) => {
  history.push(`/service-provider/${contract.id}`)
})
