import * as React from 'react'
import styled from 'styled-components'

import { themeVar } from '@/ui'
import { dateTimeSlash } from '@/lib/dates'

type Props = {
    date: string;
    title: string;
    text: string;
    more: () => void;
}
export const NewsItem = ({
  date, title, text, more,
}: Props) => (
  <NewsWrap>
    <NewsDate>
      {dateTimeSlash(date)}
    </NewsDate>
    <NewsTitle>
      {title}
    </NewsTitle>
    <NewsText>
      {text}
    </NewsText>
    <NewsMore onClick={() => more()}>
      More
    </NewsMore>
  </NewsWrap>
)

const NewsWrap = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 40px;
`

const NewsDate = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    color: ${themeVar('newsDateColor')};
    font-size: 10px;
`

const NewsTitle = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    color: ${themeVar('newsTextColor')};
    font-size: 18px;
    font-weight: 600;
`

const NewsText = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    color: ${themeVar('newsTextColor')};
    font-size: 18px;
    font-weight: 300;
`

const NewsMore = styled.div`
    display: flex;
    margin-bottom: 15px;
    color: ${themeVar('newsMoreBtnColor')};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
`
