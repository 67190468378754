import * as React from 'react'
import { useStore } from 'effector-react'
import {
  Modal,
  PrimaryButton,
  Title,
  Col,
  Row,
  InputSwitch,
  WrapField,
} from '@/ui'
import styled from 'styled-components'
import {
  $isPreRegistrationModalShown,
  hidePreRegistrationDialog,
  registrate,
} from '../../model'
import { InternalForm } from './InternalForm'
import { ExternalForm } from './ExternalForm'

export const PreRegistration = () => {
  const isPreRegistrationModalShown = useStore($isPreRegistrationModalShown)
  const [type, setType] = React.useState<boolean>(false)
  const SelectUserType = [
    { value: false, text: 'internal' },
    { value: true, text: 'external' },
  ]
  return (
    <Modal
      show={isPreRegistrationModalShown}
      onClose={() => hidePreRegistrationDialog()}
    >
      <>
        <ContentWrap>
          <Row>
            <Col noPadding width="100%">
              <WrapField>
                <Title>pre-registration</Title>
              </WrapField>
            </Col>
            <Col noPadding width="80%">
              <WrapField>
                <InputSwitch
                  value={type}
                  items={SelectUserType}
                  onChange={(val) => setType(Boolean(val))}
                />
              </WrapField>
            </Col>
          </Row>
          <Col noPadding width="80%">
            <>
              {!type && (
                <InternalForm />
              )}
              {type && (
                <ExternalForm />
              )}
            </>
          </Col>
          <Row>
            <Col noPadding width="80%">
              <PrimaryButton
                fluid
                onClick={() => registrate()}
              >
                registrate
              </PrimaryButton>
            </Col>
          </Row>
        </ContentWrap>

      </>
    </Modal>
  )
}


const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
