import {
  createDomain, combine, forward, Domain, Store, Event,
} from 'effector'
import { useStore } from 'effector-react'

interface FilterConfig<T = any> {
  value: T;
  include?: boolean;
}

export interface FiltersConfig<T> {
  [fieldName: string]: FilterConfig<T>;
}

export type AnyFiltersConfig = FiltersConfig<any>

interface Config<FiltersConfig extends AnyFiltersConfig> {
  domain?: Domain;
  filters: FiltersConfig;
  logicFilter?: boolean;
}

interface Filter<V> {
  $value: Store<V>;
  $include: Store<boolean>;
  setValue: Event<V>;
  setInclude: Event<boolean>;
  includeAndSetValue: Event<V>;
  changeValue: Event<V>;
  reset: Event<void>;
}

type ResultFilters<FiltersConfig extends AnyFiltersConfig> = {
  [K in keyof FiltersConfig]: FiltersConfig[K] extends FilterConfig<infer U>
    ? Filter<U>
    : never
}

export interface ResultDataFilter<FilterValue = any> {
  include: boolean;
  key: string;
  value: FilterValue;
}

export type ResultData = {
  filters: ResultDataFilter[];
  logicFilter: boolean;
}

interface Result<FiltersConfig extends AnyFiltersConfig> {
  resetFilters: Event<void>;
  filters: ResultFilters<FiltersConfig>;
  $result: Store<ResultData>;
}

const createField = <V>(filter: FilterConfig<V>, d: Domain): Filter<V> => {
  const { value, include = false } = filter

  const $value = d.store<V>(value)
  const $include = d.store(include)

  const setAll = d.event<{value: V; include: boolean}>()
  const setValue = d.event<V>()
  const setInclude = d.event<boolean>()
  const includeAndSetValue = d.event<V>()
  const changeValue = d.event<V>()
  const reset = d.event()

  $value
    .on(setValue, (_, x) => x)
    .on(setAll, (_, x) => x.value)
    .reset(reset)

  $include
    .on(setInclude, (_, x) => x)
    .on(setAll, (_, x) => x.include)
    .reset(reset)

  includeAndSetValue.watch((x) => {
    setAll({
      value: x,
      include: true,
    })
  })

  changeValue.watch((x) => {
    setAll({
      value: x,
      // @ts-ignore
      include: x !== null && x !== '',
    })
  })

  return {
    $value,
    $include,
    setValue,
    setInclude,
    includeAndSetValue,
    changeValue,
    reset,
  }
}

export const createFilters = <FiltersConfig extends AnyFiltersConfig>(config: Config<FiltersConfig>) => {
  const { domain, filters, logicFilter = false } = config

  const d = domain || createDomain('filtersDomain')

  const resetFilters = d.createEvent<void>()

  const resultFilters = {} as ResultFilters<AnyFiltersConfig>
  // generate fields and bind their resets to form reset
  for (const filterName in filters) {
    if (!filters.hasOwnProperty(filterName)) continue
    const field = createField(filters[filterName], d)
    resultFilters[filterName] = field

    forward({
      from: resetFilters,
      to: field.reset,
    })
  }

  // resulting store. compatible to send it to api
  const $resultFilters = combine(Object.entries(resultFilters).map(([key, value]) => combine({
    include: value.$include,
    value: value.$value,
    key,
  })))

  // todo: добавить обработку случая, когда Array.isArray(f.value). разбить на несколько объектов с одинаковым ключом
  const $result = $resultFilters.map((f) => ({
    filters: f.filter((x) => x.include),
    logicFilter,
  }))

  return {
    resetFilters,
    filters: resultFilters,
    $result,
  } as Result<FiltersConfig>
}

type UseSingleFilterHookResult<V> = {
  value: V;
  include: boolean;
  setValue: Event<V>;
  setInclude: Event<boolean>;
  includeAndSetValue: Event<V>;
  changeValue: Event<V>;
  reset: Event<void>;
}

type AnySingleFilter = {
  [key: string]: UseSingleFilterHookResult<any>;
}

type FiltersHookResult<Filters extends AnyFiltersConfig> = {
  [K in keyof Filters]: Filters[K] extends FilterConfig<infer U>
    ? UseSingleFilterHookResult<U>
    : never
}

type UseFilersHookResult<Filters extends AnyFiltersConfig> = {
  filters: FiltersHookResult<Filters>;
}


export const useSingleFilter = <V>(field: Filter<V>) => {
  const { $value, $include, ...events } = field

  const include = useStore($include)
  const value = useStore($value)

  return {
    include,
    value,
    ...events,
  } as UseSingleFilterHookResult<V>
}

export const useFilters = <FiltersConfig extends AnyFiltersConfig>(filtersResult: Result<FiltersConfig>) => {
  const { filters } = filtersResult
  const result = {} as AnySingleFilter

  for (const f in filters) {
    if (filters.hasOwnProperty(f)) {
      result[f] = useSingleFilter(filters[f])
    }
  }

  return {
    filters: result as FiltersHookResult<FiltersConfig>,
  } as UseFilersHookResult<FiltersConfig>
}
