import styled from 'styled-components'
import { ThemedStyledProps, themeVar } from '../theming'

export const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 12px;
  font-size: 12px;
  padding-top: 3px;
  width: 100%;
  text-align: center;
`
