import { createDomain, attach } from 'effector'

import {
  fetchMyAccountReqFx,
  fetchMyCompanyReqFx,
  Account,
  setAccountTariffReqFx,
} from '@/dal'

const d = createDomain()

export const $startRoute = d.store('')

export const checkTariffPlan = d.event<void>()
export const checkTariffPlanType = d.event<Account['type']>()
export const checkCompany = d.event<void>()
export const goToRoute = d.event<string>()

export const setStartRoute = d.event<void>()
export const setRoute = d.event<string>()
export const goToStartRoute = d.event<void>()

export const fetchMyAccountFx = attach({ effect: fetchMyAccountReqFx })
export const fetchMyCompanyFx = attach({ effect: fetchMyCompanyReqFx })
