import {
  createDomain, createStore, attach, Effect,
} from 'effector'
import { createForm } from 'effector-forms'

import {
  fetchCounterpartyWithContactsListReqFx,
  createCounterpartyReqFx,
  createAllCounterpartyContactsReqFx,
  CounterpartyProfile,
  CounterpartyType,
  CounterpartyWithAllContacts,
  FetchCounterpartyWithContactsListParams,
} from '@/dal'
import { rules } from '@/lib/validators'

export const d = createDomain()

export const reset = d.event<void>()

export const $isCreationModalOpen = createStore(false)
export const openCreationModal = d.event<void>()
export const closeCreationModal = d.event<void>()

export const $isEmailChecked = d.store(false)
export const $emailCheckMessage = d.store<string>('')
export const $foundCounterparty = d.store<CounterpartyWithAllContacts | null>(null)
export const counterpartyFound = d.event<CounterpartyWithAllContacts>()
export const setEmailCheckMessage = d.event<string>()

export const $formError = d.store('')
export const createNewCounterparty = d.event<void>()
export const createAllCounterpartyContacts = d.event<void>()

export const emailForm = createForm({
  domain: d,
  fields: {
    email: {
      init: '' as string,
      rules: [rules.required(), rules.isEmail()],
    },
  },
})

export const mainForm = createForm({
  domain: d,
  fields: {
    type: {
      init: 'service-provider' as CounterpartyType,
      rules: [rules.required()],
    },
    companyName: {
      init: '',
    },
    companyCode: {
      init: '',
    },
    firstName: {
      init: '',
      rules: [rules.required()],
    },
    lastName: {
      init: '',
      rules: [rules.required()],
    },
    phones: {
      init: [] as string[],
    },
    defaultPhone: {
      init: '',
      rules: [rules.required()],
    },
    fax: {
      init: '',
    },
    webSite: {
      init: '',
      rules: [rules.isURL()],
    },
    address: {
      init: '',
    },
  },
})

export const createCounterpartyFx = attach({ effect: createCounterpartyReqFx })
export const createAllCounterpartyContactsFx = attach({ effect: createAllCounterpartyContactsReqFx })
export const fetchCounterpartyListFx = attach({
  effect: fetchCounterpartyWithContactsListReqFx,
  source: emailForm.fields.email.$value,
  mapParams: (params: void, email) => ({
    offset: 0,
    limit: 1,
    filters: [
      {
        key: 'email',
        include: true,
        value: email,
      },
    ],
    logicFilter: true,
  } as FetchCounterpartyWithContactsListParams),
})
