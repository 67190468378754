import * as React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  themeVar,
  Select,
  InputField,
  PhoneInput,
  Title,
  Col,
  SmallText,
  Row,
  InputDateTime,
  SubTitle,
  AccentText,
  CheckboxList,
  PrimaryButton,
} from '@/ui'

import { useUserInfo } from '@/features/app/hooks'
import { NotificationType } from '@/dal'
import {
  $languageSelectItemsList,
  $titleSelectItemsList,
  userSettingsForm,
  $userSettings,
  getUserSettingsFx,
  getUserNotificationSettingsFx,
  userNotificationSettingsForm,
  saveUserSettings,
  updateUserSettingsReqFx,
  setUserNotificationSettingsFx,
  showModal as showChangePasswordForm,
} from '../../model'
import { ImagePicker, PhoneNumbers } from '../parts'


export const ProfileForm = () => {
  const { t } = useTranslation('settings/form')

  const languageSelectItemsList = useStore($languageSelectItemsList)
  const titleSelectItemsList = useStore($titleSelectItemsList)
  const loading = useStore(updateUserSettingsReqFx.pending)
  const notificationLoading = useStore(setUserNotificationSettingsFx.pending)
  const form = useForm(userSettingsForm)
  const notificationFields = useForm(userNotificationSettingsForm).fields
  const { user } = useUserInfo()


  React.useEffect(() => {
    getUserSettingsFx()
    getUserNotificationSettingsFx()
  }, [])


  const notificationType: {value: NotificationType; text: string; disabled?: boolean}[] = [
    { value: 'Email', text: 'Email' },
  ]
  return (
    <Card>
      <Row>
        <Title>
          {t('title')}
        </Title>
      </Row>
      <Row>
        <ImagePicker
          file={form.fields.photo.value}
          onFile={form.fields.photo.onChange}
        />
      </Row>
      <Row align="flex-start">
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              1.
              {' '}
              {t('fields.language')}
            </SubTitle>
            <Select
              items={languageSelectItemsList}
              value={form.fields.language.value}
              errorText={form.fields.language.errorText()}
              onChange={form.fields.language.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              2.
              {' '}
              {t('fields.title')}
            </SubTitle>
            <Select
              items={titleSelectItemsList}
              value={form.fields.title.value}
              errorText={form.fields.title.errorText()}
              onChange={form.fields.title.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              3.
              {' '}
              {t('fields.lastName')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              value={form.fields.lastName.value}
              onChangeValue={form.fields.lastName.onChange}
              errorText={form.fields.lastName.firstError?.errorText}
            />
          </>
        </Col>

        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              4.
              {' '}
              {t('fields.firstName')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              value={form.fields.firstName.value}
              onChangeValue={form.fields.firstName.onChange}
              errorText={form.fields.firstName.firstError?.errorText}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              5.
              {' '}
              {t('fields.marriedLastName')}
            </SubTitle>
            <InputField
              value={form.fields.marriedLastName.value}
              onChangeValue={form.fields.marriedLastName.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              6.
              {' '}
              {t('fields.lastNameOfUse')}
            </SubTitle>
            <InputField
              value={form.fields.lastNameOfUse.value}
              onChangeValue={form.fields.lastNameOfUse.onChange}
            />
          </>
        </Col>

        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              7.
              {' '}
              {t('fields.firstNameOfUse')}
            </SubTitle>
            <InputField
              value={form.fields.firstNameOfUse.value}
              onChangeValue={form.fields.firstNameOfUse.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              8.
              {' '}
              {t('fields.email')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              disabled
              value={form.fields.email.value}
              onChangeValue={form.fields.email.onChange}
              errorText={form.fields.email.firstError?.errorText}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              9.
              {' '}
              {t('fields.dateOfBirth')}
            </SubTitle>
            <InputDateTime
              onlyDate
              value={form.fields.dateOfBirth.value}
              onChange={(x) => form.fields.dateOfBirth.onChange(x || '')}
              max={new Date()}
            />
          </>
        </Col>

        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <PhoneTitleWrap>
              <SubTitle>
                10.
                {' '}
                {t('fields.phoneNumbers')}
                <AccentText>*</AccentText>
              </SubTitle>
              <AccentText>
                <SmallText>
                  {t('fields.defaultPhoneNUmber')}
                </SmallText>
              </AccentText>
            </PhoneTitleWrap>

            <PhoneInput
              accent
              value={form.fields.defaultPhone.value}
              errorText={form.fields.defaultPhone.errorText()}
              placeholder={t('fields.phone')}
              onChangeValue={form.fields.defaultPhone.onChange}
            />
          </>
        </Col>
        <PhoneNumbers
          phones={form.fields.phones.value}
          onChange={form.fields.phones.onChange}
        />

        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              11.
              {' '}
              {t('fields.fax')}
            </SubTitle>
            <InputField
              value={form.fields.fax.value}
              onChangeValue={form.fields.fax.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
          <>
            <SubTitle>
              11.
              {' '}
              {t('fields.role')}
            </SubTitle>
            <InputField
              disabled
              value={user?.role}
            />
          </>
        </Col>
      </Row>
      <Separator />
      <Row>
        <Title>
          {t('notification.title')}
        </Title>
      </Row>
      <Row align="flex-start">
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                1.
                {' '}
                {t('notification.demands')}

              </SubTitle>
            </Col>

            <CheckboxList
              items={notificationType}
              value={notificationFields.interventionDemands.value}
              onChange={(val) => notificationFields.interventionDemands.onChange(val)}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                2.
                {' '}
                {t('notification.consultations')}
              </SubTitle>
            </Col>

            <CheckboxList
              items={notificationType}
              value={notificationFields.consultations.value}
              onChange={(val) => notificationFields.consultations.onChange(val)}
            />
          </Row>
        </Col>
        <Col md="calc(100% / 3)" noMargin align="flex-start">
          <Row>
            <Col noPadding align="flex-start">
              <SubTitle>
                3.
                {' '}
                {t('notification.orders')}
              </SubTitle>
            </Col>

            <CheckboxList
              items={notificationType}
              value={notificationFields.orders.value}
              onChange={(val) => notificationFields.orders.onChange(val)}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="100%" md="180px">
          <PrimaryButton
            fluid
            onClick={() => showChangePasswordForm()}
          >
            {t('buttons.changePassword')}
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="180px">
          <PrimaryButton
            fluid
            onClick={() => saveUserSettings()}
            disabled={form.hasError() || loading || notificationLoading}
          >
            {t('buttons.save')}
          </PrimaryButton>
        </Col>
      </Row>
    </Card>
  )
}

const Card = styled.div`
    width: 100%;
    margin: 25px 0;
    background: white;
    border-radius: 8px;
    padding: 40px;
    overflow-x: hidden;
    box-shadow: 0 35px 55px rgba(213, 76, 46, 0.28);
`

const Separator = styled.hr`
  width: calc(100% + 80px);
  background: ${themeVar('brandGrayColor')};
  opacity: 0.15;
  position: relative;
  left: -40px;
  margin-bottom: 20px;
`
const PhoneTitleWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
