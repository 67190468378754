import { forward, sample } from 'effector'

import {
  $tariffList,
  filters,
  loadTariff,
  selectTariff,
  getTariffListFx,
  setAccountTypeFx,
  setAccountTariffFx,
} from './units'

$tariffList
  .on(getTariffListFx.doneData, (_, x) => x.tariffs)

forward({
  from: loadTariff,
  to: getTariffListFx,
})

forward({
  from: selectTariff.map((x) => ({ tariffID: x })),
  to: setAccountTypeFx,
})

forward({
  from: setAccountTypeFx.done.map(({ params }) => ({ tariffID: params.tariffID })),
  to: setAccountTariffFx,
})
