import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'
import { Heading, PrimaryButton } from '@/ui'
import { quickUUID } from '@/lib/random'
import { NewsItem } from '../parts'

export const NewsList = () => {
  const { t } = useTranslation('welcome/translation')
  const newsItems = [
    {
      date: '11 march, 2020',
      title: 'Global Home Price Growth Slows to a 6-Year Low in Q3',
      text: 'According to global real estate consultant Knight Frank, home prices across 56 countries and territories worldwide are rising at an annual rate of 3.7% on average ...',
    },
    {
      date: '13 march, 2020',
      title: 'Global Commercial Property Investment Flattens in 2019',
      text: 'According to new report from CBRE, global commercial real estate investment volume, including entity-level deals, rose by 7% quarter-over-quarter but fell by 2% ...',
    },
    {
      date: '17 march, 2020',
      title: 'Paris Hotels Back on Track After Rocky Start in 2019',
      text: 'In 2018 hotels in the city saw a 10.6% increase in RevPAR [rooms revenue per available room] year-on-year, but visitor numbers took a knock at the end of ...',
    },
  ]
  const newsItemList = newsItems.map(
    (el) => (<NewsItem more={() => console.log(el.title)} {...el} key={quickUUID()} />),
  )
  return (
    <Wrap>
      <Title>
        <Heading>{t('news.title')}</Heading>
      </Title>
      {newsItemList}
      <Action>
        <PrimaryButton fluid type="gray">
          {t('news.seeAll')}
        </PrimaryButton>
      </Action>
    </Wrap>
  )
}

const Wrap = styled.div`
    z-index: 2;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28);
    margin-bottom: 135px;
    padding: 50px 100px;
    ${breakpoint('mobile', 'tablet')`
        padding: 30px 18px;
        margin: 35px 6px;
    `}
`

const Title = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 35px;
`

const Action = styled.div`
    display: flex;
    width: 50%;
    margin: auto;
    ${breakpoint('mobile', 'tablet')`
        width: 100%;
    `}
`
