import i18n from 'i18next'

import {
  fetchMyUserReqFx,
  fetchMyProfileReqFx,
  fetchMyAccountReqFx,
  createOwnerProfileReqFx,
  updateProfileReqFx,
} from '@/dal'
import {
  $userInfo,
  $user,
  $accountInfo,
} from './units'

$user
  .on(fetchMyUserReqFx.doneData, (_, x) => x)

$userInfo
  .on(fetchMyProfileReqFx.doneData, (_, x) => x)
  .on(createOwnerProfileReqFx.doneData, (_, x) => x)
  .on(updateProfileReqFx.doneData, (_, x) => x)
  .watch((x) => {
    if (x?.language) {
      i18n.changeLanguage(x.language)
    }
  })

$accountInfo
  .on(fetchMyAccountReqFx.doneData, (_, x) => x.account)
