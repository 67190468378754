import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { Language } from '@/dal'
import {
  Row,
  Col,
  InputField,
  PhoneInput,
  Select,
  InputDateTime,
  SubTitle,
  AccentText,
  PrimaryButton,
  SmallText,
} from '@/ui'

import { ImagePicker, PhoneNumbers } from '../parts'
import { profileForm, $titleSelectItemsList, mounted } from '../../model'

export const CreateOwnerProfileForm = () => {
  const form = useForm(profileForm)
  const titleList = useStore($titleSelectItemsList)

  const languageList = [
    { value: 'en' as Language, text: 'En' },
    { value: 'fr' as Language, text: 'Fr' },
  ]

  const confirmFormAction = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.submit()
  }

  React.useEffect(() => {
    mounted()
  }, [])

  return (
    <Wrap onSubmit={confirmFormAction}>
      <>
        <Row>
          <ImagePicker
            file={form.fields.photo.value}
            onFile={form.fields.photo.onChange}
          />
        </Row>
        <Row align="flex-start">
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                1. language
              </SubTitle>
              <Select
                items={languageList}
                value={form.fields.language.value}
                errorText={form.fields.language.errorText()}
                onChange={form.fields.language.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                2. title
              </SubTitle>
              <Select
                items={titleList}
                value={form.fields.title.value}
                errorText={form.fields.title.errorText()}
                onChange={form.fields.title.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                3. last name
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={form.fields.lastName.value}
                onChangeValue={form.fields.lastName.onChange}
                errorText={form.fields.lastName.firstError?.errorText}
              />
            </>
          </Col>

          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                4. first name
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={form.fields.firstName.value}
                onChangeValue={form.fields.firstName.onChange}
                errorText={form.fields.firstName.firstError?.errorText}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                5. married last name
              </SubTitle>
              <InputField
                value={form.fields.marriedLastName.value}
                onChangeValue={form.fields.marriedLastName.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                6. last name of use
              </SubTitle>
              <InputField
                value={form.fields.lastNameOfUse.value}
                onChangeValue={form.fields.lastNameOfUse.onChange}
              />
            </>
          </Col>

          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                7. first name of use
              </SubTitle>
              <InputField
                value={form.fields.firstNameOfUse.value}
                onChangeValue={form.fields.firstNameOfUse.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                8. email
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                disabled
                value={form.fields.email.value}
                onChangeValue={form.fields.email.onChange}
                errorText={form.fields.email.firstError?.errorText}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                9. date of birth
              </SubTitle>
              <InputDateTime
                onlyDate
                value={form.fields.dateOfBirth.value}
                onChange={(x) => form.fields.dateOfBirth.onChange(x || '')}
                max={new Date()}
              />
            </>
          </Col>

          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <PhoneTitleWrap>
                <SubTitle>
                  10. Phone numbers
                  <AccentText>*</AccentText>
                </SubTitle>
                <AccentText>
                  <SmallText>
                    Default
                  </SmallText>
                </AccentText>
              </PhoneTitleWrap>

              <PhoneInput
                accent
                value={form.fields.defaultPhone.value}
                errorText={form.fields.defaultPhone.errorText()}
                placeholder="Phone number"
                onChangeValue={form.fields.defaultPhone.onChange}
              />
            </>
          </Col>
          <PhoneNumbers
            phones={form.fields.phones.value}
            onChange={form.fields.phones.onChange}
          />

          <Col md="calc(100% / 3)" noMargin vAlign="flex-start" align="flex-start">
            <>
              <SubTitle>
                11. fax
              </SubTitle>
              <InputField
                value={form.fields.fax.value}
                onChangeValue={form.fields.fax.onChange}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="180px">
            <PrimaryButton
              outline
              type="warning"
              appendIcon="close"
              onClick={form.reset}
            >
              clear
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px">
            <PrimaryButton
              htmlType="submit"
              appendIcon="rightChevron"
            >
              next step
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}

const Wrap = styled.form`
    padding: 20px 40px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px;
    `}
`

const PhoneTitleWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
