import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { ThemedStyledProps, themeVar } from '@/ui'

import { onCloseMenu } from '../../model'

interface Props {
  href: string;
  children?: React.ReactNode;
}

export const MenuItem: React.FC<Props> = (props) => {
  const { href, children } = props

  const [isActive, setActive] = React.useState(false)

  const isMatched = (match: any) => {
    setActive(Boolean(match))
    return Boolean(match)
  }

  return (
    <Item
      to={href}
      isActive={isMatched}
      active={isActive.toString()}
      exact
      onClick={() => onCloseMenu()}
    >
      {children}
    </Item>
  )
}

MenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

MenuItem.defaultProps = {
  children: null,
}

const Item = styled(NavLink)<{active?: string} & NavLinkProps & ThemedStyledProps>`
  display: flex;
  width: 320px;
  height: 41px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: black;
  text-decoration: none !important;
  text-transform: uppercase;
  transition: all 0.1s linear;
  ${(p) => p.active === 'true' && css`
    color: ${themeVar('mainMenuActiveItemColor')};
  `}
  
  :hover {
    color: ${themeVar('mainMenuItemHoverColor')};
    background-color: ${themeVar('mainMenuItemHoverBg')};
  }
`
