import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { rules } from '@/lib/validators'
import { createServiceProviderContractReqFx, editServiceProviderContractReqFx } from '@/dal/contracts'

import { FetchCounterpartyWithContactsListParams, fetchCounterpartyWithContactsListReqFx } from '@/dal'
import {
  ServiceProviderContractIndexingType,
  ServiceProviderContractType,
  ServiceProviderContractGroup,
  ReviewType,
  ReferenceIndex,
} from '@/dal/contracts.interfaces'

import { createAutocomplete } from '@/lib/effector-autocomplete'

const d = createDomain('d')

export const createContract = d.createEvent()
export const updateContract = d.createEvent()

export const counterpartyAutocomplete = createAutocomplete({
  domain: d,
  fields: ['companyName'],
  initValue: '',
  effect: attach({
    effect: fetchCounterpartyWithContactsListReqFx,
    mapParams: (x) => ({
      ...x,
      offset: 0,
      limit: 9999,
      logicFilter: true,
      filters: [
        {
          include: true,
          key: 'localConterpartyContact',
          value: 'have',
        },
        {
          include: true,
          key: 'type',
          value: 'service-provider',
        },
        ...x.filters,
      ],
    } as FetchCounterpartyWithContactsListParams),
  }),
})

export const GeneralForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    conterparty: {
      init: '',
      rules: [rules.required()],
    },
    code: {
      init: '',
    },
    description: {
      init: '',
    },
    active: {
      init: true,
    },
    tacitRenewal: {
      init: false,
    },
    type: {
      init: 'management' as ServiceProviderContractType | null,
    },
    grouping: {
      init: '' as ServiceProviderContractGroup | null,
    },
    effectiveDate: {
      init: '',
      rules: [rules.required()],
    },
    dateOfSigning: {
      init: '',
    },
    dateOfMovingInToPremises: {
      init: '',
    },
    termsYears: {
      init: '' as string,
      rules: [rules.required()],
    },
    termsMonths: {
      init: '' as string,
    },
    expirationDate: {
      init: '',
    },
    earlyTerminationYears: {
      init: '' as string,
    },
    earlyTerminationMonths: {
      init: '' as string,
    },
    terminationNoticePeriodYears: {
      init: '' as string,
    },
    terminationNoticePeriodMonths: {
      init: '' as string,
    },
    terminationDeadline: {
      init: '',
    },
    indexingType: {
      init: 'no-indexation' as ServiceProviderContractIndexingType,
    },
    indexingReferenceIndex: {
      init: '' as ReferenceIndex | null,
    },
    indexingReviewType: {
      init: '' as ReviewType | null,
    },
    indexingReferenceIndexValue: {
      init: '',
    },
    indexingFirstAnniversaryDate: {
      init: '',
    },
    indexingInvoicingInterval: {
      init: 3 as 1 | 2 | 3,
    },
  },
})

export const createServiceProviderFx = attach({ effect: createServiceProviderContractReqFx })
export const editServiceProviderFx = attach({ effect: editServiceProviderContractReqFx })
