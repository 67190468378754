import {
  LocalityWithParent, SelectedObjects,
  SiteWithParent,
  SurfaceWithParent,
} from './unit'

type TargetObject = SiteWithParent | SurfaceWithParent | LocalityWithParent

const toggleSurfaceChildren = (innerResult: SelectedObjects, target: TargetObject) => {
  const innerLocalitiesIds = (target as SurfaceWithParent).children.map((locality) => locality.id)
  if (innerResult.surfaces.includes(target.id)) {
    return { ...innerResult, localities: [...innerResult.localities, ...innerLocalitiesIds] }
  }
  return { ...innerResult, localities: innerResult.localities.filter((l) => !innerLocalitiesIds.includes(l)) }
}

const toggleSiteChildren = (r: SelectedObjects, target: TargetObject) => {
  let innerResult = { ...r }
  const innerSurfacesIds = (target as SiteWithParent).children.map((surface) => surface.id)
  if (innerResult.sites.includes(target.id)) {
    innerResult.surfaces = [...innerResult.surfaces, ...innerSurfacesIds]
  } else {
    innerResult.surfaces = innerResult.surfaces.filter((l) => !innerSurfacesIds.includes(l))
  }

  (target as SiteWithParent).children.forEach((surface) => {
    innerResult = toggleSurfaceChildren(innerResult, surface)
  })
  return innerResult
}

const toggleSurfaceParents = (innerResult: SelectedObjects, target: TargetObject) => {
  const site = (target as SurfaceWithParent).parent
  for (const surface of site.children) {
    // if any of sites surfaces was not found
    if (!innerResult.surfaces.includes(surface.id)) {
      return { ...innerResult, sites: innerResult.sites.filter((s) => s !== site.id) }
    }
  }
  return { ...innerResult, sites: [...innerResult.sites, site.id] }
}

const toggleLocalityParents = (innerResult: SelectedObjects, target: TargetObject) => {
  const surface = (target as LocalityWithParent).parent
  for (const locality of surface.children) {
    // if any of surface localities was not found
    if (!innerResult.localities.includes(locality.id)) {
      return toggleSurfaceParents({ ...innerResult, surfaces: innerResult.surfaces.filter((s) => s !== surface.id) }, surface)
    }
  }
  return toggleSurfaceParents({ ...innerResult, surfaces: [...innerResult.surfaces, surface.id] }, surface)
}


export const walkTreeInDepth = (selected: SelectedObjects, x: TargetObject) => {
  if (x.itemType === 'locality') {
    return selected
  }
  if (x.itemType === 'surface') {
    return toggleSurfaceChildren(selected, x)
  }
  // if site
  return toggleSiteChildren(selected, x)
}

export const walkTreeToRoot = (selected: SelectedObjects, x: TargetObject) => {
  if (x.itemType === 'site') {
    return selected
  }
  if (x.itemType === 'surface') {
    return toggleSurfaceParents(selected, x)
  }
  // if locality
  return toggleLocalityParents(selected, x)
}

export const prepareTreeOnFirstLoad = (selected: SelectedObjects, tree: SiteWithParent[]) => {
  let obj = { ...selected }
  for (const site of tree) {
    if (obj.sites.includes(site.id)) {
      obj = walkTreeInDepth(obj, site)
      continue
    }
    for (const surface of site.children) {
      if (obj.surfaces.includes(surface.id)) {
        obj = walkTreeInDepth(obj, surface)
      }
    }
  }
  return obj
}
