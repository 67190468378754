import * as React from 'react'
import styled, { css } from 'styled-components'

import { Comment } from '@/dal'
import { dateTimeSlash } from '@/lib/dates'

import { themeVar, ThemedStyledProps } from '../theming'
import { PrimaryButton } from './PrimaryButton'
import { InputTextArea } from './InputTextArea'
import { Col } from './data-table-content/Col'

type Props = {
    messages: Comment[];
    onSendComment: (message: string) => void;
    hidden: boolean;
    currentUserId: string;
}

export const Chat = ({
  hidden, messages, onSendComment, currentUserId,
}: Props) => {
  const [userMessage, setUserMessage] = React.useState<string>('')
  React.useEffect(() => {
    setUserMessage('')
  }, [messages])
  const messageList = messages.map((message) => (
    <MessageWrap key={message.id} isAuthor={message.author.id === currentUserId} hide={hidden}>
      <Col width={1} noPadding align="flex-start">
        <MessageInfo>
          { `${message.author.firstName} ${message.author.lastName}` }
        </MessageInfo>
      </Col>
      <Col width={1} noPadding align="flex-end">
        <MessageInfo>
          { dateTimeSlash(message.createdAt) }
        </MessageInfo>
      </Col>
      <Col width="100%">
        <MessageText>
          { message.text }
        </MessageText>
      </Col>
    </MessageWrap>
  ))
  return (
    <ChatWrap>
      <ChatHistory>
        {messageList}
      </ChatHistory>
      <Actions hide={hidden}>
        <Col width="100%" noPadding>
          <InputTextArea
            value={userMessage}
            onChangeValue={setUserMessage}
          />
        </Col>
        <Col width="100%" noPadding>

          <PrimaryButton
            disabled={!userMessage}
            fluid
            type="gray"
            outline
            appendIcon="plus"
            onClick={() => onSendComment(userMessage)}
          >
            send comment
          </PrimaryButton>
        </Col>
      </Actions>
    </ChatWrap>
  )
}

const ChatWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0 25px;
    width: 100%;
`
type StyledInputProps = {
    isAuthor?: boolean;
    hide?: boolean;
} & ThemedStyledProps

const ChatHistory = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`


const Actions = styled.div<StyledInputProps>`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    ${({ hide }) => hide && css`
        display: none;
    `}
`

const MessageWrap = styled.div<StyledInputProps>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 14px;
    margin-right: 0;
    border-radius: 4px;
    padding: 8px;
    background: ${themeVar('chatMessageBg')};
    border: 1px solid ${themeVar('chatMessageBorder')};
    color: ${themeVar('chatMessageInfo')};
    &::after{
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background: ${themeVar('chatMessageBg')};
        border: 1px solid ${themeVar('chatMessageBorder')};
        left: -8px;
        border-radius: 6px;
        border-right: none;
        border-top: none;
        transform: rotate(45deg);
        top: calc(50% - 8px);
    }
    ${({ isAuthor }) => isAuthor && css`
        background: ${themeVar('chatAuthorMessageBg')};
        border: 1px solid ${themeVar('chatAuthorMessageBorder')};
        color: ${themeVar('chatAuthorMessageInfo')};
        margin-right: 14px;
        margin-left: 0;
        &::after{
            
            background: ${themeVar('chatAuthorMessageBorder')};
            border: 1px solid ${themeVar('chatAuthorMessageBorder')};
            left: auto;
            right: -8px;
            border-left: none;
            border-bottom: none;
        }
    `}
    ${({ hide }) => hide && css`
        &:not(:last-child){
            display: none;
        }
    `}
`

const MessageText = styled.div`
    color: ${themeVar('chatMessageText')};
    font-size: 16px;
    margin-top: 14px;
    width: 80%;
`

const MessageInfo = styled.div`
    font-size: 10px;
`
