import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'

import {
  DataTable, TableRow, SubTitle, Action, Col,
} from '@/ui'

import { $cadastralReferencesList } from '../../model/rentalObjects/unit'

export const CadastralReference = () => {
  const { t } = useTranslation('tenant/main')
  const cadastralReferencesList = useStore($cadastralReferencesList)

  const dataTableTemplate = [
    {
      name: 'Site',
      renderHeader: (
        <SubTitle>
          {t('site')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'Section',
      renderHeader: (
        <SubTitle>
          {t('section')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'Number',
      renderHeader: (
        <SubTitle>
          {t('Number')}
        </SubTitle>
      ),
      width: 2,
    },
    {
      name: 'Location',
      renderHeader: (
        <SubTitle>
          {t('Location')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'surface area',
      renderHeader: (
        <SubTitle>
          {t('surfaceArea')}
        </SubTitle>
      ),
      width: 1,
    },
  ]

  return (
    <DataTable
      columns={dataTableTemplate}
      extractKey={(item) => `${item.id}cadastralReference`}
      data={cadastralReferencesList}
    >
      {(item) => (
        <TableRow smallText oversizeRow>
          <Col width={1}>
            {item.realEstate}
          </Col>
          <Col width={1}>
            {item.section}
          </Col>
          <Col width={2}>
            {item.number}
          </Col>
          <Col width={1}>
            {item.location}
          </Col>
          <Col width={1}>
            {item.surfaceArea}
          </Col>
        </TableRow>
      )}
    </DataTable>
  )
}
