import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { Icon, IconName } from '@/ui/icons'
import Hammer from 'react-hammerjs'
import { themeVar, ThemedStyledProps } from '../theming'

type Props<Item> = {
    actions?: Action<Item>[];
    children: React.ReactChild;
    item: Item;
    onRowClick?: () => void;
    oversizeRow?: boolean;
    marginMode?: boolean;
}

type Action<Item> = {
  color: 'warning' | 'green' | 'primary';
  icon: IconName;
  text?: string;
  action: (item: Item) => void;
}


export function DataTableRow<Item>({
  item, actions, children, onRowClick, oversizeRow, marginMode,
}: Props<Item>) {
  const RowContent = React.useRef<HTMLDivElement | null>(null)
  const ActionRow = React.useRef<HTMLDivElement | null>(null)
  const clickAction = onRowClick || (() => {})


  const moveRow = (e: HammerInput) => {
    const show = e.deltaX < 0
    const swipeWidth = show && ActionRow ? ActionRow?.current?.clientWidth : 0
    if (RowContent.current) {
      RowContent.current.style.transform = `translateX(-${swipeWidth}px)`
    }
  }

  return (
    <Row oversizeRow={Boolean(oversizeRow)} marginMode={marginMode}>
      <DataRow ref={RowContent} borderRadius={marginMode}>
        <Hammer onTap={clickAction} direction="DIRECTION_HORIZONTAL" onSwipe={(e) => moveRow(e)}>
          { children }
        </Hammer>
      </DataRow>

      <RowAction ref={ActionRow} borderRadius={marginMode}>
        {actions && actions.map(({
          action, icon, color, text,
        }) => (
          <Action
            key={`row${icon}${color}`}
            onClick={() => action(item)}
            color={color}
          >
            <ActionText>
              {text}
            </ActionText>
            <Icon
              icon={icon}
              width={15}
              fill="white"
              style={{ marginLeft: text ? '5px' : 0 }}
            />

          </Action>
        ))}

      </RowAction>
    </Row>
  )
}


const Row = styled.div<{oversizeRow: boolean; marginMode?: boolean}>`
  width: 100%;
  display: flex;
  ${({ oversizeRow }) => !oversizeRow && css`
    height: 62px;
  `}
  
  position: relative;
  
  
  ${({ marginMode }) => marginMode && css`
      margin: 5px 15px;
  `}
  ${breakpoint('mobile', 'tablet')`
    height: auto;
    margin-bottom: 11px;
    border: none;
    border-radius: 4px;
    max-width: calc(100vw - 30px);
    border: 1px solid ${themeVar('brandOrangePale2xColor')};
  `}
  
`
const DataRow = styled.div<{borderRadius?: boolean}>`
  width: 100%;
  display: flex;
  position: relative;
  transition: transform .3s;
  z-index: 2;
  cursor: pointer;
  ${({ borderRadius }) => borderRadius && css`
    border-radius: 4px;
    overflow: hidden;
  `}
  ${breakpoint('mobile', 'tablet')`
    border: none;
    border-radius: 4px;
    overflow: hidden;
    background: white;
  `}
`

const RowAction = styled.div<{borderRadius?: boolean}>`
  display: flex;
  position: absolute;
  right: 0%;
  height: 100%;
  z-index:1;
  ${({ borderRadius }) => borderRadius && css`
    border-radius: 4px;
    overflow: hidden;
  `}
  ${breakpoint('mobile', 'tablet')`
    border: none;
    border-radius: 4px;
    dorder-left: none;
    overflow: hidden;
  `}
`

type StyledActionProps = {
  color: string;
} & ThemedStyledProps

const Action = styled.div<StyledActionProps>`
  flex: 1;
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${themeVar('dataTableActionText')};
  ${({ color }) => {
    switch (color) {
      case 'warning':
        return (css` background: ${themeVar('dataTableActionBgWarning')};`)
      case 'green':
        return (css` background: ${themeVar('dataTableActionBgGreen')};`)
      default:
        return (css` background: ${themeVar('dataTableActionBgPrimary')};`)
    }
  }}
  ${breakpoint('mobile', 'tablet')`
    flex-direction: column;
    align-content: center;
    &:first-child{
      position: relative;
      &::before{
        position: absolute;
        width: 5px;
        height: 100%;
        content: '';
        background: inherit;
        left: -3px;
      }
    }
  `}
`
const ActionText = styled.div`
${breakpoint('mobile', 'tablet')`
    margin-bottom: 5px;
  `}
`
