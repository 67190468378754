import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  PrimaryButton,
  Title,
  SubTitle,
  Col,
  Row,
  WrapField,
  AccordeonRow,
  themeVar,
  Icon,
} from '@/ui'
import { Site, Locality } from '@/dal'
import { showConfirmModal } from '@/features/app'

import {
  $isShowModal,
  $site,
  hideModal,
  showModal,
} from '../../model/site-info'
import { deleteSite } from '../../model/main'


export const SiteInfo = () => {
  const { t } = useTranslation('site-creation')
  const isShowModal = useStore($isShowModal)
  const site = useStore($site)

  const onDeleteSite = (site: Site) => {
    hideModal()
    setImmediate(() => {
      showConfirmModal({
        title: 'are you sure?',
        text: `Do you want to delete site "${site.name}"?`,
        action: () => {
          deleteSite(site.id)
        },
      })
    })
  }

  const localityList = (localities: Locality[]) => localities.map((locality) => (
    <WrapField key={locality.id}>
      <AccordeonRow
        dark
        colorized
        header={(<Title>{locality.name}</Title>)}
      >
        <>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('name')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.name}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('description')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.description}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('assignment')}
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.assigment}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('type')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.type}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('availability')}
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.availability ? 'Free' : 'Booked'}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('typeOfLoad')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.typeOfLoad}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Title>{t('area')}</Title>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('surfaceSquare')}
                    <sup>2</sup>
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.areaSurface}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('perimeter')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.areaPerimeter}
                </Col>
              </>
            </Col>
          </Row>
          <Row colorized orange>
            <Col width="90%" noMargin noPadding>
              <>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('volume')}
                    <sup>3</sup>
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {locality.areaVolume}
                </Col>
              </>
            </Col>
          </Row>
        </>
      </AccordeonRow>
    </WrapField>
  ))

  const surfaceList = (surfaces: Site['surfaces']) => surfaces.map((surface) => (
    <AccordeonWrap>
      <AccordeonRow
        colorized
        header={(
          <Title>
            {surface.name}
          </Title>
      )}
      >
        <Row>
          <Col width="90%" noMargin noPadding>
            <>
              <Row colorized orange>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('surfaceName')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {surface.name}
                </Col>
              </Row>
              <Row colorized orange>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('description')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {surface.description}
                </Col>
              </Row>
              <Row colorized orange>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('assignment')}
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {surface.assigment}
                </Col>
              </Row>
              <Row colorized orange>
                <Title>{t('area')}</Title>
              </Row>
              <Row colorized orange>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>
                    {t('surfaceSquare')}
                    <sup>2</sup>
                  </SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {surface.areaSurface}
                </Col>
              </Row>
              <Row colorized orange>
                <Col width={1} noPadding align="flex-start">
                  <SubTitle>{t('perimeter')}</SubTitle>
                </Col>
                <Col width={1} align="flex-end">
                  {surface.areaPerimeter}
                </Col>
              </Row>
              {localityList(surface.localities)}
            </>
          </Col>
        </Row>
      </AccordeonRow>
    </AccordeonWrap>
  ))

  if (!site) return null

  return (
    <Modal show={isShowModal} onClose={() => hideModal()}>
      <>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('siteName')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.name}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('description')}</SubTitle>
          </Col>
          <Col width={2} align="flex-end">
            {site.description}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>
              {t('assignment')}
            </SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.assigment}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('type')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.type}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('photos')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            20:00, 20/02/2020
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('yearOfConstruction')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.yearOfConstruction}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('address')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.address}
          </Col>
        </Row>
        <Row colorized>
          <Col width="100%" noPadding>
            <Title>{t('cadastre')}</Title>
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('section')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.cadastralReference?.section}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('number')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.cadastralReference?.number}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('locality')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.cadastralReference?.location}
          </Col>
        </Row>
        <Row colorized>
          <Col width={1} noPadding align="flex-start">
            <SubTitle>{t('surface')}</SubTitle>
          </Col>
          <Col width={1} align="flex-end">
            {site.cadastralReference?.surfaceArea}
          </Col>
        </Row>
        { !!site.surfaces.length && (
          <>
            <Row colorized>
              <Col width="100%" noPadding>
                <Title>{t('allSurfaces')}</Title>
              </Col>
            </Row>
            <Row>
              {surfaceList(site.surfaces)}
            </Row>
          </>
        )}
        <Row>
          <Col width="100%" md="80%" noPadding>
            <WrapField>
              <PrimaryButton
                fluid
                outline
                type="warning"
                appendIcon="close"
                onClick={() => onDeleteSite(site)}
              >
                {t('deleteSite')}
              </PrimaryButton>
            </WrapField>
          </Col>
          <Col width="100%" md="80%" noPadding>
            <WrapField>
              <PrimaryButton
                fluid
                outline
                type="gray"
                prependIcon="leftChevron"
                onClick={() => hideModal()}
              >
                {t('return')}
              </PrimaryButton>
            </WrapField>
          </Col>
        </Row>

      </>
    </Modal>
  )
}

const AccordeonWrap = styled.div`
  width: 100%;
  margin-bottom: 15px;
`
