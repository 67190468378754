
import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useInView } from 'react-intersection-observer'
import {
  Row,
  Col,
  AccentText,
  ExtraSmallText,
  themeVar,
} from '@/ui'
import { useStore } from 'effector-react'
import { ServiceProviderContract } from '@/dal/contracts.interfaces'
import { history } from '@/lib/history'
import {
  $serviceProviderList, serviceProvider, resetServiceProvider,
} from '../../model/manage'

export const ServiceProviderList = () => {
  React.useEffect(() => {
    serviceProvider.fetchDataFx()
    return resetServiceProvider
  }, [])

  const dataItems = useStore($serviceProviderList)
  const hasNextPage = useStore(serviceProvider.$hasNext)

  const openContract = (item: ServiceProviderContract) => {
    history.push(`/service-provider/${item.id}`)
  }

  const { ref, inView } = useInView({
    threshold: 1,
  })

  React.useEffect(() => {
    if (inView && hasNextPage) {
      serviceProvider.nextPage()
    }
  }, [inView])

  const itemsList = dataItems.map((item) => (
    <TableRow key={item.id} onClick={() => openContract(item)} colorized smallText>
      <>
        <Col
          md={1}
          xs="100%"
          align="flex-start"
        >
          <ColItem>
            <ExtraSmallText align="left">
              <AccentText>
                Number
              </AccentText>
            </ExtraSmallText>
            {item.name}
          </ColItem>
        </Col>
        <Col
          md={1}
          xs="100%"
          align="flex-start"
        >
          <ColItem>
            <ExtraSmallText align="left">
              <AccentText>
                company name
              </AccentText>
            </ExtraSmallText>
            {item.conterparty?.companyName}
          </ColItem>
        </Col>
      </>
    </TableRow>
  ))

  return (
    <Wrap>
      {itemsList}
      <DataTableLoader ref={ref}> </DataTableLoader>
    </Wrap>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    ${breakpoint('mobile', 'tablet')`
        padding: 30px 0px;
        margin: 35px 6px;
    `}
`

const TableRow = styled(Row)`
  border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  ${(prop) => prop.onClick && css`
    cursor: pointer;
  `}
  &:nth-child(2n+1){
      &:after{
      background: ${themeVar('brandOrangePale2xColor')};
      }
  }
  ${breakpoint('mobile', 'tablet')`
      background: white;
      border: 2px solid ${themeVar('brandOrangePale2xColor')};
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 0 15px 15px;
      &:after{
        display: none;
      }
  `}
`
const ColItem = styled.div`
  font-size: 12px;
`

const DataTableLoader = styled.div`
  width: 100%;
  height: 1px;
`
