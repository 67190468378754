import * as React from 'react'
import { useStore } from 'effector-react'
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { PrimaryButton, ThemedStyledProps } from '@/ui'

import { loadTariff, selectTariff, $tariffList } from '../../model'
import { CardPpf, CardMe } from '../parts'

export const ChangeOwnerTariff = () => {
  React.useEffect(() => {
    loadTariff()
  }, [])

  const [mobileActiveTariff, setMobileActiveTariff] = React.useState<'ME' | 'PPF'>('ME')

  const tariffList = useStore($tariffList)

  const meTariff = tariffList.find((x) => x.accountType === 'ME')
  const ppfTariff = tariffList.find((x) => x.accountType === 'PPF')

  return (
    <Wrap>
      <TabsHeader>
        <PrimaryButton
          disabled={mobileActiveTariff === 'ME'}
          type="primary"
          onClick={() => setMobileActiveTariff('ME')}
        >
          SMALLHOLDERS
        </PrimaryButton>
        <PrimaryButton
          disabled={mobileActiveTariff === 'PPF'}
          type="primary"
          onClick={() => setMobileActiveTariff('PPF')}
        >
          REAL ESTATE PROFESSIONAL
        </PrimaryButton>
      </TabsHeader>
      <TariffsWrap>
        {
          ppfTariff && (
            <ShowMobile active={mobileActiveTariff === 'ME'}>
              <CardPpf onClick={() => selectTariff(ppfTariff.id)} />
            </ShowMobile>
          )
        }
        {
          meTariff && (
            <ShowMobile active={mobileActiveTariff === 'PPF'}>
              <CardMe onClick={() => selectTariff(meTariff.id)} />
            </ShowMobile>
          )
        }
      </TariffsWrap>
    </Wrap>
  )
}

const Wrap = styled.span`
  padding-top: 82px;
  padding-bottom: 35px;
  width: 100%;
  ${breakpoint('mobile', 'tablet')`
    padding-top: 35px;
  `}
`

const TariffsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoint('mobile', 'tablet')`
    justify-content: center;
  `}
`

const TabsHeader = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  ${breakpoint('mobile', 'tablet')`
    display: flex;
  `}
`

const TabLink = styled.a<ThemedStyledProps & {active?: boolean; left?: boolean}>`
  cursor: pointer;
  color: white !important;
  width: 140px;
  text-decoration: underline !important;
  margin: 0 5px;
  ${(p) => p.active && css`
    font-weight: 700;
    text-decoration: none !important;
  `}
  ${(p) => p.left && css`
    text-align: right;
  `}
`

const ShowMobile = styled.div<ThemedStyledProps & {active?: boolean}>`
  display: block;
  ${breakpoint('mobile', 'tablet')`
    display: none;
    ${(p: ThemedStyledProps & {active?: boolean}) => p.active && css`
      display: block; 
    `}
  `}
`
