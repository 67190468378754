import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  InputField,
  Select,
  InputDateTime,
  RadioButtonsList,
  SubTitle,
  AccentText,
  PrimaryButton,
  SmallText,
} from '@/ui'
import { getMock } from '@/features/app'
import { personalFileCompleteStep, setPersonFileStep } from '../../../model'

export const PersonDetails = () => {
  const [registrationNumber, setRegistrationNumber] = React.useState<string>('')
  const [userCode, setUserCode] = React.useState<string>('')
  const [jobTitle, setJobTitle] = React.useState<string>('')
  const [mainStatus, setMainStatus] = React.useState<string>('')
  const [detailedStatus, setDetailedStatus] = React.useState<string>('')
  const [placed, setPlaced] = React.useState<boolean>(true)
  const [directoryLocation, setDirectoryLocation] = React.useState<string>('')
  const [directoryId, setDirectoryId] = React.useState<string>('')
  const [status, setStatus] = React.useState<string>('')
  const [serviceTerms, setServiceTerms] = React.useState<string>('')
  const [badgeNumber, setBadgeNumber] = React.useState<string>('')
  const [internalPostalAddress, setInternalPostalAddress] = React.useState<string>('')
  const [profile, setProfile] = React.useState<string>('')
  const [contractType, setContractType] = React.useState<string>('')
  const [contractPeriodStart, setContractPeriodStart] = React.useState<string | null>(new Date().toISOString())
  const [contractPeriodEnd, setContractPeriodEnd] = React.useState<string | null>(new Date().toISOString())
  const [manager, setManager] = React.useState<string>('')
  const [assistant, setAssistant] = React.useState<string>('')
  const [bcp, setBcp] = React.useState<boolean>(true)
  const [fireRole, setFireRole] = React.useState<string>('')
  const [firstAidRole, setFirstAidRole] = React.useState<string>('')
  const [mailBox, setMailBox] = React.useState<string>('')
  const [accessRequested, setAccessRequested] = React.useState<string>('')
  const [accessToOffice, setAccessToOffice] = React.useState<string>('')
  const [parking, setParking] = React.useState<boolean>(true)
  const [vehicle, setVehicle] = React.useState<string>('')
  const [plateNumber, setPlateNumber] = React.useState<string>('')
  const [locker, setLocker] = React.useState<string>('')
  const [postCharge, setPostCharge] = React.useState<string>('')
  const [absence, setAbsence] = React.useState<boolean>(true)

  const selectItems = getMock().selects.newEmployee.card
  const yesNoItems = getMock().selects.newEmployee.located
  const mainStatusList = [
    { value: 'internal', text: 'Internal' },
    { value: 'External', text: 'External' },
  ]

  const confirmFormAction = () => {
    personalFileCompleteStep('details')
    setPersonFileStep('synchronisation')
  }

  return (
    <Wrap>
      <>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                1. registration number
              </SubTitle>
              <InputField
                value={registrationNumber}
                onChangeValue={setRegistrationNumber}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                2. user code
              </SubTitle>
              <InputField
                value={userCode}
                onChangeValue={setUserCode}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                3. job title choice
              </SubTitle>
              <Select
                name="Choose job title"
                items={selectItems}
                value={jobTitle}
                onChange={setJobTitle}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                4. main status
              </SubTitle>
              <RadioButtonsList
                name="Choose status"
                items={mainStatusList}
                value={mainStatus}
                onChange={setMainStatus}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                5. detailed status
              </SubTitle>
              <Select
                name="Choose detailed status"
                items={selectItems}
                value={detailedStatus}
                onChange={setDetailedStatus}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                6. placed
              </SubTitle>
              <RadioButtonsList
                name="Choose status"
                items={yesNoItems}
                value={placed}
                onChange={setPlaced}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                7. directory location
              </SubTitle>
              <InputField
                value={directoryLocation}
                onChangeValue={setDirectoryLocation}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                8. directory ID
              </SubTitle>
              <InputField
                value={directoryId}
                onChangeValue={setDirectoryId}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                9. status
              </SubTitle>
              <Select
                name="Choose status"
                items={selectItems}
                value={status}
                onChange={setStatus}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                10. service terms
              </SubTitle>
              <Select
                name="Choose service terms"
                items={selectItems}
                value={serviceTerms}
                onChange={setServiceTerms}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                11. badge number
              </SubTitle>
              <InputField
                value={badgeNumber}
                onChangeValue={setBadgeNumber}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                12. internal postal address
              </SubTitle>
              <InputField
                value={internalPostalAddress}
                onChangeValue={setInternalPostalAddress}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                13. profile
              </SubTitle>
              <Select
                name="Choose profile"
                items={selectItems}
                value={profile}
                onChange={setProfile}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                14. contract type
              </SubTitle>
              <Select
                name="Choose contract type"
                items={selectItems}
                value={contractType}
                onChange={setContractType}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                15. contact period start
              </SubTitle>
              <InputDateTime
                value={contractPeriodStart}
                onChange={setContractPeriodStart}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                16. contract period end
              </SubTitle>
              <InputDateTime
                value={contractPeriodEnd}
                onChange={setContractPeriodEnd}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                17. manager
              </SubTitle>
              <InputField
                innerIcon
                appendIcon="lense"
                value={manager}
                onChangeValue={setManager}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                18. assistant
              </SubTitle>
              <InputField
                innerIcon
                appendIcon="lense"
                value={assistant}
                onChangeValue={setAssistant}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                19. BCP
                <AccentText>
                  <TitleInfo>
                    Business Continuity Planning
                  </TitleInfo>
                </AccentText>
              </SubTitle>
              <RadioButtonsList
                name="Choose BCP"
                items={yesNoItems}
                value={bcp}
                onChange={setBcp}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                20. fire role
              </SubTitle>
              <Select
                name="Choose fire role"
                items={selectItems}
                value={fireRole}
                onChange={setFireRole}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                21. first aid role
              </SubTitle>
              <Select
                name="Choose first aid role"
                items={selectItems}
                value={firstAidRole}
                onChange={setFirstAidRole}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                22. mail box
              </SubTitle>
              <InputField
                value={mailBox}
                onChangeValue={setMailBox}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                23. access requested
              </SubTitle>
              <Select
                name="Choose access requested"
                items={selectItems}
                value={accessRequested}
                onChange={setAccessRequested}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                24. access to offices/premises
              </SubTitle>
              <InputField
                value={accessToOffice}
                onChangeValue={setAccessToOffice}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                25. parking
              </SubTitle>
              <RadioButtonsList
                name="parking"
                items={yesNoItems}
                value={parking}
                onChange={setParking}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                26.  vehicle type and brand
              </SubTitle>
              <InputField
                value={vehicle}
                onChangeValue={setVehicle}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                27. plate number
              </SubTitle>
              <InputField
                value={plateNumber}
                onChangeValue={setPlateNumber}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                28. locker
              </SubTitle>
              <InputField
                value={locker}
                onChangeValue={setLocker}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                29. post charge
              </SubTitle>
              <Select
                name="Choose post charge"
                items={selectItems}
                value={postCharge}
                onChange={setPostCharge}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                30. absence
              </SubTitle>
              <RadioButtonsList
                name="absence"
                items={yesNoItems}
                value={absence}
                onChange={setAbsence}
              />
            </>
          </Col>
        </Row>

        <Row>
          <Col xs="100%" md="300px">
            <PrimaryButton
              fluid
              outline
              type="gray"
              size="large"
            >
              view absences list
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => setPersonFileStep('contact')}
            >
              back
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              appendIcon="rightChevron"
              onClick={() => confirmFormAction()}
            >
              next step
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}


const Wrap = styled.div`
    padding: 20px 40px 20px 18px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 40px;
    `}
`

const TitleInfo = styled(SmallText)`
  text-transform: none;
  margin-left: 10px;
`
