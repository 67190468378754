import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useLocation, useParams } from 'react-router'

import { TabControl, themeVar } from '@/ui'
import { history } from '@/lib/history'

import {
  SiteSelect,
  SiteInfo,
} from '../containers'
import {
  $selectedSiteId,
  setSiteId,
  fetchSitesListFx,
  $activeTab,
  setActiveTab,
  clearActiveTab,
  clearActiveSite,
} from '../../model'


export const SitePage: React.FC = ({ children }) => {
  const selectedSiteId = useStore($selectedSiteId)
  const activeTab = useStore($activeTab)
  const location = useLocation()
  const params = useParams()

  const tabs = [
    { name: 'NEWS', value: 'news' },
    { name: 'CONTACTS', value: 'contacts' },
    { name: 'CONTRACTS', value: 'contracts' },
  ]

  const tabIsAlreadyOpen = (tabName: string) => tabName === location.pathname.replace(/\/$/, '')

  const foundTab = (tabName: string | null) => {
    const found = tabs.find(({ value }) => tabName === value)
    return found ? found.value : tabs[0].value
  }

  const setTab = (tabName: string | null) => {
    if (!tabName || tabIsAlreadyOpen(tabName)) return null
    setActiveTab(foundTab(tabName))
  }

  React.useEffect(() => {
    // @ts-ignore
    setSiteId(params.siteId)
    const startTab = tabs.find(({ value }) => tabIsAlreadyOpen(value))
    if (startTab) {
      setActiveTab(startTab.value)
    } else {
      setActiveTab(tabs[0].value)
    }
    return () => {
      clearActiveSite()
      clearActiveTab()
    }
  }, [])


  if (!selectedSiteId) return null

  return (
    <>
      <SiteSelect />
      <Card>
        <SiteInfo />
        <TabControl
          items={tabs}
          value={activeTab}
          onItemClick={(item) => setTab(item.value)}
        />
        {children}
      </Card>
    </>
  )
}

const Card = styled.div`
  background: white;
  margin-bottom: ${themeVar('tabletBottomMargin')};
  box-shadow: 0 35px 55px rgba(213, 76, 46, 0.28);
  border: none;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  ${breakpoint('mobile', 'tablet')`
      border-radius: 0px;
  `}
`
