import {
  $isInfoDialogShown,
  $isSendedDialogShown,
  $isFilterDialogShown,
  $isValidateDialogShown,
  $isValidatedDialogShown,
} from './state'
import {
  hideSendedModal,
  send,
  hideInfoDialog,
  showInfoDialog,
  hideValidateDialog,
  showValidateDialog,
  showFilterDialog,
  hideFilterDialog,
  filter,
  validate,
  hideValidatedDialog,
} from './events'

$isInfoDialogShown
  .on(showInfoDialog, () => true)
  .on(hideInfoDialog, () => false)
  .on(send, () => false)

$isFilterDialogShown
  .on(showFilterDialog, () => true)
  .on(hideFilterDialog, () => false)
  .on(filter, () => false)

$isValidateDialogShown
  .on(showValidateDialog, () => true)
  .on(hideValidateDialog, () => false)
  .on(validate, () => false)

$isValidatedDialogShown
  .on(validate, () => true)
  .on(hideValidatedDialog, () => false)

$isValidatedDialogShown
  .on(send, () => true)
  .on(hideSendedModal, () => false)
