import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { rules } from '@/lib/validators'
import {
  createSiteReqFx,
  createCadastralReferenceReqFx,
  attachCadastralReferenceToSiteReqFx,
  Site,
} from '@/dal'

export const d = createDomain('d')

export const $isShowModal = d.store(false)
export const $justCreatedSiteId = d.store<Site['id'] | null>(null)

export const showModal = d.event()
export const hideModal = d.event()

export const newSite = createForm({
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    description: {
      init: '',
    },
    assigment: {
      init: ''as Site['assigment'],
    },
    type: {
      init: '' as Site['type'],
    },
    photos: {
      init: [] as File[],
    },
    yearOfConstruction: {
      init: '',
    },
    address: {
      init: '',
      rules: [rules.required()],
    },
    cadastreSection: {
      init: '',
    },
    cadastreNumber: {
      init: '',
    },
    cadastreLocality: {
      init: '',
    },
    cadastreSurface: {
      init: '',
    },
  },
})

export const createSiteFx = attach({ effect: createSiteReqFx })
export const createCadastralReferenceFx = attach({ effect: createCadastralReferenceReqFx })
export const attachCadastralReferenceToSiteFx = attach({ effect: attachCadastralReferenceToSiteReqFx })
