import * as React from 'react'
import { useField } from 'effector-forms'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  ContractFormTitle,
  SubTitle,
  InputField,
  NumberInput,
  InputDateTime,
} from '@/ui'
import { GeneralForm } from '../../../model/general'

export const Dates = () => {
  const { t } = useTranslation('tenant/main')
  const effectiveDate = useField(GeneralForm.fields.effectiveDate)
  const dateOfSigning = useField(GeneralForm.fields.dateOfSigning)
  const termsYears = useField(GeneralForm.fields.termsYears)
  const termsMonths = useField(GeneralForm.fields.termsMonths)
  const expirationDate = useField(GeneralForm.fields.expirationDate)
  const terminationNoticePeriodMonths = useField(GeneralForm.fields.terminationNoticePeriodMonths)
  const terminationNoticePeriodYears = useField(GeneralForm.fields.terminationNoticePeriodYears)

  return (
    <Row align="flex-start">
      <Col width="100%" align="flex-start" noMargin>
        <ContractFormTitle>{t('dates')}</ContractFormTitle>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            1.
            {' '}
            {t('effectiveDate')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={effectiveDate.value}
            onChange={(val) => effectiveDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('dateOfSigning')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={dateOfSigning.value}
            onChange={(val) => dateOfSigning.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" />
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('contractTerms')}
          </SubTitle>
          <NumberInput
            placeholder={t('years')}
            value={termsYears.value}
            onChangeValue={termsYears.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-end">
        <>
          <NumberInput
            placeholder={t('month')}
            value={termsMonths.value}
            onChangeValue={termsMonths.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('expiryDate')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={expirationDate.value}
            onChange={(val) => expirationDate.onChange(val || '')}
          />
        </>
      </Col>

      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('terminationNoticePeriod')}
          </SubTitle>
          <NumberInput
            placeholder={t('years')}
            value={terminationNoticePeriodYears.value}
            onChangeValue={terminationNoticePeriodYears.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-end">
        <>
          <NumberInput
            placeholder={t('month')}
            value={terminationNoticePeriodMonths.value}
            onChangeValue={terminationNoticePeriodMonths.onChange}
          />
        </>
      </Col>
    </Row>

  )
}
