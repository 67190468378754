import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  AccentText,
  Modal,
  PrimaryButton,
  InputField,
  Heading,
  Text,
  Col,
  Row,
  WrapField,
} from '@/ui'

import {
  $isChangePasswordDialogShown,
  hideChangePasswordDialog,
  showThanksModal,
} from '../../model'
import {
  resetPasswordForm,
  $serverError,
  resetPasswordFx,
} from '../../model/password-reset'

export const ChangePassword = () => {
  const { t } = useTranslation('autorization/translation')
  const { submit, fields, hasError } = useForm(resetPasswordForm)
  const isChangePasswordDialogShown = useStore($isChangePasswordDialogShown)
  const pending = useStore(resetPasswordFx.pending)
  const serverError = useStore($serverError)

  /* const sendMail = () => {
    if (login !== '') {
      showThanksModal('We send link to your e-mail for change your password')
    } else {
      setLoginError(!loginError)
    }
  } */

  const formHasError = hasError()

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Modal
      show={isChangePasswordDialogShown}
      onClose={() => hideChangePasswordDialog()}
    >
      <ContentWrap onSubmit={onSubmit}>
        <Row>
          <Col noPadding width="100%">
            <WrapField>
              <Heading>{t('changePassword.title')}</Heading>
            </WrapField>
          </Col>
          <Col noPadding width="100%">
            <Text align="center" style={{ fontWeight: 500 }}>
              {t('changePassword.text')}
            </Text>
          </Col>
        </Row>
        {serverError && (
          <Col noPadding width="100%">
            <WrapField>
              <Text align="center">
                <AccentText warning>
                  {t('incorrectEmail')}
                </AccentText>
              </Text>
            </WrapField>
          </Col>
        )}
        <Col noPadding width="80%">
          <WrapField>
            <InputField
              name="Email"
              placeholder={t('yourEmail')}
              errorText={fields.email.errorText({
                isEmail: t('invalidEmailFormat'),
                required: t('requiredFiled'),
              })}
              value={fields.email.value}
              onChangeValue={fields.email.onChange}
            />
          </WrapField>
        </Col>
        <Row>
          <Col noPadding width="80%">
            <PrimaryButton
              fluid
              htmlType="submit"
              disabled={formHasError || pending}
            >
              {t('send')}
            </PrimaryButton>
          </Col>
        </Row>
      </ContentWrap>
    </Modal>
  )
}


const ContentWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
