import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

import {
  Icon, PrimaryButton, themeVar, InputTextArea,
} from '@/ui'
import { $isGuest } from '@/features/app'
import { showAutorizationDialog, showSignUpDialog } from '@/features/autorization'


export const FirstBlock = () => {
  const isGuest = useStore($isGuest)
  const { t } = useTranslation('welcome/translation')
  return (
    <Wrap>
      <TitleBlock>
        <Logo>
          <Icon icon="logoFull" />
        </Logo>
        <TitleText>
          {t('slogan')}
        </TitleText>
        <PrimaryButton
          fluid
          size="large"
          onClick={() => showAutorizationDialog()}
        >
          {t('signIn')}
        </PrimaryButton>
        <SignUpText>
          <p>
            {t('signUpOnMainPart1')}
            <SugnUpButton onClick={() => showSignUpDialog()}>
              {t('signUp')}
            </SugnUpButton>
            {t('signUpOnMainPart2')}
          </p>
        </SignUpText>
      </TitleBlock>

      <ImageContainer>
        <Images src="/assets/img/welcome-img1.jpg" />
      </ImageContainer>
    </Wrap>
  )
}


const Wrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    ${breakpoint('mobile', 'tablet')`
      padding: 0 25px;
    `}
`


const TitleBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    padding-top: 100px;
    align-content: flex-start;
    ${breakpoint('mobile', 'tablet')`
      width: 100%;
      padding-top: 20px;
    `}
`

const TitleText = styled.div`
    font-size: 40px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 35px;
    ${breakpoint('mobile', 'tablet')`
      font-size: 18px;
      margin-bottom: 130px;
      margin-top: 15px;
    `}
`


const Logo = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 35px;
    align-content: flex-start;
    ${breakpoint('mobile', 'tablet')`
      width: 100%;
      margin: auto;
    `}
`

const ImageContainer = styled.div`
    display: flex;
    width: 60%;
    padding-left: 55px;
    ${breakpoint('mobile', 'tablet')`
      width: 90%;
      position: absolute;
      bottom: 110px;
      right: -10px;
      z-index: -1;
    `}
`

const Images = styled.img`
    display: flex;
    flex-wrap: wrap;
    width: 200%;
    ${breakpoint('tablet')`
      margin-top: -45px;
    `}
    ${breakpoint('mobile', 'tablet')`
      width: 100%;
    `}
`

const SignUpText = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: left;
  display: flex;
  margin-top: 20px;
  &>p{
    margin: 0;
  }
`

const SugnUpButton = styled.span`
  border: none;
  background: none;
  color: ${themeVar('brandOrangeColor')};
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
`
