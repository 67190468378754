import * as React from 'react'
import Close from './svg-assets/close.svg'
import Arrow from './svg-assets/arrow.svg'
import Pet from './svg-assets/pet.svg'
import Calendar from './svg-assets/calendar.svg'
import Check from './svg-assets/check.svg'
import Trash from './svg-assets/trash.svg'
import AlertCircle from './svg-assets/alert-circle.svg'
import HelpCircle from './svg-assets/help-circle.svg'
import CheckCircle from './svg-assets/check-circle.svg'
import Rectangle from './svg-assets/rectangle.svg'
import Clean from './svg-assets/clean.svg'
import Logo from './svg-assets/logo.svg'
import LogoFull from './svg-assets/logo-full.svg'
import Menu from './svg-assets/menu.svg'
import Upload from './svg-assets/upload.svg'
import Plus from './svg-assets/plus.svg'
import LeftChevron from './svg-assets/left-chevron.svg'
import RightChevron from './svg-assets/right-chevron.svg'
import Character1 from './svg-assets/char-1.svg'
import Character2 from './svg-assets/char-2.svg'
import Character3 from './svg-assets/char-3.svg'
import Character4 from './svg-assets/char-4.svg'
import Character5 from './svg-assets/char-5.svg'
import Character6 from './svg-assets/char-6.svg'
import Character7 from './svg-assets/char-7.svg'
import Character8 from './svg-assets/char-8.svg'
import MapMarker from './svg-assets/map-marker.svg'
import Marker from './svg-assets/marker.svg'
import Mail from './svg-assets/mail.svg'
import Phone from './svg-assets/phone.svg'
import Lense from './svg-assets/lense.svg'
import ArrivalAndDeparture from './svg-assets/arrival&Departure.svg'
import Demands from './svg-assets/demands.svg'
import Manage from './svg-assets/manage.svg'
import Orders from './svg-assets/orders.svg'
import Requests from './svg-assets/requests.svg'
import Reservation from './svg-assets/reservation.svg'
import Site from './svg-assets/site.svg'
import ChevronRightCircle from './svg-assets/chevron-right-circle.svg'
import ChevronLeftCircle from './svg-assets/chevron-left-circle.svg'
import StarCircle from './svg-assets/star-circle.svg'
import HeartCircle from './svg-assets/heart-circle.svg'
import MessageCircle from './svg-assets/message-circle.svg'
import SurpriseCircle from './svg-assets/surprise-circle.svg'
import Minus from './svg-assets/minus.svg'
import Sort from './svg-assets/sort.svg'
import Subtract from './svg-assets/subtract.svg'
import MinusCircle from './svg-assets/minus-circle.svg'
import FlagFr from './svg-assets/flag-fr.svg'
import FlagEn from './svg-assets/flag-en.svg'
import MapMarkerIcon from './svg-assets/map-marker-icon.svg'
import WarningCircle from './svg-assets/warning-circle.svg'
import FAQ from './svg-assets/faq.svg'
import Logout from './svg-assets/logout.svg'
import MeetingRooms from './svg-assets/meeting-rooms.svg'
import Regulations from './svg-assets/regulations.svg'
import Settings from './svg-assets/settings.svg'
import Catering from './svg-assets/catering.svg'
import Consultation from './svg-assets/consultation.svg'
import EmptyIcon from './svg-assets/empty-icon.svg'


const icons = {
  close: Close,
  arrow: Arrow,
  pet: Pet,
  calendar: Calendar,
  check: Check,
  trash: Trash,
  alertCircle: AlertCircle,
  helpCircle: HelpCircle,
  checkCircle: CheckCircle,
  rectangle: Rectangle,
  clean: Clean,
  logo: Logo,
  menu: Menu,
  upload: Upload,
  plus: Plus,
  leftChevron: LeftChevron,
  character1: Character1,
  character2: Character2,
  character3: Character3,
  character4: Character4,
  character5: Character5,
  character6: Character6,
  character7: Character7,
  character8: Character8,
  mapMarker: MapMarker,
  marker: Marker,
  mail: Mail,
  phone: Phone,
  rightChevron: RightChevron,
  logoFull: LogoFull,
  lense: Lense,
  ArrivalAndDeparture,
  Demands,
  Manage,
  Orders,
  Requests,
  Reservation,
  Site,
  ChevronRightCircle,
  ChevronLeftCircle,
  StarCircle,
  heartCircle: HeartCircle,
  messageCircle: MessageCircle,
  surpriseCircle: SurpriseCircle,
  minus: Minus,
  sort: Sort,
  subtract: Subtract,
  minusCircle: MinusCircle,
  flagFr: FlagFr,
  flagEn: FlagEn,
  mapMarkerIcon: MapMarkerIcon,
  warningCircle: WarningCircle,
  faq: FAQ,
  logout: Logout,
  meetingRooms: MeetingRooms,
  regulations: Regulations,
  settings: Settings,
  catering: Catering,
  consultation: Consultation,
  emptyIcon: EmptyIcon,
}

export type IconName = keyof typeof icons

type Props = {
  icon: keyof typeof icons;
} & React.SVGAttributes<any>

export const Icon = ({ icon, ...svgProps }: Props) => {
  const IconComp = icons[icon]
  return <IconComp {...svgProps} />
}
