import * as React from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import {
  AccentText,
  Modal,
  Heading,
  Text,
  Col,
  Row,
  SubTitle,
  WrapField,
} from '@/ui'
import styled from 'styled-components'

import { useQuery } from '@/lib/use-query'

import {
  $serverError,
  $modalShown,
  setToken,
  closeModal,
  showModal,
} from '../../model/verification-email'


export const VerificationEmail = () => {
  const token = useQuery().get('token')
  const isModalShown = useStore($modalShown)
  const serverError = useStore($serverError)
  const { t } = useTranslation('autorization/verification')


  React.useEffect(() => {
    if (token) {
      setToken(token)
      setImmediate(() => {
        showModal()
      })
    }
  }, [])


  return (
    <Modal
      show={isModalShown}
      onClose={() => closeModal()}
    >
      <ContentWrap>
        <Row>
          <Col noPadding width="100%">
            <WrapField>
              <Heading>{t('title')}</Heading>
            </WrapField>
          </Col>
          {!serverError && (
          <Col noPadding width="100%">
            <SubTitle style={{ fontWeight: 500 }}>
              {t('checkCode')}
            </SubTitle>
          </Col>
          )}
        </Row>
        {serverError === 'VerifyEmailTokenInvalid' && (
          <Col noPadding width="100%">
            <WrapField>
              <SubTitle>
                <Error warning>
                  {t('incorrectCode')}
                </Error>
              </SubTitle>
            </WrapField>
          </Col>
        )}
        {serverError === 'VerifyEmailTokenExpired' && (
          <Col noPadding width="100%">
            <WrapField>
              <SubTitle>
                <Error warning>
                  {t('expiredCode')}
                </Error>
              </SubTitle>
            </WrapField>
          </Col>
        )}

      </ContentWrap>
    </Modal>
  )
}


const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Error = styled(AccentText)`
  text-align: center;
`
