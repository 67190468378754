import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { Document } from '@/dal'
import { CreateContractDocumentRes } from '@/dal/contracts.interfaces'
import {
  createContractDocumentReqFx,
  deleteContractDocumentReqFx,
  attachContractDocumentToInsuranceContractReqFx,
  detachContractDocumentFromInsuranceContractReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateContractDocumentRes[]>([])

export const loadList = d.createEvent<CreateContractDocumentRes[]>()
export const addItem = d.createEvent<CreateContractDocumentRes>()
export const deleteItem = d.createEvent<CreateContractDocumentRes>()
export const dropItem = d.createEvent<CreateContractDocumentRes['id']>()

export const DocumentForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    version: {
      init: '',
    },
    validityEndDate: {
      init: '',
    },
    documentType: {
      init: '',
    },
    active: {
      init: true,
    },
    description: {
      init: '',
    },
    content: {
      init: {} as Document,
    },
  },
})

export const createItemFx = attach({ effect: createContractDocumentReqFx })
export const deleteItemFx = attach({ effect: deleteContractDocumentReqFx })
export const attachItemFx = attach({ effect: attachContractDocumentToInsuranceContractReqFx })
export const detachItemFx = attach({ effect: detachContractDocumentFromInsuranceContractReqFx })
