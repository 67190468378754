import {
  forward, sample, combine, split,
} from 'effector'
import { condition } from 'patronum'

import { history } from '@/lib/history'

import {
  $sitesList,
  $selectedSiteId,
  $selectedSite,
  $activeTab,
  setSitesList,
  setSiteId,
  setSiteIdAndTab,
  setSiteData,
  setActiveTab,
  clearActiveSite,
  clearActiveTab,
  fetchSitesListFx,
  fetchSiteFx,
} from './units'

$selectedSiteId
  .on(setSiteId, (_, id) => id)
  .on(setSiteIdAndTab, (_, x) => x.siteId)
  .on(clearActiveSite, () => '')

$activeTab
  .on(setActiveTab, (_, x) => x)
  .on(setSiteIdAndTab, (_, x) => x.activeTab)
  .on(clearActiveTab, () => '')

$sitesList
  .on(setSitesList, (_, sites) => sites)

$selectedSite
  .on(setSiteData, (_, site) => site)
  .on(clearActiveSite, () => null)

forward({
  from: fetchSitesListFx.doneData.map(({ items }) => items),
  to: setSitesList,
})

const firstSiteId = fetchSitesListFx.doneData.map(({ items }) => items[0].id)
const onSitesListFetched = sample({
  source: $selectedSiteId,
  clock: firstSiteId,
  // eslint-disable-next-line no-shadow
  fn: (selectedSiteId, firstSiteId) => ({
    selectedSiteId,
    firstSiteId,
  }),
})

const a = split(onSitesListFetched, {
  idNotSetYet: (x) => !x.selectedSiteId,
})
a.idNotSetYet.watch((x) => {
  setSiteId(x.firstSiteId)
})

setSiteIdAndTab.watch((x) => console.log(x))


forward({
  from: fetchSiteFx.doneData,
  to: setSiteData,
})


condition({
  source: setSiteId.map((x) => ({ id: x })),
  if: (x) => !!x.id,
  then: fetchSiteFx,
})

combine(
  [$selectedSiteId, $activeTab],
).watch(([siteId, activeTab]) => {
  if (siteId) history.push(`/site/${siteId}/${activeTab || 'news'}`)
})
