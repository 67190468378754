import { createDomain } from 'effector'

import { NewsArticle } from '@/dal'

export const newsModal = createDomain('news-modal')

export const $showModal = newsModal.store<boolean>(false)
export const $showImage = newsModal.store<boolean>(false)
export const $imageSrc = newsModal.store<string>('/assets/img/news-image.jpg')
export const $selectedNewsArticle = newsModal.store<NewsArticle | null>(null)

export const showModal = newsModal.event<void>()
export const hideModal = newsModal.event<void>()
export const setNewsArticleData = newsModal.event<NewsArticle>()
