import * as React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Row, InputSwitch, Col, Select, Visible, PrimaryButton,
} from '@/ui'

import { LeasesList, InsuranceList, ServiceProviderList } from '../containers'
import { mounted, reset } from '../../model/manage'

export const ContractBlock = () => {
  const { t } = useTranslation('site/contracts')

  const switchItems = [
    { text: t('list.titles.lease'), value: 'leases' },
    { text: t('list.titles.insurance'), value: 'insurance' },
    { text: t('list.titles.serviceProvider'), value: 'service-providers' },
  ]

  const [manageType, setManageType] = React.useState<string>('leases')

  const contentSwitch = (name: typeof manageType) => {
    switch (name) {
      case 'leases':
        return (<LeasesList />)
      case 'insurance':
        return (<InsuranceList />)
      case 'service-providers':
        return (<ServiceProviderList />)
      default:
        return (<LeasesList />)
    }
  }

  const createButtonSwitch = (name: typeof manageType) => {
    switch (name) {
      case 'leases':
        return (
          <NavLink to="/tenant-contract">
            <PrimaryButton
              prependIcon="plus"
              size="small"
            >
              {t('list.buttons.create.lease')}
            </PrimaryButton>
          </NavLink>
        )
      case 'insurance':
        return (
          <NavLink to="/insurance-contract">
            <PrimaryButton
              prependIcon="plus"
              size="small"
            >
              {t('list.buttons.create.insurance')}
            </PrimaryButton>
          </NavLink>
        )
      case 'service-providers':
        return (
          <NavLink to="/service-provider">
            <PrimaryButton
              prependIcon="plus"
              size="small"
            >
              {t('list.buttons.create.serviceProvider')}
            </PrimaryButton>
          </NavLink>
        )
      default:
        return (<LeasesList />)
    }
  }

  React.useEffect(() => {
    mounted()
    // return reset
  }, [])

  return (
    <>
      <Wrap>
        <Row>
          <Col xs="100%" md="100%" noMargin noPadding>
            <>
              <Visible mobile fluid>
                <Select
                  items={switchItems}
                  value={manageType}
                  onChange={(val) => setManageType(val)}
                />
              </Visible>
              <Visible tablet fluid>
                <InputSwitch
                  items={switchItems}
                  value={manageType}
                  onChange={(val) => setManageType(val)}
                />
              </Visible>
            </>
          </Col>
        </Row>
        <Row>
          {createButtonSwitch(manageType)}
        </Row>
      </Wrap>
      {contentSwitch(manageType)}
    </>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
`
