import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Modal, Heading, Title, PrimaryButton, Row, Col,
} from '@/ui'
import {
  $confirmModalAction,
  $confirmModalText,
  $showConfirmModal,
  $confirmModalTitle,
  closeConfirmModal,
} from '../../model'

export const ConfirmModal = () => {
  const { t } = useTranslation('app/translate')
  const showConfirmModal = useStore($showConfirmModal)
  const confirmModalText = useStore($confirmModalText)
  const confirmModalAction = useStore($confirmModalAction)
  const confirmModalTitle = useStore($confirmModalTitle)
  const confirmActions = () => {
    if (confirmModalAction.action) confirmModalAction.action()
    closeConfirmModal()
  }
  return (
    <Modal
      onClose={() => closeConfirmModal()}
      show={showConfirmModal}
      type="confirm"
    >
      <>
        <Row>
          <Heading>
            {confirmModalTitle}
          </Heading>
        </Row>
        <Row>
          <Text>
            {confirmModalText}
          </Text>
        </Row>
        {confirmModalAction.action && (
          <>
            <InteractiveRow>
              <Col md="80%" noMargin noPadding>
                <PrimaryButton fluid size="extra large" onClick={() => confirmActions()}>
                  {t('confirmText')}
                </PrimaryButton>
              </Col>
            </InteractiveRow>
            <InteractiveRow>
              <Col md="80%" noMargin noPadding>
                <PrimaryButton fluid size="extra large" type="warning" outline onClick={() => closeConfirmModal()}>
                  {t('rejectText')}
                </PrimaryButton>
              </Col>
            </InteractiveRow>
          </>
        )}

      </>
    </Modal>
  )
}

const Text = styled.span`
  width: 80%;
  text-align: center;
  font-size: 18px;
`

const InteractiveRow = styled(Row)`
  z-index: 2;
  padding: 5px;
`
