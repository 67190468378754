import { forward, sample } from 'effector'

import { TenantLease } from '@/dal/contracts.interfaces'
import { convertToEuroCentsCurrency } from '@/lib/field-handlers'

import { getTenentLeaseFx, $tenantContract } from '../main'
import {
  $depositList,
  DepositForm,
  addDeposit,
  attachSecurityDepositToTenantLeaseFx,
  createSecurityDepositFx,
  deleteDeposit,
  deleteSecurityDepositFx,
  detachSecurityDepositFromTenantLeaseFx,
  dropDeposit,
  loadDeposit,
} from './unit'

$depositList
  .on(loadDeposit, (_, r) => r)
  .on(addDeposit, (_, r) => [..._, r])
  .on(dropDeposit, (_, r) => _.filter((el) => el.id !== r))

forward({
  from: getTenentLeaseFx.doneData.map((r) => r.tenantLease.securityDeposits),
  to: loadDeposit,
})

sample({
  source: DepositForm.$values,
  clock: DepositForm.formValidated,
  fn: (form) => ({
    ...form,
    amount: convertToEuroCentsCurrency(form.amount),
    amountRent: form.amountRent || undefined,
  }),
  target: createSecurityDepositFx,
})

forward({
  from: createSecurityDepositFx.doneData,
  to: addDeposit,
})

sample({
  clock: createSecurityDepositFx.doneData,
  source: $tenantContract,
  fn: (contract, period) => ({
    id: contract?.id as string,
    itemsID: [period.id],
  }),
  target: attachSecurityDepositToTenantLeaseFx,
})

forward({
  from: attachSecurityDepositToTenantLeaseFx.done,
  to: DepositForm.reset,
})

sample({
  clock: deleteDeposit,
  source: $tenantContract,
  fn: (contract, i) => ({ id: (contract as TenantLease).id, items: [i.id] }),
  target: detachSecurityDepositFromTenantLeaseFx,
})

forward({
  from: [
    detachSecurityDepositFromTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0] : '')),
    attachSecurityDepositToTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0]?.id : '')),
    detachSecurityDepositFromTenantLeaseFx.done.map((r) => r.params.items[0]),
  ],
  to: dropDeposit,
})


forward({
  from: deleteDeposit.map((i) => ({ id: i.id })),
  to: deleteSecurityDepositFx,
})
