import * as React from 'react'
import { useStore } from 'effector-react'
import {
  Modal,
  Heading,
  Text,
  Row,
  Col,
  InputField,
  PrimaryButton,
  AccentText,
  RadioButtonsList,
  WrapField,
  SubTitle,
} from '@/ui'
import styled from 'styled-components'
import { $siteSearchModal, hideSiteSearchModal } from '../../../model'

export const SiteSearchModal = () => {
  const siteSearchModal = useStore($siteSearchModal)
  const [siteName, setSiteName] = React.useState<string>('')
  const [country, setCountry] = React.useState<string>('')
  const [town, setTown] = React.useState<string>('')
  const [selected, setSelected] = React.useState<boolean>(false)

  const yesNoItems = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ]

  return (
    <Modal show={siteSearchModal} onClose={() => hideSiteSearchModal()}>
      <>
        <Col width="100%" noPadding align="center">
          <HeadingWrap>
            <Heading>
              new site
              {' '}
              <AccentText>
                #12314
              </AccentText>
            </Heading>
          </HeadingWrap>
        </Col>
        <Row>
          <Col xs="100%" md="80%" align="flex-start">
            <>
              <SubTitle>
                1. site name
              </SubTitle>
              <InputField
                innerIcon
                value={siteName}
                onChangeValue={setSiteName}
                appendIcon="lense"
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="80%" align="flex-start">
            <>
              <SubTitle>
                2. Country
              </SubTitle>
              <InputField
                innerIcon
                value={country}
                onChangeValue={setCountry}
                appendIcon="lense"
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="80%" align="flex-start">
            <>
              <SubTitle>
                3. Town
              </SubTitle>
              <InputField
                innerIcon
                value={town}
                onChangeValue={setTown}
                appendIcon="lense"
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="80%" align="flex-start">
            <>
              <SubTitle>
                4. Selected
              </SubTitle>
              {/* <RadioButtonsList
                items={yesNoItems}
                value={selected}
                onChange={setSelected}
              /> */}
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="80%" noMargin noPadding>
            <WrapField>
              <PrimaryButton fluid onClick={() => hideSiteSearchModal()}>
                add new site
              </PrimaryButton>
            </WrapField>
          </Col>
          <Col xs="100%" md="80%" noMargin noPadding>
            <WrapField>
              <PrimaryButton
                fluid
                outline
                type="warning"
                onClick={() => hideSiteSearchModal()}
                appendIcon="close"
              >
                refuse
              </PrimaryButton>
            </WrapField>
          </Col>
        </Row>
      </>
    </Modal>
  )
}


const HeadingWrap = styled.div`
  padding-bottom: 15px;
`
