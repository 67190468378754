import * as React from 'react'
import { useField } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import { TerminationModality } from '@/dal/contracts.interfaces'
import {
  Row,
  Col,
  ContractFormTitle,
  InputField,
  NumberInput,
  SubTitle,
  InputTextArea,
  RadioButtonsList,
  Select,
} from '@/ui'
import { PaymentForm } from '../../../model/payment'

export const SpecialClauses = () => {
  const { t } = useTranslation('tenant/main')
  const estimatedRent = useField(PaymentForm.fields.estimatedRent)
  const budgetedRent = useField(PaymentForm.fields.budgetedRent)
  const estimatedCosts = useField(PaymentForm.fields.estimatedCosts)
  const budgetedCosts = useField(PaymentForm.fields.budgetedCosts)
  const penalty = useField(PaymentForm.fields.penalty)
  const typeOfAssignment = useField(PaymentForm.fields.typeOfAssignment)
  const subLettingConditions = useField(PaymentForm.fields.subLettingConditions)
  const work = useField(PaymentForm.fields.work)
  const specialClauses = useField(PaymentForm.fields.specialClauses)
  const terminationModality = useField(PaymentForm.fields.terminationModality)
  const privateField = useField(PaymentForm.fields.private)
  const coOwnership = useField(PaymentForm.fields.coOwnership)


  const terminationModalityItems: {text: string; value: TerminationModality}[] = [
    { value: 'deed-of-the-bailiff', text: t('deedOfTheBailiff') },
    { value: 'extrajudicial-act', text: t('extrajudicialAct') },
    { value: 'registered-letter', text: t('registeredLetter') },
    { value: 'registered-letter-or-extrajudicial-act', text: t('registeredLetterOrExtrajudicialAct') },
    { value: 'registered-letter-or-bailiff', text: t('registeredLetterOrBailiff') },
  ]
  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  return (
    <Row align="flex-start">
      <Col width="100%" align="flex-start" noMargin>
        <ContractFormTitle>{t('specialClauses')}</ContractFormTitle>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            1.
            {' '}
            {t('estimatedRent')}
          </SubTitle>
          <NumberInput
            isFloat
            value={estimatedRent.value}
            onChangeValue={estimatedRent.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('budgetedRent')}
          </SubTitle>
          <NumberInput
            isFloat
            value={budgetedRent.value}
            onChangeValue={budgetedRent.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('estimatedCosts')}
          </SubTitle>
          <NumberInput
            isFloat
            value={estimatedCosts.value}
            onChangeValue={estimatedCosts.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('budgetedCosts')}
          </SubTitle>
          <NumberInput
            isFloat
            value={budgetedCosts.value}
            onChangeValue={budgetedCosts.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3 * 2)" xs="100%" vAlign="flex-start" align="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('penalty')}
          </SubTitle>
          <InputTextArea
            value={penalty.value}
            onChangeValue={penalty.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" vAlign="flex-start" align="flex-start">
        <>
          <SubTitle>
            6.
            {' '}
            {t('typeOfAssigment')}
          </SubTitle>
          <InputTextArea
            value={typeOfAssignment.value}
            onChangeValue={typeOfAssignment.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" vAlign="flex-start" align="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('sublettingConditions')}
          </SubTitle>
          <InputTextArea
            value={subLettingConditions.value}
            onChangeValue={subLettingConditions.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" vAlign="flex-start" align="flex-start">
        <>
          <SubTitle>
            8.
            {' '}
            {t('work')}
          </SubTitle>
          <InputTextArea
            value={work.value}
            onChangeValue={work.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" vAlign="flex-start" align="flex-start">
        <>
          <SubTitle>
            9.
            {' '}
            {t('specialClauses')}
          </SubTitle>
          <InputTextArea
            value={specialClauses.value}
            onChangeValue={specialClauses.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            10.
            {' '}
            {t('terminationModality')}
          </SubTitle>
          <Select
            name={t('terminationModality')}
            items={terminationModalityItems}
            value={terminationModality.value}
            onChange={terminationModality.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            11.
            {' '}
            {t('privateLease')}
          </SubTitle>
          <RadioButtonsList
            name="privateLease"
            items={YesNoItems}
            value={privateField.value}
            onChange={privateField.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            12.
            {' '}
            {t('coownershipRules')}
          </SubTitle>
          <RadioButtonsList
            name="coownershipRules"
            items={YesNoItems}
            value={coOwnership.value}
            onChange={coOwnership.onChange}
          />
        </>
      </Col>
    </Row>
  )
}
