import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import {
  UserSettings,
  fetchMyProfileReqFx,
  updateProfileReqFx,
  getTitleListReqFx,
  Language,
  Title,
  Profile,
} from '@/dal'
import { rules } from '@/lib/validators'
import { createFilters } from '@/lib/effector-filters'

export const userSettingsDomain = createDomain()

export const $userSettings = userSettingsDomain.store<Profile | null>(null)
export const $languageList = userSettingsDomain.store<Language[]>(['en', 'fr'])
export const $titleList = userSettingsDomain.store<Title[]>([])

export const $languageSelectItemsList = $languageList.map(
  (list) => list.map((lang) => ({ value: lang, text: lang })),
)

export const $titleSelectItemsList = $titleList.map(
  (list) => list.map((title) => ({ value: title.id, text: title.name })),
)

export const saveUserSettings = userSettingsDomain.event()

export const getUserSettingsFx = attach({ effect: fetchMyProfileReqFx })
export const updateUserSettingsReqFx = attach({ effect: updateProfileReqFx })

export const getTitleListFx = attach({ effect: getTitleListReqFx })

export const userSettingsForm = createForm({
  domain: userSettingsDomain,
  fields: {
    language: {
      init: 'en' as Language,
    },
    title: {
      init: '' as Title['id'] | null,
    },
    id: {
      init: '',
      rules: [rules.required()],
    },
    email: {
      init: '',
      rules: [rules.required()],
    },
    phones: {
      init: [] as string[],
      rules: [rules.required()],
    },
    defaultPhone: {
      init: '',
      rules: [rules.required()],
    },
    lastName: {
      init: '',
      rules: [rules.required()],
    },
    firstName: {
      init: '',
      rules: [rules.required()],
    },
    marriedLastName: {
      init: '',
    },
    lastNameOfUse: {
      init: '',
    },
    firstNameOfUse: {
      init: '',
    },
    fax: {
      init: '',
    },
    dateOfBirth: {
      init: '',
    },
    photo: {
      init: {} as any,
    },
  },
})

export const filters = createFilters({
  domain: userSettingsDomain,
  logicFilter: true,
  filters: {
    language: {
      include: true,
      value: '' as Language,
    },
  },
})
