import * as React from 'react'
import styled from 'styled-components'
import { Checkbox } from './Checkbox'

type Props<Value> = {
  items: Item<Value>[];
  value: Value[];
  mini?: boolean;
  fluid?: boolean;
  onChange: (val: Value[]) => void;
}

type Item<Value> = {
  value: Value;
  text: string;
  disabled?: boolean;
}


export function CheckboxList<Value>({
  items, value, mini, onChange, fluid,
}: Props<Value>) {
  const setValue = (val: Value) => {
    if (value.includes(val)) {
      const localVal = value
      const i = localVal.indexOf(val)
      localVal.splice(i, 1)
      onChange([...localVal])
    } else {
      onChange([...value, val])
    }
  }


  const list = items.map((item) => (
    <ItemWrap
      key={String(item.value)}
      fluid={Boolean(fluid)}
    >
      <Checkbox
        checked={value.includes(item.value)}
        mini={Boolean(mini)}
        onChange={() => setValue(item.value)}
        disabled={Boolean(item.disabled)}
      >
        {item.text}
      </Checkbox>
    </ItemWrap>
  ))

  return (
    <Wrap>
      {list}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
flex-wrap: wrap;
  user-select: none;
`

const ItemWrap = styled.div<{fluid: boolean}>`
    display: flex;
    margin-right: 16px;
    width: ${(p) => (p.fluid ? '100%' : 'auto')};
`
