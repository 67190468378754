import * as React from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconName } from '@/ui/icons'
import { themeVar, useTheme, ThemedStyledProps } from '../theming'

type Props = {
  error?: boolean;
  errorText?: string;
  value: number;
  onChangeValue: (val: number) => void;
} & React.InputHTMLAttributes<HTMLInputElement>

export const InputNumber = ({
  error,
  errorText,
  onChangeValue,
  value,
  ...inputProps
}: Props) => {
  const hasError = error || Boolean(errorText)
  const theme = useTheme()
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <Wrap hasError={hasError}>
      <IconWrap
        onClick={() => inputRef?.current?.stepDown()}
      >
        <Icon
          icon="minus"
          width={15}
        />
      </IconWrap>
      <StyledInput
        ref={inputRef}
        onChange={(e) => onChangeValue(+e.currentTarget.value)}
        {...inputProps}
        value={value}
        type="number"
      />


      <IconWrap
        onClick={() => inputRef?.current?.stepUp()}
      >
        <Icon
          icon="plus"
          fill={theme.brandOrangeColor}
          width={20}
        />
      </IconWrap>
      {hasError && (
        <ErrorText>{errorText}</ErrorText>
      )}
    </Wrap>
  )
}

type StyledInputWrapProps = {
  hasError?: boolean;
} & ThemedStyledProps
const Wrap = styled.div<StyledInputWrapProps>`
  width: 100%;
  height: 35px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  font-family: ${themeVar('baseFont')};
  outline: none !important;
`


type StyledInputProps = {
  hasError?: boolean;
} & ThemedStyledProps

const StyledInput = styled.input<StyledInputProps>`
  font-family: ${themeVar('baseFont')};
  font-size: 18px;
  height: 33px;
  color: ${themeVar('textColor')};
  flex: 1;
  border: none;
  border-radius: 4px;
  margin: auto;
  width: 100%;
  text-align: center;
  &:focus{
    border: none;
    outline: none
  }
  
  ${({ hasError, theme }) => hasError && css`
    border: 1px solid ${theme.inputErrorColor} !important;
  `};
  ${({ hasError, theme }) => !hasError && css`
    border: none;
  `};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
  -moz-appearance: textfield;
`


const IconWrap = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
    pointer-events: none;
    cursor: pointer;
    pointer-events: all;
`

const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
  width: 100%;
`
