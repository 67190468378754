import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateContractEndorsementRes } from '@/dal/contracts.interfaces'
import {
  createContractEndorsementReqFx,
  deleteContractEndorsementReqFx,
  attachContractEndorsementToInsuranceContractReqFx,
  detachContractEndorsementToInsuranceContractReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateContractEndorsementRes[]>([])

export const loadItem = d.createEvent<CreateContractEndorsementRes[]>()
export const addItem = d.createEvent<CreateContractEndorsementRes>()
export const deleteItem = d.createEvent<CreateContractEndorsementRes>()
export const dropItem = d.createEvent<CreateContractEndorsementRes['id']>()


export const EndorsementForm = createForm({
  domain: d,
  fields: {
    description: {
      init: '',
      rules: [rules.required()],
    },
    effectiveDate: {
      init: '',
    },
    dateOfSigning: {
      init: '',
    },
    content: {
      init: '',
    },
  },
})

export const createItemFx = attach({ effect: createContractEndorsementReqFx })
export const attachItemFx = attach({ effect: attachContractEndorsementToInsuranceContractReqFx })
export const deleteItemFx = attach({ effect: deleteContractEndorsementReqFx })
export const detachItemFx = attach({ effect: detachContractEndorsementToInsuranceContractReqFx })
