import { createCustomError } from '@/lib/errors'
import { attachWrapper } from '@/lib/effector-extra'
import { quickUUID } from '@/lib/random'

import {
  Method, requestFx,
} from './rest-api'
import {
  Site,
  Surface,
  Locality,
  PaginationParams,
  PaginationResult,
  WithFilters,
  WithElemId,
  WithSorting,
  AttachItems,
} from './common.interfaces'
import {
  NewsArticle,
  SiteContact,
  SiteContract,
  SiteLeases,
  FetchSitesListParams,
  FetchSitesListRes,
  CreateSiteParams,
  CreateSiteRes,
  DeleteSiteParams,
  DeleteSiteRes,
  AttachCadastralReferenctToSiteParams,
  AttachCadastralReferenctToSiteRes,
  CreateSurfaceParams,
  CreateSurfaceRes,
  DeleteSurfaceParams,
  DeleteSurfaceRes,
  AttachCadastralReferenctToSurfaceParams,
  AttachCadastralReferenctToSurfaceRes,
  CreateLocalityParams,
  CreateLocalityRes,
  DeleteLocalityParams,
  DeleteLocalityRes,
  AttachCadastralReferenctToLocalityParams,
  AttachCadastralReferenctToLocalityRes,
  AttachSurfaceToSiteParams,
  AttachSurfaceToSiteRes,
  DetachSurfaceFromSiteParams,
  DetachSurfaceFromSiteRes,
  AttachLocalityToSurfaceParams,
  AttachLocalityToSurfaceRes,
  CreateCadastralReferenceParams,
  CreateCadastralReferenceRes,
} from './site.interfaces'
import { UnknownError } from './common-errors'

import { companyMock } from './company'

const SITE_CREATION_ERROR = 400
const SITES_LIMIT_REACHED = 2009
const SITE_CREATION_DATA_ERROR = 2010

const SitesLimitReached = createCustomError('SitesLimitReached')
const SiteCreationDataError = createCustomError('SiteCreationDataError')

/*
 * Site
 */
export const getSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (params: { id: string }) => ({
    method: Method.post,
    url: '/site/get',
    body: params,
  }),
  mapResult: ({ result }): Site => result.data,
  mapError: ({ error }) => new Error(),
})

export const fetchSitesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchSitesListParams) => ({
    method: Method.post,
    url: '/site/list',
    body: p,
  }),
  mapResult: ({ result }): FetchSitesListRes => result.data,
  mapError: ({ error }) => new Error(),
})

export const createSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateSiteParams) => ({
    method: Method.post,
    url: '/site/add',
    body: p,
  }),
  mapResult: ({ result }): CreateSiteRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === SITE_CREATION_ERROR) {
      switch (error.response?.data.code) {
        case SITES_LIMIT_REACHED:
          return new SitesLimitReached()
        case SITE_CREATION_DATA_ERROR:
          return new SiteCreationDataError()
        default:
      }
    }
    return new UnknownError()
  },
})

export const deleteSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteSiteParams) => ({
    method: Method.delete,
    url: '/site/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteSiteRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const attachCadastralReferenceToSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachCadastralReferenctToSiteParams) => ({
    method: Method.put,
    url: '/site/editCadastralReference',
    body: p,
  }),
  mapResult: ({ result }): AttachCadastralReferenctToSiteRes => result.data,
})

/*
 * Surfaces
 */
export const createSurfaceReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateSurfaceParams) => ({
    method: Method.post,
    url: '/surface/add',
    body: p,
  }),
  mapResult: ({ result }): CreateSurfaceRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const attachSurfaceToSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachSurfaceToSiteParams) => ({
    method: Method.post,
    url: '/site/addSurfaces',
    body: p,
  }),
  mapResult: ({ result }): AttachSurfaceToSiteRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const detachSurfaceFromSiteReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachSurfaceFromSiteParams) => ({
    method: Method.post,
    url: '/site/deleteSurfaces',
    body: p,
  }),
  mapResult: ({ result }): DetachSurfaceFromSiteRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})


export const deleteSurfaceReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteSurfaceParams) => ({
    method: Method.delete,
    url: '/surface/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteSurfaceRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const attachCadastralReferenceToSurfaceReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachCadastralReferenctToSurfaceParams) => ({
    method: Method.post,
    url: '/surface/attachCadastralReference',
    body: p,
  }),
  mapResult: ({ result }): AttachCadastralReferenctToSurfaceRes => result.data,
})

/*
 * Localities
 */
export const createLocalityReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateLocalityParams) => ({
    method: Method.post,
    url: '/locality/add',
    body: p,
  }),
  mapResult: ({ result }): CreateLocalityRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const attachLocalityToSurfaceReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachLocalityToSurfaceParams) => ({
    method: Method.post,
    url: '/surface/addLocalities',
    body: p,
  }),
  mapResult: ({ result }): AttachLocalityToSurfaceRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const deleteLocalityReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteLocalityParams) => ({
    method: Method.delete,
    url: '/locality/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteLocalityRes => result.data,
  mapError: ({ error }) => new UnknownError(),
})

export const attachCadastralReferenceToLocalityReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachCadastralReferenctToLocalityParams) => ({
    method: Method.post,
    url: '/locality/attachCadastralReference',
    body: p,
  }),
  mapResult: ({ result }): AttachCadastralReferenctToLocalityRes => result.data,
})

export const createCadastralReferenceReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateCadastralReferenceParams) => ({
    method: Method.post,
    url: '/cadastralReference/add',
    body: p,
  }),
  mapResult: ({ result }): CreateCadastralReferenceRes => result.data,
})

export const getFilteredSitesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: (WithFilters & PaginationParams) | PaginationParams) => ({
    method: Method.post,
    url: '/site/list',
    body: p,
  }),
  mapResult: ({ result }): PaginationResult<Site> => result.data,
  mapError: ({ error }) => new Error(),
})

export const getNewsArticlesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (params: (PaginationParams & WithElemId) | PaginationParams) => ({
    method: Method.post,
    url: '/newsArticle/list',
    body: params,
  }),
  mapResult: ({ params, result }): PaginationResult<NewsArticle> => result.data,
  mapError: ({ error }) => new Error(),
})

export const getSiteContactsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (
    params:
      PaginationParams
      & WithElemId
      & WithFilters<'firstName' | 'lastName' | 'email'>
      & WithSorting<'firstName' | 'lastName' | 'email' | 'type' | 'createdAt'>,
  ) => ({
    method: Method.post,
    url: '/siteContact/list',
    body: params,
  }),
  mapResult: ({ params, result }): PaginationResult<SiteContact> => result.data,
  mapError: ({ error }) => new Error(),
})

/** @deprecated */
export const getSiteContractsListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (params: PaginationParams & WithElemId) => ({
    method: Method.post,
    url: '/contract/list',
    body: params,
  }),
  mapResult: ({ params, result }): PaginationResult<SiteContract> => result.data,
  mapError: ({ error }) => new Error(),
})

/** @deprecated */
export const getSiteLeasesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (params: PaginationParams & WithElemId) => ({
    method: Method.post,
    url: '/lease/list',
    body: params,
  }),
  mapResult: ({ params, result }): PaginationResult<SiteLeases> => result.data,
  mapError: ({ error }) => new Error(),
})
