import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  DateTimePicker,
  DatePicker,
  BaseDateTimePickerProps,
} from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme, ThemeOptions } from '@material-ui/core'
import { Icon } from '@/ui/icons'
import DayJs from 'dayjs'
import { themeVar, ThemedStyledProps, useTheme } from '../theming'

type Props = {
  errorText?: string;
  value: string | null;
  fill?: boolean;
  onChange: (date: string | null) => void;
  inputFormat?: string;
  outputFormat?: string;
  onlyDate?: boolean;
  disabled?: boolean;
  max?: string | Date;
  min?: string | Date;
}

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#FB8C5D',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: '#FB8C5D',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#FB8C5D',
      },
      current: {
        color: '#FB8C5D',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#FB8C5D',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#FB8C5D',
      },
      thumb: {
        backgroundColor: '#FB8C5D',
        border: '14px solid #FB8C5D',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#FB8C5D',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#FB8C5D',
      },
    },
  },
} as ThemeOptions)

export const InputDateTime = ({
  errorText, value, onChange, fill, outputFormat = 'DD.MM.YYYY', inputFormat, onlyDate, disabled, max, min,
}: Props) => {
  const hasError = Boolean(errorText)
  const theme = useTheme()
  const date = value ? DayJs(value, inputFormat).toDate() : null
  const minValue = min ? DayJs(min, inputFormat).toDate() : undefined
  const maxValue = max ? DayJs(max, inputFormat).toDate() : undefined
  return (
    <Wrap hasError={hasError}>
      <StyledInputWrap filled={Boolean(fill)} disabled={disabled}>
        <ThemeProvider theme={materialTheme}>
          {onlyDate ? (
            <StyledDateInput
              format={outputFormat}
              value={date}
              onChange={(v) => onChange(v?.toISOString() || null)}
              disabled={disabled}
              maxDate={maxValue}
              minDate={minValue}
              clearable
            />
          )
            : (
              <StyledDateTimeInput
                format={outputFormat}
                value={date}
                onChange={(v) => onChange(v?.toISOString() || null)}
                disabled={disabled}
                maxDate={maxValue}
                minDate={minValue}
                clearable
              />
            )}
        </ThemeProvider>
        {/* <StyledInput
          filled={Boolean(fill)}
          type="text"
          defaultValue={defaultValue}
          onBlur={(v) => onChange(new Date(v.target?.value) || null)}
        /> */}

        <IconWrap>
          <Icon
            fill={theme.brandOrangeColor}
            icon="calendar"
          />
        </IconWrap>
      </StyledInputWrap>

      {hasError && (
        <ErrorText filled={Boolean(fill)}>{errorText}</ErrorText>
      )}
    </Wrap>
  )
}

type StyledInputProps = {
  hasError?: boolean;

} & ThemedStyledProps

const Wrap = styled.div<StyledInputProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  outline: none !important;
  
  
`


const IconWrap = styled.div`
  margin-right: 0px;
  display: flex;
  height: 100%;
  padding: 0 10px;
  align-items: center;
  background: ${themeVar('inputDateTimeIconBg')};
`
type StyledInputWrapProps = {
  filled?: boolean;
  disabled?: boolean;
}

const StyledDateTimeInput = styled(DateTimePicker)<BaseDateTimePickerProps&StyledInputWrapProps>`
  padding-left: 12px;
  padding-right: 12px;
  font-family: ${themeVar('baseFont')};
  font-size: 18px;
  height: 100%;
  color: ${themeVar('textColor')};
  ${({ filled }) => filled && css`
    background: ${themeVar('inputBorderColor')};
  `}
  flex: 1;
  width: 100%;
  border: none;
  margin: auto;
  &:focus{
    border: none;
    outline: none
  }
  & .MuiInput-input {
    padding: 7px 12px 6px;
  }
  &>.MuiInput-underline{
    &::after, &::before{
      display: none;
    }
  }
`

const StyledDateInput = styled(DatePicker)<BaseDateTimePickerProps&StyledInputWrapProps>`
  padding-left: 12px;
  padding-right: 12px;
  font-family: ${themeVar('baseFont')};
  font-size: 18px;
  height: 100%;
  color: ${themeVar('textColor')};
  ${({ filled }) => filled && css`
    background: ${themeVar('inputBorderColor')};
  `}
  flex: 1;
  width: 100%;
  border: none;
  margin: auto;
  &:focus{
    border: none;
    outline: none
  }
  & .MuiInput-input {
    padding: 7px 12px 6px;
  }
  &>.MuiInput-underline{
    &::after, &::before{
      display: none;
    }
  }
`

const StyledInputWrap = styled.div<StyledInputWrapProps>`
  height: 35px;
  border: 2px solid ${themeVar('inputBorderColor')};
  border-radius: 4px;
  ${({ filled }) => filled && css`
    background: ${themeVar('inputBorderColor')};
  `}
  margin: auto;
  display: flex;
  width: 100%;
  ${({ disabled }) => disabled && css`
    border: none !important;
    background-image: linear-gradient(-60deg,
      ${themeVar('brandGrayPale2xColor')} 0px,
      ${themeVar('brandGrayPale2xColor')} 15px,
      ${themeVar('brandGrayPale3xColor')} 15px,
      ${themeVar('brandGrayPale3xColor')} 40px,
      ${themeVar('brandGrayPale2xColor')} 40px,
      ${themeVar('brandGrayPale2xColor')} 55px
      );
    background-repeat: repeat-x;
    background-size: 61px;
    })
  `}
`

const ErrorText = styled.div<StyledInputWrapProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
  width: 100%;
  ${({ filled }) => filled && css`
    color: white;
  `}
`
