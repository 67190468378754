import * as React from 'react'
import { useField } from 'effector-forms'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  ContractFormTitle,
  InputField,
  SubTitle,
  Select,
  InputDateTime,
} from '@/ui'
import {
  ServiceProviderContractIndexingType, ReviewType, ReferenceIndex,
} from '@/dal/contracts.interfaces.ts'
import { GeneralForm } from '../../../model/general'

export const Indexation = () => {
  const { t } = useTranslation('tenant/main')
  const indexingType = useField(GeneralForm.fields.indexingType)
  const indexingReviewType = useField(GeneralForm.fields.indexingReviewType)
  const indexingFirstAnniversaryDate = useField(GeneralForm.fields.indexingFirstAnniversaryDate)
  const indexingInvoicingInterval = useField(GeneralForm.fields.indexingInvoicingInterval)
  const indexingReferenceIndex = useField(GeneralForm.fields.indexingReferenceIndex)
  const indexingReferenceIndexValue = useField(GeneralForm.fields.indexingReferenceIndexValue)

  const leaseDurationItems: {text: string; value: 1 | 2 | 3}[] = [
    { text: t('1month'), value: 1 },
    { text: t('2month'), value: 2 },
    { text: t('3month'), value: 3 },
  ]

  const indexingTypeItems: {text: string; value: ServiceProviderContractIndexingType}[] = [
    { text: t('official'), value: 'official' },
    { text: t('noIndexation'), value: 'no-indexation' },
  ]
  const reviewTypeItems: {text: string; value: ReviewType | null}[] = [
    { text: t('notSelected'), value: null },
    { value: t('annual'), text: 'annual' },
    { value: t('conventionalTriennal'), text: 'conventional-triennal' },
    { value: t('legalTriennal'), text: 'legal-triennal' },
    { value: t('sixYearConventional'), text: 'six-year-conventional' },
    { value: t('sixYearLegal'), text: 'six-year-legal' },
    { value: t('nineYearConventional'), text: 'nine-year-conventional' },
    { value: t('nineYearLegal'), text: 'nine-year-legal' },
  ]
  const referenceIndexItems: {text: string; value: ReferenceIndex | null}[] = [
    { text: t('notSelected'), value: null },
    { value: t('residentialBuildingsConstructionCostIndex'), text: 'residential-buildings-construction-cost-index' },
    { value: t('commercialRentsIndex'), text: 'commercial-rents-index' },
    { value: t('buildingIndexForAllConstructions'), text: 'building-index-for-all-constructions' },
    { value: t('tertiaryActivitiesRentsIndex'), text: 'tertiary-activities-rents-index' },
    { value: t('rentalReferenceIndex'), text: 'rental-reference-index' },
    { value: t('commercialRentsIndexAverage'), text: 'commercial-rents-index-average' },
    { value: t('deleteThisIndex'), text: 'delete-this-index' },
  ]
  return (
    <Row align="flex-start">
      <Col width="100%" align="flex-start" noMargin>
        <ContractFormTitle>{t('indexation')}</ContractFormTitle>
      </Col>
      <Col width="100%" align="flex-start">
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" noMargin>
          <>
            <SubTitle>{t('indexationType')}</SubTitle>
            <Select
              name={t('selectType')}
              items={indexingTypeItems}
              value={indexingType.value}
              onChange={indexingType.onChange}
            />
          </>
        </Col>
      </Col>
      {(indexingType.value === 'official') && (
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
          <>
            <SubTitle>
              1.
              {' '}
              {t('referenceIndex')}
            </SubTitle>
            <Select
              name={t('selectIndex')}
              items={referenceIndexItems}
              value={indexingReferenceIndex.value}
              onChange={indexingReferenceIndex.onChange}
            />
          </>
        </Col>
      )}
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            {(indexingType.value === 'official') ? '2' : '1'}
            .
            {' '}
            {t('reviewType')}
          </SubTitle>
          <Select
            name={t('selectType')}
            items={reviewTypeItems}
            value={indexingReviewType.value}
            onChange={indexingReviewType.onChange}
          />
        </>
      </Col>
      {(indexingType.value === 'official') && (
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
          <>
            <SubTitle>
              3.
              {' '}
              {t('referenceIndexValue')}
            </SubTitle>
            <InputField
              value={indexingReferenceIndexValue.value}
              onChangeValue={indexingReferenceIndexValue.onChange}
            />
          </>
        </Col>
      )}
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            {(indexingType.value === 'official') ? '4' : '2'}
            .
            {' '}
            {t('firstAnniversaryDate')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={indexingFirstAnniversaryDate.value}
            onChange={(val) => indexingFirstAnniversaryDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            {(indexingType.value === 'official') ? '5' : '3'}
            .
            {' '}
            {t('invoicingInterval')}
          </SubTitle>
          <Select
            name={t('selectDuration')}
            items={leaseDurationItems}
            value={indexingInvoicingInterval.value}
            onChange={indexingInvoicingInterval.onChange}
          />
        </>
      </Col>
    </Row>
  )
}
