import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  PrimaryButton,
  Card,
  Title,
  AccentText,
  SmallText,
  ExpandedRow,
  Icon,
  themeVar,
  Text,
  SubTitle,
} from '@/ui'
import { Site, Surface, Locality } from '@/dal'
import { showConfirmModal } from '@/features/app'

import { showModal as showNewLocality } from '../../model/new-locality'
import { showModal as showNewSurface } from '../../model/new-surface'
import { showModal as showInfoModal } from '../../model/site-info'
import { deleteSurface, deleteLocality } from '../../model/main'


const onDeleteLocality = (locality: Locality) => showConfirmModal({
  title: 'are you sure?',
  text: 'Do you want to delete that locality?',
  action: () => {
    deleteLocality(locality.id)
  },
})

const onDeleteSurface = (e: React.MouseEvent, surface: Surface) => {
  e.stopPropagation()
  showConfirmModal({
    title: 'are you sure?',
    text: 'Do you want to delete that locality?',
    action: () => {
      deleteSurface(surface.id)
    },
  })
}


interface Props {
  site: Site;
  className?: string;
}

export const SiteCard = ({ site, className }: Props) => {
  const { t } = useTranslation('site-creation')
  const localityList = (localities: Locality[]) => localities.map((locality) => (
    <LocalityRow>
      <Col width="60%" noMargin>
        <>
          <SmallText>{t('locality')}</SmallText>
          <Text>
            {locality.name}
          </Text>
        </>
      </Col>
      <Col width={1} noMargin>
        <>
          <SmallText>
            {t('availability')}
          </SmallText>
          <Text>
            {locality.availability ? t('free') : t('booking')}
          </Text>
        </>
      </Col>
      <Col width={1} noMargin align="flex-end">
        <ClickableIcon
          width={22}
          icon="trash"
          fill="white"
          onClick={() => onDeleteLocality(locality)}
        />
      </Col>
    </LocalityRow>
  ))


  const surfaceActions = (surface: Surface) => (
    <Row>
      <SurfaceActionsButton onClick={() => showNewLocality(surface.id)}>
        {t('addLocality')}
      </SurfaceActionsButton>
    </Row>
  )

  const surfaceItems = site.surfaces.map((surface) => (
    <SurfaceRow key={surface.id}>
      <StyledExpandedRow
        noChangeColor
        hiddenContentActions={surfaceActions(surface)}
        hiddenContent={<>{localityList(surface.localities)}</>}
      >
        {(open) => (
          <SurfaceTitle>
            {!open && (
            <>
              <Col noMargin noPadding width={1} align="flex-start">
                <>
                  {surface.name}
                </>
              </Col>
              <Col noMargin noPadding width={1} align="flex-end">
                <LocalityBlock>
                  {t('allLocalities')}
                  <Count>{surface.localities.length}</Count>
                </LocalityBlock>
              </Col>
            </>
            )}
            {open && (
            <>
              <Col width="60%" noMargin>
                <>
                  <SmallText>{t('surface')}</SmallText>
                  <Text>
                    {surface.name}
                  </Text>
                </>
              </Col>
              <Col width={1} noMargin>
                <>
                  <SmallText>
                    {t('areaSquare')}
                    <sup>2</sup>
                  </SmallText>
                  <Text>
                    {surface.areaSurface}
                  </Text>
                </>
              </Col>
              <Col width={1} noMargin align="flex-end">
                <Icon
                  width={22}
                  icon="trash"
                  fill="white"
                  onClick={(e) => onDeleteSurface(e, surface)}
                />
              </Col>
            </>
            )}
          </SurfaceTitle>
        )}
      </StyledExpandedRow>
    </SurfaceRow>
  ))


  return (
    <Wrap className={className}>
      <Card>
        <Image src="/assets/img/site.jpg" />
        <Content>
          <ContentRow>
            <Col width="100%" noMargin align="flex-start">
              <>
                <FieldName>{t('site')}</FieldName>
                <Title align="left">
                  {site.name}
                </Title>
              </>
            </Col>
          </ContentRow>
          <ContentRow>
            <Col width="100%" noMargin>
              <>
                <FieldName>{t('description')}</FieldName>
                <Text>
                  {site.description}
                </Text>
              </>
            </Col>
          </ContentRow>
          <ContentRow>
            <Col width="50%" noMargin>
              <>
                <FieldName>{t('assigment')}</FieldName>
                <Text>
                  {site.assigment}
                </Text>
              </>
            </Col>
            <Col width="50%" noMargin>
              <>
                <FieldName>{t('type')}</FieldName>
                <Text>
                  {site.type}
                </Text>
              </>
            </Col>
          </ContentRow>
          <ContentRow>
            <Col width="100%" noMargin>
              <>
                <FieldName>{t('address')}</FieldName>
                <Text>
                  {site.address}
                </Text>
              </>
            </Col>
          </ContentRow>
          <SurfacesWrap>
            {surfaceItems}
          </SurfacesWrap>
          <ContentRow>
            <PrimaryButton
              fluid
              appendIcon="plus"
              onClick={() => showNewSurface(site.id)}
            >
              {t('addSurface')}
            </PrimaryButton>
          </ContentRow>
          <Divider />
          <Row>
            <PrimaryButton
              fluid
              outline
              type="gray"
              appendIcon="rightChevron"
              onClick={() => showInfoModal(site)}
            >
              {t('detailedInfo')}
            </PrimaryButton>
          </Row>
        </Content>
      </Card>
    </Wrap>
  )
}

const Wrap = styled.div`
  padding: 20px;
  padding-bottom: 0;
`
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px 20px;
`

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`

const FieldName = styled.span`
  color: ${themeVar('brandGrayColor')};
  font-size: 10px;
  width: 100%;
`

const ContentRow = styled.div`
  display: flex;
  margin-top: 19px;
`

const SurfaceTitle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 20px;
`

const Count = styled.b`
  margin-left: 5px;
`

const LocalityBlock = styled.div`
  background: ${themeVar('brandColor')};
  border-radius: 4px;
  padding: 5px 10px;
`

const StyledExpandedRow = styled(ExpandedRow)`
  border-radius: 4px;
  overflow: hidden;
`

const LocalityRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${themeVar('brandColor')};
  color: white;
  padding: 15px 10px;
  margin: 10px 10px 0px;
  border-radius: 4px;
`

const SurfaceActionsButton = styled.button`
  background: none;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
`
const Divider = styled.div`
  width: calc(100% + 40px);
  height: 1px;
  background: ${themeVar('brandGrayPaleColor')};
  margin: 20px -20px;
`

const SurfacesWrap = styled.div`
  margin-top: 33px;
`

const SurfaceRow = styled(Row)`
  padding: 7px 0;
`

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`
