import { forward } from 'effector'
import { history } from '@/lib/history'
import {
  $activeTab, setTab, $tenantContract, getTenentLeaseFx, loadContract, setContract,
} from './unit'

$activeTab
  .on(setTab, (_, r) => r)

$tenantContract
  .on(getTenentLeaseFx.doneData, (_, r) => r.tenantLease)
  .on(setContract, (_, r) => r)
  .reset(getTenentLeaseFx.fail)

forward({
  from: loadContract.map((val) => ({ id: val })),
  to: getTenentLeaseFx,
})

getTenentLeaseFx.fail.watch(() => {
  history.push('/tenant-contract')
})

setContract.watch((contract) => {
  history.push(`/tenant-contract/${contract.id}`)
})
