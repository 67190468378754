import { createDomain, attach } from 'effector'
import {
  Site,
  Surface,
  Locality,
  deleteLocalityReqFx,
  deleteSurfaceReqFx,
  deleteSiteReqFx,
} from '@/dal'

const d = createDomain('d')

export const deleteSite = d.createEvent<Site['id']>()
export const deleteSurface = d.createEvent<Surface['id']>()
export const deleteLocality = d.createEvent<Locality['id']>()
export const siteCreated = d.event<void>()

export const deleteSiteFx = attach({ effect: deleteSiteReqFx })
export const deleteSurfaceFx = attach({ effect: deleteSurfaceReqFx })
export const deleteLocalityFx = attach({ effect: deleteLocalityReqFx })
