import {
  forward, sample, split, combine, Store,
} from 'effector'
import { condition } from 'patronum'
import { CreateTenantLeaseParams, EditTenentLeaseParams, TenantLease } from '@/dal/contracts.interfaces'
import {
  counterpartyAutocomplete,
  GeneralForm,
  createTenantLeaseFx,
  createContract,
  updateContract,
  editTenantLeaseFx,
} from './unit'
import {
  getTenentLeaseFx,
  $tenantContract,
  setTab,
  setContract,
} from '../main'
import { $selectedObjectsForBackend, SelectedObjects } from '../rentalObjects/unit'

GeneralForm.fields.indexingType.$value.watch((val) => {
  switch (val) {
    case 'no-indexation':
    case 'fixed':
      GeneralForm.fields.capped.reset()
      GeneralForm.fields.indexingReferenceIndexValue.reset()
      GeneralForm.fields.indexingReferenceIndex.reset()
      break
    case 'official':
      GeneralForm.fields.capped.reset()
      break
    default:
      break
  }
})

GeneralForm.fields.leaseDurationType.$value.watch((val) => {
  if (val) {
    GeneralForm.fields.leaseDurationYears.reset()
    GeneralForm.fields.leaseDurationMonths.reset()
  }
})

type FormFields = typeof GeneralForm.$values extends Store<infer U> ? U : never
const mapSaveRequestParams = (form: FormFields, selectedObjects: SelectedObjects): CreateTenantLeaseParams => {
  const resultData: CreateTenantLeaseParams = {
    name: form.name,
    conterparty: form.conterparty,
    code: form.code,
    description: form.description,
    active: form.active,
    type: form.type,
    tacitRenewal: form.tacitRenewal,
    grouping: form.grouping || undefined,
    variableRent: form.variableRent,
    effectiveDate: form.effectiveDate,
    dateOfSigning: form.dateOfSigning,
    movingInToPremisesDate: form.movingInToPremisesDate,
    leaseDurationYears: +form.leaseDurationYears || undefined,
    leaseDurationMonths: +form.leaseDurationMonths,
    expirationDate: form.expirationDate,
    deadlineToDenunciationDate: form.deadlineToDenunciationDate,
    firm: form.firm,
    indexingType: form.indexingType,
    objectsSites: selectedObjects.sites,
    objectsSurfaces: selectedObjects.surfaces,
    objectsLocalities: selectedObjects.localities,
    indexingReviewType: form.indexingReviewType || undefined,
    indexingFirstAnniversaryDate: form.indexingFirstAnniversaryDate,
    indexingInvoicingInterval: form.indexingInvoicingInterval,
    indexingReferenceIndex: undefined,
    indexingReferenceIndexValue: undefined,
    capped: undefined,
  }
  if (resultData.indexingType === 'official') {
    resultData.indexingReferenceIndex = form.indexingReferenceIndex || undefined
    resultData.indexingReferenceIndexValue = form.indexingReferenceIndexValue
  }
  if (resultData.indexingType === 'capped') {
    resultData.capped = +form.capped
    resultData.indexingReferenceIndex = form.indexingReferenceIndex || undefined
    resultData.indexingReferenceIndexValue = form.indexingReferenceIndexValue
  }
  return resultData
}

function checkStandartDuration(month?: number, year?: number): boolean {
  if (!month && !year) return true
  if (year && [3, 6, 9, 10, 12].includes(year) && !month) return true
  return false
}

forward({
  from: getTenentLeaseFx.doneData.map((x) => ({
    ...x.tenantLease,
    leaseDurationType: checkStandartDuration(x.tenantLease.leaseDurationMonths, x.tenantLease.leaseDurationYears),
    conterparty: x.counterpartyWithContact.conterparty?.id || '',
    leaseDurationYears: `${x.tenantLease.leaseDurationYears || ''}`,
    leaseDurationMonths: `${x.tenantLease.leaseDurationMonths || ''}`,
    capped: x.tenantLease.indexingType === 'capped' ? `${x.tenantLease.capped || ''}` : undefined,
  })),
  to: GeneralForm.set,
})

forward({
  from: getTenentLeaseFx.fail,
  to: GeneralForm.reset,
})

condition({
  source: GeneralForm.formValidated,
  if: $tenantContract.map((x) => x === null),
  then: createContract,
  else: updateContract,
})

// counterparty autocomplete
getTenentLeaseFx.doneData.watch((x) => {
  counterpartyAutocomplete.onChange(x.counterpartyWithContact)
})

forward({
  from: counterpartyAutocomplete.onChange.map((x) => (x ? x.conterparty.id : '')),
  to: GeneralForm.fields.conterparty.onChange,
})


sample({
  source: [GeneralForm.$values, $selectedObjectsForBackend],
  clock: createContract,
  target: createTenantLeaseFx,
  fn: ([form, selectedObjects]): CreateTenantLeaseParams => ({ ...mapSaveRequestParams(form, selectedObjects) }),
})

sample({
  source: [GeneralForm.$values, $tenantContract, $selectedObjectsForBackend],
  clock: updateContract,
  target: editTenantLeaseFx,
  fn: ([form, contract, selectedObjects]): EditTenentLeaseParams => ({
    id: (contract as TenantLease).id,
    data: mapSaveRequestParams(form, selectedObjects),
  }),
})

forward({
  from: [
    editTenantLeaseFx.done.map(() => 'payment'),
    createTenantLeaseFx.done.map(() => 'payment'),
  ],
  to: setTab,
})

forward({
  from: createTenantLeaseFx.doneData,
  to: setContract,
})
