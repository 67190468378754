import * as React from 'react'
import styled from 'styled-components'

import { Card } from '@/ui'

import {
  Wrap,
  Title,
  Subtitle,
  Text,
  Ul,
  Li,
  Link,
} from '../parts'

export const GDPR_FR = () => (
  <Wrap>
    <Title>
      1.1. Texte du consentement
    </Title>
    <Text>
      Vos données personnelles sont protégées, elles ne seront communiquées à aucun tiers ni utilisées à d’autres
      fins que les traitements réalisés avec votre consentement sur SNOUKI. Ces données permettront de vous localiser
      sur un site de rattachement et de suivre vos affectations. Elles permettront à l’utilisateur de mieux maitriser
      son patrimoine immobilier et d’avoir une vision globale de l’ensemble des personnes, des occupants et des
      intervenants. Conformément aux articles 39 et suivants de la loi n°78-17 du 6 janvier 1978 relative
      à l’informatique, aux fichiers et aux libertés, vous pouvez obtenir communication et, le cas échéant,
      rectification ou suppression des informations vous concernant, en vous adressant à votre administrateur.
    </Text>


    <Title>
      1.2. Texte de la politique des cookies
    </Title>
    <Text>
      Sur SNOUKI nous émettons uniquement des cookies fonctionnels (
      <b>last_skin</b>
      {' '}
      pour connaître le dernier thème utilisé,
      {' '}
      <b>fluideToken</b>
      {' '}
      pour la fonction EXPERT,
      {' '}
      <b>userType</b>
      {' '}
      pour savoir si l’utilisateur a des droits sur plusieurs sites et
      {' '}
      <b>jsessionid</b>
      {' '}
      pour savoir si l'utilisateur a une session active)
    </Text>
    <Text>
      Nous n’émettons ni des cookies analytiques ni publicitaires.
    </Text>
    <Text>
      Le texte suivant ne sera donc pas amené à changer sur SNOUKI.
    </Text>

    <Ul>
      <Li noMarker>
        <Subtitle>
          Qu’est ce qu’un cookies ?
        </Subtitle>
        <Text>
          Un cookie est un fichier texte stocké sur le disque dur de votre terminal (ordinateur, tablette, mobile) par
          le biais de votre logiciel de navigation à l'occasion de la consultation d'un service en ligne.
        </Text>
        <Text>
          Cela signifie qu’un cookie nous est envoyé à chaque fois que vous ouvrez une page sur notre site à
          partir de votre ordinateur ou de votre appareil mobile.
        </Text>
        <Text>
          Les cookies enregistrés ne vous reconnaissent pas personnellement en tant qu'individu, mais reconnaissent
          seulement l'appareil que vous êtes en train d'utiliser.
        </Text>
        <Text>
          Les cookies ne stockent aucune donnée personnelle sensible ou directement identifiante mais donnent simplement
          une information sur votre navigation de façon à ce que votre terminal puisse être reconnu plus tard.
        </Text>
      </Li>
      <Li noMarker>
        <Subtitle>
          Cookies utilisés par SNOUKI
        </Subtitle>
        <Text>
          Sur SNOUKI nous émettons uniquement des cookies fonctionnels.
        </Text>
        <Text>
          Ces cookies nous permettent:
        </Text>
        <Ul>
          <Li>
            de vous identifier lorsque vous vous connectez sur notre Site ;
          </Li>
          <Li>
            d'adapter la présentation de nos Sites (langue utilisée, résolution d'affichage, système d'exploitation
            utilisé, thèmes), selon les matériels et les logiciels de visualisation ou de lecture que votre terminal
            comporte;
          </Li>
        </Ul>
        <Text>
          Ces cookies ont une durée de conservation de 13 mois.
        </Text>
      </Li>
    </Ul>


    <Title>
      Politique de confidentialité
    </Title>
    <Subtitle>
      Qui sommes-nous ?
    </Subtitle>
    <Text>
      L’adresse de notre site Web est:
      {' '}
      <Link href="http://www.snouki.fr" blank>http://www.snouki.fr</Link>
    </Text>

    <Subtitle>
      Utilisation des données personnelles collectées
    </Subtitle>

    <Subtitle>
      Commentaires
    </Subtitle>
    <Text>
      Quand vous laissez un commentaire sur notre site web, les données inscrites dans le formulaire de commentaire,
      mais aussi votre adresse IP et l’agent utilisateur de votre navigateur sont collectés pour nous aider à la
      détection des commentaires indésirables.
    </Text>
    <Text>
      Une chaîne anonymisée créée à partir de votre adresse de messagerie (également appelée hash) peut être
      envoyée au service Gravatar pour vérifier si vous utilisez ce dernier. Les clauses de confidentialité
      du service Gravatar sont disponibles ici : https://automattic.com/privacy/. Après validation de votre
      commentaire, votre photo de profil sera visible publiquement à coté de votre commentaire.
    </Text>

    <Subtitle>
      Médias
    </Subtitle>
    <Text>
      Si vous êtes un utilisateur ou une utilisatrice enregistré•e et que vous téléversez des images sur le site web,
      nous vous conseillons d’éviter de téléverser des images contenant des données EXIF de coordonnées GPS. Les
      visiteurs de votre site web peuvent télécharger et extraire des données de localisation depuis ces images.
    </Text>

    <Subtitle>
      1. La politique de confidentialité chez Snouki
    </Subtitle>
    <Text>
      La politique de confidentialité concerne l’ensemble des pratiques liée à l’utilisation de vos données personnelles
      sur le site Snouki Cette politique s’applique également à l’ensemble des applications, supports et opérations de
      communication émis par Snouki susceptibles de faire appel à des données personnelles.
    </Text>

    <Subtitle>
      2. Les données collectées
    </Subtitle>
    <Subtitle>
      Les interactions sur
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
    </Subtitle>
    <Text>
      Dans le cadre d’une inscription sur
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      , d’une inscription aux alertes newsletters ou d’une demande
      effectuée via le formulaire d’ouverture de compte,
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      collecte des données vous concernant.
    </Text>
    <Text>
      Il peut s’agir de votre nom, votre prénom, votre civilité, votre adresse, votre code postal,
      la ville dans laquelle vous habitez, votre adresse e-mail. En fonction de votre interaction avec le site
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      certaines de ces données peuvent se montrer obligatoires à fournir tandis que d’autres sont facultatives.
      Ces informations peuvent être mises à jour par vos soins dans votre espace personnel et peuvent être
      combinées pour vous proposer une offre plus adaptée.
    </Text>

    <Subtitle>
      Les alertes newsletters Snouki
    </Subtitle>
    <Text>
      Les différentes communications de Snouki que vous pouvez recevoir par email sont tracées. Ce traçage nous permet
      de savoir si vous avez réceptionné, ouvert et éventuellement interagi avec le contenu transmis. Si vous ne
      souhaitez pas communiquer d’information via les e-mails Snouki, vous avez la possibilité de vous désabonner
      en modifiant vos préférences d’alertes.
    </Text>

    <Subtitle>
      Publicité sur internet
    </Subtitle>
    <Text>
      Snouki peut être amené à effectuer de la publicité sur internet. Ces publicités apparaissent dans la mesure où
      votre navigation a été ciblée par nos différentes campagnes. Ce ciblage peut s’effectuer d’un point de vue
      géographique mais également si le site sur lequel la publicité apparait présente un intérêt potentiel connexe
      à l’activité de Snouki. Un traçage peut être effectué suite à une visite provenant d’une publicité sur internet
      par l’intermédiaire d’une balise de traçage, un script de programmation permettant de mesurer le comportement
      d’un internaute.
    </Text>

    <Subtitle>
      Réseaux sociaux
    </Subtitle>
    <Text>
      Snouki dispose d’une présence sur Facebook ainsi que sur d’autres réseaux sociaux. Par l’intermédiaire de boutons,
      il est possible de suivre @Snouki sur twitter ainsi que « d’aimer » Snouki sur Facebook. Les informations
      suggérées par ces actions peuvent éventuellement être utilisées par le réseau social concerné. Consulter
      leur politique de confidentialité est donc nécessaire pour connaitre en détail les conséquences éventuelles.
    </Text>
    <Text>
      Par ailleurs, il est possible de s’inscrire sur Snouki par l’intermédiaire d’un compte Facebook.
      Les informations collectées grâce à cette opération peuvent être votre nom, votre prénom, votre adresse
      e-mail, votre photo de profil, la ville dans laquelle vous vivez et votre date de naissance. Ces informations
      peuvent mises à jour dans votre compte et peuvent être utilisées et combinées par Snouki dans le but de vous
      proposer l'offre la plus adaptée.
    </Text>

    <Subtitle>
      3. Modifications de vos données
    </Subtitle>
    <Text>
      Conformément à la loi informatique et liberté (Certifiée RGPD), vous êtes libre à tout moment de modifier ou
      supprimer vos données personnelles. Ceci peut s’effectuer en vous connectant à votre compte sur www.snouki.fr.
      Notre service client reste également à votre joignable depuis notre formulaire de contact. Bien que vous soyez
      désinscrit de nos alertes e-mails, vos données peuvent être conservées.
    </Text>

    <Subtitle>
      4. Modification de la politique de confidentialité
    </Subtitle>
    <Text>
      La présente politique de confidentialité peut être modifiée à tout moment. Toute modification significative vous
      sera communiquée. En cas de changement, le fait que vous continuiez à utiliser les fonctionnalités du site
      www.snouki.fr équivaut à l’acceptation de cette dernière.
    </Text>

    <Subtitle>
      5. Utilisation des données collectés
    </Subtitle>
    <Text>
      Snouki utilise les données collectées à plusieurs fins:
    </Text>
    <Text>
      Vous communiquer l’offre la plus adaptée possible en fonction des éléments en notre possession
    </Text>
    <Text>
      Améliorer nos contenus afin de vous proposer la meilleure navigation possible sur le site www.snouki.fr
    </Text>
    <Text>
      Répondre le mieux possible aux questions que vous nous poseriez (en fonction de votre magasin par exemple)
    </Text>

    <Subtitle>
      6. Cookies
    </Subtitle>
    <Text>
      Votre navigation sur www.snouki.fr peut donner lieu à l’utilisation de cookies. Il s’agit de petits volumes
      installés par votre navigateur sur votre disque dur. Une fois reconnus, ils permettent de nous informer sur
      les caractéristiques de votre visite sur notre site. Par défaut, la plupart des navigateurs acceptent les cookies.
      Toutefois, il vous est possible de modifier ce choix pour que votre visite ne soit pas comptabilisée dans nos
      outils de mesures d’audience. Le cas échéant, cela ne gêne pas la navigation sur le site et l’ensemble des
      fonctionnalités de ce dernier restent accessibles. Vous trouverez plus d'informations sur le site de la CNIL
    </Text>
    <Text>
      Snouki est amené à utiliser 3 types de cookies:
    </Text>
    <Ul>
      <Li>
        <b>Les cookies de session ou de préférences: </b>
        ces cookies sont indispensables à la navigation et au bon fonctionnement du site. Par conséquent, vous ne
        pouvez-vous opposer à leur utilisation.
      </Li>
      <Li>
        <b>Les cookies de mesure d'audience: </b>
        ces cookies permettent de suivre votre navigation sur le site à des fins statistiques et pour permettre à
        Snouki de mieux connaitre son audience pour améliorer son service, vous pouvez vous opposer à leur utilisation.
      </Li>
      <Li>
        <b>Les cookies de tierce partie: </b>
        ces cookies sont déposés par des partenaires dans le cadre de partenariats commerciaux ou publicitaires.
        Vous pouvez vous opposer à leur utilisation.
      </Li>
    </Ul>

    <Ul>
      <Li noMarker>
        <Subtitle>
          Informations  Cookies
        </Subtitle>
        <Text>
          Si vous déposez un commentaire sur notre site, il vous sera proposé d’enregistrer votre nom, adresse de
          messagerie et site web dans des cookies. C’est uniquement pour votre confort afin de ne pas avoir à saisir
          ces informations si vous déposez un autre commentaire plus tard. Ces cookies expirent au bout d’un an.
        </Text>
        <Text>
          Si vous vous rendez sur la page de connexion, un cookie temporaire sera créé afin de déterminer si votre
          navigateur accepte les cookies. Il ne contient pas de données personnelles et sera supprimé
          automatiquement à la fermeture de votre navigateur.
        </Text>
        <Text>
          Lorsque vous vous connecterez, nous mettrons en place un certain nombre de cookies pour enregistrer vos
          informations de connexion et vos préférences d’écran. La durée de vie d’un cookie de connexion est de
          deux jours, celle d’un cookie d’option d’écran est d’un an. Si vous cochez « Se souvenir de moi », votre
          cookie de connexion sera conservé pendant deux semaines. Si vous vous déconnectez de votre compte,
          le cookie de connexion sera effacé.
        </Text>
        <Text>
          En modifiant ou en publiant une publication, un cookie supplémentaire sera enregistré dans votre navigateur.
          Ce cookie ne comprend aucune donnée personnelle. Il indique simplement l’ID de la publication que vous
          venez de modifier. Il expire au bout d’un jour.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Contenu embarqué depuis d’autres sites
        </Subtitle>
        <Text>
          Les articles de ce site peuvent inclure des contenus intégrés (par exemple des vidéos, images, articles…).
          Le contenu intégré depuis d’autres sites se comporte de la même manière que si le visiteur se rendait
          sur cet autre site.
        </Text>
        <Text>
          Ces sites web pourraient collecter des données sur vous, utiliser des cookies, embarquer des outils de suivis
          tiers, suivre vos interactions avec ces contenus embarqués si vous disposez d’un compte connecté sur
          leur site web.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Statistiques et mesures d’audience
        </Subtitle>
      </Li>

      <Li noMarker>
        <Subtitle>
          Utilisation et transmission de vos données personnelles
        </Subtitle>
      </Li>

      <Li noMarker>
        <Subtitle>
          Durées de stockage de vos données
        </Subtitle>
        <Text>
          Si vous laissez un commentaire, le commentaire et ses métadonnées sont conservés indéfiniment. Cela permet
          de reconnaître et approuver automatiquement les commentaires suivants au lieu de les laisser dans la file
          de modération.
        </Text>
        <Text>
          Pour les utilisateurs et utilisatrices qui s’inscrivent sur notre site (si cela est possible), nous stockons
          également les données personnelles indiquées dans leur profil. Tous les utilisateurs et utilisatrices peuvent
          voir, modifier ou supprimer leurs informations personnelles à tout moment (à l’exception de leur nom).
          Les gestionnaires du site peuvent aussi voir et modifier ces informations.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Les droits que vous avez sur vos données
        </Subtitle>
        <Text>
          Si vous avez un compte ou si vous avez laissé des commentaires sur le site, vous pouvez demander à recevoir
          un fichier contenant toutes les données personnelles que nous possédons à votre sujet, incluant celles que
          vous nous avez fournies. Vous pouvez également demander la suppression des données personnelles vous
          concernant. Cela ne prend pas en compte les données stockées à des fins administratives, légales
          ou pour des raisons de sécurité.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Transmission de vos données personnelles
        </Subtitle>
        <Text>
          Les commentaires des visiteurs peuvent être vérifiés à l’aide d’un service
          automatisé de détection des commentaires indésirables.
        </Text>
      </Li>
    </Ul>
  </Wrap>
)
