import * as React from 'react'
import styled from 'styled-components'
import {
  Row,
  Col,
  Modal,
  Title,
  SubTitle,
  Text,
} from '@/ui'
import { useStore } from 'effector-react'

import { CounterpartyContact } from '@/dal'

import {
  $showContactDetailsModal,
  hideContactDetailsModal,
  $selectedContact,
} from '../../model/contacts/units'


const phoneList = (phones: CounterpartyContact['phones']) => phones.map((x) => (
  <Text align="right">{x}</Text>
))

export const ContactDetailsModal = () => {
  const showContactDetailsModal = useStore($showContactDetailsModal)
  const selectedContact = useStore($selectedContact)
  if (!selectedContact) return null
  return (
    <Modal show={showContactDetailsModal} onClose={() => hideContactDetailsModal()}>
      <>
        <Title align="center">contact details</Title>
        <Row colorized size="small">
          <ColFirst width={1} align="flex-start">
            <SubTitle>first name</SubTitle>
          </ColFirst>
          <ColLast width={1} align="flex-end">
            <Text align="right">{selectedContact.contact.firstName}</Text>
          </ColLast>
        </Row>
        <Row colorized size="small">
          <ColFirst width={1} align="flex-start">
            <SubTitle>last name</SubTitle>
          </ColFirst>
          <ColLast width={1} align="flex-end">
            <Text align="right">{selectedContact.contact.lastName}</Text>
          </ColLast>
        </Row>
        <Row colorized size="small">
          <ColFirst width={1} align="flex-start">
            <SubTitle>FAX </SubTitle>
          </ColFirst>
          <ColLast width={1} align="flex-end">
            <Text align="right">{selectedContact.contact.fax}</Text>
          </ColLast>
        </Row>
        <Row colorized size="small">
          <ColFirst width={1} align="flex-start">
            <SubTitle>phone number</SubTitle>
          </ColFirst>
          <ColLast width={1} align="flex-end">
            <>
              {phoneList(selectedContact.contact.phones)}
            </>
          </ColLast>
        </Row>
        <Row colorized size="small">
          <ColFirst width={1} align="flex-start">
            <SubTitle>address</SubTitle>
          </ColFirst>
          <ColLast width={1} align="flex-end">
            <Text align="right">{selectedContact.contact.address}</Text>
          </ColLast>
        </Row>
      </>
    </Modal>
  )
}

const TitleWithMargin = styled(Title)`
  margin-top: 16px;
`
const MyCol = styled(Col)`
  margin-top: 0 !important;
`
const ColFirst = styled(MyCol)`
  margin-left: 0 !important;
  padding-left: 0 !important;
`
const ColLast = styled(MyCol)`
  margin-right: 0 !important;
  padding-right: 0 !important;
`
