import * as React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import { CounterpartyType } from '@/dal'
import {
  Modal,
  Col,
  Title,
  SubTitle,
  InputField,
  PhoneInput,
  AccentText,
  Select,
  PrimaryButton,
  SmallText,
} from '@/ui'

import { PhoneNumbers } from '../parts'
import {
  closeCreationModal,
  $isCreationModalOpen,
  $isEmailChecked,
  $foundCounterparty,
  $emailCheckMessage,
  $formError,
  emailForm,
  mainForm,
  reset,
  createCounterpartyFx,
  createAllCounterpartyContactsFx, fetchCounterpartyListFx,
} from '../../model/create-local-counterparty'

const types = [{
  value: 'auditor' as CounterpartyType,
  text: 'auditor',
},
{
  value: 'insurer' as CounterpartyType,
  text: 'insurer',
},
{
  value: 'service-provider' as CounterpartyType,
  text: 'service-provider',
},
{
  value: 'tenant' as CounterpartyType,
  text: 'tenant',
}]

export const CreateLocalCounterpartyModal = () => {
  const { t } = useTranslation('site-creation')
  const isVisible = useStore($isCreationModalOpen)
  const isEmailChecked = useStore($isEmailChecked)
  const isEmailFetching = useStore(fetchCounterpartyListFx.pending)
  const emailCheckMessage = useStore($emailCheckMessage)

  const emailF = useForm(emailForm)

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    mainForm.submit()
  }

  const searchEmail = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      emailF.submit()
    }
  }

  return (
    <Modal
      show={isVisible}
      onClose={() => closeCreationModal()}
    >
      <ContentWrap onSubmit={onSubmit}>
        <Col width="100%" noPadding>
          <Title>{t('newCounterparty')}</Title>
        </Col>
        <Col width="100%" noPadding align="flex-start">
          <>
            <SubTitle>
              1.
              {' '}
              {t('email')}
              <AccentText warning>*</AccentText>
            </SubTitle>
            <InputField
              value={emailF.fields.email.value}
              placeholder={t('email')}
              type="email"
              disabled={isEmailChecked}
              errorText={emailF.fields.email.errorText()}
              onChangeValue={emailF.fields.email.onChange}
              onKeyDown={searchEmail}
            />

            {
              emailCheckMessage && (
                <EmailCheckMessage
                  green={isEmailChecked}
                  warning={!isEmailChecked}
                >
                  {emailCheckMessage}
                </EmailCheckMessage>
              )
            }

            {
              isEmailChecked
                ? (
                  <CheckEmailButton
                    type="warning"
                    disabled={isEmailFetching}
                    onClick={reset}
                  >
                    {t('changeEmail')}
                  </CheckEmailButton>
                )
                : (
                  <CheckEmailButton
                    disabled={isEmailFetching}
                    onClick={emailF.submit}
                  >
                    {t('checkEmail')}
                  </CheckEmailButton>
                )
            }
          </>
        </Col>

        {isEmailChecked && (
          <RenderForm />
        )}
      </ContentWrap>
    </Modal>
  )
}

const RenderForm = () => {
  const { t } = useTranslation('site-creation')
  const counterpartyFound = useStore($foundCounterparty.map((x) => !!x))
  const pendingCounterpartyCreation = useStore(createCounterpartyFx.pending)
  const pendingProfileCreation = useStore(createAllCounterpartyContactsFx.pending)
  const formError = useStore($formError)

  const isPending = pendingCounterpartyCreation || pendingProfileCreation

  const { fields } = useForm(mainForm)

  return (
    <>
      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('type')}
            {' '}
            <AccentText warning>*</AccentText>
          </SubTitle>
          {
            counterpartyFound
              ? (
                <InputField
                  value={fields.type.value}
                  disabled
                />
              )
              : (
                <Select<CounterpartyType>
                  value={fields.type.value}
                  items={types}
                  onChange={fields.type.onChange}
                />
              )
          }

        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('companyName')}
          </SubTitle>
          <InputField
            value={fields.companyName.value}
            disabled={counterpartyFound}
            errorText={fields.companyName.errorText()}
            onChangeValue={fields.companyName.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('companyCode')}
          </SubTitle>
          <InputField
            value={fields.companyCode.value}
            disabled={counterpartyFound}
            errorText={fields.companyCode.errorText()}
            onChangeValue={fields.companyCode.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('firstName')}
            {' '}
            <AccentText warning>*</AccentText>
          </SubTitle>
          <InputField
            value={fields.firstName.value}
            errorText={fields.firstName.errorText()}
            onChangeValue={fields.firstName.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('lastName')}
            {' '}
            <AccentText warning>*</AccentText>
          </SubTitle>
          <InputField
            value={fields.lastName.value}
            errorText={fields.lastName.errorText()}
            onChangeValue={fields.lastName.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <PhoneTitleWrap>
            <SubTitle>
              6.
              {' '}
              {t('phoneNumbers')}
              {' '}
              <AccentText>*</AccentText>
            </SubTitle>
            <AccentText>
              <SmallText>
                {t('default')}
              </SmallText>
            </AccentText>
          </PhoneTitleWrap>

          <PhoneInput
            accent
            value={fields.defaultPhone.value}
            errorText={fields.defaultPhone.errorText()}
            placeholder={t('phoneNumber')}
            onChangeValue={fields.defaultPhone.onChange}
          />

          <PhoneNumbers
            phones={fields.phones.value}
            onChange={fields.phones.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('fax')}
          </SubTitle>
          <InputField
            value={fields.fax.value}
            errorText={fields.fax.errorText()}
            onChangeValue={fields.fax.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('website')}
          </SubTitle>
          <InputField
            value={fields.webSite.value}
            errorText={fields.webSite.errorText()}
            onChangeValue={fields.webSite.onChange}
          />
        </>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <>
          <SubTitle>
            8.
            {' '}
            {t('address')}
          </SubTitle>
          <InputField
            value={fields.address.value}
            errorText={fields.address.errorText()}
            onChangeValue={fields.address.onChange}
          />
        </>
      </Col>

      {
        formError && (
          <Col width="100%" noPadding align="flex-start">
            <EmailCheckMessage green>
              {formError}
            </EmailCheckMessage>
          </Col>
        )
      }

      <Col width="100%" noPadding align="flex-start">
        <SaveButton
          disabled={isPending}
          htmlType="submit"
        >
          {t('create')}
        </SaveButton>
      </Col>
    </>
  )
}

const ContentWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const CheckEmailButton = styled(PrimaryButton)`
  width: 170px;
  margin: 20px auto;
`

const PhoneTitleWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
const EmailCheckMessage = styled(AccentText)`
  text-align: center;
  margin: 10px 0;
  width: 100%;
`
const SaveButton = styled(PrimaryButton)`
  width: 180px;
  margin: 30px auto 0;
`
