import {
  $createPersonFileStep,
  $personFileCompletedSteps,
  $personFileCreatedModal,
  $createUserCompletedSteps,
  $createUserStep,
  $siteSearchModal,
} from './state'
import {
  setPersonFileStep,
  personalFileCompleteStep,
  hidePersonFileCreatedModal,
  showPersonFileCreatedModal,
  setUserStep,
  userCompleteStep,
  hideSiteSearchModal,
  showSiteSearchModal,
} from './events'

//  Persona File

$createPersonFileStep
  .on(setPersonFileStep, (_, stepName) => stepName)

$personFileCompletedSteps
  .on(personalFileCompleteStep, (_, step) => {
    const completedSteps = _
    completedSteps.push(step)
    return completedSteps
  })

$personFileCreatedModal
  .on(showPersonFileCreatedModal, () => true)
  .on(hidePersonFileCreatedModal, () => false)

// Create User

$createUserStep
  .on(setUserStep, (_, stepName) => stepName)

$createUserCompletedSteps
  .on(userCompleteStep, (_, step) => {
    const completedSteps = _
    completedSteps.push(step)
    return completedSteps
  })

$siteSearchModal
  .on(showSiteSearchModal, () => true)
  .on(hideSiteSearchModal, () => false)
