import * as React from 'react'
import styled from 'styled-components'

import {
  InputField,
  PhoneInput,
  Col,
  Row,
  themeVar,
} from '@/ui'

interface Props {
    phones: string[];
    onChange: (val: string[]) => {};
}
export const PhoneNumbers = ({ onChange, phones }: Props) => {
  const localPhones = [...phones]

  const changeValue = (value: string, index: number) => {
    localPhones.splice(index, 1, value)
    onChange(localPhones)
  }

  const addNewPhone = () => {
    localPhones.push('')
    onChange(localPhones)
  }

  const phoneList = localPhones.map((phone, index) => (
    <Col
      key={index}
      md="calc(100% / 3)"
      noMargin
      vAlign="flex-end"
      align="flex-start"
    >
      <NewRow align="flex-end">
        <Col noPadding align="flex-start" vAlign="flex-end" />
        <PhoneInput
          value={phone}
          placeholder="Phone number"
          onChangeValue={(val) => changeValue(val, index)}
        />
      </NewRow>
    </Col>
  ))

  return (
    <>
      {phoneList}
      <Col md="calc(100% / 3)" noMargin vAlign="flex-end" align="flex-start">
        <NewRow>
          <Col noPadding align="flex-start" vAlign="flex-end" />
          <AddNewText onClick={addNewPhone}>
            Add new phone +
          </AddNewText>
        </NewRow>
      </Col>
    </>
  )
}

const AddNewText = styled.div`
    cursor: pointer;
    color: ${themeVar('brandOrangeColor')};
    font-size: 14px;
    text-decoration: underline;
    height: 35px;
    display: flex;
    align-items: center;
`

const NewRow = styled(Row)`
  padding-top: 37.8px;
  padding-bottom: 0;
`
