import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../../theming'

type Props = {
  notFluid?: boolean;
  align?: 'left' | 'right' | 'center';
} & ThemedStyledProps

export const Text = styled.div<Props>`
font-family: ${themeVar('baseFont')};
font-size: 12px;
${({ notFluid }) => !notFluid && css`
  width: 100%;
`}
text-align: ${({ align = 'left' }) => align};
${breakpoint('mobile', 'tablet')`
    font-size: 14px;
  `}
`
