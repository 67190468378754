import React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'
import { ThemedStyledProps, themeVar } from '@/ui'

import { MenuHeader } from './MenuHeader'
import { MenuItem } from './MenuItem'

import { onCloseMenu, $isMenuOpen } from '../../model'

export const MainMenu = () => {
  const isMenuOpen = useStore($isMenuOpen)
  const { t } = useTranslation('main-menu')

  return (
    <ContentWrap show={isMenuOpen}>
      <MenuHeader />
      <MenuBody>
        <MenuItem href="/home">{t('home')}</MenuItem>
        <MenuItem href="/site">{t('site')}</MenuItem>
        <MenuItem href="/demands">{t('demands')}</MenuItem>
        {/* <MenuItem href="/orders">{t('orders')}</MenuItem> */}
        {/* <MenuItem href="/consultations">{t('consultations')}</MenuItem> */}
        {/* <MenuItem href="/reservations">{t('reservations')}</MenuItem> */}
        {/* <MenuItem href="/pre-registration">{t('pre-registration')}</MenuItem> */}
        {/* <MenuItem href="/manage">{t('manage')}</MenuItem> */}
        {/* <MenuItem href="/faq">{t('faq')}</MenuItem> */}
        <MenuItem href="/settings">{t('settings')}</MenuItem>
      </MenuBody>
    </ContentWrap>
  )
}

const ContentWrap = styled.div<{show: boolean} & ThemedStyledProps>`
  position: fixed;
  top: ${themeVar('headerHeight')};
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 0;
  pointer-events: none;
  z-index: 105;
  ${breakpoint('mobile', 'tablet')`
    z-index: 200;
  `}
  transition: opacity 0.1s linear;
  ${(p) => p.show && css`
    opacity: 1;
    pointer-events: all;
  `}  
`

const MenuBody = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`
