import * as React from 'react'
import styled from 'styled-components'

import {
  PhoneInput,
  Col,
  Row,
  themeVar,
} from '@/ui'

interface Props {
    phones: string[];
    onChange: (val: string[]) => {};
}
export const PhoneNumbers = ({ onChange, phones }: Props) => {
  const localPhones = [...phones]

  const changeValue = (value: string, index: number) => {
    localPhones.splice(index, 1, value)
    onChange(localPhones)
  }

  const addNewPhone = () => {
    localPhones.push('')
    onChange(localPhones)
  }

  const canAddMore = localPhones.length < 4

  const phoneList = localPhones.map((phone, index) => (
    <>
      <Col noPadding align="flex-start" vAlign="flex-end" />
      <InputWrap>
        <PhoneInput
          value={phone}
          placeholder="Phone number"
          onChangeValue={(val) => changeValue(val, index)}
        />
      </InputWrap>
    </>
  ))

  return (
    <>
      {phoneList}
      {
        canAddMore && (
          <AddNewText onClick={addNewPhone}>
            Add new phone +
          </AddNewText>
        )
      }
    </>
  )
}

const AddNewText = styled.div`
    cursor: pointer;
    color: ${themeVar('brandOrangeColor')};
    font-size: 14px;
    text-decoration: underline;
    height: 20px;
    display: flex;
    margin: 0 auto;
    align-items: center;
`

const InputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  margin-top: 15px;
`
