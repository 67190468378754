import * as React from 'react'
import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'
import { Title } from './typo/Title'

type Props<Value> = {
    items: Item<Value>[];
    value?: Value;
    onChange: (val: Value) => void;
  }

  type Item<Value> = {
    value: Value;
    text: string;
  }


export function InputSwitch<Value>({
  items, value, onChange,
}: Props<Value>) {
  const list = items?.map((item, index) => (
    <ItemWrap
      active={value === item.value}
      key={index.toString() + item.value}
      onClick={() => onChange(item.value)}
    >
      <Title>
        {item.text}
      </Title>
    </ItemWrap>
  ))

  return (
    <Wrap>
      {list}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  border: none;
  padding: 2px;
  background: ${themeVar('inputSwitchActiveButton')};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  font-family: ${themeVar('baseFont')};
`

type StyledItemProps = {
  active: boolean;
} & ThemedStyledProps

const ItemWrap = styled.div<StyledItemProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${themeVar('inputSwitchText')};
  cursor: pointer;
  background: ${themeVar('inputSwitchNotActiveButton')};
  transition: background .2s;
  ${({ active }) => active && css`
    background: ${themeVar('inputSwitchActiveButton')};
    color: ${themeVar('inputSwitchActiveText')};
    &:nth-child(2n){
      background: ${themeVar('inputSwitchActiveButton2n')};
    }
  `}
`
