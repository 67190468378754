export const handleFloatInput = (val: string, fractionMaxLength = 2) => {
  const str = val.replace(/[^0-9,.]/gu, '').replace(',', '.')
  let dotFound = false
  let decimalCount = 0
  let result = ''
  for (const [index, letter] of str.split('').entries()) {
    if (letter === '.') {
      if (index === 0) {
        result += '0'
      }
      if (!dotFound) {
        result += letter
        dotFound = true
      }
      continue
    }

    if (!dotFound) {
      result += letter
      continue
    }
    if (decimalCount < fractionMaxLength) {
      result += letter
      decimalCount++
    }
  }
  return result
}

export const handleIntInput = (val: string) => val.replace(/[^0-9]/gu, '')

export const convertToEuroCurrency = (val: number) => `${+(val / 100).toFixed(2)}`
export const convertToEuroCentsCurrency = (val: string) => Math.trunc(parseFloat(val) * 100)
