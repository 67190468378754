import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar } from '../../theming'

export const ExtraSmallText = styled.div<{align?: 'left' | 'right' | 'center'}>`
font-family: ${themeVar('baseFont')};
font-size: 8px;
width: 100%;
text-align: ${({ align = 'left' }) => align};
${breakpoint('mobile', 'tablet')`
    font-size: 10px;
  `}
`
