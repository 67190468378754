import { forward, sample, split } from 'effector'
import { condition } from 'patronum'

import { FetchMyAccountRes, setAccountTariffReqFx } from '@/dal'
import {
  checkOwnerCreated,
  $userInfo,
  ownerRegistrationFinished,
} from '@/features/app'
import { history } from '@/lib/history'

import {
  $startRoute,
  checkTariffPlan,
  checkTariffPlanType,
  checkCompany,
  goToRoute,
  setRoute,
  setStartRoute,
  goToStartRoute,
  fetchMyAccountFx,
  fetchMyCompanyFx,
} from './untis'

$startRoute
  .on(setRoute, (_, x) => x)

goToRoute
  .watch((route) => {
    history.replace(route)
  })

setStartRoute
  .watch(() => {
    setRoute(history.location.pathname)
  })

sample({
  source: $startRoute,
  clock: goToStartRoute,
  fn: (x) => x,
  target: goToRoute,
})

forward({
  from: ownerRegistrationFinished,
  to: goToStartRoute,
})

// check if profile already created
const isProfileExists = sample({
  source: $userInfo,
  clock: checkOwnerCreated,
  fn: (x) => !!x,
})

forward({
  from: isProfileExists,
  to: setStartRoute,
})

condition({
  source: isProfileExists,
  if: (x) => x,
  then: checkTariffPlan,
  else: goToRoute.prepend(() => '/owner-registration/profile'),
})


// check if tariff plan already selected
forward({
  from: checkTariffPlan,
  to: fetchMyAccountFx,
})

condition({
  source: fetchMyAccountFx.doneData,
  if: (x) => !!x.account.tariff,
  then: checkTariffPlanType.prepend((x) => (x as FetchMyAccountRes).account.type),
  else: goToRoute.prepend(() => '/owner-registration/tariff'),
})

// todo: add error catching
const fetchAccountErrors = split(fetchMyAccountFx.failData, {
  userDontHaveAnAccount: (x) => x.name === 'UserDontHaveAnAccount',
})
fetchAccountErrors.userDontHaveAnAccount.watch(() => {
  goToRoute('/owner-registration/tariff')
})

// when tariff complete set
condition({
  source: setAccountTariffReqFx.doneData,
  if: (x) => x.account.type === 'ME',
  then: checkCompany,
  else: ownerRegistrationFinished,
})

condition({
  source: checkTariffPlanType,
  if: (x) => x === 'ME',
  then: checkCompany,
  else: ownerRegistrationFinished,
})


// check if company already created
forward({
  from: checkCompany,
  to: fetchMyCompanyFx,
})

forward({
  from: fetchMyCompanyFx.doneData,
  to: ownerRegistrationFinished,
})

condition({
  source: fetchMyCompanyFx.failData,
  if: (x) => x.name === 'CompanyNotFound',
  then: goToRoute.prepend(() => '/owner-registration/company'),
  else: goToRoute.prepend(() => '/'), // error fallback
})
