import * as React from 'react'
import styled from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'

type Props<ItemValue> = {
    items: Item<ItemValue>[];
    name: string;
    value?: ItemValue;
    onChange: (val: ItemValue) => void;
  }

  type Item<ItemValue> = {
    value: ItemValue;
    text: string;
  }


export function RadioButtonsList<ItemValue>({
  items, name, value, onChange,
}: Props<ItemValue>) {
  const list = items.map((item, index) => (
    <ItemWrap
      key={index.toString() + item.value}
      onClick={() => onChange(item.value)}
    >
      <RadioIcon>
        {item.value === value && (
          <RadioIconCenter />
        )}

      </RadioIcon>
      {item.text}
    </ItemWrap>
  ))
  return (
    <Wrap>
      {list}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: ${themeVar('baseFont')};
`

const RadioIcon = styled.div<ThemedStyledProps>`
    border: 2px solid ${themeVar('inputRadioIconBorder')};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 7px;
    justify-content: center;
    background: white;
`

const RadioIconCenter = styled.div<ThemedStyledProps>`
    border: none;
    border-radius: 50%;
    margin: 3px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    background: ${themeVar('inputRadioIconBackground')};
`

const ItemWrap = styled.div`
    display: flex;
    margin-right: 16px;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
    align-items: center;
`
