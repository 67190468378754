import { palette } from './palette'

export const mainTheme = {
  baseFont: 'Cocogoose Classic',
  textColor: palette.dark,
  textColorInwerse: palette.white,

  tabletBottomMargin: '75px',

  brandTextColor: palette.orange,

  brandColor: palette.brand,

  brandOrangeColor: palette.orange,
  brandOrangePaleColor: palette.orangePale,
  brandOrangePale2xColor: palette.orangePale2x,

  brandGreenColor: palette.green,
  brandGreenPaleColor: palette.greenPale,

  brandWarningColor: palette.dangerPale,

  brandGrayColor: palette.gray,
  brandGrayPaleColor: palette.grayPale,
  brandGrayPale2xColor: palette.grayPale2x,
  brandGrayPale3xColor: palette.grayPale3x,

  paleTextColor: palette.pale,
  headingTextColor: palette.dark,

  primaryButtonTextColor: palette.white,
  primaryButtonDarkTextColor: palette.dark,

  primaryButtonPrimaryBorder: palette.orangePale,
  primaryButtonPrimaryBackground: palette.brandGradientRotate,
  primaryButtonPrimaryBackgroundHover: palette.orange,
  primaryButtonPrimaryIcon: palette.white,

  primaryButtonGrayBackground: palette.grayPale,
  primaryButtonGrayBackgroundHover: palette.gray,
  primaryButtonGrayBorderColor: palette.grayPale,
  primaryButtonGrayIcon: palette.orange,

  primaryButtonOutlinedBackground: palette.white,
  primaryButtonOtlinedIcon: palette.orange,

  primaryButtonWarningTextColor: palette.danger,
  primaryButtonWarningTextColorHover: palette.white,
  primaryButtonWarningBackground: palette.danger,
  primaryButtonWarningFilledBackground: palette.dangerDark,
  primaryButtonWarningOutlinedBackground: palette.dangerPale2x,
  primaryButtonWarningIcon: palette.danger,

  primaryButtonInverseTextColor: palette.white,
  primaryButtonInverseBackground: palette.orange,
  primaryButtonInverseBackgroundHover: palette.orangePale,
  primaryButtonInverseBorderColor: palette.white,
  primaryButtonInverseIcon: palette.white,

  primaryButtonWhiteTextColor: palette.dark,
  primaryButtonWhiteBackground: palette.white,
  primaryButtonWhiteBackgroundHover: 'transparent',
  primaryButtonWhiteBorderColor: palette.white,
  primaryButtonWhiteIcon: palette.orange,
  primaryButtonWhiteTextColorHover: palette.white,
  primaryButtonWhiteIconHover: palette.white,

  primaryButtonDisabledBackground: palette.grayPale,
  primaryButtonDisabledBorderColor: palette.grayPale,
  primaryButtonDisabledText: palette.gray,

  primaryButtonOutlinedDisabledBackground: palette.white,
  primaryButtonOutlinedDisabledText: palette.grayPale,

  inputBorderColor: palette.orangePale2x,
  inputIconColor: palette.orange,
  inputErrorColor: palette.dangerPale,
  errorAlertBackgroundColor: palette.dangerPale,
  errorAlertBorderColor: palette.danger,
  errorAlertTextColor: palette.danger,
  panelColor: palette.white,
  badgeBackgroundColor: palette.danger,
  badgeTextColor: palette.white,
  paperBorderColor: '#f0f0f0',
  paperBackgroundColor: palette.white,
  counterBtnBackgroundColor: palette.brand,
  counterBtnTextColor: palette.white,
  breakpoints: {
    mobile: 0,
    tablet: 693,
    desktop: 992,
  },
  inputSliderRangeText: palette.orange,
  inputSliderFilledColor: palette.orange,
  inputSliderMainColor: palette.orangePale,
  inputSliderPointer: palette.orange,
  inputSliderPointerText: palette.dark,
  inputSelectMainColor: palette.orangePale2x,
  inputSelectSelectedColor: palette.orange,
  inputSelectSelectedTextColor: palette.white,
  inputSelectTextColor: palette.dark,
  inputSelectArrowColor: palette.orange,
  inputRadioIconBorder: palette.orangePale2x,
  inputRadioIconBackground: palette.orange,
  inputRadioText: palette.dark,
  inputSwitchBorder: palette.orange,
  inputSwitchNotActiveButton: palette.white,
  inputSwitchActiveButton: palette.brandGradient,
  inputSwitchActiveButton2n: palette.brandGradientRotate,
  inputSwitchActiveText: palette.white,
  inputSwitchText: palette.gray,
  filePickerDeleteIconColor: palette.dangerPale,
  inputDateTimeIconBg: palette.orangePale2x,
  InputCheckboxCheckIconColor: palette.white,
  InputCheckboxCheckBg: palette.orange,
  dataTableHeaderIcons: palette.orange,
  dataTableHeaderBg: palette.white,
  dataTableRow2ndColor: palette.grayPale3x,
  dataTableActionBgWarning: palette.dangerPale,
  dataTableActionBgGreen: palette.greenPale,
  dataTableActionBgPrimary: palette.orange,
  dataTableActionText: palette.white,
  dataTableNewLabelBg: palette.orange,
  dataTableNewLabelText: palette.white,
  dataTableRowDefaultBg: palette.white,
  dataTableRowWarningBg: palette.dangerPale3x,
  dataTableRowWarningText: palette.dangerPale,
  dataTableRowGrayBg: palette.grayPale3x,
  dataTableRowGrayText: palette.gray,
  dataTableRowSuccessBg: palette.greenPale,
  dataTableRowSuccessText: palette.green,
  dataTableColColorWarning: palette.dangerPale,
  dataTableColColorGray: palette.gray,
  dataTableColColorSuccess: palette.green,
  dataTableColColorPrimary: palette.orange,
  dataTableRowPaddingMobile: '15px',
  dataTableLabelColor: palette.white,
  dataTableSortIconColor: palette.orange,
  dataTableMobileFilterBg: palette.whiteOpacity,
  dataTableMobileFilterColor: palette.white,
  headBackground: palette.white,
  headIconColor: palette.orangePale,
  headerHeight: '68px',
  containerMaxWidth: '692px',
  appBackground: palette.brandGradientRotate,
  modalOverlayBackground: palette.brandGradientRotate,
  modalBackground: palette.white,
  modalCloseColor: palette.dangerPale,
  modalCloseColorMobile: palette.white,
  modalTotalBg: palette.dangerPale,
  modalTotalText: palette.white,
  rowMainbg: palette.white,
  rowSecondBg: palette.grayPale3x,
  chatAuthorMessageBg: palette.orangePale,
  chatAuthorMessageBorder: palette.orangePale,
  chatAuthorMessageInfo: palette.orange,
  chatMessageBg: palette.white,
  chatMessageBorder: palette.grayPale,
  chatMessageInfo: palette.grayPale,
  chatMessageText: palette.dark,
  hamburgerButtonColor: palette.orange,
  mainMenuItemColor: 'black',
  mainMenuActiveItemColor: palette.orange,
  mainMenuItemHoverColor: palette.white,
  mainMenuItemHoverBg: palette.orange,
  logoutButtonColor: palette.danger,

  newsModalDateColor: palette.greyPale4x,
  newsDateColor: palette.gray,
  newsMoreBtnColor: palette.orange,
  newsTextColor: palette.dark,
  newsAuthotColor: palette.orange,

  whiteOpacity: palette.dark,
}
