import { forward, sample, combine } from 'effector'
import { condition } from 'patronum'

import { Site } from '@/dal'

import { siteCreated as globalSiteCreated } from '../main/unit'
import {
  hideModal,
  showModal,
  newSite,
  $isShowModal,
  $justCreatedSiteId,
  createSiteFx,
  createCadastralReferenceFx,
  attachCadastralReferenceToSiteFx,
} from './unit'

$isShowModal
  .on(showModal, () => true)
  .reset(hideModal, createSiteFx.done)

$justCreatedSiteId
  .reset(hideModal)

forward({
  from: hideModal,
  to: newSite.reset,
})

forward({
  from: attachCadastralReferenceToSiteFx.done,
  to: hideModal,
})

// create site
forward({
  from: newSite.formValidated.map(({
    cadastreLocality,
    cadastreNumber,
    cadastreSection,
    cadastreSurface,
    ...site
  }) => ({ ...site, surfaces: [] })),
  to: createSiteFx,
})

forward({
  from: createSiteFx.doneData.map((x) => x.id),
  to: $justCreatedSiteId,
})

// cadastral reference
const siteCreated = sample({
  source: newSite.$values,
  clock: createSiteFx.doneData,
  fn: (form, result) => ({ form, requestResult: result }),
})

condition({
  source: siteCreated,
  if: ({ form }) => Boolean(
    form.cadastreLocality
    || form.cadastreNumber
    || form.cadastreSection
    || form.cadastreSurface,
  ),
  then: createCadastralReferenceFx.prepend((a) => {
    const x = a as Exclude<typeof a, void>
    return {
      surfaceArea: x.form.cadastreSurface,
      location: x.form.cadastreLocality,
      number: x.form.cadastreNumber,
      section: x.form.cadastreSection,
      realEstate: undefined,
    }
  }),
  else: globalSiteCreated,
})

sample({
  source: $justCreatedSiteId,
  clock: createCadastralReferenceFx.doneData,
  fn: (siteId, res) => ({
    id: siteId as Site['id'],
    data: {
      cadastralReference: res.id,
    },
  }),
  target: attachCadastralReferenceToSiteFx,
})

forward({
  from: attachCadastralReferenceToSiteFx.done.map(() => undefined),
  to: globalSiteCreated,
})
