import dayjs from 'dayjs'

export const formatDate = (date: string, format: string): null | string => {
  const d = dayjs(date)
  if (!d.isValid()) return null
  return d.format(format)
}

export const dateToHuman = (date: string) => formatDate(date, 'DD MMMM, YY') || ''
export const dateSlash = (date: string) => formatDate(date, 'DD/MM/YYYY') || ''
export const dateTimeSlash = (date: string) => formatDate(date, 'HH:mm, DD/MM/YYYY') || ''
