import { createDomain, attach } from 'effector'
import { Site, fetchSitesListReqFx } from '@/dal'

const d = createDomain('d')
export const $list = d.store<Site[]>([])

export const reloadSitesList = d.event<void>()

export const fetchSitesListFx = attach({
  effect: fetchSitesListReqFx,
  mapParams: () => ({
    offset: 0,
    limit: 99999
  }),
})
