import { forward } from 'effector'

import {
  getUserSettingsReqFx, logoutReqFx, fetchMyUserReqFx, fetchMyProfileReqFx,
} from '@/dal'
import { userAuthChecked, userAuthenticated, checkUserAuth } from '@/features/app'
import { history } from '@/lib/history'

import { closeSigninModal } from './signin/units'
import { closePasswordResetModal } from './password-reset'
import { checkTokenReqFx } from './verification-email/units'

import './signin/init'
import './password-reset/init'
import './set-new-password/init'
import './owner-registration/init'
import './verification-email/init'
import './owner-creation-flow/init'

import {
  $isAutorizationDialogShown,
  $isChangePasswordDialogShown,
  $isNewPasswordDialogShown,
  $isThanksModalShown,
  $isThanksModalText,
  $isSignUpDialogShown,
  $isVerificationEmailSuccess,
  hideVerificationSuccess,
  showVerificationSuccess,
  hideAutorizationDialog,
  showAutorizationDialog,
  hideNewPasswordDialog,
  hideChangePasswordDialog,
  hideThanksModal,
  sendChangePasswordMailDialog,
  showChangePasswordDialog,
  showNewPasswordDialog,
  showThanksModal,
  showSignUpDialog,
  hideSignUpDialog,
  logout,
} from './units'

$isAutorizationDialogShown
  .on(showAutorizationDialog, () => true)
  .on(hideAutorizationDialog, () => false)
  .on(showSignUpDialog, () => false)

$isChangePasswordDialogShown
  .on(showChangePasswordDialog, () => true)
  .on(hideChangePasswordDialog, () => false)
  .on(showThanksModal, () => false)

$isThanksModalShown
  .on(showThanksModal, () => true)
  .on(hideThanksModal, () => false)

$isThanksModalText
  .on(showThanksModal, (_, text) => text)
  .on(hideThanksModal, () => '')
$isNewPasswordDialogShown
  .on(showNewPasswordDialog, () => true)
  .on(hideNewPasswordDialog, () => false)

$isSignUpDialogShown
  .on(showSignUpDialog, () => true)
  .on(hideSignUpDialog, () => false)
  .on(showAutorizationDialog, () => false)
  .on(showThanksModal, () => false)


$isVerificationEmailSuccess
  .on(showVerificationSuccess, () => true)
  .on(hideVerificationSuccess, () => false)
  .on(checkTokenReqFx.done, () => true)

// authorization
forward({
  from: checkUserAuth,
  to: fetchMyUserReqFx,
})

forward({
  from: fetchMyUserReqFx.fail,
  to: userAuthChecked,
})

forward({
  from: fetchMyUserReqFx.done,
  to: fetchMyProfileReqFx,
})

forward({
  from: fetchMyProfileReqFx.finally,
  to: [userAuthenticated, userAuthChecked],
})

// logout
forward({
  from: logout,
  to: logoutReqFx,
})

logoutReqFx.done
  .watch(() => {
    history.replace('/')
  })

forward({
  from: closeSigninModal,
  to: hideAutorizationDialog,
})

closePasswordResetModal.watch(() => {
  showThanksModal('We sent you instructions by e-mail')
})

checkTokenReqFx.done
  .watch(() => {
    history.replace('/')
  })
