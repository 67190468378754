export default
{
  arrivalAndDeparture: [
    {
      id: 0,
      type: 'arrival', // "departure". "modification"
      number: 20201236,
      date: '23/09/2019',
      applicant: 'Philippe Durand',
      status: 'done', // "active", "cancel", "rejected"
      new: false,
    },
    {
      id: 1,
      type: 'arrival', // "departure". "modification"
      number: 20201236,
      date: '23/09/2019',
      applicant: 'Philippe Durand',
      status: 'done', // "active", "cancel", "rejected"
      new: false,
    },
    {
      id: 2,
      type: 'arrival', // "departure". "modification"
      number: 20201236,
      date: '23/09/2019',
      applicant: 'Philippe Durand',
      status: 'done', // "active", "cancel", "rejected"
      new: false,
    },
    {
      id: 3,
      type: 'arrival', // "departure". "modification"
      number: 20201236,
      date: '23/09/2019',
      applicant: 'Philippe Durand',
      status: 'done', // "active", "cancel", "rejected"
      new: false,
    },
    {
      id: 4,
      type: 'arrival', // "departure". "modification"
      number: 20201236,
      date: '23/09/2019',
      applicant: 'Philippe Durand',
      status: 'done', // "active", "cancel", "rejected"
      new: false,
    },
  ],
  arrivalAndDepartureSearch: [
    {
      id: 0,
      type: 'arrival', // "departure". "modification"
      site: 'La Defense',
      location: 'La Defense, K, 02, area 02,  258',
      entity: 'La Defense inc.',
      number: 202063698,
      date: '23/09/2019',
      applicant: 'Marc philippe',
      status: 'done', // "active", "cancel", "rejected"
      new: true,
    },
  ],
  consultations: [
    {
      id: 0,
      validation: 'to validate', // "validated"
      number: 202063699,
      date: '23/09/2019',
      category: 'conditioner',
      status: 'done', // "active", "cancel", "rejected", "bids"
      new: false,
    },
  ],
  consultationsBids: [
    {
      id: 0,
      rating: 0, // 0-100
      madeBy: 'Bertrand Molle',
      comment: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
      date: '23/09/2019',
      status: 'rejected', // "active", "approved", "rejected"
      new: false,
    },
  ],
  demands: [
    {
      id: 0,
      type: 'demand', // "request", "quotetion"
      number: 202063697,
      date: '23/09/2019',
      category: 'Heating/Vent/Air cond.',
      status: 'done', // "active", "cancel", "rejected"
      new: true, // false
      validate: 'to validate',
    },
    {
      id: 1,
      type: 'my Delegated request', // "request", "quotetion"
      number: 202063697,
      date: '23/09/2019',
      category: 'Heating/Vent/Air cond.',
      status: 'active',
      new: false,
      validate: 'validated',
    },
    {
      id: 3,
      type: 'my request', // "request", "quotetion"
      number: 202063697,
      date: '23/09/2019',
      category: 'Heating/Vent/Air cond.',
      status: 'active',
      new: false,
      validate: 'can`t validate',
    },
  ],
  orders: [
    {
      id: 0,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
    {
      id: 1,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
    {
      id: 2,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
    {
      id: 3,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
    {
      id: 4,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
    {
      id: 5,
      validation: 'to validate', // "validated"
      number: 202063696,
      date: '23/09/2019',
      name: 'conditioner',
      status: 'done', // "active", "cancel", "rejected",
      new: false,
    },
  ],
  reports: [
    {
      id: 0,
      reportName: 'FGS130620020',
      providerName: 'SOCOTEC',
      site: 'site 1',
      reportReference: 'electrical report',
      number: 'BNP-RUEILELECFGS130620020',
      date: '23/09/2019',
      new: true,
      status: 'pending', // "initiated", "rejected", "closed",
    },
  ],
  commands: {
    list: [
      {
        id: 0,
        commandType: 'my command',
        supplier: 'James Alan Hatfield',
        validation: 'to validate',
        number: 123456789,
        creationDate: '12/09/2020',
        deliveryDate: '18:00, 12/09/2020',
        applicant: 'Lars Ulrich',
        totalAmount: 590,
        status: 'active',
      },
    ],
    supplierList: [
      {
        id: 0,
        name: 'James Hetfield',
        jobTitle: 'CEO',
        location: '2 floor, 215',
        phone: '+8 123 456 78 90',
        email: 'example@entity.com',
        card: '1234 5678 9012',
        country: 'France',
        town: 'Paris',
        state: 'Paris',
        adress: '361 acenue du President Wilson',
        fax: '+8 123 456 78 90',
        mobilePhone: '+8 123 456 78 90',
        status: 'online',
      },
      {
        id: 2,
        name: 'James Hetfield',
        jobTitle: 'CEO',
        location: '2 floor, 215',
        phone: '+8 123 456 78 90',
        email: 'example@entity.com',
        card: '1234 5678 9012',
        country: 'France',
        town: 'Paris',
        state: 'Paris',
        adress: '361 acenue du President Wilson',
        fax: '+8 123 456 78 90',
        mobilePhone: '+8 123 456 78 90',
        status: 'offline',
      },
    ],
    itemsList: [
      {
        name: 'Category name 01',
        description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
        items: [
          {
            name: 'subcategory name 01',
            items: [
              {
                id: 1,
                name: 'Item name 01',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 2,
                name: 'Item name 02',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 3,
                name: 'Item name 03',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
            ],
          },
          {
            name: 'subcategory name 02',
            items: [
              {
                id: 4,
                name: 'Item name 01',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 5,
                name: 'Item name 02',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
            ],
          },
        ],
      },
      {
        name: 'Category name 02',
        description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
        items: [
          {
            name: 'subcategory name 01',
            items: [
              {
                id: 6,
                name: 'Item name 01',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 7,
                name: 'Item name 02',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 8,
                name: 'Item name 03',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
            ],
          },
          {
            name: 'subcategory name 02',
            items: [
              {
                id: 9,
                name: 'Item name 01',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
              {
                id: 10,
                name: 'Item name 02',
                img: '/assets/img/tv.jpg',
                description: 'Generation a speed of light sun Captain Kirk in species in Volcon space discovers new moon Captain in federation orbit alien.',
                price: 100,
              },
            ],
          },
        ],
      },
    ],
  },
  newEmloyee: {
    accessBySite: [
      {
        id: 0,
        name: 'Agen Sentini',
        repository: false,
        services: false,
        meetingRooms: false,
        workOrders: false,
        arrivalDeparture: false,
        statements: false,
        office: false,
        plans: false,
        controls: false,
        purchases: false,
        homePage: false,
        consultations: false,
        command: false,
        park: false,
      },
      {
        id: 1,
        name: 'Aix Les Bains',
        repository: false,
        services: false,
        meetingRooms: false,
        workOrders: false,
        arrivalDeparture: false,
        statements: false,
        office: false,
        plans: false,
        controls: false,
        purchases: false,
        homePage: false,
        consultations: false,
        command: false,
        park: false,
      },
      {
        id: 2,
        name: 'Agen Fontaine',
        repository: false,
        services: false,
        meetingRooms: false,
        workOrders: false,
        arrivalDeparture: false,
        statements: false,
        office: false,
        plans: false,
        controls: false,
        purchases: false,
        homePage: false,
        consultations: false,
        command: false,
        park: false,
      },
      {
        id: 3,
        name: 'Aix En Provence Lunel P',
        repository: false,
        services: false,
        meetingRooms: false,
        workOrders: false,
        arrivalDeparture: false,
        statements: false,
        office: false,
        plans: false,
        controls: false,
        purchases: false,
        homePage: false,
        consultations: false,
        command: false,
        park: false,
      },
    ],
    membersList: [
      {
        group: 'group 01',
        description: 'Short description of a group of users',
        module: 'module 1',
        members: [
          {
            id: 1,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: true,
          },
          {
            id: 2,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: false,
          },
          {
            id: 3,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: true,
          },
        ],
      },
      {
        group: 'group 01',
        description: 'Short description of a group of users',
        module: 'module 1',
        members: [
          {
            id: 4,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: true,
          },
          {
            id: 5,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: false,
          },
          {
            id: 6,
            name: 'James Hetfield',
            jobTitle: 'CEO',
            address: '2 floor, 215',
            phone: '+8 123 456 78 90',
            email: 'example@entity.com',
            online: true,
          },
        ],
      },
    ],
  },
  selects: {
    demands: {
      type: [
        { value: 'demand', text: 'DEMAND' },
        { value: 'request', text: 'REQUEST' },
        { value: 'quotation', text: 'QUOTATION' },
      ],
      category: [
        { value: 'air', text: 'Heating ' },
      ],
      subcategory: [
        { value: 'conditioning', text: 'Air conditioning' },
      ],
      location: [
        { value: ' ', text: '/Air cond.' },
      ],
      project: [
        { value: ' ', text: '/Air cond.' },
      ],
      status: [
        { value: 'done', text: 'DONE' },
        { value: 'active', text: 'ACTIVE' },
        { value: 'rejected', text: 'REJECTED' },
        { value: 'cancel ', text: 'CANCEL' },
      ],
    },
    orders: {
      validation: [
        { value: true, text: 'VALIDATED' },
        { value: false, text: 'TOVALIDATE' },
      ],
      thumbnail: [
        { value: 'uno', text: 'Thumbnail Uno' },
      ],
      supplier: [
        { value: 'uno', text: 'Supplier Uno' },
      ],
      location: [
        { value: 'test', text: '/Air cond.' },
      ],
      status: [
        { value: 'done', text: 'DONE' },
        { value: 'active', text: 'ACTIVE' },
        { value: 'rejected', text: 'REJECTED' },
        { value: 'cancel ', text: 'CANCEL' },
      ],
    },
    arrival: {
      type: [
        { value: 'arrival', text: 'ARRIVAL' },
        { value: 'departure', text: 'DEPARTURE' },
        { value: 'modification', text: 'MODIFICATION ' },
      ],
      location: [
        { value: 'test', text: '/Air cond.' },
      ],
      site: [
        { value: 'test', text: 'Test ' },
      ],
      status: [
        { value: 'done', text: 'DONE' },
        { value: 'active', text: 'ACTIVE' },
        { value: 'rejected', text: 'REJECTED' },
        { value: 'cancel ', text: 'CANCEL' },
      ],
    },
    preRegistration: {
      person: [
        { value: 'test', text: 'Test ' },
      ],
      profile: [
        { value: 'test', text: '/Air cond.' },
      ],
      contact: [
        { value: 'test', text: 'Test ' },
      ],
      localisation: [
        { value: 'test', text: 'Test ' },
      ],
      entity: [
        { value: 'test', text: 'Test ' },
      ],
      identity: [
        { value: 'test', text: 'Test ' },
      ],
      remarks: [
        { value: 'test', text: 'Test ' },
      ],
    },
    consultation: {
      validation: [
        { value: true, text: 'VALIDATED' },
        { value: false, text: 'TOVALIDATE' },
      ],
      category: [
        { value: 'air', text: 'Heating ' },
      ],
      site: [
        { value: 'air', text: 'Heating ' },
      ],
      siteCode: [
        { value: 'air', text: 'Heating ' },
      ],
      location: [
        { value: 'test', text: '/Air cond.' },
      ],
      quoteTemplate: [
        { value: 'air', text: 'Heating ' },
      ],
      equipmentCategory: [
        { value: 'uno', text: 'Supplier Uno' },
      ],
      equipmentSubcategory: [
        { value: 'test', text: '/Air cond.' },
      ],
      equipmentType: [
        { value: 'uno', text: 'Supplier Uno' },
      ],
      equipmentUnit: [
        { value: 'test', text: '/Air cond.' },
      ],
      status: [
        { value: 'done', text: 'DONE' },
        { value: 'active', text: 'ACTIVE' },
        { value: 'rejected', text: 'REJECTED' },
        { value: 'cancel ', text: 'CANCEL' },
      ],
    },
    settings: {
      languge: [
        { value: 'en', text: 'English' },
        { value: 'fr', text: 'Français' },
        { value: 'rus', text: 'Russian ' },
      ],
      title: [
        { value: 'test', text: '/Air cond.' },
      ],
    },
    authorization: {
      entity: [
        { value: 'test', text: '/Air cond.' },
      ],
      dropDwn: [
        { value: 'test', text: '/Air cond.' },
      ],
    },
    site: {
      siteName: [
        { value: 'test', text: '/Air cond.' },
      ],
      surfaceType: [
        { value: 'test', text: '/Air cond.' },
      ],
      activities: [
        { value: 'test', text: '/Air cond.' },
      ],
    },
    commands: {
      location: [
        { value: 'test', text: '/Air cond.' },
      ],
    },
    reservation: {
      filter: {
        location: [
          { value: 'test', text: '/Air cond.' },
        ],
        fullDay: [
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ],
        equipmentList: [
          { value: 'Conditioner', text: 'Conditioner' },
          { value: 'Bottled water', text: 'Bottled water' },
          { value: 'Water cooler', text: 'Water cooler' },
          { value: 'Snacks', text: 'Snacks' },
          { value: 'Video conference technics', text: 'Video conference technics' },
          { value: 'Plastic board with markers', text: 'Plastic board with markers' },
        ],
        weekdaysNumber: [
          { value: 0, text: 'Monday' },
          { value: 1, text: 'Tuesday' },
          { value: 2, text: 'Wednesday' },
          { value: 3, text: 'Thursday' },
          { value: 4, text: 'Friday' },
          { value: 5, text: 'Saturday' },
          { value: 6, text: 'Sunday' },
        ],
      },
    },
    newEmployee: {
      location: [
        { value: 'test', text: 'Test location' },
      ],
      locationAttribute: [
        { value: 'test', text: 'Test location' },
      ],
      locationType: [
        { value: 'test', text: 'Test location' },
      ],
      located: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      vip: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      statusBoolean: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' },
      ],
      status: [
        { value: 'test', text: 'Test status' },
      ],
      card: [
        { value: 'test', text: 'Test location' },
      ],
      continent: [
        { value: 'test', text: 'Test location' },
      ],
      country: [
        { value: 'test', text: 'Test location' },
      ],
      region: [
        { value: 'test', text: 'Test location' },
      ],
      state: [
        { value: 'test', text: 'Test location' },
      ],
      postCode: [
        { value: 'test', text: 'Test location' },
      ],
      town: [
        { value: 'test', text: 'Test location' },
      ],
      district: [
        { value: 'test', text: 'Test location' },
      ],
      addres: [
        { value: 'test', text: 'Test location' },
      ],
    },
  },
  cadastralReference: [
    {
      id: 0,
      site: 'Site name 01',
      section: 'Site name 01',
      number: 202063697,
      location: 'Site name 01',
      surfaceArea: 'Site name 01',
    },
    {
      id: 1,
      site: 'Site name 01',
      section: 'Site name 01',
      number: 202063697,
      location: 'Site name 01',
      surfaceArea: 'Site name 01',
    },
    {
      id: 2,
      site: 'Site name 01',
      section: 'Site name 01',
      number: 202063697,
      location: 'Site name 01',
      surfaceArea: 'Site name 01',
    },
    {
      id: 3,
      site: 'Site name 01',
      section: 'Site name 01',
      number: 202063697,
      location: 'Site name 01',
      surfaceArea: 'Site name 01',
    },
  ],
  rentPeriod: [
    {
      id: 0,
      type: 'Additional rent',
      start: '01/01/2021',
      end: '25/03/2021',
      amount: 300,
    },
    {
      id: 1,
      type: 'Free rent',
      start: '25/03/2021',
      end: '-',
      amount: 0,
    },
    {
      id: 2,
      type: 'Additional rent',
      start: '01/01/2021',
      end: '25/03/2021',
      amount: 300,
    },
    {
      id: 3,
      type: 'Additional rent',
      start: '01/01/2021',
      end: '25/03/2021',
      amount: 300,
    },
  ],
  securityDeposit: [
    {
      id: 0,
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 1,
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 2,
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
  ],
  bankSecurity: [
    {
      id: 0,
      type: 'Security type',
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 1,
      type: 'Security type',
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 2,
      type: 'Security type',
      date: '01/01/2021',
      deposit: 300,
      amount: '3 month',
      lastReview: '01/01/2021',
      guarantee: 'Guarantee type',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
  ],
  signatures: [
    {
      id: 0,
      contractSignatory: 'James  Alan Hatfield',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 1,
      contractSignatory: 'James  Alan Hatfield',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
    {
      id: 2,
      contractSignatory: 'James  Alan Hatfield',
      remarks: 'I can execute your order tomorrow, it will take 3 days: 1 day for diagnosis and 2 days for repair. The cost of the service less than specified by you - 350 $.',
    },
  ],
  documents: [
    {
      id: 0,
      documentName: 'Document name 01',
      version: '000213',
      validityEndDate: '25/03/2021',
      documentType: 'PDF',
      content: '',
    },
    {
      id: 1,
      documentName: 'Document name 01',
      version: '000213',
      validityEndDate: '25/03/2021',
      documentType: 'PDF',
      content: '',
    },
    {
      id: 2,
      documentName: 'Document name 01',
      version: '000213',
      validityEndDate: '25/03/2021',
      documentType: 'PDF',
      content: '',
    },
  ],
}
