import { attachWrapper } from '@/lib/effector-extra'
import { createCustomError } from '@/lib/errors'
import { quickUUID } from '@/lib/random'

import { requestFx, Method, authServiceRequestFx } from './rest-api'
import { Profile } from './common.interfaces'
import {
  CreateOwnerProfileParams, CreateOwnerProfileRes, SetOwnerProfileParams, SetNewPasswordParams,
} from './profile.interfaces'
import { UserNotFound, UnknownError } from './common-errors'

const CREATE_PROFILE_DATA_ERROR = 400
const PROFILE_ERROR = 404
const USER_NOT_FOUND = 2019
const USER_PROFILE_NOT_CREATED = 2020

const UserProfileNotCreated = createCustomError('UserProfileNotCreated')

const profileMock = (): Profile => ({
  id: quickUUID(),
  email: 'text@example.com',
  dateOfBirth: (new Date()).toISOString(),
  fax: '999-888-77-66',
  firstName: 'Vasilij',
  lastName: 'Mashina',
  language: 'en',
  phones: [
    '999-888-77-66',
    '111-222-33-44',
    '777-777',
  ],
  defaultPhone: '999-888-77-66',
  photo: {
    id: quickUUID(),
    link: 'https://i.pinimg.com/originals/d9/56/9b/d9569bbed4393e2ceb1af7ba64fdf86a.jpg',
    name: 'avatar',
  },
})

export const createOwnerProfileReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateOwnerProfileParams) => ({
    method: Method.post,
    url: '/profile/addMy',
    body: p,
  }),
  mapResult: ({ result }): CreateOwnerProfileRes => result.data,
  mapError: () => new UnknownError(),
})

export const fetchMyProfileReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/profile/getMy',
  }),
  mapResult: ({ result }): Profile => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === PROFILE_ERROR) {
      switch (error.response.data.code) {
        case USER_NOT_FOUND:
          return new UserNotFound()
        case USER_PROFILE_NOT_CREATED:
          return new UserProfileNotCreated()
        default:
      }
    }
    return new UnknownError()
  },
})

export const updateProfileReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: SetOwnerProfileParams) => ({
    method: Method.put,
    url: '/profile/edit',
    body: p,
  }),
  mapResult: ({ result }): CreateOwnerProfileRes => result.data,
  mapError: () => new UnknownError(),
})
