import { createDomain, attach } from 'effector'
import { Site } from '@/dal'

export const d = createDomain('d')

export const $isShowModal = d.store(false)
export const $site = d.store<Site| null>(null)

export const showModal = d.event<Site>()
export const hideModal = d.event<void>()
