import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import ReactDOM from 'react-dom'
import {
  themeVar, useTheme, ThemedStyledProps,
} from '../theming'
import { Icon } from '../icons'
import { Title } from './typo'
import { BlurContext } from './BlurApp'

type ModalProps = {
    children?: React.ReactChild;
    show?: boolean;
    type?: 'base' | 'info' | 'login' | 'confirm';
    onClose?: () => void;
}

const overflowBody = (show: boolean) => {
  const root = document.getElementById('root')
  if (!root) return
  if (show) {
    root.classList.add('open-modal')
  } else {
    root.classList.remove('open-modal')
  }
}

export const Modal = ({
  children, show = false, onClose, type = 'base',
}: ModalProps) => {
  const theme = useTheme()
  const {
    blurApp, focusApp, modalPortal, portalMounted,
  } = React.useContext(BlurContext)

  React.useEffect(() => {
    if (show) {
      overflowBody(true)
    } else {
      overflowBody(false)
    }
  }, [show])
  React.useEffect(() => {
    if (portalMounted && show) {
      blurApp()
    } else {
      focusApp()
    }
  }, [show, portalMounted])

  if (!modalPortal?.current || !portalMounted) return null

  const modalCharacters = (modalType: string) => {
    switch (modalType) {
      case 'base':
        return null
      case 'info':
        return (
          <InfoBackground>
            <Icon
              icon="character1"
            />
            <Icon
              icon="character2"
            />
          </InfoBackground>
        )
      case 'login':
        return (
          <InfoBackground>
            <Icon
              icon="character3"
            />
            <Icon
              icon="character4"
            />
          </InfoBackground>
        )
      case 'confirm':
        return (
          <InfoBackground confirm>
            <Icon
              icon="character7"
            />
          </InfoBackground>
        )
      default:
        return null
    }
  }

  return ReactDOM.createPortal((
    <ModalWrap show={show}>
      <ModalOverflow show={show}>
        <ModalCloseMobile onClick={onClose}>
          <IconWrap>
            <Icon
              icon="leftChevron"
              fill={theme.modalCloseColorMobile}
              width={20}
            />
          </IconWrap>
          <Title>Back</Title>
        </ModalCloseMobile>
        <ModalContent
          show={show}
          info={type !== 'base'}
          confirm={type === 'confirm'}
        >
          <ModalClose onClick={onClose}>
            <Icon
              icon="close"
              fill={theme.modalCloseColor}
              width={24}
            />
          </ModalClose>
          <ModalContentWrap info={type !== 'base'}>
            {children}
          </ModalContentWrap>
          {modalCharacters(type)}
        </ModalContent>
      </ModalOverflow>
      <ModalOverlay show={show} />
    </ModalWrap>
  ), modalPortal.current)
}

const OverflowDelay = keyframes`
    0% {
        overflow: hidden;
    }
    100% {
        overflow: auto;
    }
`

const ModalWrap = styled.div<ContentProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: backdrop-filter .3s;
    z-index: 100;
    pointer-events: none;
    ${({ show }) => show && css`
        z-index: 50;
        pointer-events: all;
    `}
    ${({ show }) => !show && css`
        z-index: -1;
        opacity: 0;
    `}
    ${breakpoint('mobile', 'tablet')`
        height: 100vh;
        padding-top: 68px;
    `}
`
type ContentProps = {
    info?: boolean;
    show?: boolean;
    confirm?: boolean;
} & ThemedStyledProps

const ModalContent = styled.div<ContentProps>`
  position: relative;
  max-width: 495px;
  width: 100%;
  min-height: 370px;
  transition: transform .3s;
  border-radius: 4px;
  ${({ theme }) => css`
    ${breakpoint('mobile', 'tablet')`
      margin: 0 7px 7px 7px;
      width: calc(100% - 14px);
      max-width: unset;
      min-height: calc(100% - ${theme.headerHeight});
    `}
  `}
  ${({ info }) => info && css`
    background: ${themeVar('modalBackground')};
    ${breakpoint('tablet')`
      padding-bottom: 175px;
    `}
    ${breakpoint('mobile', 'tablet')`
      margin: 0 7px 15px 7px;
    `}
  `}
  ${({ info, confirm }) => info && confirm && css`
    z-index: 2;
    ${breakpoint('tablet')`
        padding-bottom: 0px;
    `}
  `}
  ${breakpoint('tablet')`
    margin: 145px auto;
  `}
  transform: translateY(100vh);
  ${({ show }) => show && css`
    transform: translateY(0);
  `}    
`
const ModalContentWrap = styled.div<ContentProps>`
    position: relative;
    width: 100%;
    border-radius: 8px;
    background: ${themeVar('modalBackground')};
    overflow-x: hidden;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 18px;
    `}
    ${breakpoint('tablet')`
        padding: 42px 70px;
    `}
    
`

const ModalOverlay = styled.div<ContentProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${themeVar('modalOverlayBackground')};
    opacity: 0;
    z-index: -1;
    ${breakpoint('mobile', 'tablet')`
        height: calc(100% + ${themeVar('headerHeight')});
    `}
    transition: opacity .3s;
    ${({ show }) => show && css`
        opacity: 0.25;
        ${breakpoint('mobile', 'tablet')`
            opacity: 1;
        `}
    `}
`
const ModalOverflow = styled.div<ContentProps>`
    animation:${OverflowDelay} .3s forwards;
    width: 100%;
    height: 100%;
    overflow: auto;
    ${({ show }) => !show && css`
        overflow: hidden;
    `}
`
const ModalClose = styled.div`
    position: absolute;
    display: flex;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 5;
    cursor: pointer;
    ${breakpoint('mobile', 'tablet')`
        display: none;
    `}
`
const ModalCloseMobile = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    color: ${themeVar('modalCloseColorMobile')};
    fill: ${themeVar('modalCloseColorMobile')};
    ${breakpoint('tablet')`
        display: none;
    `}
`

const IconWrap = styled.div`
    display: flex;
    margin: auto 4px;
`

const InfoBackground = styled.div<{confirm?: boolean}>`
    display: flex;
    justify-content: space-between;
    z-index: -1;
    ${breakpoint('tablet')`
        width: 100%;
        padding: 0 30px;
        height: 175px;
        bottom: 0;
    `}

    ${breakpoint('mobile', 'tablet')`
        bottom: -14px;
        width: 100vw;
        left: -7px;
    `}
    overflow: hidden;
    position: absolute;
    ${({ confirm }) => confirm && css`
      z-index: 1;
      ${breakpoint('tablet')`
          overflow: hidden;
          height: 100%;
          & > svg {
            position: relative;
            top: 20px;
            left: -80px;
          }
      `}
      ${breakpoint('mobile', 'tablet')`
          justify-content: center;
          bottom: 0;
          & > svg {
            position: relative;
            top: 150px;
          }
      `}
    `}
`
