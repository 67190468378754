import * as React from 'react'
import styled, { css } from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'

type Props = {
  unit?: string;
  min: number;
  max: number;
  value?: number;
  onChange: (val: number) => void;
}

export const SliderField = ({
  unit, min, max, value, onChange,
}: Props) => {
  const valueInPercent = value ? ((value - min) / (max - min)) * 100 : 0

  const setValue = (positionX: number, targetWidth: number, offset: number) => {
    onChange(Math.round(((positionX - offset) / targetWidth) * (max - min) + min))
  }

  return (
    <Wrap
      onMouseUp={(e) => setValue(
        e.clientX,
        e.currentTarget.clientWidth,
        e.currentTarget.getBoundingClientRect().left,
      )}
      onTouchMove={(e) => setValue(e.touches[0].clientX,
        e.currentTarget.clientWidth,
        e.currentTarget.getBoundingClientRect().left)}
    >
      <InputLine>
        <InputLineFilled width={valueInPercent}>
          <InputPointer>
            <PointerValue>
              {value}
              {unit}
            </PointerValue>
          </InputPointer>
        </InputLineFilled>

      </InputLine>
      <InputRange>
        <div>
          {min}
          {unit}
        </div>
        <div>
          {max}
          {unit}
        </div>
      </InputRange>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  height: 34px;
  cursor: pointer;
  padding-top: 18px;
  font-family: ${themeVar('baseFont')};
`

type StyledInputProps = {
  hasError?: boolean;
} & ThemedStyledProps

const InputLine = styled.div<ThemedStyledProps>`
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 3px;
  pointer-events: none;
  background: ${themeVar('inputSliderMainColor')};
  border-radius: 3px;
`

const InputLineFilled = styled.div<ThemedStyledProps & {width: number}>`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: width .2s;
  ${({ width }) => width && css`
        width: ${width}%;
  `}
  height: 3px;
  background: ${themeVar('inputSliderFilledColor')};
  border-radius: 3px;
`

const InputPointer = styled.div<ThemedStyledProps>`
  display: block;
  position: absolute;
  bottom: -3px;
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  right: -4px;
  background: ${themeVar('inputSliderPointer')};
`

const PointerValue = styled.div<ThemedStyledProps>`
  display: block;
  position: absolute;
  bottom: 100%;
  left: -50%;
  font-size: 12px;
  color: ${themeVar('inputSliderPointerText')};
`

const InputRange = styled.div<ThemedStyledProps>`
  display: flex;
  width: 100%;
  padding-top: 5px;
  flex-wrap: wrap;
  font-size: 8px;
  justify-content: space-between;
  color: ${themeVar('inputSliderRangeText')};
`
