import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import { RentFreePeriodType } from '@/dal/contracts.interfaces'
import {
  InputField,
  NumberInput,
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  Select,
  InputDateTime,
  Icon,
  SmallText,
  Text,
} from '@/ui'
import { RentForm } from '../../model/rentPeriod'

export const AddRentPeriodForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields, submit, reset } = useForm(RentForm)
  const rentType: {text: string; value: RentFreePeriodType | null}[] = [
    { text: t('notSelected'), value: null },
    { text: t('additionalRent'), value: 'additional-rent' },
    { text: t('freeRent'), value: 'rent-free-period' },
  ]
  return (
    <BlockWrap>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('rentType')}
          </SubTitle>
          <Select
            items={rentType}
            value={fields.type.value}
            onChange={fields.type.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('startDate')}
            <sup>*</sup>
          </SubTitle>
          <InputDateTime
            fill
            onlyDate
            max={fields.endDate.value}
            value={fields.startDate.value}
            onChange={(val) => fields.startDate.onChange(val || '')}
            errorText={fields.startDate.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('endDate')}
            <sup>*</sup>
          </SubTitle>
          <InputDateTime
            fill
            onlyDate
            min={fields.startDate.value}
            disabled={!!fields.numberOfMonth.value}
            value={fields.endDate.value}
            onChange={(val) => fields.endDate.onChange(val || '')}
            errorText={fields.endDate.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            {t('numberOfMonth')}
            <sup>*</sup>
          </SubTitle>
          <NumberInput
            disabled={fields.endDate.value !== ''}
            value={fields.numberOfMonth.value}
            onChangeValue={fields.numberOfMonth.onChange}
            errorText={fields.numberOfMonth.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('amount')}
            <sup>*</sup>
          </SubTitle>
          <NumberInput
            isFloat
            value={fields.amount.value}
            onChangeValue={fields.amount.onChange}
            errorText={fields.amount.errorText()}
          />
        </>
      </Col>
      <Col width="100%" align="flex-start">
        <WarningBlock>
          <Col width="60px" noMargin>
            <Icon icon="warningCircle" />
          </Col>
          <Text>
            {t('addRentWarning')}
          </Text>
        </WarningBlock>
      </Col>
      <Col width="100%" noMargin noPadding>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
              onClick={reset}
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
              onClick={submit}
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}

  & [class*="ErrorText"], & [class*="MuiFormHelperText-root"] {
    color: white !important;
  }
`

const WarningBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0; 
`
