import * as React from 'react'
import styled from 'styled-components'

import { Card } from '@/ui'

import {
  Wrap,
  Title,
  Subtitle,
  Text,
  Ul,
  Li,
  Link,
} from '../parts'

export const GDPR_EN = () => (
  <Wrap>
    <Title>
      1.1. Consent text
    </Title>
    <Text>
      Your personal data is protected, it will not be communicated to any third party or used for purposes other
      than processing carried out with your consent on SNOUKI. This data will allow you to locate on a connecting
      site and track your assignments. They will allow the user to better control their real estate assets and have
      a global vision of all people, occupants and stakeholders. In accordance with articles 39 and following of the
      law n°78-17 of January 6, 1978 relating to data processing, files and freedoms, you can obtain communication
      and, if necessary, rectification or deletion of information concerning you, by contacting your administrator.
    </Text>


    <Title>
      1.2 Text of the cookie policy
    </Title>
    <Text>
      On SNOUKI we only issue functional cookies (
      <b>last_skin</b>
      {' '}
      to know the last theme used,
      {' '}
      <b>fluideToken</b>
      {' '}
      for the EXPERT
      function,
      {' '}
      <b>userType</b>
      {' '}
      to know if the user has rights to several sites and
      {' '}
      <b>jsessionid</b>
      {' '}
      to know if the user has an active session)
    </Text>
    <Text>
      We do not issue analytical or advertising cookies.
    </Text>
    <Text>
      The following text will not change on SNOUKI.
    </Text>

    <Ul>
      <Li noMarker>
        <Subtitle>
          What is a cookie?
        </Subtitle>
        <Text>
          A cookie is a text file stored on the hard drive of your terminal (computer, tablet, mobile) through your
          browser software when consulting an online service.
        </Text>
        <Text>
          This means that a cookie is sent to us every time you open a page on our site from your
          computer or mobile device.
        </Text>
        <Text>
          Registered cookies do not recognize you personally as an individual, but only recognize the device you are using.
        </Text>
        <Text>
          Cookies do not store any sensitive or directly identifying personal data but simply give information
          about your browsing so that your terminal can be recognized later.
        </Text>
      </Li>
      <Li noMarker>
        <Subtitle>
          Cookies used by SNOUKI
        </Subtitle>
        <Text>
          On SNOUKI we only issue functional cookies.
        </Text>
        <Text>
          These cookies allow us:
        </Text>
        <Ul>
          <Li>
            to identify you when you log in to our Site;
          </Li>
          <Li>
            to adapt the presentation of our Sites (language used, display resolution, operating system used, themes),
            according to the hardware and software for viewing or reading that your terminal includes;
          </Li>
        </Ul>
        <Text>
          These cookies have a shelf life of 13 months.
        </Text>
      </Li>
    </Ul>


    <Title>
      Privacy Policy
    </Title>
    <Subtitle>
      Who are we?
    </Subtitle>
    <Text>
      The address of your website is:
      {' '}
      <Link href="http://www.snouki.fr" blank>http://www.snouki.fr</Link>
    </Text>

    <Subtitle>
      Use of personal data collected
    </Subtitle>

    <Subtitle>
      Comments
    </Subtitle>
    <Text>
      When you leave a comment on our website, the data entered in the comment form, as well as your IP address and
      the user agent of your browser are collected to help us detect unwanted comments.
    </Text>
    <Text>
      An anonymized string created from your email address (also called a hash) can be sent to the Gravatar service to
      check if you are using the latter. Gravatar&apos;s privacy policy is available here: https://automattic.com/privacy/.
      after validation of your comment, your profile picture will be publicly visible next to your comment.
    </Text>

    <Subtitle>
      Mass media
    </Subtitle>
    <Text>
      If you are a registered user and upload images to the website, We advise you to avoid uploading images
      containing EXIF GPS coordinates data. Visitors to your website can download and extract location data from
      these images.
    </Text>

    <Subtitle>
      1. Privacy Policy at Snouki
    </Subtitle>
    <Text>
      The privacy policy concerns all practices related to the use of your personal data on the Snouki site this
      policy also applies to all applications, media and Communication Operations issued by Snouki that
      may use personal data.
    </Text>

    <Subtitle>
      2. The data collected
      <br />
      Interactions on
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
    </Subtitle>
    <Text>
      As part of a registration on
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      , a subscription to newsletter alerts or a request made via the
      account opening form,
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      collection of data about you.
    </Text>
    <Text>
      It can be your name, first name, civility, address, Zip code, the city in which you live, your email address.
      Based on your interaction with the site
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      some of this data may be mandatory while others are
      optional. This information can be updated by you in your personal space and can be combined to offer you a
      more suitable offer.
    </Text>

    <Subtitle>
      Snouki newsletter alerts
    </Subtitle>
    <Text>
      The various communications from Snouki that you can receive by email are traced. This tracking allows us to know
      if you have received, opened and possibly interacted with the transmitted content. If you do not wish to
      communicate information via Snouki emails, you have the option to unsubscribe by changing your alert preferences.
    </Text>

    <Subtitle>
      Internet advertising
    </Subtitle>
    <Text>
      Snouki may be required to perform advertising on the internet. These advertisements appear to the extent that
      your browsing has been targeted by our various campaigns. This targeting can be done from a geographical point
      of view but also if the site on which the advertisement appears has a potential interest related to the activity
      of Snouki. Tracking can be done following a visit from an internet advertisement via a tracking tag, a
      programming script to measure the behavior of an internet user.
    </Text>

    <Subtitle>
      Social networks
    </Subtitle>
    <Text>
      Snouki has a presence on Facebook as well as on other social networks. Via buttons, it is possible to follow
      @Snouki on twitter as well as &quot;like&quot; Snouki on Facebook. The information suggested by these actions may
      possibly be used by the social network concerned. Consult their privacy policy is therefore necessary to
      know in detail the possible consequences.
    </Text>
    <Text>
      In addition, it is possible to register on Snouki through a Facebook account. The information collected through
      this operation can be your name, first name, email address, profile picture, city in which you live and date
      of birth. This information may be updated in your account and may be used and combined by Snouki for the purpose
      of providing you with the most suitable offer.
    </Text>

    <Subtitle>
      3. Changes to your data
    </Subtitle>
    <Text>
      In accordance with the Data Protection Act (GDPR certified), you are free to modify or delete your personal data
      at any time. This can be done by logging into your account on www.snouki.fr. our customer service is also
      available from our contact form. Although you are unsubscribed from our email alerts, your data may be retained.
    </Text>

    <Subtitle>
      4. Privacy Policy modification
    </Subtitle>
    <Text>
      This privacy policy is subject to change at any time. Any significant changes will be communicated to you.
      In case of change, the fact that you continue to use the features of the site www.snouki.fr is equivalent
      to the acceptance of the latter.
    </Text>

    <Subtitle>
      5. Use of collected data
    </Subtitle>
    <Text>
      Snouki uses the collected data for several purposes:
    </Text>
    <Text>
      Communicate to you the most suitable offer possible according to the elements in our possession
    </Text>
    <Text>
      Improve our content in order to offer you the best possible navigation on the site www.snouki.fr
    </Text>
    <Text>
      Answer as best as possible the questions you ask us (depending on your store for example)
    </Text>

    <Subtitle>
      6. Cookies
    </Subtitle>
    <Text>
      Your browsing on www.snouki.fr may give rise to the use of cookies. These are small volumes installed by your
      browser on your hard drive. Once recognized, they allow us to inform us about the characteristics of your
      visit to our site. By default, most browsers accept cookies. However, you can change this choice so that your
      visit is not counted in our audience measurement tools. If necessary, this does not interfere with navigation
      on the site and all the features of the latter remain accessible. You will find more information on the
      CNIL website
    </Text>
    <Text>
      Snouki is required to use 3 types of cookies:
    </Text>
    <Ul>
      <Li>
        <b>Session or preference cookies: </b>
        these cookies are essential for navigation and the proper functioning
        of the site. Therefore, you can not object to their use.
      </Li>
      <Li>
        <b>Audience measurement cookies: </b>
        these cookies allow you to track your navigation on the site for statistical
        purposes and to allow Snouki to better know its audience to improve its service, you can object to their use.
      </Li>
      <Li>
        <b>Third party cookies: </b>
        these cookies are placed by partners in commercial or advertising partnerships. You can object to their use.
      </Li>
    </Ul>

    <Ul>
      <Li noMarker>
        <Subtitle>
          Information  Cookies
        </Subtitle>
        <Text>
          If you submit a comment on our site, you will be offered to save your name, email address and website in
          cookies. This is only for your convenience so you don&apos;t have to enter this information if you drop another
          comment later. These cookies expire after one year.
        </Text>
        <Text>
          If you visit the login page, a temporary cookie will be created to determine whether your browser accepts
          cookies. It does not contain any personal data and will be deleted automatically when you close your browser.
        </Text>
        <Text>
          When you log in, we will set a number of cookies to store your login information and screen preferences.
          The lifetime of a login cookie is two days, the lifetime of a screen option cookie is one year. If you check
          &quot;remember me&quot;, your login cookie will be kept for two weeks. If you log out of your account, the login cookie
          will be deleted.
        </Text>
        <Text>
          By editing or publishing a post, an additional cookie will be saved in your browser. This cookie does
          not include any personal data. It simply indicates the ID of the post you just edited. It expires after a day.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Embedded content from other sites
        </Subtitle>
        <Text>
          The articles on this site may include embedded content(e.g. videos, images, articles...). Content embedded
          from other sites behaves in the same way as if the visitor were visiting that other site.
        </Text>
        <Text>
          These Websites may collect data about you, use cookies, embed third-party tracking tools, track your
          interactions with such embedded content if you have a connected account on their website.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Audience statistics and metrics
        </Subtitle>
      </Li>

      <Li noMarker>
        <Subtitle>
          Use and transmission of your personal data
        </Subtitle>
      </Li>

      <Li noMarker>
        <Subtitle>
          Storage times of your data
        </Subtitle>
        <Text>
          If you leave a comment, the comment and its metadata are retained indefinitely. This allows subsequent
          comments to be recognized and approved automatically instead of leaving them in the moderation queue.
        </Text>
        <Text>
          For users who register on our site (if possible), we also store the personal data indicated in their profile.
          All users may view, modify or delete their personal information at any time (except for their name).
          Site managers can also view and edit this information.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          The rights you have over your data
        </Subtitle>
        <Text>
          If you have an account or have left comments on the site, you may request to receive a file containing
          all the personal data we have about you, including that you have provided to us. You can also request the
          deletion of your personal data. This does not take into account data stored for administrative, legal or
          security purposes.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Transmission of your personal data
        </Subtitle>
        <Text>
          Visitor feedback can be verified using an automated spam detection service.
        </Text>
      </Li>
    </Ul>
  </Wrap>
)
