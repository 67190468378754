import { createCustomError } from '@/lib/errors'
import { attachWrapper } from '@/lib/effector-extra'
import {
  Method,
  authServiceRequestFx,
} from './rest-api'
import { User } from './common.interfaces'
import {
  LoginParams,
  ResetSetNewPasswordParams,
  VerifyEmailParams,
  VerifyEmailRes,
  VerifyEmailResendParams,
  VerifyEmailResendRes,
  RegisterOwnerParams,
  RegisterOwnerRes,
  ResetPasswordParams,
} from './auth.interfaces'
import { UnknownError, UserNotFound } from './common-errors'
import { CreateOwnerProfileRes, SetNewPasswordParams } from './profile.interfaces'

const LOGIN_ERROR = 404
const USER_NOT_FOUND = 2016
const USER_EMAIL_NOT_VERIFIED = 2015
const VERIFY_TOKEN_ERROR = 404
const VERIFY_TOKEN_INVALID = 2001
const VERIFY_TOKEN_EXPIRED = 2002
const REGISTER_OWNER_ERROR = 404
const REGISTER_OWNER_EMAIL_EXISTS = 2003
const REGISTER_OWNER_EMAIL_EXISTS_BUT_NOT_ACTIVATED = 2014
const REGISTER_OWNER_PASSWORD_WEAK = 2004
const RESET_TOKEN_ERROR = 404
const RESET_TOKEN_INVALID = 2005
const RESET_TOKEN_EXPIRED = 2006

export const UserEmailNotVerified = createCustomError('UserEmailNotVerified')
export const VerifyEmailTokenInvalid = createCustomError('VerifyEmailTokenInvalid')
export const VerifyEmailTokenExpired = createCustomError('VerifyEmailTokenExpired')
export const EmailExists = createCustomError('EmailExists')
export const EmailExistsButNotActivated = createCustomError('EmailExistsButNotActivated')
export const PasswordTooWeak = createCustomError('PasswordTooWeak')
export const ResetTokenInvalid = createCustomError('ResetTokenInvalid')
export const ResetTokenExpired = createCustomError('ResetTokenExpired')

export const loginReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (params: LoginParams) => ({
    method: Method.post,
    url: '/login',
    body: params,
  }),
  mapError: ({ error }) => {
    if (error.response?.status === LOGIN_ERROR) {
      switch (error.response?.data.code) {
        case USER_EMAIL_NOT_VERIFIED:
          return new UserEmailNotVerified()
        case USER_NOT_FOUND:
          return new UserNotFound()
        default:
      }
    }
    return new UnknownError()
  },
})

export const fetchMyUserReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (_: void) => ({
    method: Method.post,
    url: '/get-user-profile',
  }),
  // fixme: бэк возвращает нам 200 даже если юзер не залогинен, поэтому проверяем, что вернулся нужный объект
  mapResult: ({ result }): User => {
    if (result.data.id) {
      return {
        ...result.data,
        role: 'owner', // FIXME: убрать хардкод роли, когда бэк добавит возвращение роли из этого эндпоинта
      }
    }
    throw new UserNotFound()
  },
  mapError: ({ error }) => new UserNotFound(),
})

export const resetPasswordReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (params: ResetPasswordParams) => ({
    method: Method.post,
    url: '/reset-password',
    body: params,
  }),
  mapError: ({ error }) => {
    if (error.response?.status === USER_NOT_FOUND) {
      return new UserNotFound()
    }
    return new UnknownError()
  },
})

export const setNewPasswordReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (params: ResetSetNewPasswordParams) => ({
    method: Method.post,
    url: '/set-new-password',
    body: params,
  }),
  mapError: ({ error }) => {
    if (error.response?.status === RESET_TOKEN_ERROR) {
      switch (error.response?.data.code) {
        case RESET_TOKEN_INVALID:
          return new ResetTokenInvalid()
        case RESET_TOKEN_EXPIRED:
          return new ResetTokenExpired()
        case REGISTER_OWNER_PASSWORD_WEAK:
          return new PasswordTooWeak()
        default:
      }
    }
    return new UnknownError()
  },
})

export const logoutReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/logout',
  }),
  mapError: () => new Error(),
})

export const verifyEmailReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: VerifyEmailParams) => ({
    method: Method.post,
    url: '/register-activate',
    body: p,
  }),
  mapResult: ({ result }): VerifyEmailRes => undefined,
  mapError: ({ error }) => {
    if (error.response?.status === VERIFY_TOKEN_ERROR) {
      switch (error.response?.data.code) {
        case VERIFY_TOKEN_INVALID:
          return new VerifyEmailTokenInvalid()
        case VERIFY_TOKEN_EXPIRED:
          return new VerifyEmailTokenExpired()
      }
    }
    return new UnknownError()
  },
})

export const verifyEmailResendReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: VerifyEmailResendParams) => ({
    method: Method.post,
    url: '/register-activate-resend-email',
    body: p,
  }),
  mapResult: ({ result }): VerifyEmailResendRes => undefined,
  mapError: ({ error }) => new UnknownError(),
})

export const registerOwnerReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: RegisterOwnerParams) => ({
    method: Method.post,
    url: '/request-registration',
    body: p,
  }),
  mapResult: ({ result }): RegisterOwnerRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === REGISTER_OWNER_ERROR) {
      switch (error.response?.data.code) {
        case REGISTER_OWNER_EMAIL_EXISTS:
          return new EmailExists()
        case REGISTER_OWNER_EMAIL_EXISTS_BUT_NOT_ACTIVATED:
          return new EmailExistsButNotActivated()
        case REGISTER_OWNER_PASSWORD_WEAK:
          return new PasswordTooWeak()
      }
    }
    return new UnknownError()
  },
})


export const setProfilePasswordReqFx = attachWrapper({
  effect: authServiceRequestFx,
  mapParams: (p: SetNewPasswordParams) => ({
    method: Method.post,
    url: '/change-password',
    body: p,
  }),
  mapResult: ({ result }): CreateOwnerProfileRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === RESET_TOKEN_ERROR) {
      switch (error.response?.data.code) {
        case REGISTER_OWNER_PASSWORD_WEAK:
          return new PasswordTooWeak()
        default:
      }
    }
    return new UnknownError()
  },
})
