import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateBankSecurityRes, BankSecurityType } from '@/dal/contracts.interfaces'
import {
  createBankSecurityReqFx,
  deleteBankSecurityReqFx,
  attachBankSecurityToTenantLeaseReqFx,
  detachBankSecurityFromTenantLeaseReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateBankSecurityRes[]>([])

export const loadList = d.createEvent<CreateBankSecurityRes[]>()
export const addItem = d.createEvent<CreateBankSecurityRes>()
export const deleteItem = d.createEvent<CreateBankSecurityRes>()
export const dropItem = d.createEvent<CreateBankSecurityRes['id']>()

export const BankSecurityForm = createForm({
  domain: d,
  fields: {
    type: {
      init: '' as BankSecurityType | null,
      rules: [rules.required()],
    },
    date: {
      init: '',
      rules: [rules.required()],
    },
    amount: {
      init: '' as string,
      rules: [rules.required()],
    },
    amountRent: {
      init: null as 1 | 2 | 3 | 6 | null,
    },
    indexedDeposit: {
      init: true,
    },
    lastReviewDate: {
      init: '',
    },
    typeOfGuarantee: {
      init: '',
    },
    remarks: {
      init: '',
    },
  },
})

export const createItemFx = attach({ effect: createBankSecurityReqFx })
export const attachItemToTenantLeaseFx = attach({ effect: attachBankSecurityToTenantLeaseReqFx })
export const deleteItemFx = attach({ effect: deleteBankSecurityReqFx })
export const detachItemFromTenantLeaseFx = attach({ effect: detachBankSecurityFromTenantLeaseReqFx })
