import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  PrimaryButton,
  Title,
  SubTitle,
  Col,
  InputField,
  Select,
  InputTextArea,
  FilePicker,
  SliderField,
  WrapField,
  AccentText,
} from '@/ui'
import { $isShowModal, newSite, hideModal } from '../../model/new-site'
import {
  useSiteTypeList,
  useSiteAssigmentList,
} from '../../helper/GetSelectItems'


export const NewSite = () => {
  const { t } = useTranslation('site-creation')
  const isShowModal = useStore($isShowModal)
  const { submit, fields, reset } = useForm(newSite)
  const siteAssigmentItemsList = useSiteAssigmentList()
  const siteTypeItemsList = useSiteTypeList()

  const saveForm = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  const setYearOfConstruction = (val: string) => {
    fields.yearOfConstruction.onChange(val.replace(/\D/g, ''))
  }

  return (
    <Modal show={isShowModal} onClose={() => hideModal()}>
      <Form onSubmit={saveForm}>
        <Col width="100%" noPadding>
          <Title>{t('newSite')}</Title>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              1.
              {' '}
              {t('siteName')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              placeholder={t('siteName')}
              value={fields.name.value}
              onChangeValue={fields.name.onChange}
              errorText={fields.name.errorText()}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              2.
              {' '}
              {t('description')}
            </SubTitle>
            <InputTextArea
              maxLength={150}
              value={fields.description.value}
              onChangeValue={fields.description.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              3.
              {' '}
              {t('assigment')}
            </SubTitle>
            <Select
              name={t('chooseAssigment')}
              value={fields.assigment.value}
              items={siteAssigmentItemsList}
              onChange={fields.assigment.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              4.
              {' '}
              {t('type')}
            </SubTitle>
            <Select
              name={t('chooseType')}
              value={fields.type.value}
              items={siteTypeItemsList}
              onChange={fields.type.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              5.
              {' '}
              {t('photos')}
            </SubTitle>
            <FilePicker
              files={fields.photos.value}
              onFile={(file) => fields.photos.onChange([...fields.photos.value, file])}
              onDelete={(index) => {
                fields.photos.value.splice(index, 1)
                fields.photos.onChange([...fields.photos.value])
              }}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              6.
              {' '}
              {t('yearOfConstruction')}
            </SubTitle>
            <InputField
              placeholder="YYYY"
              value={fields.yearOfConstruction.value}
              onChangeValue={setYearOfConstruction}
            />
          </>
        </Col>

        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              7.
              {' '}
              {t('address')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              placeholder={t('enterAddress')}
              value={fields.address.value}
              onChangeValue={fields.address.onChange}
              errorText={fields.address.errorText()}
            />
          </>
        </Col>

        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              8.
              {' '}
              {t('cadastre')}
            </SubTitle>
            <WrapField>

              <InputField
                placeholder={t('section')}
                value={fields.cadastreSection.value}
                onChangeValue={fields.cadastreSection.onChange}
              />
            </WrapField>
            <WrapField>

              <InputField
                placeholder={t('number')}
                value={fields.cadastreNumber.value}
                onChangeValue={fields.cadastreNumber.onChange}
              />
            </WrapField>
            <WrapField>

              <InputField
                placeholder={t('locality')}
                value={fields.cadastreLocality.value}
                onChangeValue={fields.cadastreLocality.onChange}
              />
            </WrapField>
            <WrapField>

              <InputField
                placeholder={t('surface')}
                value={fields.cadastreSurface.value}
                onChangeValue={fields.cadastreSurface.onChange}
              />
            </WrapField>
          </>
        </Col>

        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              htmlType="submit"
            >
              {t('save')}
            </PrimaryButton>
          </WrapField>
        </Col>
        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              outline
              type="warning"
              appendIcon="close"
              onClick={() => reset()}
            >
              {t('clear')}
            </PrimaryButton>
          </WrapField>
        </Col>
        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => hideModal()}
            >
              {t('return')}
            </PrimaryButton>
          </WrapField>
        </Col>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
`
