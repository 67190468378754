import { attachWrapper } from '@/lib/effector-extra'
import { createCustomError } from '@/lib/errors'
import { requestFx, Method } from '@/dal/rest-api'
import { quickUUID } from '@/lib/random'

import { Company } from './common.interfaces'
import {
  CreateCompanyParams,
  CreateCompanyRes,
  FetchMyCompanyParams,
  FetchMyCompanyRes,
} from './company.interfaces'
import { UnknownError } from './common-errors'

const COMPANY_NOT_FOUND = 404

const CompanyNotFound = createCustomError('CompanyNotFound')

export const companyMock = (): Company => ({
  id: quickUUID(),
  name: 'Company name',
  description: 'description',
  code: '123321',
  address: 'Moscow city hall',
  email: 'test@example.com',
  phones: ['123-321'], // at least one
  defaultPhone: '999-888-77-66',
  webSite: 'https://google.com',
  fax: '999-888-77-66',
})

export const createCompanyReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateCompanyParams) => ({
    method: Method.post,
    url: '/company/addMy',
    body: p,
  }),
  mapResult: ({ result }): CreateCompanyRes => result.data,
})

export const fetchMyCompanyReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchMyCompanyParams) => ({
    method: Method.post,
    url: '/company/getMy',
  }),
  mapResult: ({ result }): FetchMyCompanyRes => result.data,
  mapError: ({ error }) => {
    if (error.response?.status === COMPANY_NOT_FOUND) {
      return new CompanyNotFound()
    }
    return new UnknownError()
  },
})
