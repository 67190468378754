import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

export const Goodies = () => {
  const { t } = useTranslation('welcome/translation')
  return (
    <GoodiesWrap>
      <GoodiesItem>
        <GoodiesImg src="/assets/img/goodies1.jpg" />
        <GoodiesText>
          {t('advantage1')}
        </GoodiesText>
      </GoodiesItem>
      <GoodiesItem>
        <GoodiesImg src="/assets/img/goodies2.jpg" />
        <GoodiesText>
          {t('advantage2')}
        </GoodiesText>
      </GoodiesItem>
      <GoodiesItem>
        <GoodiesImg src="/assets/img/goodies3.jpg" />
        <GoodiesText>
          {t('advantage3')}
        </GoodiesText>
      </GoodiesItem>
    </GoodiesWrap>
  )
}


const GoodiesWrap = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    ${breakpoint('tablet')`
        width: calc( 100% + 50px );
        margin: 60px -25px 0;
    `}
    ${breakpoint('mobile', 'tablet')`
        margin-top: 35px;
    `}
`

const GoodiesItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 0 25px;
    margin-bottom: 45px;
    ${breakpoint('mobile', 'tablet')`
        flex: 100%;
        padding: 0 50px;
    `}
`

const GoodiesImg = styled.img`
    display: flex;
    width: 100%;
    object-fit: contain;
`

const GoodiesText = styled.div`
    display: flex;
    margin-top: 25px;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
`
