export const palette = {
  white: 'white',
  pale: 'rgba(0, 0, 0, 0.6)',
  dark: '#3D3939',
  brand: '#FFA669',
  brandGradient: 'linear-gradient(10.49deg, #FFC776 3.24%, #FFA669 83.24%) ',
  brandGradientRotate: 'linear-gradient(182.7deg, #FFC776 -21.36%, #FFA669 98.25%) ',
  orange: '#FB8C5D',
  orangePale: '#FFE1CA',
  orangePale2x: '#FFF5ED',
  danger: '#EC4A4A',
  dangerPale: '#FF7373',
  dangerPale2x: '#FFBABA',
  dangerPale3x: '#FFEDED',
  dangerDark: '#D83131',
  green: '#75C26D',
  greenPale: '#E9FFE7',
  gray: '#A1A1A1',
  grayPale: '#E8E8E8',
  grayPale2x: '#F4F4F4',
  grayPale3x: '#FBFBFB',
  greyPale4x: '#CACACA',
  whiteOpacity: 'rgba(255, 245, 237, 0.25)',
}
