import { guard, sample, combine } from 'effector'

import { resetAuthState, logoutReqFx, Language } from '@/dal'
import i18n from '@/i18n'

import { bool } from 'prop-types'
import {
  $isAuthStateReady,
  $isAuth,
  $confirmModalAction,
  $confirmModalText,
  $confirmModalTitle,
  $showConfirmModal,
  $lang,
  $ownerRegistrationFlowFinished,
  ownerRegistrationFinished,
  userAuthChecked,
  userAuthenticated,
  showConfirmModal,
  closeConfirmModal,
  checkUserAuth,
  changeLanguage,
  checkOwnerCreated,
} from './units'
import { $userInfo, $user } from '../user/units'

$isAuthStateReady.on(userAuthChecked, () => true)
$isAuth
  .on(userAuthenticated, () => true)
  .reset(resetAuthState, logoutReqFx.done)


$ownerRegistrationFlowFinished
  .on(ownerRegistrationFinished, () => true)
// start owner registration flow if current user is owner
// FIXME: должен быть профиль, должен быть выбран тариф. если тариф соответствующий, должна быть создана компания
guard(
  sample({
    source: $user,
    clock: userAuthenticated,
    fn: (user) => user?.role === 'owner',
  }),
  {
    filter: (x) => x,
    target: checkOwnerCreated.prepend((x: boolean) => undefined),
  },
)

$confirmModalAction
  .on(showConfirmModal, (_, { action = null }) => ({ action }))
  .on(closeConfirmModal, () => ({ action: null }))

$confirmModalTitle
  .on(showConfirmModal, (_, { title = '' }) => title)
  .on(closeConfirmModal, () => '')
$confirmModalText
  .on(showConfirmModal, (_, { text = '' }) => text)
  .on(closeConfirmModal, () => '')
$showConfirmModal
  .on(showConfirmModal, () => true)
  .on(closeConfirmModal, () => false)
$lang
  .on(changeLanguage, (_, x) => x)

i18n.on('languageChanged', (lng: Language) => changeLanguage(lng))

// fixme: временный костыль, чтобы модуль authorization успел инициализироваться
setImmediate(() => {
  checkUserAuth()
})
