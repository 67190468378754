import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { Card, Row } from '@/ui'

import {
  CreateOwnerProfileForm,
} from '../containers'

export const CreateOwnerProfile = () => (
  <Wrap>
    <Row>
      <Title>
        profile creation
      </Title>
    </Row>
    <Card>
      <CreateOwnerProfileForm />
    </Card>
  </Wrap>
)

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: white;
`
const Wrap = styled.span`
  margin-bottom: 35px;
  width: 100%;
`
