import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { $userInfo } from '@/features/app'
import {
  Title, Language, getTitleListReqFx, createOwnerProfileReqFx,
} from '@/dal'
import { rules } from '@/lib/validators'
import { createFilters } from '@/lib/effector-filters'

export const d = createDomain('d')

export const $titleList = d.store<Title[]>([])
export const $titleSelectItemsList = $titleList.map(
  (list) => list.map((title) => ({ value: title.id, text: title.name })),
)

export const mounted = d.event<void>()

export const profileForm = createForm({
  domain: d,
  fields: {
    language: {
      init: 'en' as Language,
      rules: [rules.required()],
    },
    title: {
      init: '',
    },
    lastName: {
      init: '',
      rules: [rules.required()],
    },
    firstName: {
      init: '',
      rules: [rules.required()],
    },
    marriedLastName: {
      init: '',
    },
    lastNameOfUse: {
      init: '',
    },
    firstNameOfUse: {
      init: '',
    },
    email: {
      init: '',
      rules: [rules.required(), rules.isEmail()],
    },
    phones: {
      init: [] as string[],
    },
    defaultPhone: {
      init: '',
      rules: [rules.required()],
    },
    fax: {
      init: '',
    },
    photo: {
      init: null as File | null,
    },
    dateOfBirth: {
      init: '' as string,
    },
  },
})

export const filters = createFilters({
  domain: d,
  filters: {
    language: {
      include: true,
      value: '',
    },
  },
})

export const getTitleListFx = attach({ effect: getTitleListReqFx })
export const createOwnerProfileFx = attach({ effect: createOwnerProfileReqFx })
