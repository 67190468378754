import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

type Props = {
  children: React.ReactChild;
}

type BlurContext = {
  blurApp: () => void;
  focusApp: () => void;
  modalPortal: React.RefObject<HTMLDivElement> | null;
  portalMounted: boolean;
}


export const BlurApp = ({ children }: Props) => {
  const [appBlur, setAppBlur] = React.useState<boolean>(false)
  const [isMounted, setIsMounted] = React.useState<boolean>(false)

  const blurApp = () => setAppBlur(true)
  const focusApp = () => setAppBlur(false)
  const modalPortal = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <BlurContext.Provider value={{
      blurApp, focusApp, modalPortal, portalMounted: isMounted,
    }}
    >
      <>
        <BlurStyled blur={appBlur}>
          {children}
        </BlurStyled>
        <div ref={modalPortal} />
      </>
    </BlurContext.Provider>
  )
}

export const BlurContext = React.createContext<BlurContext>({
  blurApp: () => {},
  focusApp: () => {},
  modalPortal: null,
  portalMounted: false,
})

type BlurStyledProps = {
   blur: boolean;
}

const BlurStyled = styled.div<BlurStyledProps>`
    ${({ blur }) => blur && css`
        ${breakpoint('tablet')`
            filter: blur(15px);
        `}
    `}
    transition: filter .3s;
    overflow: hidden;
`
