import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  InputField,
  NumberInput,
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  Select,
  InputDateTime,
  RadioButtonsList,
  InputTextArea,
} from '@/ui'
import { DepositForm } from '../../model/securityDeposit'

export const AddSecurityDepositForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields, submit, reset } = useForm(DepositForm)
  const amountRentType: {text: string; value: null | 1 | 2 | 3 | 6}[] = [
    { text: t('notSelected'), value: null },
    { text: t('1month'), value: 1 },
    { text: t('2month'), value: 2 },
    { text: t('3month'), value: 3 },
    { text: t('6month'), value: 6 },
  ]

  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  return (
    <BlockWrap>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('date')}
            <sup>*</sup>
          </SubTitle>
          <InputDateTime
            fill
            onlyDate
            value={fields.date.value}
            onChange={(val) => fields.date.onChange(val || '')}
            errorText={fields.date.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('depositedAmount')}
            <sup>*</sup>
          </SubTitle>
          <NumberInput
            isFloat
            value={fields.amount.value}
            onChangeValue={fields.amount.onChange}
            errorText={fields.amount.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('amountRent')}
          </SubTitle>
          <Select
            items={amountRentType}
            value={fields.amountRent.value}
            onChange={fields.amountRent.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('indexedDeposit')}
          </SubTitle>
          <RadioButtonsList
            name="indexedDeposit"
            items={YesNoItems}
            value={fields.indexedDeposit.value}
            onChange={fields.indexedDeposit.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('lastReviewDate')}
          </SubTitle>
          <InputDateTime
            fill
            onlyDate
            value={fields.lastReviewDate.value}
            onChange={(val) => fields.lastReviewDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            6.
            {' '}
            {t('typeOfGuarantee')}
          </SubTitle>
          <InputField
            value={fields.typeOfGuarantee.value}
            onChangeValue={fields.typeOfGuarantee.onChange}
          />
        </>
      </Col>
      <Col width="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('remarks')}
          </SubTitle>
          <InputTextArea
            value={fields.remarks.value}
            onChangeValue={fields.remarks.onChange}
          />
        </>
      </Col>
      <Col width="100%" noMargin noPadding>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
              onClick={reset}
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
              onClick={submit}
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
  & [class*="ErrorText"] {
    color: white !important;
  }
`
