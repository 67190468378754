import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Row } from '@/ui'

import { CompanyCreateForm } from '../containers'

export const CompanyCreatePage = () => {
  const { t } = useTranslation('company/main')
  return (
    <>
      <Row>
        <Title>
          {t('createPageTitle')}
        </Title>
      </Row>
      <CompanyCreateForm />
    </>
  )
}

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: white;
`
