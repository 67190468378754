import * as React from 'react'
import { Card, Row } from '@/ui'
import styled from 'styled-components'
import {
  MemberList,

} from '../containers'

export const EditMember = () => (
  <Wrap>
    <Row>
      <Title>
        Member
      </Title>
    </Row>
    <Card>
      <MemberList />
    </Card>
  </Wrap>
)

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: white;
`
const Wrap = styled.span`
  margin-bottom: 35px;
  width: 100%;
`
