import * as React from 'react'
import { Card, Row, Visible } from '@/ui'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { CreateUserForm } from '../containers'
import { MobileFilter } from '../containers/user'
import { $createUserStep } from '../../model'

export const NewUser = () => {
  const step = useStore($createUserStep)
  return (
    <Wrap>
      <Row>
        <Title>
          User
        </Title>
      </Row>
      <Visible mobile>
        {step === 'access' && (
        <MobileFilter />
        )}
      </Visible>
      <Card>
        <CreateUserForm />
      </Card>
    </Wrap>
  )
}

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: white;
`
const Wrap = styled.span`
  margin-bottom: 35px;
  width: 100%;
`
