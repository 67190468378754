import * as React from 'react'
import { TabControl, Visible } from '@/ui'
import { useStore } from 'effector-react'
import { PersonalFileTabEmunITabEmunI } from '@/features/app'
import { $createPersonFileStep, setPersonFileStep, $personFileCompletedSteps } from '../../model'
import {
  Person, Location, ContactDetails, PersonDetails, Synchronisation, CreatedModal,
} from './person-file'

export const CreatePersonalFileForm = () => {
  const createPersonFileStep = useStore($createPersonFileStep)
  const completedSteps = useStore($personFileCompletedSteps)

  type StepsI = {value: PersonalFileTabEmunITabEmunI; name: string}[]

  const steps: StepsI = [
    { value: 'person', name: '1. Person' },
    { value: 'location', name: '2. Location' },
    { value: 'contact', name: '3. Contact details' },
    { value: 'details', name: '4. Person details' },
    { value: 'synchronisation', name: '5. Synchronisation ' },
  ]

  const CommandFormContent = (stepName: PersonalFileTabEmunITabEmunI) => {
    switch (stepName) {
      case 'person':
        return (<Person />)
      case 'location':
        return (<Location />)
      case 'contact':
        return (<ContactDetails />)
      case 'details':
        return (<PersonDetails />)
      case 'synchronisation':
        return (<Synchronisation />)
      default:
        return null
    }
  }

  const changeStep = (step: PersonalFileTabEmunITabEmunI) => {
    setPersonFileStep(step)
  }

  return (
    <>
      <TabControl
        items={steps}
        value={createPersonFileStep}
        onItemClick={(val) => changeStep(val.value)}
        checkedIcon="check"
        checkedSteps={completedSteps}
      />
      {CommandFormContent(createPersonFileStep)}
      <CreatedModal />
    </>
  )
}
