import { consultation } from './domain'

export const $isCreateConsultationDialogShown = consultation.store<boolean>(false)
export const $isConsultationCreatedDialogShown = consultation.store<boolean>(false)
export const $isConsultationInfoDialogShown = consultation.store<boolean>(false)
export const $isValidateConsultationDialogShown = consultation.store<boolean>(false)
export const $isConsultationValidatedDialogShown = consultation.store<boolean>(false)
export const $isCreateParticipantDialogShown = consultation.store<boolean>(false)
export const $isParticipantValidateDialogShown = consultation.store<boolean>(false)
export const $isParticipantInfoDialogShown = consultation.store<boolean>(false)
