import * as React from 'react'
import styled from 'styled-components'
import {
  Checkbox, themeVar, Row, Col,
} from '@/ui'

type Props = {
  item: ItemI;
}

type ItemI = {
  id: number;
  name: string;
  repository: boolean;
  services: boolean;
  meetingRooms: boolean;
  workOrders: boolean;
  arrivalDeparture: boolean;
  statements: boolean;
  office: boolean;
  plans: boolean;
  controls: boolean;
  purchases: boolean;
  homePage: boolean;
  consultations: boolean;
  command: boolean;
  park: boolean;
}

export const AccessBySiteCard = ({ item }: Props) => (
  <Wrap>
    <Title>

      {item.name}
    </Title>
    <ConfigList>

      <Cell>
        <FieldName>
          repository
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          services
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          meeting rooms
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          work orders
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          arrival&Departure
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          statements
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          office
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          plans
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          controls
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          purchases
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          homePage
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          consultations
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          command
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
      <Cell>
        <FieldName>
          park
        </FieldName>
        <Checkbox
          checked={false}
          onChange={() => {}}
        />
      </Cell>
    </ConfigList>
  </Wrap>
)

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;
  border: 1px solid ${themeVar('brandOrangePale2xColor')};
  margin-bottom: 10px;
`
const Title = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${themeVar('brandOrangeColor')};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  padding: 18px;
`
const ConfigList = styled.div`
  display: grid;
  grid-template-columns: repeat(500, minmax(100px, 1fr));
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: auto;
`

const Cell = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
`

const FieldName = styled.div`
  font-size: 12px;
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 10px;
`
