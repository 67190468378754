import {
  forward, sample, guard, merge,
} from 'effector'

import { history } from '@/lib/history'
import { Profile } from '@/dal/common.interfaces'
import { $userInfo } from '@/features/app'

import {
  mounted,
  form,
  useUserData,
  createCompanyFx,
  d,
  reset,
} from './unit'


d.onCreateStore((x) => x.reset(reset))

sample({
  source: $userInfo,
  clock: guard({
    source: merge([mounted, $userInfo.updates]),
    filter: $userInfo.map((x) => !!x),
  }),
  fn: (x) => ((x as Profile).email), // fixme: strange types error
  target: form.fields.email.onChange,
})

sample({
  source: $userInfo,
  clock: useUserData,
  fn: (userData) => ({
    fax: userData?.fax,
    defaultPhone: userData?.defaultPhone,
    phones: userData?.phones.filter((_, i) => i !== 0),
    email: userData?.email,
  }),
  target: form.set,
})

forward({
  from: useUserData,
  to: form.resetErrors,
})

sample({
  source: form.$values,
  clock: form.formValidated,
  fn: (formData) => ({
    ...formData,
    phones: [formData.defaultPhone, ...formData.phones.filter((x) => x !== '')],
  }),
  target: createCompanyFx,
})

createCompanyFx.done.watch(() => {
  history.replace('/home')
})
