import { forward } from 'effector'
import { $list, fetchSitesListFx, reloadSitesList } from './unit'

$list
  .on(fetchSitesListFx.doneData, (_, x) => [...x.items])


forward({
  from: reloadSitesList,
  to: fetchSitesListFx,
})
