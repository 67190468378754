import * as React from 'react'
import styled, { css } from 'styled-components'
import { themeVar, useTheme, ThemedStyledProps } from '@/ui/theming'
import { Icon, IconName } from '@/ui/icons'

type Props = {
    htmlType?: 'submit' | 'reset' | 'button';
    type?: 'primary' | 'gray' | 'warning' | 'inverse' | 'white';
    outline?: boolean;
    fluid?: boolean;
    prependIcon?: IconName;
    appendIcon?: IconName;
    disabled?: boolean;
    children?: React.ReactNode;
    onClick?: () => void;
    onHover?: () => void;
    size?: 'extra small' | 'small' | 'medium' | 'large' | 'extra large';
    className?: string;
}

export const PrimaryButton = ({
  htmlType = 'button',
  prependIcon,
  appendIcon,
  onClick,
  onHover,
  disabled = false,
  children,
  fluid,
  outline = false,
  type = 'primary',
  size = 'medium',
  className,
}: Props) => (
  <Wrap
    fluid={fluid}
    outline={outline}
    onClick={onClick}
    onMouseEnter={onHover}
    type={htmlType}
    disabled={disabled}
    buttonType={type}
    size={size}
    className={className}
  >
    {(appendIcon || prependIcon) && (
    <IconWrap>
      <Icon
        icon={prependIcon || 'emptyIcon'}
        width={20}
      />
    </IconWrap>
    )}
    <TextWrapper
      prependPadding={Boolean(prependIcon)}
      appendPadding={Boolean(appendIcon)}
    >
      {children}
    </TextWrapper>
    {(appendIcon || prependIcon) && (
    <IconWrap>
      <Icon
        icon={appendIcon || 'emptyIcon'}
        width={20}
      />
    </IconWrap>
    )}
  </Wrap>
)

const IconWrap = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:last-child{
      margin-left: 10px;
      margin-right: 0px;
    }
`

const TextWrapper = styled.div<{prependPadding: boolean;appendPadding: boolean}>`
  margin-top: 4px;
  margin: 0 auto;
`

type WrapProps = {
  disabled?: boolean;
  buttonType?: 'primary' | 'gray' | 'warning' | 'inverse' | 'white';
  size?: 'extra small' | 'small' | 'medium' | 'large' | 'extra large';
  outline?: boolean;
  fluid?: boolean;
} & ThemedStyledProps

const Wrap = styled.button<WrapProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${themeVar('baseFont')};
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  ${({ size }) => {
    switch (size) {
      case 'extra small':
        return css`
          height: 20px;
          padding: 0 6px;
          font-size: 8px;
        `
      case 'small':
        return css`
          height: 30px;
          padding: 0 10px;
          font-size: 10px;
        `
      case 'medium':
        return css`
          height: 40px;
          padding: 0 15px;
          font-size: 14px;
        `
      case 'large':
        return css`
          height: 50px;
          padding: 0 45px;
        `
      case 'extra large':
        return css`
          height: 52px;
          padding: 0 16px;
        `
      default:
        return css`
          height: 40px;
          padding: 0 15px;
        `
    }
  }
}
  
  border: 0;
  outline: none;
  text-transform: uppercase;
  pointer-events: all;
  ${({ disabled }) => disabled && css`
      opacity: 0.6;
  `}
  ${({ fluid }) => Boolean(fluid) && css`
    width: 100%;
  `}
  ${({ buttonType, outline }) => buttonType === 'primary' && css`
    ${() => {
    if (outline) {
      return css`
        border: 2px solid ${themeVar('primaryButtonPrimaryBorder')};
      `
    }
  }};
    
    background: ${!outline
    ? themeVar('primaryButtonPrimaryBackground')
    : themeVar('primaryButtonOutlinedBackground')
};
    color: ${!outline
    ? themeVar('primaryButtonTextColor')
    : themeVar('primaryButtonDarkTextColor')
};
    fill: ${!outline
    ? themeVar('primaryButtonTextColor')
    : themeVar('primaryButtonPrimaryBackgroundHover')
};
    &:hover{
      background: ${!outline
    ? themeVar('primaryButtonPrimaryBackgroundHover')
    : themeVar('primaryButtonPrimaryBackground')
};
  color: ${themeVar('primaryButtonTextColor')};
  fill: ${themeVar('primaryButtonTextColor')};
  ${!outline
    ? css`
        border-image: unset;
        border-color: ${themeVar('primaryButtonPrimaryBackgroundHover')};
      `
    : css`
        border: none;
      `
};
    }
  `}
  ${({ buttonType, outline }) => buttonType === 'gray' && css`
    border: 2px solid ${themeVar('primaryButtonGrayBorderColor')};
    background: ${!outline
    ? themeVar('primaryButtonGrayBorderColor')
    : themeVar('primaryButtonOutlinedBackground')
};
    color: ${themeVar('primaryButtonDarkTextColor')};
    fill: ${themeVar('primaryButtonGrayIcon')};
    &:hover{
      border: 2px solid ${!outline
    ? themeVar('primaryButtonGrayBackgroundHover')
    : themeVar('primaryButtonGrayBackground')
};
      background: ${!outline
    ? themeVar('primaryButtonGrayBackgroundHover')
    : themeVar('primaryButtonGrayBackground')
};
      color: ${!outline
    ? themeVar('primaryButtonTextColor')
    : themeVar('primaryButtonDarkTextColor')
};
      fill: ${!outline
    ? themeVar('primaryButtonTextColor')
    : themeVar('primaryButtonPrimaryBackground')
};
    }
  `}

  ${({ buttonType, outline }) => buttonType === 'warning' && css`
    border: 2px solid ${!outline
    ? themeVar('primaryButtonWarningBackground')
    : themeVar('primaryButtonWarningOutlinedBackground')
};
    background: ${!outline
    ? themeVar('primaryButtonWarningBackground')
    : themeVar('primaryButtonOutlinedBackground')
};
    color: ${!outline
    ? themeVar('primaryButtonWarningTextColorHover')
    : themeVar('primaryButtonWarningTextColor')
};
    fill: ${!outline
    ? themeVar('primaryButtonWarningTextColorHover')
    : themeVar('primaryButtonWarningTextColor')
};
    &:hover{
      border: 2px solid ${!outline
    ? themeVar('primaryButtonWarningFilledBackground')
    : themeVar('primaryButtonWarningOutlinedBackground')
};
      background: ${!outline
    ? themeVar('primaryButtonWarningFilledBackground')
    : themeVar('primaryButtonWarningOutlinedBackground')
};
      color: ${themeVar('primaryButtonWarningTextColorHover')};
      fill: ${!outline
    ? themeVar('primaryButtonWarningTextColorHover')
    : themeVar('primaryButtonWarningTextColor')
};
    }
  `}

  ${({ buttonType }) => buttonType === 'inverse' && css`
      background: ${themeVar('primaryButtonInverseBackground')};
      color: ${themeVar('primaryButtonInverseTextColor')};
      fill: ${themeVar('primaryButtonInverseTextColor')};
      border: 2px solid ${themeVar('primaryButtonInverseBorderColor')};
      padding: 13px 13px;
      &:hover{
        background: ${themeVar('primaryButtonInverseBackgroundHover')};
      }
  `}

  ${({ buttonType, outline }) => buttonType === 'white' && css`
    border: 2px solid ${themeVar('primaryButtonWhiteBorderColor')};
    background: ${!outline
    ? themeVar('primaryButtonWhiteBackground')
    : themeVar('primaryButtonWhiteBackgroundHover')
};
    color: ${!outline
    ? themeVar('primaryButtonWhiteTextColor')
    : themeVar('primaryButtonWhiteTextColorHover')
};
    fill: ${!outline
    ? themeVar('primaryButtonWhiteIcon')
    : themeVar('primaryButtonWhiteIconHover')
};
    &:hover{
      background: ${!outline
    ? themeVar('primaryButtonWhiteBackgroundHover')
    : themeVar('primaryButtonWhiteBackground')
};
    color: ${!outline
    ? themeVar('primaryButtonWhiteTextColorHover')
    : themeVar('primaryButtonWhiteTextColor')
};
    fill: ${!outline
    ? themeVar('primaryButtonWhiteIconHover')
    : themeVar('primaryButtonWhiteIcon')
};
    }
  `}

  ${({ disabled, outline }) => disabled && css`
    cursor: default;
    pointer-events: none;
    border: 2px solid ${themeVar('primaryButtonDisabledBorderColor')};
    background: ${!outline
    ? themeVar('primaryButtonDisabledBackground')
    : themeVar('primaryButtonOutlinedDisabledBackground')
};
    color: ${!outline
    ? themeVar('primaryButtonDisabledText')
    : themeVar('primaryButtonOutlinedDisabledText')
};
    fill: ${!outline
    ? themeVar('primaryButtonDisabledText')
    : themeVar('primaryButtonOutlinedDisabledText')
};
    &:hover{
      border: 2px solid ${themeVar('primaryButtonDisabledBorderColor')};
      background: ${!outline
    ? themeVar('primaryButtonDisabledBackground')
    : themeVar('primaryButtonOutlinedDisabledBackground')
};
      color: ${!outline
    ? themeVar('primaryButtonDisabledText')
    : themeVar('primaryButtonOutlinedDisabledText')
};
      fill: ${!outline
    ? themeVar('primaryButtonDisabledText')
    : themeVar('primaryButtonOutlinedDisabledText')
};
    }
  `}
`
