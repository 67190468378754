import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'

import { SelectShort, IconName } from '@/ui'
import { $lang } from '@/features/app'

export const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const selectedLanguage = useStore($lang)

  const languageList: {value: string; text: string; icon: IconName}[] = [
    { value: 'en', text: 'EN', icon: 'flagEn' },
    { value: 'fr', text: 'FR', icon: 'flagFr' },
  ]

  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (
    <SelectShort
      items={languageList}
      value={selectedLanguage}
      onChange={setLanguage}
    />
  )
}
