import { forward, sample, split } from 'effector'

import { TenantLease } from '@/dal/contracts.interfaces'
import { convertToEuroCurrency, convertToEuroCentsCurrency } from '@/lib/field-handlers'

import {
  PaymentForm,
  paymentToAutocomplete,
  setTenantLeasePaymentAndSpecialClausesFx,
} from './unit'
import { getTenentLeaseFx, $tenantContract, setTab } from '../main'
import { GeneralForm } from '../general'

forward({
  from: getTenentLeaseFx.doneData.map(({ tenantLease: x }) => ({
    ...x,
    joiningFeeAmount: x.joiningFeeAmount ? convertToEuroCurrency(x.joiningFeeAmount) : '',
    despecialisationIndemnityAmount: x.despecialisationIndemnityAmount ? convertToEuroCurrency(x.despecialisationIndemnityAmount) : '',
    paymentAmount: x.paymentAmount ? convertToEuroCurrency(x.paymentAmount) : '',
    transferOfBusinessAmount: x.transferOfBusinessAmount ? convertToEuroCurrency(x.transferOfBusinessAmount) : '',
    repurchasingOfSharesAmount: x.repurchasingOfSharesAmount ? convertToEuroCurrency(x.repurchasingOfSharesAmount) : '',
    estimatedRent: x.estimatedRent ? convertToEuroCurrency(x.estimatedRent) : '',
    budgetedRent: x.budgetedRent ? convertToEuroCurrency(x.budgetedRent) : '',
    estimatedCosts: x.estimatedCosts ? convertToEuroCurrency(x.estimatedCosts) : '',
    budgetedCosts: x.budgetedCosts ? convertToEuroCurrency(x.budgetedCosts) : '',
  })),
  to: PaymentForm.set,
})

forward({
  from: getTenentLeaseFx.fail,
  to: PaymentForm.reset,
})

sample({
  source: [PaymentForm.$values, $tenantContract],
  clock: PaymentForm.formValidated,
  fn: ([form, contract]) => ({
    id: (contract as TenantLease).id,
    data: {
      ...form,
      despecialisationIndemnityPaymentTo: form.paymentTo,
      joiningFeeAmount: form.joiningFee && form.joiningFeeAmount
        ? convertToEuroCentsCurrency(form.joiningFeeAmount)
        : undefined,
      despecialisationIndemnityAmount: form.despecialisationIndemnity && form.despecialisationIndemnityAmount
        ? convertToEuroCentsCurrency(form.despecialisationIndemnityAmount)
        : undefined,
      paymentAmount: (contract as TenantLease).type === 'lease' && form.paymentAmount
        ? convertToEuroCentsCurrency(form.paymentAmount)
        : undefined,
      transferOfBusinessAmount: form.transferOfBusiness && form.transferOfBusinessAmount
        ? convertToEuroCentsCurrency(form.transferOfBusinessAmount)
        : undefined,
      repurchasingOfSharesAmount: form.repurchasingOfShares && form.repurchasingOfSharesAmount
        ? convertToEuroCentsCurrency(form.repurchasingOfSharesAmount)
        : undefined,
      estimatedRent: form.estimatedRent
        ? convertToEuroCentsCurrency(form.estimatedRent)
        : undefined,
      budgetedRent: form.budgetedRent
        ? convertToEuroCentsCurrency(form.budgetedRent)
        : undefined,
      estimatedCosts: form.estimatedCosts
        ? convertToEuroCentsCurrency(form.estimatedCosts)
        : undefined,
      budgetedCosts: form.budgetedCosts
        ? convertToEuroCentsCurrency(form.budgetedCosts)
        : undefined,
      taxation: form.taxation || undefined,
      paymentTerms: form.paymentTerms || undefined,
    },
  }),
  target: setTenantLeasePaymentAndSpecialClausesFx,
})

forward({
  from: setTenantLeasePaymentAndSpecialClausesFx.done.map(() => 'security'),
  to: setTab,
})

// paymentTo autocomplete
getTenentLeaseFx.doneData.watch((x) => {
  if (x.despecialisationIndemnityPaymentToWithContact) {
    paymentToAutocomplete.onChange(x.despecialisationIndemnityPaymentToWithContact)
  }
})

forward({
  from: paymentToAutocomplete.onChange.map((x) => (x ? x.conterparty.id : '')),
  to: PaymentForm.fields.paymentTo.onChange,
})

forward({
  from: PaymentForm.fields.despecialisationIndemnityPaymentToMyOrganization.onChange,
  to: [
    paymentToAutocomplete.onChange.prepend(() => null),
    PaymentForm.fields.paymentTo.onChange.prepend(() => ''),
  ],
})

GeneralForm.fields.type.$value.watch((val) => {
  PaymentForm.fields.type.onChange(val)
})
