import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { GDPR_EN, GDPR_FR } from '../containers'

export const GDPRPage = () => {
  const { i18n } = useTranslation()

  if (i18n.language === 'fr') {
    return (
      <GDPR_FR />
    )
  }

  return <GDPR_EN />
}
