import {
  combine,
  attach,
  forward,
  guard,
  sample,
  createDomain,
  Domain,
  Effect,
  Store,
} from 'effector'
import { PaginationParams, PaginationResult } from '@/dal'

interface ConfigWithParams<Params, Result> {
  domain?: Domain;
  effect: Effect<PaginationParams & Params, PaginationResult<Result>, Error>;
  params: Store<Params>;
  perPage: number;
  // min value is 1
  initPage?: number;
}
interface ConfigWithoutParams<Params, Result> {
  domain?: Domain;
  effect: Effect<PaginationParams, PaginationResult<Result>, Error>;
  params?: undefined;
  perPage: number;
  // min value is 1
  initPage?: number;
}

export type Config<P, R> = ConfigWithoutParams<P, R> | ConfigWithParams<P, R>

export const createPagination = <P, R>(config: Config<P, R>) => {
  const {
    domain,
    effect,
    params: $params,
    perPage,
    initPage = 1,
  } = config

  const d = domain || createDomain('paginationDomain')

  const $count = d.store<number>(0)
  const $currentPage = d.store(initPage)

  const $offset = $currentPage.map((currentPage) => (currentPage - 1) * perPage)
  const $pagesCount = $count.map((count) => Math.ceil(count / perPage))
  const $hasNext = combine($count, $currentPage, (count, page) => count > page * perPage)

  const setCurrentPage = d.event<number>()
  const nextPage = d.event<void>()
  const prevPage = d.event<void>()
  const setCount = d.event<number>()
  const reset = d.event<void>()

  const fetchDataFx = $params
    ? attach({
      effect,
      source: combine({ offset: $offset, params: $params }),
      mapParams: (arg: void, { offset, params }) => ({
        offset,
        limit: perPage,
        ...params,
      }),
    })
    : attach({
      effect,
      source: $offset,
      mapParams: (arg: void, offset) => ({
        offset,
        limit: perPage,
      }),
    })

  $currentPage
    .on(
      guard({
        source: sample($pagesCount, setCurrentPage, (count, page) => ({ count, page })),
        filter: ({ count, page }) => (page > 0 && page <= count),
      }),
      (_, { page }) => page,
    )
    .reset(reset)

  forward({
    from: sample($currentPage, nextPage, (currentPage) => currentPage + 1),
    to: setCurrentPage,
  })

  forward({
    from: sample($currentPage, prevPage, (currentPage) => currentPage - 1),
    to: setCurrentPage,
  })

  forward({
    from: fetchDataFx.doneData.map(({ count }) => count),
    to: setCount,
  })

  $count
    .on(setCount, (_, count) => count)
    .reset(reset)

  return {
    $count,
    $currentPage,
    $pagesCount,
    $hasNext,
    nextPage,
    prevPage,
    setCurrentPage,
    setCount,
    fetchDataFx,
    resetPagination: reset,
  }
}
