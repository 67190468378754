import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  Select,
  SliderField,
  RadioButtonsList,
  SubTitle,
  AccentText,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import { personalFileCompleteStep, setPersonFileStep } from '../../../model'

export const Location = () => {
  const [locationType, setLocationType] = React.useState<string>('')
  const [located, setLocated] = React.useState<boolean>(true)
  const [locationAttribute, setLocationAttribute] = React.useState<string>('')
  const [presenceRate, setPresenceRate] = React.useState<number>(45)
  const [location, setLocation] = React.useState<string>('')

  const locationTypeItems = getMock().selects.newEmployee.locationType
  const locationAttributeItems = getMock().selects.newEmployee.locationAttribute
  const locationItems = getMock().selects.newEmployee.location
  const locatedItems = getMock().selects.newEmployee.located

  const confirmFormAction = () => {
    personalFileCompleteStep('location')
    setPersonFileStep('contact')
  }

  return (
    <Wrap>
      <Row>
        <Col xs="100%" md={3}>
          <img src="/assets/img/map.jpg" alt="map" />
        </Col>
        <Col xs="100%" md={2}>
          <Row>
            <Col xs="100%" align="flex-start" noMargin noPadding>
              <>
                <SubTitle>
                  1. type of location
                </SubTitle>
                <Select
                  name="type of location"
                  value={locationType}
                  items={locationTypeItems}
                  onChange={setLocationType}
                />
              </>
            </Col>
            <Col xs="100%" align="flex-start" noPadding>
              <>
                <SubTitle>
                  2. located
                </SubTitle>
                <RadioButtonsList
                  name="located"
                  value={located}
                  items={locatedItems}
                  onChange={setLocated}
                />
              </>
            </Col>
            <Col xs="100%" align="flex-start" noPadding>
              <>
                <SubTitle>
                  3. location attribute
                  <AccentText>*</AccentText>
                </SubTitle>
                <Select
                  name="location attribute"
                  value={locationAttribute}
                  items={locationAttributeItems}
                  onChange={setLocationAttribute}
                />
              </>
            </Col>
            <Col xs="100%" align="flex-start" noPadding>
              <>
                <SubTitle>
                  4. presence rate
                  <AccentText>*</AccentText>
                </SubTitle>
                <SliderField
                  min={0}
                  max={100}
                  value={presenceRate}
                  onChange={setPresenceRate}
                />
              </>
            </Col>
            <Col xs="100%" align="flex-start" noPadding>
              <>
                <SubTitle>
                  5. location
                  <AccentText>*</AccentText>
                </SubTitle>
                <Select
                  name="location"
                  value={location}
                  items={locationItems}
                  onChange={setLocation}
                />
              </>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="100%" md="150px">
          <PrimaryButton
            fluid
            outline
            type="gray"
            prependIcon="leftChevron"
            onClick={() => setPersonFileStep('person')}
          >
            back
          </PrimaryButton>
        </Col>
        <Col xs="100%" md="150px">
          <PrimaryButton
            fluid
            appendIcon="rightChevron"
            onClick={() => confirmFormAction()}
          >
            next step
          </PrimaryButton>
        </Col>
      </Row>
    </Wrap>
  )
}

const Wrap = styled.div`
  width:100%;
  padding: 20px 40px 20px 18px;
  ${breakpoint('mobile', 'tablet')`
      padding: 20px 40px;
  `}
`
