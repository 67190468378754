import {
  createDomain,
  forward,
  sample,
  attach,
  Effect,
  Domain,
  Store,
  Event,
} from 'effector'
import { debounce } from 'patronum'
import { useStore } from 'effector-react'

import {
  PaginationParams, PaginationResult, WithFilters, WithSorting,
} from '@/dal'

import { createFilters, FiltersConfig, ResultData } from './effector-filters'

interface Config<T, Result> {
  domain?: Domain;
  effect: Effect<
    (PaginationParams & WithFilters),
    PaginationResult<Result>,
    Error>;
  initValue: T;
  fields: string[];
}

interface AutocompleteResult<T, Item> {
  $value: Store<Item>;
  $searchValue: Store<T>;
  $items: Store<Item[]>;
  effect: Effect<ResultData, PaginationResult<Item>, Error>;
  onSearchChange: Event<T>;
  onChange: Event<Item | null>;
  reset: Event<void>;
}

export const createAutocomplete = <T, Item>(config: Config<T, Item>) => {
  const {
    domain, fields, initValue, effect,
  } = config

  const d = domain || createDomain('paginationDomain')

  const $value = d.store<Item | null>(null)
  const $searchValue = d.store<T>(initValue)
  const $items = d.store<Item[]>([])

  const onSearchChange = d.event<T>()
  const onChange = d.event<Item | null>()
  const reset = d.event<void>()

  const effectWithParams = attach({
    effect,
    mapParams: (x: ResultData) => ({ ...x, limit: 10, offset: 0 }),
  })

  const initFilters: FiltersConfig<T> = {}
  fields.forEach((f) => {
    initFilters[f] = {
      include: true,
      value: initValue,
    }
  })
  const filters = createFilters({
    domain: d,
    filters: initFilters,
  })

  $value
    .on(onChange, (_, x) => x)

  $searchValue
    .on(onSearchChange, (_, x) => x)
    .reset(reset)

  $items
    .on(effectWithParams.doneData, (_, { items }) => items)

  for (const f in filters.filters) {
    if (filters.filters.hasOwnProperty(f)) {
      forward({
        from: onSearchChange,
        to: filters.filters[f].setValue,
      })
    }
  }

  forward({
    from: reset,
    to: filters.resetFilters,
  })

  forward({
    from: debounce({
      source: sample(filters.$result, onSearchChange),
      timeout: 400,
    }),
    to: effectWithParams,
  })

  return {
    $value,
    $searchValue,
    $items,
    effect: effectWithParams,
    onSearchChange,
    onChange,
    reset,
  } as AutocompleteResult<T, Item>
}

export const useAutocomplete = <T, Item>(result: AutocompleteResult<T, Item>) => {
  const {
    $value, $items, $searchValue, effect, onSearchChange, reset, onChange,
  } = result

  const value = useStore($value)
  const searchValue = useStore($searchValue)
  const items = useStore($items)

  return {
    value,
    searchValue,
    items,
    effect,
    onSearchChange,
    onChange,
    reset,
  }
}
