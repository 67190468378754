import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  InputField,
  RadioButtonsList,
  SubTitle,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import { personalFileCompleteStep, setPersonFileStep, showPersonFileCreatedModal } from '../../../model'

export const Synchronisation = () => {
  const [externalGuid, setExternalGuid] = React.useState<string>('')
  const [synchronised, setSynchronised] = React.useState<boolean>(true)

  const yesNoItems = getMock().selects.newEmployee.located

  const completeForm = () => {
    showPersonFileCreatedModal()
    personalFileCompleteStep('synchronisation')
  }

  return (
    <Wrap>
      <>
        <Row>
          <Col xs="100%" md={3} align="flex-start">
            <>
              <SubTitle>
                1. external GUID
              </SubTitle>
              <InputField
                value={externalGuid}
                onChangeValue={setExternalGuid}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                2. synchronised
              </SubTitle>
              <RadioButtonsList
                name="synchronised"
                items={yesNoItems}
                value={synchronised}
                onChange={setSynchronised}
              />
            </>
          </Col>

        </Row>

        <Row>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => setPersonFileStep('details')}
            >
              back
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="250px">
            <PrimaryButton
              fluid
              outline
              type="warning"
              appendIcon="close"
            >
              discard changes
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              appendIcon="rightChevron"
              onClick={() => completeForm()}
            >
              validate
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}


const Wrap = styled.div`
    padding: 20px 40px 20px 18px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 40px;
    `}
`
