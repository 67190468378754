import { forward, sample, guard } from 'effector'

import { Site } from '@/dal'

import { reloadSitesList } from '../list/unit'
import {
  hideModal,
  showModal,
  newSurface,
  $isShowModal,
  $selectedSiteId,
  createSurfaceFx,
  attachSurfaceToSiteFx,
} from './unit'

$isShowModal
  .on(showModal, () => true)
  .reset(hideModal)
  .reset(attachSurfaceToSiteFx.done)

$selectedSiteId
  .on(showModal, (_, x) => x)
  .reset(hideModal)

forward({
  from: newSurface.formValidated.map((x) => ({ ...x, localities: [] })),
  to: createSurfaceFx,
})

// attach surface to site
sample({
  source: $selectedSiteId,
  clock: guard({
    source: createSurfaceFx.doneData,
    filter: $selectedSiteId.map((x) => !!x),
  }),
  fn: (siteId, data) => ({
    id: siteId as Site['id'],
    itemsID: [data.id],
  }),
  target: attachSurfaceToSiteFx,
})

forward({
  from: attachSurfaceToSiteFx.done,
  to: reloadSitesList,
})

forward({
  from: hideModal,
  to: newSurface.reset,
})
