import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { showConfirmModal } from '@/features/app/hooks'
import { CreateContractDocumentRes } from '@/dal/contracts.interfaces'
import {
  DataTable, TableRow, SubTitle, Action, Col, AccentText, Link, Visible,
} from '@/ui'
import { dateSlash } from '@/lib/dates'
import { deleteItem, $list } from '../../model/document'

export const DocumentList = () => {
  const { t } = useTranslation('tenant/main')
  const documentList = useStore($list)
  const dataTableTemplate = [
    {
      name: 'name',
      renderHeader: (
        <SubTitle>
          {t('documentName')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'version',
      renderHeader: (
        <SubTitle>
          <AccentText>
            {t('version')}
          </AccentText>
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'validityEndDate',
      renderHeader: (
        <SubTitle>
          {t('validityEndDate')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'documentType',
      renderHeader: (
        <SubTitle>
          {t('documentType')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'content',
      renderHeader: (
        <SubTitle>
          <AccentText>
            {t('content')}
          </AccentText>
        </SubTitle>
      ),
      width: 1,
    },
  ]
  const dataTableActions: Action<CreateContractDocumentRes>[] = [
    {
      color: 'warning',
      icon: 'trash',
      action: (i) => {
        showConfirmModal({
          title: t('deleteDocumentTile'),
          text: t('deleteDocumentText'),
          action: () => {
            deleteItem(i)
          },
        })
      },
    },
  ]
  return (
    <DataTable
      noMarginTop
      columns={dataTableTemplate}
      actions={dataTableActions}
      extractKey={(item) => `${item.id}cadastralReference`}
      data={documentList}
    >
      {(item) => (
        <TableRow smallText oversizeRow>
          <Visible tablet>
            <Col width={1}>
              {item.name}
            </Col>
            <Col width={1}>
              {item.version}
            </Col>

            <Col width={1}>
              {item.validityEndDate ? dateSlash(item.validityEndDate) : ''}
            </Col>
            <Col width={1}>
              {item.type}
            </Col>
            <Col width={1}>
              <Link href={item.content.link}>
                {t('vievDocument')}
              </Link>
            </Col>
          </Visible>
          <Visible tablet>
            <Col width="50%" name={t('name')} align="flex-start">
              {item.name}
            </Col>
            <Col width="50%" name={t('version')} align="flex-start">
              {item.version}
            </Col>

            <Col width="50%" name={t('validityEndDate')} align="flex-start">
              {item.validityEndDate ? dateSlash(item.validityEndDate) : ''}
            </Col>
            <Col width="50%" name={t('documentType')} align="flex-start">
              {item.type}
            </Col>
            <Col width="50%" name={t('content')} align="flex-start">
              <Link href={item.content.link}>
                {t('vievDocument')}
              </Link>
            </Col>
          </Visible>
        </TableRow>
      )}
    </DataTable>
  )
}
