import {
  forward, sample,
} from 'effector'

import { Profile } from '@/dal'
import {
  getUserSettingsFx,
  updateUserSettingsReqFx,
  userSettingsForm,
  $userSettings,
  $titleList,
  filters,
  getTitleListFx,
  saveUserSettings,
} from './units'

$userSettings
  .on(getUserSettingsFx.doneData, (_, x) => x)
  .on(updateUserSettingsReqFx.doneData, (_, x) => x)

$titleList
  .on(getTitleListFx.doneData, (_, x) => x.items)


forward({
  from: getUserSettingsFx.doneData.map((x) => ({ ...x, title: x.title?.id })),
  to: userSettingsForm.setForm,
})

forward({
  from: [
    getUserSettingsFx.doneData.map(({ language }) => language || 'fr'),
    userSettingsForm.fields.language.onChange,
  ],
  to: filters.filters.language.setValue,
})

forward({
  from: userSettingsForm.fields.language.onChange,
  to: userSettingsForm.fields.title.resetValue,
})

forward({
  from: filters.$result,
  to: getTitleListFx,
})

forward({
  from: saveUserSettings,
  to: userSettingsForm.validate,
})

sample({
  source: [userSettingsForm.$values, $userSettings],
  clock: userSettingsForm.formValidated,
  fn: ([{ id, photo, ...form }, profile]) => ({
    id: (profile as Profile).id,
    data: {
      ...form,
      title: form.title || undefined,
    },
  }),
  target: updateUserSettingsReqFx,
})
