import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { rules } from '@/lib/validators'
import {
  createSurfaceReqFx,
  attachSurfaceToSiteReqFx,
  Site,
} from '@/dal'

export const d = createDomain('d')

export const $isShowModal = d.store(false)
export const $selectedSiteId = d.store<Site['id'] | null>(null)

export const showModal = d.event<Site['id']>()
export const hideModal = d.event()

export const newSurface = createForm({
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    description: {
      init: '',
    },
    assigment: {
      init: '' as Site['surfaces'][0]['assigment'],
    },
    surface: {
      init: '',
    },
    perimetr: {
      init: '',
    },
  },
})

export const createSurfaceFx = attach({ effect: createSurfaceReqFx })
export const attachSurfaceToSiteFx = attach({ effect: attachSurfaceToSiteReqFx })
