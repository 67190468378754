import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateContractSignatureRes } from '@/dal/contracts.interfaces'
import {
  createContractSignatureReqFx,
  deleteContractSignatureReqFx,
  attachContractSignatureToServiceProviderContractReqFx,
  detachContractSignatureToServiceProviderContractReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateContractSignatureRes[]>([])

export const loadList = d.createEvent<CreateContractSignatureRes[]>()
export const addItem = d.createEvent<CreateContractSignatureRes>()
export const deleteItem = d.createEvent<CreateContractSignatureRes>()
export const dropItem = d.createEvent<CreateContractSignatureRes['id']>()

export const SignatoryForm = createForm({
  domain: d,
  fields: {
    signatory: {
      init: '',
      rules: [rules.required()],
    },
    remarks: {
      init: '',
    },
  },
})

export const createItemFx = attach({ effect: createContractSignatureReqFx })
export const deleteItemFx = attach({ effect: deleteContractSignatureReqFx })
export const attachItemFx = attach({ effect: attachContractSignatureToServiceProviderContractReqFx })
export const detachItemFx = attach({ effect: detachContractSignatureToServiceProviderContractReqFx })
