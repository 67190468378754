import { forward, sample, split } from 'effector'
import { condition } from 'patronum'

import {
  CreateServiceProviderContractParams,
  EditServiceProviderContractParams,
  ServiceProviderContract,
} from '@/dal/contracts.interfaces'

import {
  GeneralForm,
  createServiceProviderFx,
  createContract,
  updateContract,
  counterpartyAutocomplete,
  editServiceProviderFx,
} from './unit'
import {
  getServiceProviderFx, $serviceProviderContract, setTab, setContract,
} from '../main'

GeneralForm.fields.indexingType.$value.watch((val) => {
  switch (val) {
    case 'no-indexation':
      GeneralForm.fields.indexingReferenceIndexValue.reset()
      GeneralForm.fields.indexingReferenceIndex.reset()
      break
    default:
      break
  }
})

forward({
  from: getServiceProviderFx.doneData.map(({ serviceProviderContract: x, counterpartyWithContact }) => ({
    ...x,
    conterparty: counterpartyWithContact.conterparty?.id || '',
    termsYears: `${x.termsYears || ''}`,
    termsMonths: `${x.termsMonths || ''}`,
    terminationNoticePeriodMonths: `${x.terminationNoticePeriodMonths || ''}`,
    terminationNoticePeriodYears: `${x.terminationNoticePeriodYears || ''}`,
    indexingReferenceIndex: x.indexingReferenceIndex || null,
    indexingReferenceIndexValue: '',
  })),
  to: GeneralForm.set,
})

forward({
  from: getServiceProviderFx.fail,
  to: GeneralForm.reset,
})

condition({
  source: GeneralForm.formValidated,
  if: $serviceProviderContract.map((x) => x === null),
  then: createContract,
  else: updateContract,
})

// counterparty autocomplete
getServiceProviderFx.doneData.watch((x) => {
  counterpartyAutocomplete.onChange(x.counterpartyWithContact)
})

forward({
  from: counterpartyAutocomplete.onChange.map((x) => (x ? x.conterparty.id : '')),
  to: GeneralForm.fields.conterparty.onChange,
})


sample({
  source: GeneralForm.$values,
  clock: createContract,
  target: createServiceProviderFx,
  fn: (form): CreateServiceProviderContractParams => {
    const resultData: EditServiceProviderContractParams['data'] = {
      name: form.name,
      conterparty: form.conterparty,
      code: form.code,
      description: form.description,
      active: form.active,
      type: form.type || undefined,
      effectiveDate: form.effectiveDate,
      dateOfSigning: form.dateOfSigning,
      tacitRenewal: form.tacitRenewal,
      termsYears: +form.termsYears || undefined,
      termsMonths: +form.termsMonths || undefined,
      terminationNoticePeriodMonths: +form.terminationNoticePeriodMonths || undefined,
      terminationNoticePeriodYears: +form.terminationNoticePeriodYears || undefined,
      grouping: form.grouping || undefined,
      terminationDeadline: form.terminationDeadline,
      expirationDate: form.expirationDate,
      indexingType: form.indexingType,
      indexingReviewType: form.indexingReviewType || undefined,
      indexingFirstAnniversaryDate: form.indexingFirstAnniversaryDate,
      indexingInvoicingInterval: form.indexingInvoicingInterval,
      indexingReferenceIndex: undefined,
      indexingReferenceIndexValue: undefined,
    }
    if (resultData.indexingType === 'official') {
      resultData.indexingReferenceIndex = form.indexingReferenceIndex || undefined
      resultData.indexingReferenceIndexValue = undefined
    }
    return { ...resultData }
  },
})

sample({
  source: [GeneralForm.$values, $serviceProviderContract],
  clock: updateContract,
  target: editServiceProviderFx,
  fn: ([form, contract]): EditServiceProviderContractParams => {
    const resultData: EditServiceProviderContractParams['data'] = {
      name: form.name,
      conterparty: form.conterparty,
      code: form.code,
      description: form.description,
      active: form.active,
      type: form.type || undefined,
      effectiveDate: form.effectiveDate,
      dateOfSigning: form.dateOfSigning,
      tacitRenewal: form.tacitRenewal,
      termsYears: +form.termsYears || undefined,
      termsMonths: +form.termsMonths || undefined,
      terminationNoticePeriodMonths: +form.terminationNoticePeriodMonths || undefined,
      terminationNoticePeriodYears: +form.terminationNoticePeriodYears || undefined,
      grouping: form.grouping || undefined,
      terminationDeadline: form.terminationDeadline,
      expirationDate: form.expirationDate,
      indexingType: form.indexingType,
      indexingReviewType: form.indexingReviewType || undefined,
      indexingFirstAnniversaryDate: form.indexingFirstAnniversaryDate,
      indexingInvoicingInterval: form.indexingInvoicingInterval,
      indexingReferenceIndex: undefined,
      indexingReferenceIndexValue: undefined,
    }
    if (form.indexingType === 'official') {
      resultData.indexingReferenceIndex = form.indexingReferenceIndex || undefined
      resultData.indexingReferenceIndexValue = undefined
    }
    return { id: (contract as ServiceProviderContract).id, data: resultData }
  },
})

forward({
  from: [
    editServiceProviderFx.done.map((_) => 'documents'),
    createServiceProviderFx.done.map((_) => 'documents'),
  ],
  to: setTab,
})

forward({
  from: createServiceProviderFx.doneData,
  to: setContract,
})
