import { order } from './domain'

export const showCreateOrderDialog = order.event()
export const hideCreateOrderDialog = order.event()
export const createOrder = order.event()
export const hideOrderCreatedModal = order.event()
export const showOrderInfoDialog = order.event()
export const hideOrderInfoDialog = order.event()
export const showOrderValidateDialog = order.event()
export const hideOrderValidateDialog = order.event()
export const validateOrder = order.event()
export const hideValidatedOrderDialog = order.event()
