
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import {
  AccountType,
  SiteAssignment,
  SiteType,
  SurfaceAssignment,
  LocalityAssignment,
  LocalityType,
  LocalityAvailability,
  LocalityLoad,

} from '@/dal'
import { $accountInfo } from '@/features/app/model'

type SelectItems<value> = {text: string; value: value}[]


export const useSiteAssigmentList = (): SelectItems<SiteAssignment> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'apartment', text: t('apartment') },
      { value: 'garage', text: t('garage') },
      { value: 'untapped', text: t('untapped') },
      { value: 'rental', text: t('rental') },
      { value: 'parking', text: t('parking') },

    ]
  }
  return [
    { value: 'agency', text: t('agency') },
    { value: 'trade-agency', text: t('trade-agency') },
    { value: 'multi-purpose', text: t('multi-purpose') },
    { value: 'administrative-site', text: t('administrative-site') },
    { value: 'garage', text: t('garage') },
    { value: 'untapped', text: t('untapped') },
    { value: 'rental', text: t('rental') },
    { value: 'parking', text: t('parking') },
  ]
}

export const useSiteTypeList = (): SelectItems<SiteType> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'business', text: t('business') },
      { value: 'housing-complex', text: t('housing-complex') },
      { value: 'building', text: t('building') },
      { value: 'private-house', text: t('private-house') },
      { value: 'mansion', text: t('mansion') },
    ]
  }
  return [
    { value: 'business', text: t('business') },
    { value: 'housing-complex', text: t('housing-complex') },
    { value: 'building', text: t('building') },
    { value: 'hotel', text: t('hotel') },
    { value: 'public-building', text: t('public-building') },
    { value: 'high-rise-building', text: t('high-rise-building') },
  ]
}

export const useSurfaceAssignmentList = (): SelectItems<SurfaceAssignment> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'apartment', text: t('apartment') },
      { value: 'business', text: t('business') },
      { value: 'land', text: t('land') },
    ]
  }
  return [
    { value: 'offices', text: t('offices') },
    { value: 'apartment', text: t('apartment') },
    { value: 'business', text: t('business') },
    { value: 'land', text: t('land') },
  ]
}

export const useLocalityAssignmentList = (): SelectItems<LocalityAssignment> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'lift', text: t('lift') },
      { value: 'balcony', text: t('balcony') },
      { value: 'horizontal-circulation', text: t('horizontal-circulation') },
      { value: 'stairs', text: t('stairs') },
      { value: 'private-room', text: t('private-room') },
      { value: 'technical-room', text: t('technical-room') },
    ]
  }
  return [
    { value: 'lift', text: t('lift') },
    { value: 'balcony', text: t('balcony') },
    { value: 'horizontal-circulation', text: t('horizontal-circulation') },
    { value: 'stairs', text: t('stairs') },
    { value: 'private-room', text: t('private-room') },
    { value: 'technical-room', text: t('technical-room') },
  ]
}

export const useLocalityTypeList = (): SelectItems<LocalityType> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'studio', text: t('studio') },
      { value: 'balcony', text: t('balcony') },
      { value: 'laundry', text: t('laundry') },
      { value: 'cellar', text: t('cellar') },
      { value: 'bedroom', text: t('bedroom') },
      { value: 'corridor', text: t('corridor') },
      { value: 'kitchen', text: t('kitchen') },
      { value: 'stairs', text: t('stairs') },
      { value: 'ventilation-shaft', text: t('ventilation-shaft') },
      { value: 'technical-shaft', text: t('technical-shaft') },
      { value: 'push-chair-storage', text: t('push-chair-storage') },
      { value: 'bike-room', text: t('bike-room') },
      { value: 'garage', text: t('garage') },
      { value: 'entrance-hall', text: t('entrance-hall') },
      { value: 'kitchenette', text: t('kitchenette') },
      { value: 'launderette', text: t('launderette') },
      { value: 'root', text: t('root') },
      { value: 'garbage-room', text: t('garbage-room') },
      { value: 'technical-area', text: t('technical-area') },
      { value: 'caretakers-lodge', text: t('caretakers-lodge') },
      { value: 'official-residence', text: t('official-residence') },
      { value: 'lift-machinery', text: t('lift-machinery') },
      { value: 'media-library', text: t('media-library') },
      { value: 'parking', text: t('parking') },
      { value: 'indoor-swimming-pool', text: t('indoor-swimming-pool') },
      { value: 'outdoor-swimming-pool', text: t('outdoor-swimming-pool') },
      { value: 'integral-cupboard', text: t('integral-cupboard') },
      { value: 'porch', text: t('porch') },
      { value: 'store-room', text: t('store-room') },
      { value: 'dinning-room', text: t('dinning-room') },
      { value: 'bathroom', text: t('bathroom') },
      { value: 'multi-purpose-room', text: t('multi-purpose-room') },
      { value: 'living-room', text: t('living-room') },
      { value: 'basement', text: t('basement') },
      { value: 'terrace', text: t('terrace') },
      { value: 'toilets', text: t('toilets') },
      { value: 'roof', text: t('roof') },
      { value: 'veranda', text: t('veranda') },
    ]
  }
  return [
    { value: 'reception', text: t('reception') },
    { value: 'records', text: t('records') },
    { value: 'studio', text: t('studio') },
    { value: 'balcony', text: t('balcony') },
    { value: 'office', text: t('office') },
    { value: 'corridor', text: t('corridor') },
    { value: 'kitchen', text: t('kitchen') },
    { value: 'stairs', text: t('stairs') },
    { value: 'launderette', text: t('launderette') },
    { value: 'room', text: t('room') },
    { value: 'parking', text: t('parking') },
    { value: 'porch', text: t('porch') },
    { value: 'sanitary', text: t('sanitary') },
    { value: 'basement', text: t('basement') },
    { value: 'storage', text: t('storage') },
    { value: 'terrace', text: t('terrace') },
    { value: 'toilets', text: t('toilets') },
    { value: 'roof', text: t('roof') },
    { value: 'veranda', text: t('veranda') },
    { value: 'cloakroom', text: t('cloakroom') },
    { value: 'ventilation-shaft', text: t('ventilation-shaft') },
    { value: 'technical-shaft', text: t('technical-shaft') },
    { value: 'living-room', text: t('living-room') },
    { value: 'multi-purpose-room', text: t('multi-purpose-room') },
    { value: 'it-room', text: t('it-room') },
    { value: 'rest-room', text: t('rest-room') },
    { value: 'sports-room', text: t('sports-room') },
    { value: 'meeting-room', text: t('meeting-room') },
    { value: 'conference-room', text: t('conference-room') },
    { value: 'waiting-room', text: t('waiting-room') },
    { value: 'store-room', text: t('store-room') },
    { value: 'integral-cupboard', text: t('integral-cupboard') },
    { value: 'lift-machinery', text: t('lift-machinery') },
    { value: 'company-housing', text: t('company-housing') },
    { value: 'caretakers-lodge', text: t('caretakers-lodge') },
    { value: 'bike-room', text: t('bike-room') },
    { value: 'push-chair-storage', text: t('push-chair-storage') },
    { value: 'technical-area', text: t('technical-area') },
    { value: 'garbage-room', text: t('garbage-room') },
    { value: 'staff-room', text: t('staff-room') },
    { value: 'commercial-premises', text: t('commercial-premises') },
    { value: 'entrance-hall', text: t('entrance-hall') },
  ]
}

export const useLocalityAvailabilityList = (): SelectItems<LocalityAvailability> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'work-in-progress', text: t('work-in-progress') },
      { value: 'free', text: t('free') },
      { value: 'occupied', text: t('occupied') },
      { value: 'booked', text: t('booked') },
    ]
  }
  return [
    { value: 'work-in-progress', text: t('work-in-progress') },
    { value: 'free', text: t('free') },
    { value: 'occupied', text: t('occupied') },
    { value: 'booked', text: t('booked') },
  ]
}
export const useLocalityLoadList = (): SelectItems<LocalityLoad> => {
  const { t } = useTranslation('site/select-items')
  const accountType: AccountType = useStore($accountInfo)?.type || 'ME'
  if (accountType === 'PPF') {
    return [
      { value: 'common-areas', text: t('common-areas') },
      { value: 'private-common-areas', text: t('private-common-areas') },
      { value: 'private-areas', text: t('private-areas') },
    ]
  }
  return [
    { value: 'common-areas', text: t('common-areas') },
    { value: 'private-common-areas', text: t('private-common-areas') },
    { value: 'private-areas', text: t('private-areas') },
  ]
}
