import { createDomain, attach } from 'effector'
import { fetchLocalCounterpartyContactsListReqFx } from '@/dal'
import { GetAllCounterpartyContact } from '@/dal/counterparty.interfaces'
import { createPagination } from '@/lib/effector-pagination'
import { createFilters } from '@/lib/effector-filters'

export const siteContactsDomain = createDomain()

export const addContactsToList = siteContactsDomain.event<GetAllCounterpartyContact[]>()
export const addContactsToSearchList = siteContactsDomain.event<GetAllCounterpartyContact[]>()
export const updateSearchValue = siteContactsDomain.event<string>()
export const setCurrentSearchValue = siteContactsDomain.event<string>()
export const setSearchActive = siteContactsDomain.event<boolean>()
export const startSearch = siteContactsDomain.event<void>()
export const endSearch = siteContactsDomain.event<void>()
export const updateFilters = siteContactsDomain.event<void>()
export const reset = siteContactsDomain.event<void>()
export const selectContact = siteContactsDomain.event<GetAllCounterpartyContact>()
export const showContactDetailsModal = siteContactsDomain.event<void>()
export const hideContactDetailsModal = siteContactsDomain.event<void>()
export const mounted = siteContactsDomain.event<void>()

siteContactsDomain.onCreateStore(($store) => {
  $store.reset(reset)
})

export const $contactsList = siteContactsDomain.store<GetAllCounterpartyContact[]>([])
export const $searchContactsList = siteContactsDomain.store<GetAllCounterpartyContact[]>([])
export const $searchValue = siteContactsDomain.store('')
export const $currentSearchValue = siteContactsDomain.store('')
export const $isSearchActive = siteContactsDomain.store(false)
export const $selectedContact = siteContactsDomain.store<GetAllCounterpartyContact | null>(null)
export const $showContactDetailsModal = siteContactsDomain.store(false)

export const filters = createFilters({
  domain: siteContactsDomain,
  filters: {
    elemID: {
      include: true,
      value: '',
    },
  },
})

export const searchFilters = createFilters({
  domain: siteContactsDomain,
  filters: {
    /* elemID: {
      include: true,
      value: '',
    }, */
    /* 'contact.email': {
      include: true,
      value: '',
    }, */
    'contact.firstName': {
      include: true,
      value: '',
    },
    'contact.lastName': {
      include: true,
      value: '',
    },
  },
})

export const contactsPagination = createPagination({
  domain: siteContactsDomain,
  effect: fetchLocalCounterpartyContactsListReqFx,
  params: filters.$result.map((x) => ({
    ...x,
    sortBy: 'firstName' as string,
    orderByASC: false,
  })),
  perPage: 10,
})

export const searchPagination = createPagination({
  domain: siteContactsDomain,
  effect: fetchLocalCounterpartyContactsListReqFx,
  params: searchFilters.$result.map((x) => ({
    ...x,
    sortBy: 'firstName' as string,
    orderByASC: false,
  })),
  perPage: 10,
})
