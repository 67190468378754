import * as React from 'react'
import { useField } from 'effector-forms'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

import { useAutocomplete } from '@/lib/effector-autocomplete'
import {
  InputField,
  SubTitle,
  Select,
  InputTextArea,
  RadioButtonsList,
  AccentText, Autocomplete,
} from '@/ui'
import {
  TenantLeaseType,
  TenantLeaseGroup,
} from '@/dal/contracts.interfaces.ts'

import { CounterpartyWithAllContacts } from '@/dal'
import { GeneralForm, counterpartyAutocomplete } from '../../../model/general'

export const MainForm = () => {
  const { t } = useTranslation('tenant/main')
  const counterpartyAuto = useAutocomplete(counterpartyAutocomplete)

  const name = useField(GeneralForm.fields.name)
  const description = useField(GeneralForm.fields.description)
  const active = useField(GeneralForm.fields.active)
  const type = useField(GeneralForm.fields.type)
  const code = useField(GeneralForm.fields.code)
  const counterparty = useField(GeneralForm.fields.conterparty)
  const variableRent = useField(GeneralForm.fields.variableRent)
  const grouping = useField(GeneralForm.fields.grouping)
  const tacitRenewal = useField(GeneralForm.fields.tacitRenewal)

  const groupItems: {text: string; value: TenantLeaseGroup | null}[] = [
    { text: t('notSelected'), value: null },
    { value: 'endorsement', text: t('endorsement') },
    { value: 'accession-agreement-endorsement', text: t('accession-agreement-endorsement') },
    { value: 'civil-lease-endorsement', text: t('civil-lease-endorsement') },
    { value: 'commercial-lease-endorsement', text: t('commercial-lease-endorsement') },
    { value: 'provision-lease-endorsement', text: t('provision-lease-endorsement') },
    { value: 'professional-lease-endorsement', text: t('professional-lease-endorsement') },
    { value: 'rental-contract-endorsement', text: t('rental-contract-endorsement') },
    { value: 'subletting-endorsement', text: t('subletting-endorsement') },
    { value: 'lease', text: t('lease') },
    { value: 'ground-lease', text: t('ground-lease') },
    { value: 'farm-lease', text: t('farm-lease') },
    { value: 'rental-lease', text: t('rental-lease') },
    { value: 'civil-lease', text: t('civil-lease') },
    { value: 'commercial-lease', text: t('commercial-lease') },
    { value: 'commercial-lease-before-completion', text: t('lease-before-completion') },
    { value: 'residential-lease', text: t('residential-lease') },
    { value: 'short-term-lease', text: t('short-term-lease') },
    { value: 'derogatory-lease', text: t('derogatory-lease') },
    { value: 'long-term-lease', text: t('long-term-lease') },
    { value: 'precarious-lease', text: t('precarious-lease') },
    { value: 'professional-lease', text: t('professional-lease') },
    { value: 'subletting-lease', text: t('subletting-lease') },
    { value: 'administrative-contract', text: t('administrative-contract') },
    { value: 'subscription-contract', text: t('subscription-contract') },
    { value: 'rental-contract', text: t('rental-contract') },
    { value: 'provision-contract', text: t('provision-contract') },
    { value: 'subletting-contract', text: t('subletting-contract') },
    { value: 'agreement', text: t('agreement') },
    { value: 'fixed-term-agreement', text: t('fixed-term-agreement') },
    { value: 'accession-agreement', text: t('accession-agreement') },
    { value: 'occupancy-agreement', text: t('occupancy-agreement') },
    { value: 'precarious-occupancy-agreement', text: t('precarious-occupancy-agreement') },
    { value: 'management-delegation-agreement', text: t('management-delegation-agreement') },
    { value: 'tenancy-agreement', text: t('tenancy-agreement') },
    { value: 'provision-agreement', text: t('provision-agreement') },
    { value: 'service-agreement', text: t('service-agreement') },
    { value: 'rental-agreement', text: t('rental-agreement') },
    { value: 'leasing', text: t('leasing') },
    { value: 'rules-of-procedure', text: t('rules-of-procedure') },
    { value: 'renewal', text: t('renewal') },
    { value: 'commercial-lease-renewal', text: t('commercial-lease-renewal') },
    { value: 'professional-lease-renewal', text: t('professional-lease-renewal') },
    { value: 'subletting-renewal', text: t('subletting-renewal') },
  ]
  const statusItems = [
    { text: t('active'), value: true },
    { text: t('inactive'), value: false },
  ]
  const typeItems: {text: string; value: TenantLeaseType}[] = [
    { text: t('lease'), value: 'lease' },
    { text: t('leasing'), value: 'leasing' },
  ]

  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]
  return (
    <MainFieldWrap>
      <GridCol gridArea="name">
        <SubTitle>
          1.
          {' '}
          {t('contractName')}
          <AccentText>*</AccentText>
        </SubTitle>
        <InputField
          value={name.value}
          onChangeValue={name.onChange}
          errorText={name.errorText()}
        />
      </GridCol>
      <GridCol gridArea="code">
        <SubTitle>
          2.
          {' '}
          {t('contractCode')}
        </SubTitle>
        <InputField
          value={code.value}
          onChangeValue={code.onChange}
        />
      </GridCol>
      <GridCol gridArea="description">
        <SubTitle>
          3.
          {' '}
          {t('description')}
        </SubTitle>
        <InputTextArea
          fluid
          maxLength={150}
          value={description.value}
          onChangeValue={description.onChange}
        />
      </GridCol>
      <GridCol gridArea="status">
        <SubTitle>
          4.
          {' '}
          {t('status')}
        </SubTitle>
        <RadioButtonsList
          name="status"
          value={active.value}
          items={statusItems}
          onChange={active.onChange}
        />
      </GridCol>
      <GridCol gridArea="type">
        <SubTitle>
          5.
          {' '}
          {t('type')}
        </SubTitle>
        <RadioButtonsList
          name="type"
          value={type.value}
          items={typeItems}
          onChange={type.onChange}
        />
      </GridCol>
      <GridCol gridArea="tacit">
        <SubTitle>
          6.
          {' '}
          {t('tacitRenewal')}
        </SubTitle>
        <RadioButtonsList
          name="status"
          value={tacitRenewal.value}
          items={YesNoItems}
          onChange={tacitRenewal.onChange}
        />
      </GridCol>
      <GridCol gridArea="rent">
        <SubTitle>
          7.
          {' '}
          {t('variableRent')}
        </SubTitle>
        <RadioButtonsList
          name="variableRent"
          value={variableRent.value}
          items={YesNoItems}
          onChange={variableRent.onChange}
        />
      </GridCol>
      <GridCol gridArea="group">
        <SubTitle>
          8.
          {' '}
          {t('contractGroup')}
        </SubTitle>
        <Select
          value={grouping.value}
          items={groupItems}
          onChange={grouping.onChange}
        />
      </GridCol>
      <GridCol gridArea="counterparty">
        <SubTitle>
          9.
          {' '}
          {t('counterparty')}
          <AccentText>*</AccentText>
        </SubTitle>
        <Autocomplete<CounterpartyWithAllContacts>
          value={counterpartyAuto.value}
          inputValue={counterpartyAuto.searchValue}
          extractLabel={(item) => item.conterparty.companyName || item.conterparty.email || `${item.localContact.contact.firstName} ${item.localContact.contact.lastName}`}
          extractKey={(item) => item.conterparty.id}
          placeholder="Choose counterparty"
          errorText={counterparty.errorText()}
          items={counterpartyAuto.items}
          onInputChange={(val) => counterpartyAuto.onSearchChange(val)}
          onChange={(val) => counterpartyAuto.onChange(val)}
        />
      </GridCol>
    </MainFieldWrap>
  )
}


const MainFieldWrap = styled.div`
  display: grid;
  width: 100%;
  padding: 0 34px;
  align-items: flex-start;
  grid-template-rows: 73px 73px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 
    "name code description"
    "status type description"
    "tacit rent group"
    "counterparty counterparty none";
  grid-gap: 20px;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 15px;
    grid-template-columns: 1fr;
    grid-template-rows: none;
    grid-template-areas: 
      "name"
      "code"
      "description"
      "status"
      "type"
      "tacit"
      "rent"
      "group"
      "counterparty";
  `}
`

const GridCol = styled.div<{ gridArea: string}>`
  grid-area: ${({ gridArea }) => gridArea};
`
