import * as React from 'react'
import styled from 'styled-components'
import {
  Col,
  InputField,
  Select,
  SubTitle,
  RadioButtonsList,
  InputDateTime,
  WrapField,
} from '@/ui'
import { getMock } from '@/features/app'

export const InternalForm = () => {
  const [person, setPerson] = React.useState<string | number | boolean | null>(null)
  const [personSearch, setPersonSearch] = React.useState<string>('')
  const [parking, setParking] = React.useState<string | number | boolean | null>(null)
  const [luggageNumber, setLuggageNumber] = React.useState<string>('')
  const [cloakroom, setCloakroom] = React.useState<string | number | boolean | null>(null)
  const [estimatedDate, setEstimatedDate] = React.useState<string | null>(new Date().toISOString())
  const [profile, setProfile] = React.useState<string | number | boolean | null>(null)
  const [contact, setContact] = React.useState<string | number | boolean | null>(null)
  const [localisation, setLocalisation] = React.useState<string | number | boolean | null>(null)
  const [mobileNumber, setMobileNumber] = React.useState<string>('')
  const [phoneNumber, setPhoneNumber] = React.useState<string>('')
  const [remarks, setRemarks] = React.useState<string | number | boolean | null>(null)
  const SelectEntityItems = (name: string) => [
    { value: 1, text: `${name} Uno` },
    { value: 2, text: `${name} Dos` },
    { value: 3, text: `${name} Tres` },
  ]

  const YesNo = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ]
  return (
    <FormWrap>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>1. person</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Search by name, email, entity etc."
            items={getMock().selects.preRegistration.person}
            value={person}
            onChange={(val) => setPerson(val)}
            activeField={(
              <InputField
                innerIcon
                appendIcon="lense"
                placeholder="Find person"
                value={personSearch}
                onChangeValue={(val) => setPersonSearch(val)}
              />
            )}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>2. parking</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <RadioButtonsList
            name="parking"
            items={YesNo}
            value={parking}
            onChange={(val) => setParking(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>3. luggage number</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="luggageNumber"
            placeholder="Enter luggage number"
            value={luggageNumber}
            onChangeValue={(val) => setLuggageNumber(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>4. cloakroom </SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <RadioButtonsList
            name="cloakroom"
            items={YesNo}
            value={cloakroom}
            onChange={(val) => setCloakroom(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>5. estimated date and time</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputDateTime
            value={estimatedDate}
            onChange={setEstimatedDate}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>6. profile</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose profile"
            items={getMock().selects.preRegistration.profile}
            value={profile}
            onChange={(val) => setProfile(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>7. contact</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose contact"
            items={getMock().selects.preRegistration.contact}
            value={contact}
            onChange={(val) => setContact(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>8. localisation</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose localisation"
            items={getMock().selects.preRegistration.localisation}
            value={localisation}
            onChange={(val) => setLocalisation(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>9. mobile number</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="mobile number"
            placeholder="+7"
            value={mobileNumber}
            onChangeValue={(val) => setMobileNumber(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>10. phone number</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="phone number"
            placeholder="+7"
            value={phoneNumber}
            onChangeValue={(val) => setPhoneNumber(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>11. remarks</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose remarks"
            items={getMock().selects.preRegistration.remarks}
            value={remarks}
            onChange={(val) => setRemarks(val)}
          />
        </WrapField>
      </Col>
    </FormWrap>
  )
}
const FormWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
