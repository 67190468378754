import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  Modal,
  PrimaryButton,
  Heading,
  Col,
  Row,
  WrapField,
  InputField,
  ErrorText,
} from '@/ui'

import { PrivacyBlock } from '../parts'
import {
  $isSignUpDialogShown,
  hideSignUpDialog,
  showAutorizationDialog,
} from '../../model'
import {
  mounted,
  reset,
  form,
  $serverError,
} from '../../model/owner-registration'

export const SignUp = () => {
  const { t } = useTranslation('autorization/translation')

  const isSignUpDialogShown = useStore($isSignUpDialogShown)
  const serverError = useStore($serverError)
  const [privacyPolicy, setPrivacyPolicy] = React.useState<boolean>(false)

  const {
    fields, submit, isValid,
  } = useForm(form)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  const onClose = () => {
    hideSignUpDialog()
    reset()
    setPrivacyPolicy(false)
  }

  React.useEffect(() => {
    mounted()
  }, [])

  return (
    <Modal
      show={isSignUpDialogShown}
      onClose={onClose}
    >
      <>
        <ContentWrap
          onSubmit={onSubmit}
        >
          <Row>
            <Col noPadding width="100%">
              <WrapField>
                <Heading>{t('signUp')}</Heading>
              </WrapField>
            </Col>
          </Row>
          <Col noPadding width="80%">
            <FormWrap>
              <Col noPadding width="100%">
                <WrapField>
                  <InputField
                    name="email"
                    placeholder={t('email')}
                    value={fields.email.value}
                    errorText={fields.email.errorText({
                      isEmail: t('invalidEmailFormat'),
                      required: t('requiredFiled'),
                    })}
                    onChangeValue={fields.email.onChange}
                  />
                </WrapField>
              </Col>
              <Col noPadding width="100%">
                <WrapField>
                  <InputField
                    type="password"
                    name="password"
                    placeholder={t('password')}
                    value={fields.password.value}
                    errorText={fields.password.errorText({
                      required: t('requiredFiled'),
                    })}
                    onChangeValue={fields.password.onChange}
                  />
                </WrapField>
              </Col>
              <Col noPadding width="100%">
                <WrapField>
                  <InputField
                    type="password"
                    name="repeat_password"
                    placeholder="Repeat password"
                    value={fields.passwordRepeat.value}
                    errorText={fields.passwordRepeat.errorText({
                      isConfirmed: t('passwordNotConfirmed'),
                      required: t('requiredFiled'),
                    })}
                    onChangeValue={fields.passwordRepeat.onChange}
                  />
                </WrapField>
              </Col>
            </FormWrap>
          </Col>
          { serverError && (
          <Col noPadding width="80%">
            <ErrorText>{t(`errors.${serverError}`)}</ErrorText>
          </Col>
          )}
          <Row>
            <Col noPadding width="80%">
              <WrapField>
                <PrimaryButton
                  fluid
                  htmlType="submit"
                  disabled={(Boolean(serverError) && serverError !== 'UnknownError') || !isValid || !privacyPolicy}
                >
                  {t('send')}
                </PrimaryButton>
              </WrapField>
            </Col>
            <Col noPadding width="80%">
              <PrimaryButton
                fluid
                type="gray"
                prependIcon="leftChevron"
                onClick={() => showAutorizationDialog()}
              >
                {t('signIn')}
              </PrimaryButton>
            </Col>
          </Row>
        </ContentWrap>
        <PrivacyBlock checked={privacyPolicy} onchange={setPrivacyPolicy} />
      </>
    </Modal>
  )
}


const ContentWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const FormWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
