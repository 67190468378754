import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import { ContractClauseType } from '@/dal/contracts.interfaces'
import {
  InputField,
  NumberInput,
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  Select,
  Title,
  RadioButtonsList,
  InputTextArea,
} from '@/ui'
import { ContractClausesForm } from '../../model/contractClauses'

export const AddContractClausesForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields, submit, reset } = useForm(ContractClausesForm)

  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]
  const clauseTypeItems: {text: string; value: ContractClauseType}[] = [
    { value: 'notarial-deed', text: t('notarial-deed') },
    { value: 'section-606', text: t('section-606') },
    { value: 'subjected-to-the-rental-income-tax', text: t('subjected-to-the-rental-income-tax') },
    { value: 'subjected-to-the-vat', text: t('subjected-to-the-vat') },
    { value: 'condo-fees', text: t('condo-fees') },
    { value: 'lease-assignment-right', text: t('lease-assignment-right') },
    { value: 'maintenance', text: t('maintenance') },
    { value: 'co-ownership-presence', text: t('co-ownership-presence') },
    { value: 'management-fee', text: t('management-fee') },
    { value: 'covenant-in-preference', text: t('covenant-in-preference') },
    { value: 'authorized-subletting', text: t('authorized-subletting') },
    { value: 'sweeping-tax', text: t('sweeping-tax') },
    { value: 'land-tax-on-companies', text: t('land-tax-on-companies') },
    { value: 'fascia-signs-and-advertising-tax', text: t('fascia-signs-and-advertising-tax') },
    { value: 'warehouse-tax', text: t('warehouse-tax') },
    { value: 'property-tax', text: t('property-tax') },
    { value: 'housing-tax', text: t('housing-tax') },
    { value: 'household-waste-tax', text: t('household-waste-tax') },
    { value: 'offices-tax', text: t('offices-tax') },
  ]

  return (
    <BlockWrap>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('clauseType')}
            <sup>*</sup>
          </SubTitle>
          <Select
            name={t('selectClauseType')}
            items={clauseTypeItems}
            value={fields.type.value}
            onChange={fields.type.onChange}
            errorText={fields.type.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('documentName')}
          </SubTitle>
          <InputField
            value={fields.name.value}
            onChangeValue={fields.name.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('page')}
          </SubTitle>
          <InputField
            value={fields.page.value}
            onChangeValue={fields.page.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('clause')}
          </SubTitle>
          <InputTextArea
            value={fields.clause.value}
            onChangeValue={fields.clause.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 2)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('description')}
          </SubTitle>
          <InputTextArea
            value={fields.description.value}
            onChangeValue={fields.description.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            6.
            {' '}
            {t('active')}
          </SubTitle>
          <RadioButtonsList
            name="active"
            items={YesNoItems}
            value={fields.active.value}
            onChange={fields.active.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('share')}
          </SubTitle>
          <NumberInput
            isFloat
            value={fields.share.value}
            onChangeValue={fields.share.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            8.
            {' '}
            {t('amount')}
          </SubTitle>
          <NumberInput
            isFloat
            value={fields.amount.value}
            onChangeValue={fields.amount.onChange}
          />
        </>
      </Col>
      <Col width="100%" noMargin noPadding>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
              onClick={reset}
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
              onClick={submit}
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
  & [class*="ErrorText"] {
    color: white !important;
  }
`
