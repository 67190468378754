import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Col,
  Row,
  themeVar,
  PhoneInput,
  SubTitle,
} from '@/ui'

interface Props {
    phones: string[];
    onChange: (val: string[]) => {};
}

export const PhoneNumbers = ({ onChange, phones }: Props) => {
  const { t } = useTranslation('company/main')
  const localPhones = [...phones]

  const changeValue = (value: string, index: number) => {
    localPhones.splice(index, 1, value)
    onChange(localPhones)
  }

  const addNewPhone = () => {
    localPhones.push('')
    onChange(localPhones)
  }

  const phoneList = localPhones.map((phone, index) => (
    <Col
      key={index}
      md="calc(100% / 3)"
      noMargin
      vAlign="flex-end"
      align="flex-start"
    >
      <>
        <SubTitle />
        <PhoneInput
          value={phone}
          placeholder={t('phoneNumber')}
          onChangeValue={(val) => changeValue(val, index)}
        />
      </>
    </Col>
  ))

  return (
    <>
      {phoneList}
      {localPhones.length < 4 && (
        <Col md="calc(100% / 3)" noMargin vAlign="flex-end" align="flex-start">
          <>
            <SubTitle />
            <AddNewText onClick={addNewPhone}>
              {t('addNewPhone')}
              {' '}
              +
            </AddNewText>
          </>
        </Col>
      )}
    </>
  )
}

const AddNewText = styled.div`
  cursor: pointer;
  color: ${themeVar('brandOrangeColor')};
  font-size: 14px;
  text-decoration: underline;
  height: 35px;
  display: flex;
  align-items: center;
`
