import * as React from 'react'
import styled from 'styled-components'
import {
  Icon, Heading, Link, themeVar, IconName,
} from '@/ui'

type Props = {
    item: {
        name: string;
        description: string;
        character: IconName;
        href: string;
    };
}

export const CategoryItem = ({ item }: Props) => (
  <Wrap href={item.href}>
    <ItemTitle>
      {item.name}
    </ItemTitle>
    <ItemLink href="#" align="center">
      ABOUT
    </ItemLink>
    <Description>
      {item.description}
    </Description>
    <IconWrap>
      <ItemIcon icon={item.character} />
    </IconWrap>
  </Wrap>
)

const Wrap = styled.a`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    background: white;
    padding: 35px 33px 0;
    max-width: 225px;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    align-content: space-between;
    top: 0px;
    transition: top .3s ease-in-out;
    color: black;
    cursor: pointer;
    &:hover, &:focus{
        top: -25px;
    }
`

const Description = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: ${themeVar('brandGrayColor')};
`

const IconWrap = styled.div`
    position: relative;
    height: 200px; 
    overflow: hidden;
    width: 100%;
`

const ItemIcon = styled(Icon)`
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 200%;
`

const ItemTitle = styled(Heading)`
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
`
const ItemLink = styled(Link)`
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 12px;
`
