import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { NavLink } from 'react-router-dom'

import { Icon, Heading } from '@/ui'
import { themeVar } from '@/ui/theming'
import {
  useUserInfo, LanguageSelector, $isGuest, $ownerRegistrationFlowFinished,
} from '@/features/app'
import { HamburgerButton } from '@/features/main-menu'

type Props = {
  title?: string;
  menu?: React.ReactChild;
}


export const HeaderLayout = ({ title, menu }: Props) => {
  const isGuest = useStore($isGuest)
  const ownerRegistrationFlowFinished = useStore($ownerRegistrationFlowFinished)
  return (
    <StyledHeaderLayout>
      <HeadWrap>
        <NavLink to="/home">
          <HeaderIcon icon="logo" />
        </NavLink>
        <Heading>
          {title}
        </Heading>
        {isGuest && (
          <LanguageSelector />
        )}
        {!isGuest && ownerRegistrationFlowFinished && (
          <div>
            <HamburgerButton />
          </div>
        )}
      </HeadWrap>
    </StyledHeaderLayout>
  )
}

const HeadWrap = styled.div`
  fill: ${themeVar('headIconColor')};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${themeVar('containerMaxWidth')};
`
const StyledHeaderLayout = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: ${themeVar('headBackground')};
  width: 100%;
  height: ${themeVar('headerHeight')};
  justify-content: center;
  align-items: center;
  padding: 13px 25px;
  z-index: 100;
`
const HeaderIcon = styled(Icon)`
  width: 24px;
  ${breakpoint('tablet')`
    width: 40px;
  `}
`
