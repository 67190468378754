import { createDomain } from 'effector'
import {
  Profile,
  User,
  Account,
  fetchMyProfileReqFx,
} from '@/dal'

const d = createDomain('user')

export const $user = d.store<User | null>(null)
export const $userInfo = d.store<Profile | null>(null)
export const $accountInfo = d.store<Account | null>(null)

export const $isAdmin = $user.map((x) => x?.role === 'owner')

export const getUserInfoFx = fetchMyProfileReqFx
