import {
  forward, merge, sample, combine,
} from 'effector'
import { condition } from 'patronum'

import { CadastralReference } from '@/dal'
import { $insuranceContract } from '../main/unit'
import {
  cadastralForm,
  $sitesList,
  $selectedObjects,
  $selectedObjectsForBackend,
  $cadastralReferencesList,
  mounted,
  loadSelectedObjectsData,
  loadTree,
  calculateTree,
  toggleSite,
  toggleSurface,
  toggleLocality,
  fetchSitesListFx,
  $sitesListWithParentLinks,
  SelectedObjects,
} from './unit'
import { walkTreeInDepth, walkTreeToRoot, prepareTreeOnFirstLoad } from './helpers'

// mounted
forward({
  from: mounted,
  to: fetchSitesListFx,
})

$sitesList
  .on(fetchSitesListFx.doneData, (_, x) => x.items)

// fill selected objects store
condition({
  source: $insuranceContract.updates,
  if: (x) => !!x,
  then: loadSelectedObjectsData,
})

forward({
  from: loadSelectedObjectsData.map((x) => ({
    sites: x.objectsSites,
    surfaces: x.objectsSurfaces,
    localities: x.objectsLocalities,
  })),
  to: [$selectedObjects, loadTree],
})

sample({
  source: combine($sitesListWithParentLinks, $selectedObjects),
  clock: merge([loadTree, $sitesListWithParentLinks.updates]),
  fn: ([sitesList, selectedObjects]) => {
    if (!sitesList.length) {
      return selectedObjects
    }
    return prepareTreeOnFirstLoad(selectedObjects, sitesList)
  },
  target: $selectedObjects,
})

$selectedObjects
  .on(toggleSite, (state, x) => {
    if (state.sites.includes(x.id)) {
      return { ...state, sites: state.sites.filter((site) => site !== x.id) }
    } return { ...state, sites: [...state.sites, x.id] }
  })
  .on(toggleSurface, (state, x) => {
    if (state.surfaces.includes(x.id)) {
      return { ...state, surfaces: state.surfaces.filter((surf) => surf !== x.id) }
    } return { ...state, surfaces: [...state.surfaces, x.id] }
  })
  .on(toggleLocality, (state, x) => {
    if (state.localities.includes(x.id)) {
      return { ...state, localities: state.localities.filter((surf) => surf !== x.id) }
    } return { ...state, localities: [...state.localities, x.id] }
  })

merge([
  toggleSite,
  toggleSurface,
  toggleLocality,
]).watch((x) => {
  calculateTree(x)
})

sample({
  source: $selectedObjects,
  clock: calculateTree,
  fn: (state, branch) => walkTreeToRoot(walkTreeInDepth(state, branch), branch),
  target: $selectedObjects,
})

sample({
  source: combine($sitesListWithParentLinks, $selectedObjects),
  clock: $selectedObjects.updates,
  fn: ([sitesList, selectedObjects]) => {
    const obj: SelectedObjects = {
      sites: [],
      surfaces: [],
      localities: [],
    }
    for (const site of sitesList) {
      if (selectedObjects.sites.includes(site.id)) {
        obj.sites.push(site.id)
        continue
      }
      for (const surface of site.children) {
        if (selectedObjects.surfaces.includes(surface.id)) {
          obj.surfaces.push(surface.id)
          continue
        }
        for (const locality of surface.children) {
          if (selectedObjects.localities.includes(locality.id)) {
            obj.localities.push(locality.id)
          }
        }
      }
    }
    return obj
  },
  target: $selectedObjectsForBackend,
})

sample({
  source: $sitesListWithParentLinks,
  clock: $selectedObjectsForBackend.updates,
  fn: (sitesList, selectedObjects) => {
    const list: CadastralReference[] = []

    // what a code!
    for (const site of sitesList) {
      if (selectedObjects.sites.includes(site.id) && site.cadastralReference && !list.includes(site.cadastralReference)) {
        list.push(site.cadastralReference)
        continue
      }
      for (const surface of site.children) {
        if (selectedObjects.surfaces.includes(surface.id) && site.cadastralReference && !list.includes(site.cadastralReference)) {
          list.push(site.cadastralReference)
          continue
        }
        for (const locality of surface.children) {
          if (selectedObjects.localities.includes(locality.id) && site.cadastralReference && !list.includes(site.cadastralReference)) {
            list.push(site.cadastralReference)
          }
        }
      }
    }
    return list
  },
  target: $cadastralReferencesList,
})

$cadastralReferencesList.watch(console.log)
