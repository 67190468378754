import * as React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'
import { breakpoint } from 'styled-components-breakpoint'

import { Site } from '@/dal'
import {
  AccentText,
  Checkbox,
  SmallText,
  Title,
} from '@/ui'

import { GridCol, StyledSubTitle } from './components'
import { SiteWithParent } from '../../../model/rentalObjects'

interface Props {
  site: SiteWithParent;
  checked: boolean;
  onClick: () => void;
}

export const SiteRow = (props: Props) => {
  const { site, checked, onClick } = props

  return (
    <SiteRowWrap>
      <GridCol gridArea="checkBox">
        <Checkbox
          contrastMode
          checked={checked}
          onChange={() => onClick()}
        />
      </GridCol>
      <GridCol gridArea="name">
        <Title>
          {site.name}
        </Title>
      </GridCol>
      <GridCol gridArea="square">
        <StyledSubTitle>
          {
            site.cadastralReference?.surfaceArea && (
              <AccentText>
                {site.cadastralReference?.surfaceArea}
                {' '}
                m
                <sup>2</sup>
              </AccentText>
            )
          }
        </StyledSubTitle>
      </GridCol>
      <GridCol gridArea="address">
        <AddressText>
          {site.address}
        </AddressText>
      </GridCol>
    </SiteRowWrap>
  )
}

const SiteRowWrap = styled.div`
  display: grid;
  width: 100%;
  grid-template: 
    "checkBox name square address" 1fr / 30px 2fr 60px 3fr;
  grid-gap: 5px;
  ${breakpoint('mobile', 'tablet')`
    grid-template: 
        "checkBox name square" 1fr
        "checkBox address address" 1fr / 30px 1fr 60px;
  `}
`

const AddressText = styled(SmallText)`
  text-align: right;
  ${breakpoint('mobile', 'tablet')`
    text-align: left;
  `}
`
