import { forward } from 'effector'

import { reloadSitesList } from '../list/unit'
import {
  deleteLocality,
  deleteLocalityFx,
  deleteSurface,
  deleteSurfaceFx,
  deleteSite,
  deleteSiteFx,
  siteCreated,
} from './unit'


forward({
  from: deleteSurface.map((x) => ({ id: x })),
  to: deleteSurfaceFx,
})

forward({
  from: deleteSurfaceFx.done,
  to: reloadSitesList,
})

forward({
  from: deleteLocality.map((x) => ({ id: x })),
  to: deleteLocalityFx,
})

forward({
  from: deleteLocalityFx.done,
  to: reloadSitesList,
})

forward({
  from: deleteSite.map((x) => ({ id: x })),
  to: deleteSiteFx,
})

forward({
  from: deleteSiteFx.done,
  to: reloadSitesList,
})

forward({
  from: siteCreated,
  to: reloadSitesList,
})
