import * as React from 'react'
import styled from 'styled-components'
import { themeVar } from '@/ui/theming'

type Props = {
    children?: React.ReactChild;
}


export const Container = ({ children }: Props) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${themeVar('containerMaxWidth')};
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  align-items: flex-start;
  align-content: flex-start;
`
