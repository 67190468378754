import {
  $showImage,
  $showModal,
  $selectedNewsArticle,
  showModal,
  hideModal,
  setNewsArticleData,
} from './units'


$showModal
  .on(showModal, () => true)
  .on(hideModal, () => false)

$showImage
  .on(showModal, (_, show) => show)
  .on(hideModal, () => false)

$selectedNewsArticle
  .on(setNewsArticleData, (_, data) => data)
