import styled, { css } from 'styled-components'
import { themeVar } from '../../theming'

type Props = {
  green?: boolean;
  warning?: boolean;
}

export const AccentText = styled.span<Props>`
  color: ${themeVar('brandTextColor')};
  ${({ green }) => green && css`
    color: ${themeVar('brandGreenColor')};
  `}
  ${({ warning }) => warning && css`
    color: ${themeVar('brandWarningColor')};
  `}
  display: inline-block;
`
