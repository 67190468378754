import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/validators'
import {
  FetchCounterpartyWithContactsListParams,
} from '@/dal'
import { fetchCounterpartyWithContactsListReqFx } from '@/dal/counterparty'
import { createTenantLeaseReqFx, editTenantLeaseReqFx } from '@/dal/contracts'
import {
  TenantLeaseIndexingType,
  TenantLeaseType,
  TenantLeaseGroup,
  ReviewType,
  ReferenceIndex,
} from '@/dal/contracts.interfaces'
import { createAutocomplete } from '@/lib/effector-autocomplete'


const d = createDomain('d')

export const createContract = d.createEvent()
export const updateContract = d.createEvent()

export const counterpartyAutocomplete = createAutocomplete({
  domain: d,
  fields: ['companyName'],
  initValue: '',
  effect: attach({
    effect: fetchCounterpartyWithContactsListReqFx,
    mapParams: (x) => ({
      ...x,
      offset: 0,
      limit: 9999,
      logicFilter: true,
      filters: [
        {
          include: true,
          key: 'localConterpartyContact',
          value: 'have',
        },
        {
          include: true,
          key: 'type',
          value: 'tenant',
        },
        ...x.filters,
      ],
    } as FetchCounterpartyWithContactsListParams),
  }),
})

export const GeneralForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
      validateOn: ['submit', 'blur'],
    },
    conterparty: {
      init: '' as string,
      rules: [rules.required()],
    },
    code: {
      init: '',
    },
    description: {
      init: '',
    },
    active: {
      init: true,
    },
    type: {
      init: 'lease' as TenantLeaseType,
    },
    tacitRenewal: {
      init: false as boolean,
    },
    grouping: {
      init: '' as TenantLeaseGroup | null,
    },
    variableRent: {
      init: false as boolean,
    },
    effectiveDate: {
      init: '',
    },
    dateOfSigning: {
      init: '',
    },
    movingInToPremisesDate: {
      init: '',
    },
    leaseDurationType: {
      init: true,
    },
    leaseDurationMonths: {
      init: '' as string,
    },
    leaseDurationYears: {
      init: '' as string,
    },
    firm: {
      init: false,
    },
    expirationDate: {
      init: '',
    },
    deadlineToDenunciationDate: {
      init: '',
    },
    indexingType: {
      init: 'no-indexation' as TenantLeaseIndexingType,
    },
    indexingReferenceIndex: {
      init: '' as ReferenceIndex | null,
    },
    indexingReviewType: {
      init: '' as ReviewType | null,
    },
    indexingReferenceIndexValue: {
      init: '',
    },
    indexingFirstAnniversaryDate: {
      init: '',
    },
    indexingInvoicingInterval: {
      init: 3 as 1 | 2 | 3 | 6 | 12,
    },
    capped: {
      init: '' as string,
    },
  },
})

export const createTenantLeaseFx = attach({ effect: createTenantLeaseReqFx })
export const editTenantLeaseFx = attach({ effect: editTenantLeaseReqFx })
