import * as React from 'react'
import { InputSwitch, Row, Col } from '@/ui'
import styled from 'styled-components'
import { ContactList } from './ContactList'
import { NewEntry } from './NewEntry'

export const ContactDetails = () => {
  const [contactType, setContactType] = React.useState<'old'| 'new'>('old')

  const contactTypeList: { value: 'old'| 'new';text: string }[] = [
    { value: 'old', text: 'Full list' },
    { value: 'new', text: 'New entry' },
  ]

  const selectContact = (val: 'old'| 'new') => {
    switch (val) {
      case 'old':
        return (<ContactList />)
      case 'new':
        return (<NewEntry />)
      default:
        return null
    }
  }

  return (
    <Wrap>
      <Row>
        <Col xs="100%" md="50%">
          <InputSwitch
            items={contactTypeList}
            value={contactType}
            onChange={setContactType}
          />
        </Col>
      </Row>
      <Row>
        {selectContact(contactType)}
      </Row>
    </Wrap>
  )
}


const Wrap = styled.div`
  width:100%;
  padding: 20px 0px 20px 0px;
`
