import * as React from 'react'
import styled from 'styled-components'
import { themeVar } from '@/ui/theming'
import { Container } from '../parts'
import { ConfirmModal } from '../containers'

type Props = {
  children?: React.ReactChild;
  header?: React.ReactChild;
}


export const MainLayout = ({ children, header }: Props) => (
  <StyledMainLayout id="mainLayout">
    {header}
    <Container>
      {children}
    </Container>
    <ConfirmModal />
  </StyledMainLayout>
)

const StyledMainLayout = styled.div`
  width: 100%;
  height: calc(100vh - ${themeVar('headerHeight')});
  background: ${themeVar('appBackground')};
  margin-top: ${themeVar('headerHeight')};
  overflow-y: auto;
  overflow-x: hidden;
`
