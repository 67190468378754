import * as React from 'react'
import { useForm } from 'effector-forms'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  ContractFormTitle,
  themeVar,
  PrimaryButton,
} from '@/ui'
import { GeneralForm } from '../../model/general'
import { RentalObjects } from './RentalObjects'
import { Dates, MainForm } from './general-form'
import { CadastralReference } from '../parts'

export const General = () => {
  const { t } = useTranslation('tenant/main')

  const nextStep = () => {
    GeneralForm.submit()
  }
  return (
    <Wrap>
      <Row>
        <MainForm />
        <RentalObjects />
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('cadastralReferences')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <CadastralReference />

        <BlockWrap>
          <Dates />
        </BlockWrap>

        <BlockWrap>
          <Row>
            <Col xs="100%" md="180px" noMargin>
              <PrimaryButton
                outline
                fluid
                type="gray"
                prependIcon="leftChevron"
              >
                {t('back')}
              </PrimaryButton>
            </Col>
            <Col xs="100%" md="180px">
              <PrimaryButton
                outline
                fluid
                type="warning"
                appendIcon="close"
                onClick={GeneralForm.reset}
              >
                {t('clear')}
              </PrimaryButton>
            </Col>
            <Col xs="100%" md="180px">
              <PrimaryButton
                fluid
                appendIcon="rightChevron"
                onClick={() => nextStep()}
              >
                {t('nextStep')}
              </PrimaryButton>
            </Col>
          </Row>
        </BlockWrap>
      </Row>
    </Wrap>
  )
}

const Wrap = styled.form`
`

const BlockWrap = styled.div<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 34px;
  ${breakpoint('mobile', 'tablet')`
    padding: 0  0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
`
