import * as React from 'react'
import {
  SignIn, ChangePassword, ThanksModal, SignUp, VerificationEmailSuccess,
} from '../containers'

export const AutorizationLayer = () => (
  <>
    <SignIn />
    <ChangePassword />
    <ThanksModal />
    <SignUp />
    <VerificationEmailSuccess />
  </>
)
