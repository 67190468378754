import { createDomain } from 'effector'

import { Language } from '@/dal'

type CallbackAction = (() => any) | null

export const app = createDomain('app')

export const $isAuthStateReady = app.store(false)
export const $isAuth = app.store(false)
export const $isGuest = $isAuth.map((s) => !s)

export const $showConfirmModal = app.store(false)
export const $confirmModalAction = app.store<{action: CallbackAction}>({ action: null })
export const $confirmModalTitle = app.store('')
export const $confirmModalText = app.store('')
export const $lang = app.store<Language>('en')
export const $ownerRegistrationFlowFinished = app.store(false)

export const checkUserAuth = app.event<void>()
export const userAuthChecked = app.event<void>()
export const userAuthenticated = app.event<void>()

export const changeLanguage = app.event<Language>()
export const ownerRegistrationFinished = app.event<void>()

export const checkOwnerCreated = app.event<void>()

export const showConfirmModal = app.event<{title: string; text: string; action?: CallbackAction}>()
export const closeConfirmModal = app.event()
