import * as React from 'react'
import {
  Row,
  Col,
  SortHeader,
  ExpandedRow,
  PrimaryButton,
  Visible,
  Icon,
} from '@/ui'
import styled from 'styled-components'
import { getMock } from '@/features/app'
import { personalFileCompleteStep, setPersonFileStep } from '../../../model'

export const ContactList = () => {
  const supplierItems = getMock().commands.supplierList
  const [selectedSupplierId, setSelectedSupplierId] = React.useState<number>()
  const [tmpSortBy, setTmpSortBy] = React.useState('number')
  const [tmpSortDirection, setTmpSortDirection] = React.useState<'ASC'|'DESC'>('ASC')
  const setSort = (name: string) => {
    if (tmpSortBy !== name) {
      setTmpSortBy(name)
      setTmpSortDirection('ASC')
      return
    }
    if (tmpSortDirection === 'ASC') {
      setTmpSortDirection('DESC')
      return
    }
    setTmpSortBy('')
  }

  const itemsStatus: {[key: string]: string} = {
    online: 'success',
    offline: 'primary',
  }
  const getType = (status: string) => itemsStatus[status] || ''

  const nextStep = () => {
    personalFileCompleteStep('contact')
    setPersonFileStep('details')
  }

  const rowContentTemplate = (item: typeof supplierItems[0]) => (
    <>
      <Visible tablet>
        <Row>
          <Col width="90%" noMargin noPadding>
            <>
              <Row>
                <Col width={1} name="Card" align="flex-start">
                  {item.card}
                </Col>
                <Col width={1}>
                  <>
                    <Col width={1} name="Country" align="flex-start" noMargin>
                      {item.country}
                    </Col>
                    <Col width={1} name="Town" align="flex-start">
                      {item.town}
                    </Col>
                  </>
                </Col>
                <Col width={1} name="State/County" align="flex-start">
                  {item.state}
                </Col>
                <Col width={1} name="Email" align="flex-start">
                  {item.email}
                </Col>

              </Row>
              <Row>
                <Col width={1} name="Adress" align="flex-start">
                  {item.adress}
                </Col>
                <Col width={1} name="Fax" align="flex-start">
                  {item.fax}
                </Col>
                <Col width={1} name="Phone" align="flex-start">
                  {item.phone}
                </Col>
                <Col width={1} name="Mobile" align="flex-start">
                  {item.mobilePhone}
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Visible>
      <Visible mobile>
        <Row>
          <Col width="50%" name="Card" align="flex-start">
            {item.card}
          </Col>
          <Col width="50%" name="Country" align="flex-start" noMargin>
            {item.country}
          </Col>
          <Col width="50%" name="Town" align="flex-start">
            {item.town}
          </Col>
          <Col width="50%" name="State/County" align="flex-start">
            {item.state}
          </Col>
          <Col width="50%" name="Email" align="flex-start">
            {item.email}
          </Col>
          <Col width="50%" name="Adress" align="flex-start">
            {item.adress}
          </Col>
          <Col width="50%" name="Fax" align="flex-start">
            {item.fax}
          </Col>
          <Col width="50%" name="Phone" align="flex-start">
            {item.phone}
          </Col>
          <Col width="50%" name="Mobile" align="flex-start">
            {item.mobilePhone}
          </Col>
        </Row>
      </Visible>
    </>
  )

  const rowContentActions = (

    <Row>
      <Col md="25%" xs="100%" align="flex-end" noMargin>
        <PrimaryButton type="inverse" fluid prependIcon="leftChevron" onClick={() => setPersonFileStep('location')}>
          back
        </PrimaryButton>
      </Col>
      <Col md="25%" xs="100%" align="flex-start">
        <PrimaryButton type="white" fluid appendIcon="rightChevron" onClick={() => nextStep()}>
          next step
        </PrimaryButton>
      </Col>
    </Row>
  )

  const supplierList = supplierItems.map((item) => (
    <ExpandedRow
      hiddenContent={rowContentTemplate(item)}
      hiddenContentActions={rowContentActions}
      onClick={() => setSelectedSupplierId(item.id)}
      colorized
      key={`${item.id}supplierListItem`}
    >
      {() => (
        <>
          <Visible tablet>
            <Col width={1}>
              {item.name}
            </Col>
            <Col width={1} big>
              {item.jobTitle}
            </Col>
            <Col width={1}>
              {item.location}
            </Col>
            <Col width={1}>
              {item.phone}
            </Col>
            <Col width={1}>
              {item.email}
            </Col>
            <Col width={1} big color={getType(item.status)}>
              {item.status}
            </Col>
            <Col width="30px" align="flex-start">
              <>
                {' '}
                {selectedSupplierId === item.id && (
                <Icon icon="check" />
                )}
              </>
            </Col>
          </Visible>
          <Visible mobile>
            <Col name="Name" width="50%" align="flex-start">
              {item.name}
            </Col>
            <Col width={1} big color={getType(item.status)}>
              {item.status}
            </Col>
            <Col width="30px" align="flex-start">
              <>
                {' '}
                {selectedSupplierId === item.id && (
                <Icon icon="check" />
                )}
              </>
            </Col>
            <Col name="Job title" width="50%" big align="flex-start">
              {item.jobTitle}
            </Col>
            <Col name="Location" width="50%" align="flex-start">
              {item.location}
            </Col>
            <Col name="Phone" width="50%" align="flex-start">
              {item.phone}
            </Col>
            <Col name="Email" width="50%" align="flex-start">
              {item.email}
            </Col>

          </Visible>
        </>
      )}
    </ExpandedRow>
  ))
  return (
    <>
      <Visible tablet>
        <Row>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'name' ? tmpSortDirection : undefined}
              onChange={() => setSort('name')}
            >
              Name
            </SortHeader>
          </Col>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'jobTitle' ? tmpSortDirection : undefined}
              onChange={() => setSort('jobTitle')}
            >
              Job title

            </SortHeader>
          </Col>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'location' ? tmpSortDirection : undefined}
              onChange={() => setSort('location')}
            >
              Location
            </SortHeader>
          </Col>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'phone' ? tmpSortDirection : undefined}
              onChange={() => setSort('phone')}
            >
              Phone
            </SortHeader>
          </Col>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'email' ? tmpSortDirection : undefined}
              onChange={() => setSort('email')}
            >
              Email
            </SortHeader>
          </Col>
          <Col width={1}>
            <SortHeader
              direction={tmpSortBy === 'status' ? tmpSortDirection : undefined}
              onChange={() => setSort('status')}
            >
              Status
            </SortHeader>
          </Col>
          <Col width="30px" />
        </Row>
      </Visible>
      <ListWrap>
        <Col xs="90%" md={1} noPadding noMargin>
          <>
            {supplierList}
          </>
        </Col>
      </ListWrap>
    </>
  )
}

const ListWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
  `
