import * as React from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import {
  AccentText,
  Modal,
  Heading,
  Text,
  Col,
  Row,
  SubTitle,
  WrapField,
} from '@/ui'
import styled from 'styled-components'


import {
  $isVerificationEmailSuccess,
  hideVerificationSuccess,
} from '../../model'


export const VerificationEmailSuccess = () => {
  const isModalShown = useStore($isVerificationEmailSuccess)
  const { t } = useTranslation('autorization/verification')


  return (
    <Modal
      type="info"
      show={isModalShown}
      onClose={() => hideVerificationSuccess()}
    >
      <ContentWrap>
        <Row>
          <Col noPadding width="100%">
            <WrapField>
              <Heading>{t('title')}</Heading>
            </WrapField>
          </Col>
          <Row>
            <Col noPadding width="100%">
              <SubTitle style={{ fontWeight: 500 }}>
                {t('emailConfirmed')}
              </SubTitle>
            </Col>
          </Row>
        </Row>
      </ContentWrap>
    </Modal>
  )
}


const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
