import * as React from 'react'
import { useField } from 'effector-forms'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

import {
  InputField,
  NumberInput,
  SubTitle,
  InputTextArea,
  RadioButtonsList,
  AccentText,
  Select, Autocomplete,
} from '@/ui'
import {
  InsuranceContractGroup,
} from '@/dal/contracts.interfaces.ts'
import { useAutocomplete } from '@/lib/effector-autocomplete'

import { CounterpartyWithAllContacts } from '@/dal'
import { GeneralForm, counterpartyAutocomplete } from '../../../model/general'

export const MainForm = () => {
  const { t } = useTranslation('tenant/main')

  const counterpartyAuto = useAutocomplete(counterpartyAutocomplete)

  const name = useField(GeneralForm.fields.name)
  const description = useField(GeneralForm.fields.description)
  const active = useField(GeneralForm.fields.active)
  const guaranteedCapital = useField(GeneralForm.fields.guaranteedCapital)
  const code = useField(GeneralForm.fields.code)
  const tacitRenewal = useField(GeneralForm.fields.tacitRenewal)
  const grouping = useField(GeneralForm.fields.grouping)
  const counterparty = useField(GeneralForm.fields.conterparty)

  const statusItems = [
    { text: t('active'), value: true },
    { text: t('inactive'), value: false },
  ]

  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  const groupItems: {text: string; value: InsuranceContractGroup}[] = [
    { value: 'accident', text: t('accident') },
    { value: 'personal-injury', text: t('personal-injury') },
    { value: 'damage', text: t('damage') },
    { value: 'building-damage', text: t('building-damage') },
    { value: 'right-to-lease', text: t('right-to-lease') },
    { value: 'multi-hazard', text: t('multi-hazard') },
    { value: 'operating-loss', text: t('operating-loss') },
    { value: 'civil-liability', text: t('civil-liability') },
    { value: 'hazard', text: t('hazard') },
    { value: 'thief', text: t('thief') },
    { value: 'cash-thief', text: t('cash-thief') },
  ]
  return (
    <MainFieldWrap>
      <GridCol gridArea="name">
        <SubTitle>
          1.
          {' '}
          {t('contractName')}
          <AccentText>*</AccentText>
        </SubTitle>
        <InputField
          value={name.value}
          onChangeValue={name.onChange}
          errorText={name.errorText()}
        />
      </GridCol>
      <GridCol gridArea="code">
        <SubTitle>
          2.
          {' '}
          {t('contractCode')}
        </SubTitle>
        <InputField
          value={code.value}
          onChangeValue={code.onChange}
        />
      </GridCol>
      <GridCol gridArea="description">
        <SubTitle>
          3.
          {' '}
          {t('description')}
        </SubTitle>
        <InputTextArea
          fluid
          maxLength={150}
          value={description.value}
          onChangeValue={description.onChange}
        />
      </GridCol>
      <GridCol gridArea="status">
        <SubTitle>
          4.
          {' '}
          {t('status')}
        </SubTitle>
        <RadioButtonsList
          name="status"
          value={active.value}
          items={statusItems}
          onChange={active.onChange}
        />
      </GridCol>
      <GridCol gridArea="tatic">
        <SubTitle>
          5.
          {' '}
          {t('tacitRenewal')}
        </SubTitle>
        <RadioButtonsList
          name="status"
          value={tacitRenewal.value}
          items={YesNoItems}
          onChange={tacitRenewal.onChange}
        />
      </GridCol>
      <GridCol gridArea="group">
        <SubTitle>
          6.
          {' '}
          {t('group')}
          <AccentText>*</AccentText>
        </SubTitle>
        <Select
          name="group"
          value={grouping.value}
          items={groupItems}
          onChange={grouping.onChange}
          errorText={grouping.errorText()}
        />
      </GridCol>
      <GridCol gridArea="capital">
        <SubTitle>
          7.
          {' '}
          {t('guaranteedCapital')}
        </SubTitle>
        <NumberInput
          isFloat
          placeholder={t('guaranteedCapital')}
          value={guaranteedCapital.value}
          onChangeValue={guaranteedCapital.onChange}
        />
      </GridCol>
      <GridCol gridArea="counterparty">
        <SubTitle>
          8.
          {' '}
          {t('counterparty')}
          <AccentText>*</AccentText>
        </SubTitle>
        <Autocomplete<CounterpartyWithAllContacts>
          value={counterpartyAuto.value}
          inputValue={counterpartyAuto.searchValue}
          extractLabel={(item) => item.conterparty.companyName || item.conterparty.email || `${item.localContact.contact.firstName} ${item.localContact.contact.lastName}`}
          extractKey={(item) => item.conterparty.id}
          placeholder="Choose counterparty"
          errorText={counterparty.errorText()}
          items={counterpartyAuto.items}
          onInputChange={(val) => counterpartyAuto.onSearchChange(val)}
          onChange={(val) => counterpartyAuto.onChange(val)}
        />
      </GridCol>
    </MainFieldWrap>
  )
}

const MainFieldWrap = styled.div`
  display: grid;
  width: 100%;
  padding: 0 34px;
  align-items: flex-start;
  grid-template-rows: 73px 73px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 
    "name code description"
    "status tatic description"
    "group capital description"
    "counterparty counterparty .";
  grid-gap: 20px;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 15px;
    grid-template-columns: 1fr;
    grid-template-rows: none;
    grid-template-areas: 
      "name"
      "code"
      "description"
      "status"
      "tatic"
      "group"
      "capital"
      "counterparty";
  `}
`

const GridCol = styled.div<{ gridArea: string}>`
  grid-area: ${({ gridArea }) => gridArea};
`
