import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../../theming'

type Props = {
  align?: 'left' | 'right' | 'center';
} & ThemedStyledProps

export const Title = styled.div<Props>`
font-family: ${themeVar('baseFont')};
font-weight: 600;
font-size: 18px;
text-transform: uppercase; 
text-align: ${({ align = 'left' }) => align};
${breakpoint('mobile', 'tablet')`
    font-size: 14px;
  `}
`

export const ContractFormTitle = styled(Title)`
  ${breakpoint('mobile', 'tablet')`
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 100%;
  `}
`
