import {
  forward, sample,
} from 'effector'
import { NotificationType } from '@/dal'
import { updateUserSettingsReqFx } from '../user'
import {
  getUserNotificationSettingsFx,
  setUserNotificationSettingsFx,
  userNotificationSettingsForm,
} from './units'

function createNotificationArray(email: any, sms: any) {
  const arr: NotificationType[] = []
  if (email) arr.push('Email')
  if (sms) arr.push('SMS')
  return arr
}

forward({
  from: getUserNotificationSettingsFx.doneData.map((x) => ({
    consultations: createNotificationArray(x.consultationsEmail, x.consultationsSMS),
    interventionDemands: createNotificationArray(x.interventionDemandsEmail, x.interventionDemandsSMS),
    orders: createNotificationArray(x.ordersEmail, x.ordersSMS),
  })),
  to: userNotificationSettingsForm.setForm,
})

sample({
  source: userNotificationSettingsForm.$values,
  clock: updateUserSettingsReqFx.doneData,
  fn: (x) => ({
    data: {
      consultationsEmail: x.consultations.includes('Email'),
      consultationsSMS: x.consultations.includes('SMS'),
      interventionDemandsEmail: x.interventionDemands.includes('Email'),
      interventionDemandsSMS: x.interventionDemands.includes('SMS'),
      ordersEmail: x.orders.includes('Email'),
      ordersSMS: x.orders.includes('SMS'),
    },
  }),
  target: setUserNotificationSettingsFx,
})
