import { forward } from 'effector'
import {
  hideModal,
  showModal,
  $site,
  $isShowModal,
} from './unit'

$isShowModal
  .on(showModal, () => true)
  .reset(hideModal)

$site
  .on(showModal, (_, x) => x)
