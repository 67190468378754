import v from 'validator'
import { Rule } from 'effector-forms'

export const createRule = <Value>(
  name: string,
  validator: (v: any, form?: any) => boolean,
  errorText?: string,
): Rule<Value> => ({
    name,
    validator,
    errorText,
  })

export const rules = {
  isEmail: () => createRule(
    'isEmail',
    (value) => v.isEmail(value),
    'Email is invalid',
  ),
  required: () => createRule(
    'required',
    (value) => {
      if (Number.isNaN(value)) return true
      if (value) {
        return Array.isArray(value) || typeof value === 'string' ? Boolean(value.length) : true
      }
      return false
    },
    'Field is required',
  ),
  isConfirmed: (fieldToConfirm: string) => createRule(
    'isConfirmed',
    (value, form) => (form.hasOwnProperty(fieldToConfirm) ? form[fieldToConfirm] === value : false),
    'Field values are different',
  ),
  isURL: () => createRule(
    'isURL',
    (value) => !value || v.isURL(value),
    'Wrong URL format',
  ),
}
