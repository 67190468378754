import * as React from 'react'
import styled from 'styled-components'
import { IconName } from '@/ui'
import { CategoryItem } from '../parts'

export const SelectEntityType = () => {
  const itemsList: {
        name: string;
        description: string;
        character: IconName;
        href: string;
    }[] = [
      {
        name: 'Personal file',
        description: 'here you need a little description of each entity to make it clear which client you register who you\'re registering',
        character: 'character8',
        href: '/new-employee/personal-file',
      },
      {
        name: 'user',
        description: 'here you need a little description of each entity to make it clear which client you register who you\'re registering',
        character: 'character3',
        href: '/new-employee/user',
      },
      {
        name: 'member',
        description: 'here you need a little description of each entity to make it clear which client you register who you\'re registering',
        character: 'character4',
        href: '/new-employee/member',
      },
    ]
  const categoryList = itemsList.map((item) => (
    <CategoryItem item={item} />
  ))
  return (
    <Wrap>
      <ItemsGrid>
        {categoryList}
      </ItemsGrid>
    </Wrap>
  )
}

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    min-height: 90vh;
    width: 100%;
`

const ItemsGrid = styled.div`
    display: grid;
    grid-template: 1fr/ 1fr 1fr 1fr;
    justify-content: center;
    margin: auto;
    width: 100%;
`
