import * as React from 'react'
import styled from 'styled-components'
import {
  Checkbox, themeVar, AccentText,
} from '@/ui'

type Props = {
  items: ItemI[];
}

type ItemI = {
  id: number;
  name: string;
  repository: boolean;
  services: boolean;
  meetingRooms: boolean;
  workOrders: boolean;
  arrivalDeparture: boolean;
  statements: boolean;
  office: boolean;
  plans: boolean;
  controls: boolean;
  purchases: boolean;
  homePage: boolean;
  consultations: boolean;
  command: boolean;
  park: boolean;
}

export const AccessBySiteTable = ({ items }: Props) => {
  const keyList = [
    'name',
    'repository',
    'services',
    'meetingRooms',
    'workOrders',
    'arrivalDeparture',
    'statements',
    'office',
    'plans',
    'controls',
    'purchases',
    'homePage',
    'consultations',
    'command',
    'park',
  ]
  const getCoolumn = (columnName: string) => keyList.indexOf(columnName)
  const rows = items.map((item, index) => (
    <>

      <Cell row={index + 1} col={getCoolumn('repository')}>
        <Checkbox
          checked={item.repository}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('services')}>
        <Checkbox
          checked={item.services}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('meetingRooms')}>
        <Checkbox
          checked={item.meetingRooms}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('workOrders')}>
        <Checkbox
          checked={item.workOrders}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('arrivalDeparture')}>
        <Checkbox
          checked={item.arrivalDeparture}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('statements')}>
        <Checkbox
          checked={item.statements}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('office')}>
        <Checkbox
          checked={item.office}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('plans')}>
        <Checkbox
          checked={item.plans}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('controls')}>
        <Checkbox
          checked={item.controls}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('purchases')}>
        <Checkbox
          checked={item.purchases}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('homePage')}>
        <Checkbox
          checked={item.homePage}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('consultations')}>
        <Checkbox
          checked={item.consultations}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('command')}>
        <Checkbox
          checked={item.command}
          onChange={() => {}}
        />
      </Cell>
      <Cell row={index + 1} col={getCoolumn('park')}>
        <Checkbox
          checked={item.park}
          onChange={() => {}}
        />
      </Cell>
      <TitleCell row={index + 1} col={getCoolumn('name')}>
        {item.name}
      </TitleCell>
    </>
  ))
  return (
    <Wrap>
      <Table>
        <TitleCell row={0} col={0}>
          <SiteTitle>
            <AccentText>
              sites
            </AccentText>
          </SiteTitle>
        </TitleCell>
        <HeadCell row={0} col={1}>
          <ColumnTitle>
            repository
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={2}>
          <ColumnTitle>
            services
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={3}>
          <ColumnTitle>
            meetingRooms
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={4}>
          <ColumnTitle>
            workOrders
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={5}>
          <ColumnTitle>
            arrivalDeparture
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={6}>
          <ColumnTitle>
            statements
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={7}>
          <ColumnTitle>
            office
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={8}>
          <ColumnTitle>
            plans
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={9}>
          <ColumnTitle>
            controls
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={10}>
          <ColumnTitle>
            purchases
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={11}>
          <ColumnTitle>
            homePage
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={12}>
          <ColumnTitle>
            consultations
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={13}>
          <ColumnTitle>
            command
          </ColumnTitle>
        </HeadCell>
        <HeadCell row={0} col={14}>
          <ColumnTitle>
            park
          </ColumnTitle>
        </HeadCell>
        {rows}
      </Table>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;
`

type CellProps = {
  row: number;
  col: number;
}

const Cell = styled.div<CellProps>`
  padding: 22px 30px;
  grid-row-start: ${({ row }) => row + 1};
  grid-row-end: ${({ row }) => row + 2};
  grid-column-start: ${({ col }) => col + 1};
  grid-column-end: ${({ col }) => col + 2};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
`

const TitleCell = styled(Cell)`
  border-right: 1px solid ${themeVar('brandOrangeColor')};
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
`

const HeadCell = styled(Cell)`
  position: sticky;
  top: 0;
  z-index: 1;
`

const Table = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 180px repeat(500, 1fr);
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  ${TitleCell}:first-child{
    border-top-right-radius: 4px;
    border-top: 1px solid ${themeVar('brandOrangeColor')};
  }
  ${TitleCell}:last-child{
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${themeVar('brandOrangeColor')};
  }
`

const ColumnTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
`
const SiteTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`
