import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons'
import { AccentText } from './typo'
import { themeVar } from '../theming'

interface Props {
    lat: number;
    lng: number;
    text: string;
    address: string;
}

export const MapMarker = ({ text, address }: Props) => (
  <Wrap>
    <Marker>
      <Icon icon="mapMarkerIcon" />
    </Marker>
    <Address>
      {address}
    </Address>
    <Description>
      <AccentText>
        {text}
      </AccentText>
    </Description>
    <Background>
      <svg width="100%" height="100%" viewBox="0 0 208 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.6352 2.55977C21.3461 1.30874 22.7088 0.535925 24.1476 0.535921C101.719 0.535703 126.826 0.524797 203.636 0.524424C205.851 0.524413 207.684 2.32531 207.675 4.54085L207.431 64.0213C207.422 66.224 205.609 68.0049 203.406 68.0049C126.426 68.0049 81.5103 68.0049 4.53547 68.0049C2.32607 68.0049 0.510067 66.2136 0.510439 64.0042L0.514645 39.0257C0.514762 38.3331 0.694713 37.6524 1.03688 37.0502L20.6352 2.55977Z" fill="white" />
      </svg>

    </Background>
  </Wrap>
)

const Wrap = styled.div`
    position: relative;
    width: 207px;
    display: flex;
    flex-wrap: wrap;
`

const Background = styled.div`
    position: absolute;
    width: 100%;
    z-index: -1;
`

const Marker = styled.div`
    width: 46px;
    margin-right: 8px;
`
const Address = styled.div`
    display: flex;
    flex: 1;
    margin-right: 8px;
    font-size: 16px;
    font-weight: 400;
    align-items: center;
`

const Description = styled.div`
    display: flex;
    width: 100%;
    margin: 4px 8px;
    font-size: 10px;
    font-weight: 400;
    align-items: center;
    background: white;
    align-items: center;
    border-radius: 4px;
`
