import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'

import { showConfirmModal } from '@/features/app/hooks'
import { CreateContractClauseRes } from '@/dal/contracts.interfaces'
import { convertToEuroCurrency } from '@/lib/field-handlers'
import {
  DataTable,
  TableRow,
  SubTitle,
  Action,
  Col,
  AccentText,
  Visible,
} from '@/ui'

import { $list, deleteItem } from '../../model/contractClauses'

export const ContractClausesList = () => {
  const { t } = useTranslation('tenant/main')
  const contractClausesList = useStore($list)
  const dataTableTemplate = [
    {
      name: 'type',
      renderHeader: (
        <SubTitle>
          {t('type')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'amount',
      renderHeader: (
        <SubTitle>
          <AccentText>
            {t('amount')}
          </AccentText>
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'name',
      renderHeader: (
        <SubTitle>
          {t('name')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'share',
      renderHeader: (
        <SubTitle>
          {t('share')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'active',
      renderHeader: (
        <SubTitle>
          {t('active')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'description',
      renderHeader: (
        <SubTitle>
          {t('description')}
        </SubTitle>
      ),
      width: 3,
    },
  ]
  const dataTableActions: Action<CreateContractClauseRes>[] = [
    {
      color: 'warning',
      icon: 'trash',
      action: (i) => {
        showConfirmModal({
          title: t('deleteClauseTile'),
          text: t('deleteClauseText'),
          action: () => {
            deleteItem(i)
          },
        })
      },
    },
  ]
  return (
    <DataTable
      noMarginTop
      columns={dataTableTemplate}
      actions={dataTableActions}
      extractKey={(item) => `${item.id}cadastralReference`}
      data={contractClausesList}
    >
      {(item) => (
        <TableRow smallText oversizeRow>
          <Visible tablet>
            <Col width={1}>
              {item.type}
            </Col>
            <Col width={1}>
              {
                item.amount && (
                  <AccentText>
                    €
                    {' '}
                    {convertToEuroCurrency(item.amount)}
                  </AccentText>
                )
              }
            </Col>

            <Col width={1}>
              {item.name}
            </Col>
            <Col width={1}>
              {item.share}
            </Col>
            <Col width={1}>
              {item.active ? 'Yes' : 'No'}
            </Col>
            <Col width={3}>
              {item.description}
            </Col>
          </Visible>
          <Visible mobile>
            <Col width="50%" name={t('type')} align="flex-start">
              {item.type}
            </Col>
            <Col width="50%" name={t('amount')} align="flex-start">
              <AccentText>
                €
                {' '}
                {item.amount}
              </AccentText>
            </Col>

            <Col width="50%" name={t('name')} align="flex-start">
              {item.name}
            </Col>
            <Col width="50%" name={t('share')} align="flex-start">
              {item.share}
            </Col>
            <Col width="50%" name={t('active')} align="flex-start">
              {item.active ? 'Yes' : 'No'}
            </Col>
            <Col width="100%" name={t('description')} align="flex-start">
              {item.description}
            </Col>
          </Visible>
        </TableRow>
      )}
    </DataTable>
  )
}
