import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { FetchCounterpartyWithContactsListParams, fetchCounterpartyWithContactsListReqFx } from '@/dal'
import { rules } from '@/lib/validators'
import { createInsuranceContractReqFx, editInsuranceContractReqFx } from '@/dal/contracts'
import {
  InsuranceContractGroup,
} from '@/dal/contracts.interfaces'
import { createAutocomplete } from '@/lib/effector-autocomplete'

const d = createDomain('d')

export const createContract = d.createEvent()
export const updateContract = d.createEvent()

export const counterpartyAutocomplete = createAutocomplete({
  domain: d,
  fields: ['companyName'],
  initValue: '',
  effect: attach({
    effect: fetchCounterpartyWithContactsListReqFx,
    mapParams: (x) => ({
      ...x,
      offset: 0,
      limit: 9999,
      logicFilter: true,
      filters: [
        {
          include: true,
          key: 'localConterpartyContact',
          value: 'have',
        },
        {
          include: true,
          key: 'type',
          value: 'insurer',
        },
        ...x.filters,
      ],
    } as FetchCounterpartyWithContactsListParams),
  }),
})

export const GeneralForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    conterparty: {
      init: '',
      rules: [rules.required()],
    },
    code: {
      init: '',
    },
    description: {
      init: '',
    },
    active: {
      init: true,
    },
    tacitRenewal: {
      init: false,
    },
    grouping: {
      init: '' as InsuranceContractGroup,
      rules: [rules.required()],
    },
    guaranteedCapital: {
      init: '' as string,
    },
    effectiveDate: {
      init: '',
    },
    dateOfSigning: {
      init: '',
    },
    dateOfMovingInToPremises: {
      init: '',
    },
    termsYears: {
      init: '' as string,
    },
    termsMonths: {
      init: '' as string,
    },
    expirationDate: {
      init: '',
    },
    terminationNoticePeriodYears: {
      init: '' as string,
    },
    terminationNoticePeriodMonths: {
      init: '' as string,
    },
  },
})

export const createInsuranceContractFx = attach({ effect: createInsuranceContractReqFx })
export const editInsuranceContractFx = attach({ effect: editInsuranceContractReqFx })
