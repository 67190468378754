import * as React from 'react'
import styled from 'styled-components'
import { themeVar, Icon, PrimaryButton } from '@/ui'


type Props = {
  file: File | null;
  onFile: (file: File | null) => void;
}

export const ImagePicker = ({ file, onFile }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [previewSrc, setPreviewSrc] = React.useState<string | null>(null)

  const clickUploadHadnler = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }
  const reader = new FileReader()
  reader.onload = () => {
    setPreviewSrc(reader.result as string)
  }

  const uploadHandler = (files: FileList | null) => {
    if (!files) return null
    onFile(files[0])
    reader.readAsDataURL(files[0])
    if (inputRef.current) {
      inputRef.current.value = null as unknown as string
    }
  }

  return (
    <Wrap>
      <HiddenInput
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={(e) => uploadHandler(e.currentTarget.files)}
      />
      {previewSrc !== null && (
        <Preview src={previewSrc} />
      )}
      <ActionButton
        onClick={clickUploadHadnler}
      >
        upload photo
      </ActionButton>
    </Wrap>
  )
}

const Preview = styled.img`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: opacity .2s ease-in-out;
`

const Wrap = styled.div`
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border: none;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    width: 145px;
    height: 145px;
    &:hover>${Preview}{
        opacity: 0.5;
    }
`


const HiddenInput = styled.input`
    display: none;
`


const ActionButton = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${themeVar('brandOrangePale2xColor')};
    color: ${themeVar('brandOrangeColor')};
    font-size: 16px;
    font-weight: 300;
    display: flex;
    text-align: center;
    align-items: center;
    text-transform: uppercase;
    padding: 20px;
    transition: all .2s ease-in-out;
    z-index: 1;
    &:hover{
        background: ${themeVar('brandOrangeColor')};
        color: white;
    }
`
