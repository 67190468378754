import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateRentFreePeriodRes, RentFreePeriodType } from '@/dal/contracts.interfaces'
import {
  createRentFreePeriodReqFx,
  deleteRentFreePeriodReqFx,
  attachRentFreePeriodToTenantLeaseReqFx,
  detachRentFreePeriodFromTenantLeaseReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $periodList = d.createStore<CreateRentFreePeriodRes[]>([])

export const loadPeriod = d.createEvent<CreateRentFreePeriodRes[]>()
export const addPeriod = d.createEvent<CreateRentFreePeriodRes>()
export const deletePeriod = d.createEvent<CreateRentFreePeriodRes>()
export const dropPeriod = d.createEvent<CreateRentFreePeriodRes['id']>()


export const RentForm = createForm({
  domain: d,
  fields: {
    type: {
      init: '' as RentFreePeriodType | null,
    },
    startDate: {
      init: '',
      rules: [rules.required()],
    },
    endDate: {
      init: '',
      rules: [
        {
          name: 'end-of-period',
          validator: (value: string, { numberOfMonth }) => (numberOfMonth === '' ? value !== '' : true),
          errorText: 'End date or number of month is required',
        },
      ],
    },
    numberOfMonth: {
      init: '' as string,
      rules: [
        {
          name: 'end-of-period',
          validator: (value: string, { endDate }) => (endDate === '' ? value !== '' : true),
          errorText: 'End date or number of month is required',
        },
      ],
    },
    amount: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const createRentFreePeriodFx = attach({ effect: createRentFreePeriodReqFx })
export const attachRentFreePeriodToTenantLeaseFx = attach({ effect: attachRentFreePeriodToTenantLeaseReqFx })
export const deleteRentFreePeriodFx = attach({ effect: deleteRentFreePeriodReqFx })
export const detachRentFreePeriodFromTenantLeaseFx = attach({ effect: detachRentFreePeriodFromTenantLeaseReqFx })
