import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Col, InputField, Select, WrapField,
} from '@/ui'
import { getMock } from '@/features/app'

type Props = {
  entity?: boolean;
}

export const NotCustomerForm = ({ entity }: Props) => {
  const { t } = useTranslation('autorization/translation')
  const [dropdwn, setDropdwn] = React.useState<string | number | boolean | null>(null)
  const [name, setName] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [companyFunction, setCompanyFunction] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [companyName, setCompanyName] = React.useState<string>('')
  const selectItems = [
    { value: 1, text: 'Opt Uno' },
    { value: 2, text: 'Opt Dos' },
    { value: 3, text: 'Opt Tres' },
  ]
  const [selectedEntity, setSelectedEntity] = React.useState<string | number | boolean | null>(null)
  const SelectEntityItems = [
    { value: 1, text: 'Entity Uno' },
    { value: 2, text: 'Entity Dos' },
    { value: 3, text: 'Entity Tres' },
  ]
  return (
    <FormWrap>
      <Col noPadding width="50%">
        <WrapField>
          <Select
            name="Drp-Dwn"
            items={getMock().selects.authorization.dropDwn}
            value={dropdwn}
            onChange={(val) => setDropdwn(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="50%">
        <WrapField>
          <InputField
            name="name"
            placeholder="Name"
            value={name}
            onChangeValue={(val) => setName(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="firstName"
            placeholder="First name"
            value={firstName}
            onChangeValue={(val) => setFirstName(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="email"
            placeholder="Email"
            value={email}
            onChangeValue={(val) => setEmail(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="companu function"
            placeholder="Function"
            value={companyFunction}
            onChangeValue={(val) => setCompanyFunction(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="phone"
            placeholder="Phone"
            value={phone}
            onChangeValue={(val) => setPhone(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="company name"
            placeholder="Name of you compage"
            value={companyName}
            onChangeValue={(val) => setCompanyName(val)}
          />
        </WrapField>
      </Col>
      {entity && (
        <Col noPadding width="100%">
          <WrapField>
            <Select
              name="Choose entity"
              items={getMock().selects.authorization.entity}
              value={selectedEntity}
              onChange={(val) => setSelectedEntity(val)}
            />
          </WrapField>
        </Col>
      )}
    </FormWrap>
  )
}
const FormWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
