import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { NavLink } from 'react-router-dom'

import { history } from '@/lib/history'

import { $userInfo, getUserInfoFx } from '@/features/app'

import {
  fetchSitesListFx,
  $sitesList,
} from '../../model/main/units'
import {
  SiteSelect,
} from '../containers'

export const SitePageDummy: React.FC = ({ children }) => {
  const isPendingRequest = useStore(getUserInfoFx.pending)
  const isPendingSitesFetching = useStore(fetchSitesListFx.pending)
  const userInfo = useStore($userInfo)
  const sitesList = useStore($sitesList)

  if (!userInfo && isPendingRequest) return null

  return (
    <>
      <SiteSelect />
      {
        !sitesList.length && !isPendingSitesFetching && (
          <Wrap>
            <Heading>
              You need to add at least one site first.
              <Link to="/sites">Add my first site</Link>
            </Heading>
          </Wrap>
        )
      }
    </>
  )
}

const Card = styled.div`
  background: white;
  box-shadow: 0 35px 55px rgba(213, 76, 46, 0.28);
  border: none;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  ${breakpoint('mobile', 'tablet')`
      border-radius: 0px;
  `}
`

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Heading = styled.h3`
  width: 100%;
  text-align: center;
`
const Link = styled(NavLink)`
  display: block;
  color: white !important;
  text-decoration: underline;
`
