import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar } from '../../theming'

export const SubTitle = styled.div`
font-family: ${themeVar('baseFont')};
font-weight: 600;
font-size: 12px;
text-transform: uppercase; 
margin-bottom: 10px;
margin-top: 14px;
${breakpoint('mobile', 'tablet')`
    font-size: 12 px;
  `}
`
