import { forward, sample } from 'effector'

import { $lang, $user } from '@/features/app'
import { history } from '@/lib/history'

import {
  $titleList,
  profileForm,
  filters,
  mounted,
  getTitleListFx,
  createOwnerProfileFx,
} from './units'

$titleList
  .on(getTitleListFx.doneData, (_, data) => data.items)

sample({
  source: $lang,
  clock: mounted,
  target: profileForm.fields.language.onChange,
})

sample({
  source: $user.map((x) => x?.email || ''),
  clock: mounted,
  target: profileForm.fields.email.onChange,
})

profileForm.reset.watch(() => {
  profileForm.fields.email.onChange($user.getState()?.email as string)
})

forward({
  from: profileForm.fields.language.changed,
  to: filters.filters.language.setValue,
})

forward({
  from: filters.$result,
  to: getTitleListFx,
})

sample({
  clock: profileForm.formValidated,
  source: profileForm.$values,
  fn: (form) => ({
    ...form,
    photo: '', // fixme: подставить реальное значение, когда бэк научится работать с файлами
    phones: [form.defaultPhone, ...form.phones.filter((x) => x !== '')],
  }),
  target: createOwnerProfileFx,
})

createOwnerProfileFx.done.watch(() => history.replace('/owner-registration/tariff'))
