import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'
import Markdown from 'react-markdown'

import {
  Row,
  Col,
  Modal,
  Title,
  PrimaryButton,
  SmallText,
  AccentText,
  themeVar,
} from '@/ui'
import { dateToHuman } from '@/lib/dates'

import {
  $selectedNewsArticle,
  $imageSrc,
  $showImage,
  $showModal,
  hideModal,
} from '../../model'

export const NewsModal = () => {
  const article = useStore($selectedNewsArticle)
  const imageSrc = useStore($imageSrc)
  const showImage = useStore($showImage)
  const showModal = useStore($showModal)

  return (
    <Modal show={showModal} onClose={() => hideModal()}>
      <>
        <NewsHeader>
          <Col md="25%" xs="100%" noMargin noPadding>
            <NewsAuthor>
              <AccentText>
                Main department
                <br />
                by
                {' '}
                {article?.postedBy.authorName}
              </AccentText>
            </NewsAuthor>
          </Col>
          <Col md="75%" xs="100%" noMargin noPadding>
            <NewsDate>
              {article && dateToHuman(article.createdAt)}
            </NewsDate>
          </Col>
        </NewsHeader>
        {showImage && (
          <NewsImage src={imageSrc} />
        )}
        <NewsTitle>{article?.title}</NewsTitle>
        <NewsText>
          <Markdown source={article?.article} />
        </NewsText>
        <Row>
          <Col width={1} noMargin>
            <PrimaryButton prependIcon="leftChevron" outline>
              Previous news
            </PrimaryButton>
          </Col>
          <Col width={1} noMargin align="flex-end">
            <PrimaryButton appendIcon="rightChevron" outline>
              Next news
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Modal>
  )
}

const NewsImage = styled.img`
  width: calc(100% + 140px);
  margin: 0 -70px 40px;
`
const NewsTitle = styled(Title)`
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 40px;
`
const NewsAuthor = styled(SmallText)`
  font-weight: 400;
  ${breakpoint('mobile', 'tablet')`
    margin-bottom: 15px;
  `}
`
const NewsDate = styled(SmallText)`
  font-weight: 400;
  text-transform: uppercase;
  color: ${themeVar('newsModalDateColor')};
  ${breakpoint('tablet')`
    text-align: center;
  `}
`

const NewsHeader = styled(Row)`
  margin: -40px -50px 0;
  ${breakpoint('mobile', 'tablet')`
    margin: -15px 0 15px;
  `}
`
const NewsText = styled.div`
  margin-bottom: 40px;
`
