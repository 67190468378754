import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import GoogleMapReact from 'google-map-react'

import {
  themeVar, Title, Col, Text, SmallText, MapMarker,
} from '@/ui'

import { $selectedSite } from '../../model'


export const SiteInfo = () => {
  const [center, setCenter] = React.useState({
    lat: 48.856663,
    lng: 2.351556,
  })
  const [showMap, setShowMap] = React.useState(false)
  const [gMaps, setMaps] = React.useState(null as any)
  const [geocoder, setGeocoder] = React.useState(null as google.maps.Geocoder | null)
  const zoom = 16

  const site = useStore($selectedSite)

  const mapLoaded = (mapsInstance: any) => {
    setMaps(mapsInstance)
    setGeocoder(new mapsInstance.Geocoder())
  }

  React.useEffect(() => {
    if (site && geocoder) {
      geocoder.geocode({ address: site?.address }, (res) => {
        if (res[0]) {
          setShowMap(true)
          setCenter({
            lat: res[0].geometry.location.lat(),
            lng: res[0].geometry.location.lng(),
          })
          return
        }
        setShowMap(false)
      })
    }
  }, [site, geocoder])

  if (!site) return null

  return (
    <Wrap>
      <MapWrap
        style={{
          display: showMap ? 'block' : 'none',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GOOGLE_MAPS_KEY as string,
            libraries: ['places'],
          }}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps }) => mapLoaded(maps)}
        >
          <MapMarker
            lat={center.lat}
            lng={center.lng}
            address={site.address}
            text={`Site name: ${site.name}`}
          />
        </GoogleMapReact>
      </MapWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
`
const MapWrap = styled.div`
    min-height: 360px;
    width: 100%;
    ${breakpoint('mobile', 'tablet')`
        min-height: 260px;
    `}
`
