import * as React from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { RangeSliderField } from './RangeSliderField'

dayjs.extend(duration)
dayjs.extend(customParseFormat)

type Props = {
    value: [string, string];
    onChange: (val: [string, string]) => void;
}

export const TimeRangeSlider = ({ value, onChange }: Props) => {
  const startDay = () => dayjs().set('hour', 0).set('minute', 0).set('second', 0)
  const changeValue = ([start, end]: [number, number]) => {
    const timeRange: [string, string] = [
      startDay().add(start, 'second').format('HH:mm'),
      startDay().add(end, 'second').format('HH:mm'),
    ]
    onChange(timeRange)
  }

  const timeToNumber: [number, number] = [
    dayjs(value[0], 'HH:mm').diff(startDay(), 'second') + 1,
    dayjs(value[1], 'HH:mm').diff(startDay(), 'second') + 1,
  ]
  return (
    <RangeSliderField
      min={0}
      max={86360}
      value={timeToNumber}
      outputModificator={(val) => startDay().add(val, 'second').format('HH:mm')}
      onChange={(val) => changeValue(val)}
    />
  )
}
