import { $isAuth } from '@/features/app'

import {
  onCloseMenu,
  onOpenMenu,
  onToggleMenu,
  $isMenuOpen,
} from './units'

$isMenuOpen
  .on(onOpenMenu, () => true)
  .on(onCloseMenu, () => false)
  .on(onToggleMenu, (state) => !state)
