import { forward, sample } from 'effector'

import { convertToEuroCentsCurrency } from '@/lib/field-handlers'
import { TenantLease } from '@/dal/contracts.interfaces'

import { getTenentLeaseFx, $tenantContract } from '../main'
import {
  $periodList,
  RentForm,
  deletePeriod,
  loadPeriod,
  addPeriod,
  dropPeriod,
  createRentFreePeriodFx,
  attachRentFreePeriodToTenantLeaseFx,
  deleteRentFreePeriodFx,
  detachRentFreePeriodFromTenantLeaseFx,
} from './unit'

$periodList
  .on(loadPeriod, (_, r) => r)
  .on(addPeriod, (_, r) => [..._, r])
  .on(dropPeriod, (_, r) => _.filter((el) => el.id !== r))

forward({
  from: getTenentLeaseFx.doneData.map((r) => r.tenantLease.rentFreePeriods),
  to: loadPeriod,
})

sample({
  clock: RentForm.formValidated,
  source: RentForm.$values,
  fn: (form) => ({
    ...form,
    type: form.type || undefined,
    numberOfMonths: +form.numberOfMonth || undefined,
    amount: convertToEuroCentsCurrency(form.amount),
  }),
  target: createRentFreePeriodFx,
})

forward({
  from: createRentFreePeriodFx.doneData,
  to: addPeriod,
})

sample({
  clock: createRentFreePeriodFx.doneData,
  source: $tenantContract,
  fn: (contract, period) => ({
    id: contract?.id as string,
    itemsID: [period.id],
  }),
  target: attachRentFreePeriodToTenantLeaseFx,
})

forward({
  from: attachRentFreePeriodToTenantLeaseFx.done,
  to: RentForm.reset,
})

sample({
  clock: deletePeriod,
  source: $tenantContract,
  fn: (contract, i) => ({ id: (contract as TenantLease).id, items: [i.id] }),
  target: detachRentFreePeriodFromTenantLeaseFx,
})

forward({
  from: [
    detachRentFreePeriodFromTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0] : '')),
    attachRentFreePeriodToTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0]?.id : '')),
    detachRentFreePeriodFromTenantLeaseFx.done.map((r) => (r.params.items ? r.params.items[0] : '')),
  ],
  to: dropPeriod,
})


forward({
  from: deletePeriod.map((i) => ({ id: i.id })),
  to: deleteRentFreePeriodFx,
})
