import { forward } from 'effector'
import { history } from '@/lib/history'
import {
  $activeTab, setTab, $insuranceContract, getInsuranceContractFx, loadContract, setContract,
} from './unit'

$activeTab
  .on(setTab, (_, r) => r)


$insuranceContract
  .on(getInsuranceContractFx.doneData, (_, r) => r.insuranceContract)
  .on(setContract, (_, r) => r)
  .reset(getInsuranceContractFx.fail)

forward({
  from: loadContract.map((val) => ({ id: val })),
  to: getInsuranceContractFx,
})

getInsuranceContractFx.fail.watch(() => {
  history.push('/insurance-contract')
})

setContract.watch((contract) => {
  history.push(`/insurance-contract/${contract.id}`)
})
