import { attachWrapper } from '@/lib/effector-extra'
import { createCustomError } from '@/lib/errors'
import { requestFx, Method } from '@/dal/rest-api'

import {
  TenantLease,
  GetTenantLeaseParams,
  GetTenantLeaseRes,
  GetServiceProviderContractParams,
  GetServiceProviderContractRes,
  GetInsuranceContractParams,
  GetInsuranceContractRes,
  CreateTenantLeaseParams,
  EditTenentLeaseParams,
  CreateTenantLeaseRes,
  CreateServiceProviderContractParams,
  EditServiceProviderContractParams,
  CreateServiceProviderContractRes,
  SetTenantLeasePaymentAndSpecialClausesParams,
  SetTenantLeasePaymentAndSpecialClausesRes,
  CreateRentFreePeriodParams,
  CreateRentFreePeriodRes,
  DeleteRentFreePeriodParams,
  DeleteRentFreePeriodRes,
  AttachRentFreePeriodToTenantLeaseParams,
  AttachRentFreePeriodToTenantLeaseRes,
  DetachRentFreePeriodFromTenantLeaseParams,
  DetachRentFreePeriodFromTenantLeaseRes,
  CreateSecurityDepositParams,
  CreateSecurityDepositRes,
  DeleteSecurityDepositParams,
  DeleteSecurityDepositRes,
  AttachSecurityDepositToTenantLeaseParams,
  AttachSecurityDepositToTenantLeaseRes,
  DetachSecurityDepositFromTenantLeaseParams,
  DetachSecurityDepositFromTenantLeaseRes,
  CreateBankSecurityParams,
  CreateBankSecurityRes,
  DeleteBankSecurityParams,
  DeleteBankSecurityRes,
  AttachBankSecurityToTenantLeaseParams,
  AttachBankSecurityToTenantLeaseRes,
  DetachBankSecurityFromTenantLeaseParams,
  DetachBankSecurityFromTenantLeaseRes,
  CreateContractSignatureParams,
  CreateContractSignatureRes,
  DeleteContractSignatureParams,
  DeleteContractSignatureRes,
  AttachContractSignatureToTenantLeaseParams,
  AttachContractSignatureToTenantLeaseRes,
  DetachContractSignatureFromTenantLeaseParams,
  DetachContractSignatureFromTenantLeaseRes,
  CreateContractClauseParams,
  CreateContractClauseRes,
  DeleteContractClauseParams,
  DeleteContractClauseRes,
  AttachContractClauseToTenantLeaseParams,
  AttachContractClauseToTenantLeaseRes,
  DetachContractClauseFromTenantLeaseParams,
  DetachContractClauseFromTenantLeaseRes,
  CreateContractDocumentParams,
  CreateContractDocumentRes,
  DeleteContractDocumentParams,
  DeleteContractDocumentRes,
  AttachContractDocumentToTenantLeaseParams,
  AttachContractDocumentToTenantLeaseRes,
  DetachContractDocumentFromTenantLeaseParams,
  DetachContractDocumentFromTenantLeaseRes,
  CreateContractEndorsementParams,
  CreateContractEndorsementRes,
  DeleteContractEndorsementParams,
  DeleteContractEndorsementRes,
  AttachContractEndorsementToServiceProviderContractParams,
  AttachContractEndorsementToServiceProviderContractRes,
  DetachContractEndorsementFromServiceProviderContractParams,
  DetachContractEndorsementFromServiceProviderContractRes,
  AttachContractSignatureToServiceProviderContractParams,
  AttachContractSignatureToServiceProviderContractRes,
  DetachContractSignatureFromServiceProviderContractParams,
  DetachContractSignatureFromServiceProviderContractRes,
  AttachContractDocumentToServiceProviderContractParams,
  AttachContractDocumentToServiceProviderContractRes,
  DetachContractDocumentFromServiceProviderContractParams,
  DetachContractDocumentFromServiceProviderContractRes,
  CreateInsuranceContractParams,
  CreateInsuranceContractRes,
  EditInsuranceContractParams,
  EditInsuranceContractRes,
  AttachContractDocumentToInsuranceContractParams,
  AttachContractDocumentToInsuranceContractRes,
  DetachContractDocumentFromInsuranceContractParams,
  DetachContractDocumentFromInsuranceContractRes,
  AttachContractEndorsementToInsuranceContractParams,
  AttachContractEndorsementToInsuranceContractRes,
  DetachContractEndorsementFromInsuranceContractParams,
  DetachContractEndorsementFromInsuranceContractRes,
  AttachContractSignatureToInsuranceContractParams,
  AttachContractSignatureToInsuranceContractRes,
  DetachContractSignatureFromInsuranceContractParams,
  DetachContractSignatureFromInsuranceContractRes,
  GetContractListParams,
  GetTenantLeaseListRes,
  GetInsuranceListRes,
  GetServiceProviderListRes,
} from './contracts.interfaces'

export const getTenentLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/getWithAllContacts',
    body: p,
  }),
  mapResult: ({ result }): GetTenantLeaseRes => result.data,
})

export const createTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/add',
    body: p,
  }),
  mapResult: ({ result }): CreateTenantLeaseRes => result.data,
})

export const editTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: EditTenentLeaseParams) => ({
    method: Method.put,
    url: '/tenantLease/edit',
    body: p,
  }),
  mapResult: ({ result }): CreateTenantLeaseRes => result.data,
})

export const setTenantLeasePaymentAndSpecialClausesReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: SetTenantLeasePaymentAndSpecialClausesParams) => ({
    method: Method.put,
    url: '/tenantLease/editPaymentAndClauses',
    body: p,
  }),
  mapResult: ({ result }): SetTenantLeasePaymentAndSpecialClausesRes => result.data,
})

export const getServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/getWithAllContacts',
    body: p,
  }),
  mapResult: ({ result }): GetServiceProviderContractRes => result.data,
})

export const createServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/add',
    body: p,
  }),
  mapResult: ({ result }): CreateServiceProviderContractRes => result.data,
})

export const editServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: EditServiceProviderContractParams) => ({
    method: Method.put,
    url: '/serviceProviderContract/edit',
    body: p,
  }),
  mapResult: ({ result }): CreateServiceProviderContractRes => result.data,
})

export const getInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/getWithAllContacts',
    body: p,
  }),
  mapResult: ({ result }): GetInsuranceContractRes => result.data,
})

export const createInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/add',
    body: p,
  }),
  mapResult: ({ result }): CreateInsuranceContractRes => result.data,
})

export const editInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: EditInsuranceContractParams) => ({
    method: Method.put,
    url: '/insuranceContract/edit',
    body: p,
  }),
  mapResult: ({ result }): EditInsuranceContractRes => result.data,
})

/**
 * RENT FREE PERIODS
 */
export const createRentFreePeriodReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateRentFreePeriodParams) => ({
    method: Method.post,
    url: '/rentFreePeriod/add',
    body: p,
  }),
  mapResult: ({ result }): CreateRentFreePeriodRes => result.data,
})

export const deleteRentFreePeriodReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteRentFreePeriodParams) => ({
    method: Method.delete,
    url: '/rentFreePeriod/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteRentFreePeriodRes => result.data,
})

export const attachRentFreePeriodToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachRentFreePeriodToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addRentFreePeriods',
    body: p,
  }),
  mapResult: ({ result }): AttachRentFreePeriodToTenantLeaseRes => result.data,
})

export const detachRentFreePeriodFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachRentFreePeriodFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteRentFreePeriods',
    body: p,
  }),
  mapResult: ({ result }): DetachRentFreePeriodFromTenantLeaseRes => result.data,
})

/**
 * SECURITY DEPOSIT
 */
export const createSecurityDepositReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateSecurityDepositParams) => ({
    method: Method.post,
    url: '/securityDeposit/add',
    body: p,
  }),
  mapResult: ({ result }): CreateSecurityDepositRes => result.data,
})

export const deleteSecurityDepositReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteSecurityDepositParams) => ({
    method: Method.delete,
    url: '/securityDeposit/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteSecurityDepositRes => result.data,
})

export const attachSecurityDepositToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachSecurityDepositToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addSecurityDeposits',
    body: p,
  }),
  mapResult: ({ result }): AttachSecurityDepositToTenantLeaseRes => result.data,
})

export const detachSecurityDepositFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachSecurityDepositFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteSecurityDeposits',
    body: p,
  }),
  mapResult: ({ result }): DetachSecurityDepositFromTenantLeaseRes => result.data,
})

/**
 * BANK SECURITY
 */
export const createBankSecurityReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateBankSecurityParams) => ({
    method: Method.post,
    url: '/bankSecurity/add',
    body: p,
  }),
  mapResult: ({ result }): CreateBankSecurityRes => result.data,
})

export const deleteBankSecurityReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteBankSecurityParams) => ({
    method: Method.delete,
    url: '/bankSecurity/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteBankSecurityRes => result.data,
})

export const attachBankSecurityToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachBankSecurityToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addBankSecurities',
    body: p,
  }),
  mapResult: ({ result }): AttachBankSecurityToTenantLeaseRes => result.data,
})

export const detachBankSecurityFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachBankSecurityFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteBankSecurities',
    body: p,
  }),
  mapResult: ({ result }): DetachBankSecurityFromTenantLeaseRes => result.data,
})

/**
 * CONTRACT SIGNATURES
 */
export const createContractSignatureReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateContractSignatureParams) => ({
    method: Method.post,
    url: '/contractSignature/add',
    body: p,
  }),
  mapResult: ({ result }): CreateContractSignatureRes => result.data,
})

export const deleteContractSignatureReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteContractSignatureParams) => ({
    method: Method.delete,
    url: '/contractSignature/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteContractSignatureRes => result.data,
})

export const attachContractSignatureToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractSignatureToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addSignatures',
    body: p,
  }),
  mapResult: ({ result }): AttachContractSignatureToTenantLeaseRes => result.data,
})

export const detachContractSignatureFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractSignatureFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteSignatures',
    body: p,
  }),
  mapResult: ({ result }): DetachContractSignatureFromTenantLeaseRes => result.data,
})

export const attachContractSignatureToServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractSignatureToServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/addSignatures',
    body: p,
  }),
  mapResult: ({ result }): AttachContractSignatureToServiceProviderContractRes => result.data,
})

export const detachContractSignatureToServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractSignatureFromServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/deleteSignatures',
    body: p,
  }),
  mapResult: ({ result }): DetachContractSignatureFromServiceProviderContractRes => result.data,
})

export const attachContractSignatureToInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractSignatureToInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/addSignatures',
    body: p,
  }),
  mapResult: ({ result }): AttachContractSignatureToInsuranceContractRes => result.data,
})

export const detachContractSignatureToInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractSignatureFromInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/deleteSignatures',
    body: p,
  }),
  mapResult: ({ result }): DetachContractSignatureFromInsuranceContractRes => result.data,
})

/**
 * CONTRACT CLAUSES
 */
export const createContractClauseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateContractClauseParams) => ({
    method: Method.post,
    url: '/contractClause/add',
    body: p,
  }),
  mapResult: ({ result }): CreateContractClauseRes => result.data,
})

export const deleteContractClauseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteContractClauseParams) => ({
    method: Method.delete,
    url: '/contractClause/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteContractClauseRes => result.data,
})

export const attachContractClauseToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractClauseToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addClauses',
    body: p,
  }),
  mapResult: ({ result }): AttachContractClauseToTenantLeaseRes => result.data,
})

export const detachContractClauseFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractClauseFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteClauses',
    body: p,
  }),
  mapResult: ({ result }): DetachContractClauseFromTenantLeaseRes => result.data,
})

/**
 * CONTRACT DOCUMENTS
 */
export const createContractDocumentReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateContractDocumentParams) => ({
    method: Method.post,
    url: '/contractDocument/add',
    body: p,
  }),
  mapResult: ({ result }): CreateContractDocumentRes => result.data,
})

export const deleteContractDocumentReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteContractDocumentParams) => ({
    method: Method.delete,
    url: '/contractDocument/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteContractDocumentRes => result.data,
})

export const attachContractDocumentToTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractDocumentToTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/addDocuments',
    body: p,
  }),
  mapResult: ({ result }): AttachContractDocumentToTenantLeaseRes => result.data,
})

export const detachContractDocumentFromTenantLeaseReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractDocumentFromTenantLeaseParams) => ({
    method: Method.post,
    url: '/tenantLease/deleteDocuments',
    body: p,
  }),
  mapResult: ({ result }): DetachContractDocumentFromTenantLeaseRes => result.data,
})

export const attachContractDocumentToServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractDocumentToServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/addDocuments',
    body: p,
  }),
  mapResult: ({ result }): AttachContractDocumentToServiceProviderContractRes => result.data,
})

export const detachContractDocumentFromServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractDocumentFromServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/deleteDocuments',
    body: p,
  }),
  mapResult: ({ result }): DetachContractDocumentFromServiceProviderContractRes => result.data,
})

export const attachContractDocumentToInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractDocumentToInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/addDocuments',
    body: p,
  }),
  mapResult: ({ result }): AttachContractDocumentToInsuranceContractRes => result.data,
})

export const detachContractDocumentFromInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractDocumentFromInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/deleteDocuments',
    body: p,
  }),
  mapResult: ({ result }): DetachContractDocumentFromInsuranceContractRes => result.data,
})

/**
 * CONTRACT ENDORSEMENTS
 */
export const createContractEndorsementReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateContractEndorsementParams) => ({
    method: Method.post,
    url: '/contractEndorsement/add',
    body: p,
  }),
  mapResult: ({ result }): CreateContractEndorsementRes => result.data,
})

export const deleteContractEndorsementReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteContractEndorsementParams) => ({
    method: Method.delete,
    url: '/contractEndorsement/delete',
    body: p,
  }),
  mapResult: ({ result }): DeleteContractEndorsementRes => result.data,
})

export const attachContractEndorsementToServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractEndorsementToServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/addEndorsements',
    body: p,
  }),
  mapResult: ({ result }): AttachContractEndorsementToServiceProviderContractRes => result.data,
})

export const detachContractEndorsementToServiceProviderContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractEndorsementFromServiceProviderContractParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/deleteEndorsements',
    body: p,
  }),
  mapResult: ({ result }): DetachContractEndorsementFromServiceProviderContractRes => result.data,
})

export const attachContractEndorsementToInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: AttachContractEndorsementToInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/addEndorsements',
    body: p,
  }),
  mapResult: ({ result }): AttachContractEndorsementToInsuranceContractRes => result.data,
})

export const detachContractEndorsementToInsuranceContractReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DetachContractEndorsementFromInsuranceContractParams) => ({
    method: Method.post,
    url: '/insuranceContract/deleteEndorsements',
    body: p,
  }),
  mapResult: ({ result }): DetachContractEndorsementFromInsuranceContractRes => result.data,
})

/**
 * CONTRACT list
 */

export const getTenentLeaseListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetContractListParams) => ({
    method: Method.post,
    url: '/tenantLease/list',
    body: p,
  }),
  mapResult: ({ result }): GetTenantLeaseListRes => result.data,
  mapError: ({ error }) => new Error(),
})

export const getServiceProviderListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetContractListParams) => ({
    method: Method.post,
    url: '/serviceProviderContract/list',
    body: p,
  }),
  mapResult: ({ result }): GetServiceProviderListRes => result.data,
  mapError: ({ error }) => new Error(),
})

export const getInsuranceListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: GetContractListParams) => ({
    method: Method.post,
    url: '/insuranceContract/list',
    body: p,
  }),
  mapResult: ({ result }): GetInsuranceListRes => result.data,
  mapError: ({ error }) => new Error(),
})
