import * as React from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconName } from '@/ui/icons'
import { useClickOutside } from '@/lib/click-outside'
import { themeVar, useTheme, ThemedStyledProps } from '../theming'

type Props<Value> = {
  items: Item<Value>[];
  name?: string;
  value?: Value;
  icon?: IconName;
  activeField?: React.ReactChild;
  onChange: (val: Value) => void;
  errorText?: string;
}

type Item<Value> = {
  value: Value;
  text: string;
  icon?: IconName;
}

export function SelectShort<Value>({
  items, value, name, icon, onChange, activeField, errorText,
}: Props<Value>) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    setOpen(false)
  })

  const onClickItem = (item: Item<Value>) => {
    onChange(item.value)
    setOpen(!open)
  }

  const hasError = Boolean(errorText)

  const getIcon = (val: Value): IconName => items.find((x) => x.value === val)?.icon || 'flagFr'

  const listItems = items.map((item, index) => (
    <ListItem
      key={index.toString() + item.value}
      selected={value === item.value}
      onClick={() => { onClickItem(item) }}
    >
      <ValueWrap>
        {item.text}
      </ValueWrap>
      {item.icon && (
        <IconWrap>
          <Icon
            icon={item.icon}
            width="30px"
          />
        </IconWrap>
      )}
    </ListItem>
  ))
  const getText = (val: Value) => items.find((el) => el.value === val)?.text

  const mainContent = (
    <MainValue onClick={() => setOpen(!open)}>

      <ValueWrap>
        {!value ? (name) : (getText(value))}
      </ValueWrap>

      {value && (
        <IconWrap>
          <Icon
            icon={getIcon(value)}
          />
        </IconWrap>
      )}
      <List open={open}>
        {listItems}
      </List>
    </MainValue>
  )

  return (
    <Wrap ref={ref}>
      {open && activeField}
      {(!open || !activeField) && mainContent}


      {hasError && (
        <ErrorText>{errorText}</ErrorText>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  max-width: 85px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  
  font-size: 16px;
  justify-content: space-between;
  cursor: pointer;
  
  user-select: none;
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputSelectTextColor')};
`

const IconWrap = styled.div`
    margin-left: 2px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    transition: transform .3s;
    width: 30px;
    &>svg{
      fill: ${themeVar('inputSelectArrowColor')} !important;
    }
`
type StyledListProps = {
  open: boolean;
} & ThemedStyledProps

const List = styled.div<StyledListProps>`
  position: absolute;
  width:100%;
  top: calc(100% - 4px);
  left: 0;
  display: flex;
  flex-wrap: wrap;
  background: ${themeVar('inputSelectMainColor')};
  padding-top: 4px;
  display: none;
  ${({ open }) => open && css`
    display: block;
  `};
  z-index: 10;
  max-height: 192px;
  overflow-y: auto;
`
type StyledItemProps = {
  selected: boolean;
} & ThemedStyledProps

const ListItem = styled.div<StyledItemProps>`
  display: flex;
  padding: 7px 13px;
  width: 100%;
  text-align: left;
  min-height: 32px;
  fill: ${themeVar('inputSelectSelectedColor')};
  ${({ selected, theme }) => selected && css`
    background: ${theme.inputSelectSelectedColor};
    color: ${theme.inputSelectSelectedTextColor};
    fill: ${theme.inputSelectSelectedTextColor};
  `};
  
  &:hover {
    background: ${themeVar('inputSelectSelectedColor')};
    color: ${themeVar('inputSelectSelectedTextColor')};
    fill: ${themeVar('inputSelectSelectedTextColor')};
  }
`

const ValueWrap = styled.div`
  flex: 1;
  text-align: left;
  font-weight: 300;
`

const MainValue = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 11px;
  background: ${themeVar('inputSelectMainColor')};
  border-radius: 4px;
`

const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
  width: 100%;
`
