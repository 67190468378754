// todo: Проверить необходимость этого
import { attachWrapper } from '@/lib/effector-extra'

import { Method, requestFx } from './rest-api'
import {
  FetchCateringListParams,
  FetchCateringListRes,
  CreateCateringOrderParams,
  CreateCateringOrderRes,
  ChangeCateringOrderStatusParams,
  ChangeCateringOrderStatusRes,
  CreateCateringOrderCommentParams,
  CreateCateringOrderCommentRes,
  DeleteCateringOrderParams,
  FetchCateringSuppliersListRes,
  MarkCateringOrderAsSeenParams,
  FetchCateringSupplierCategoriesListParams,
  FetchCateringSupplierCategoriesListRes,
  FetchCateringSupplierCategoryRes, FetchCateringSupplierCategoryParams,
} from './catering.interfaces'

export const fetchCateringListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCateringListParams) => ({
    method: Method.post,
    url: '/catering/list',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): FetchCateringListRes => ({} as FetchCateringListRes),
  mapError: () => new Error(),
})

export const createCateringOrderReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateCateringOrderParams) => ({
    method: Method.post,
    url: '/catering/add',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): CreateCateringOrderRes => ({} as CreateCateringOrderRes),
  mapError: () => new Error(),
})

export const acceptCateringOrderReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: ChangeCateringOrderStatusParams) => ({
    method: Method.post,
    url: '/catering/accept',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): ChangeCateringOrderStatusRes => ({} as ChangeCateringOrderStatusRes),
  mapError: () => new Error(),
})

export const rejectCateringOrderReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: ChangeCateringOrderStatusParams) => ({
    method: Method.post,
    url: '/catering/reject',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): ChangeCateringOrderStatusRes => ({} as ChangeCateringOrderStatusRes),
  mapError: () => new Error(),
})

export const createCateringOrderCommentReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: CreateCateringOrderCommentParams) => ({
    method: Method.post,
    url: '/catering/comment',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): CreateCateringOrderCommentRes => ({} as CreateCateringOrderCommentRes),
  mapError: () => new Error(),
})

export const deleteCateringOrderReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: DeleteCateringOrderParams) => ({
    method: Method.delete,
    url: '/catering/delete',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): void => undefined,
  mapError: () => new Error(),
})

export const markCateringOrderAsSeenReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: MarkCateringOrderAsSeenParams) => ({
    method: Method.post,
    url: '/catering/markAsSeen',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): void => undefined,
  mapError: () => new Error(),
})

export const fetchCateringSuppliersListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: void) => ({
    method: Method.post,
    url: '/catering/suppliers/list',
    fake: { success: true },
  }),
  mapResult: ({ result }): FetchCateringSuppliersListRes => ({} as FetchCateringSuppliersListRes),
  mapError: () => new Error(),
})

export const fetchCateringSupplierCategoriesListReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCateringSupplierCategoriesListParams) => ({
    method: Method.post,
    url: '/catering/suppliers/category/list',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): FetchCateringSupplierCategoriesListRes => ({} as FetchCateringSupplierCategoriesListRes),
  mapError: () => new Error(),
})

export const fetchCateringSupplierCategoryReqFx = attachWrapper({
  effect: requestFx,
  mapParams: (p: FetchCateringSupplierCategoryParams) => ({
    method: Method.post,
    url: '/catering/suppliers/category/get',
    body: p,
    fake: { success: true },
  }),
  mapResult: ({ result }): FetchCateringSupplierCategoryRes => ({} as FetchCateringSupplierCategoryRes),
  mapError: () => new Error(),
})
