import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'
import { Card, TabControl, Heading } from '@/ui'
import {
  General, Payment, Security, Documents,
} from '../containers'
import {
  $activeTab, setTab, loadContract, $tenantContract,
} from '../../model/main'

export const ContractPage = () => {
  const { id } = useParams()
  const tenantContract = useStore($tenantContract)
  const { t } = useTranslation('tenant/main')
  const activeTab = useStore($activeTab)

  React.useEffect(() => {
    if (id && id !== tenantContract?.id) loadContract(id)
  }, [])

  React.useEffect(() => {
    document?.getElementById('mainLayout')?.scroll({ top: 0, behavior: 'smooth' })
  }, [activeTab])

  const stepList = [
    { name: `1. ${t('general')}`, value: 'general' },
    { name: `2. ${t('payment')}`, value: 'payment', disabled: tenantContract === null },
    { name: `3. ${t('security')}`, value: 'security', disabled: tenantContract === null },
    { name: `4. ${t('documents&approval')}`, value: 'documents', disabled: tenantContract === null },
  ]

  const activeContent = (step: typeof stepList[number]['value']) => {
    switch (step) {
      case 'general':
        return (<General />)
      case 'payment':
        return (<Payment />)
      case 'security':
        return (<Security />)
      case 'documents':
        return (<Documents />)
      default:
        return null
    }
  }
  return (
    <>
      <PageTitle color="white">
        {tenantContract?.id ? t('pageTitleEdit') : t('pageTitleNew') }
        {' '}
        {t('pageTitleTenant')}
      </PageTitle>
      <Card>
        <TabControl
          items={stepList}
          value={activeTab}
          onItemClick={(val) => setTab(val.value)}
        />
        {activeContent(activeTab)}
      </Card>
    </>
  )
}

const PageTitle = styled(Heading)`
    color: white;
    margin: 20px 0;
`
