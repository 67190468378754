import * as React from 'react'
import styled from 'styled-components'

import { Card } from '@/ui'

import {
  Wrap,
  Title,
  Subtitle,
  Text,
  Ul,
  Li,
  Link,
} from '../parts'

export const TosEN = () => (
  <Wrap>
    <Title>
      ARTICLE 1: PURPOSE
    </Title>
    <Text>
      The purpose of these "general conditions of use" is the legal framework of the terms of provision of the services
      of the site
      {' '}
      <Link href="http://www.snouki.fr" blank>http://www.snouki.fr</Link>
      {' '}
      and their use by the "user".
    </Text>
    <Text>
      The general conditions of use must be accepted by any user wishing to access the site. Access to the site by the
      user signifies his acceptance of these general conditions of use.
    </Text>
    <Text>
      Possibly:
    </Text>
    <Ul>
      <Li>
        In case of non-acceptance of the general conditions of use, the user must renounce access to the services
        offered by the site.
      </Li>
      <Li>
        SNOUKI reserves the right to modify unilaterally and at any time the content of these general conditions of use.
      </Li>
    </Ul>


    <Title>
      ARTICLE 2: LEGAL NOTICE
    </Title>
    <Subtitle>
      1. Privacy Policy at Snouki
    </Subtitle>
    <Text>
      The privacy policy concerns all practices related to the use of your personal data on the Snouki site this
      policy also applies to all applications, media and Communication Operations issued by Snouki that may use personal data.
    </Text>

    <Subtitle>
      2. The data collected
    </Subtitle>
    <Ul>
      <Li noMarker>
        <Subtitle>
          Interactions on
          {' '}
          <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
        </Subtitle>

        <Text>
          As part of a registration on www.snouki.fr, a subscription to newsletter alerts or a request made via the
          account opening form, www.snouki.fr collection of data about you.
        </Text>
        <Text>
          It can be your name, first name, civility, address, Zip code, the city in which you live, your email address.
          Based on your interaction with the site www.snouki.fr some of this data may be mandatory while others are
          optional. This information can be updated by you in your personal space and can be combined to offer you
          a more suitable offer.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Snouki newsletter alerts
        </Subtitle>
        <Text>
          The various communications from Snouki that you can receive by email are traced. This tracking allows us
          to know if you have received, opened and possibly interacted with the transmitted content. If you do not
          wish to communicate information via Snouki emails, you have the option to unsubscribe by changing your
          alert preferences.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Internet advertising
        </Subtitle>
        <Text>
          Snouki may be required to perform advertising on the internet. These advertisements appear to the extent that
          your browsing has been targeted by our various campaigns. This targeting can be done from a geographical
          point of view but also if the site on which the advertisement appears has a potential interest related to
          the activity of Snouki. Tracking can be done following a visit from an internet advertisement via a tracking
          tag, a programming script to measure the behavior of an internet user.
        </Text>
      </Li>

      <Li noMarker>
        <Subtitle>
          Social networks
        </Subtitle>
        <Text>
          Snouki has a presence on Facebook as well as on other social networks. Via buttons, it is possible to
          follow @Snouki on twitter as well as "like" Snouki on Facebook. The information suggested by these actions
          may possibly be used by the social network concerned. Consult their privacy policy is therefore necessary to
          know in detail the possible consequences.
        </Text>
        <Text>
          In addition, it is possible to register on Snouki through a Facebook account. The information collected
          through this operation can be your name, first name, email address, profile picture, city in which you live
          and date of birth. These updates in your account and may be used and combined by Snouki for the purpose of
          providing you with the most suitable offer.
        </Text>
      </Li>
    </Ul>

    <Subtitle>
      3. Changes to your data
    </Subtitle>
    <Text>
      In accordance with the Data Protection Act (GDPR certified), you are free to modify or delete your personal data
      at any time. This can be done by logging into your account on
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      . our customer service is also
      available from our contact form. Although you are unsubscribed from our email alerts, your data may be retained.
    </Text>

    <Subtitle>
      4. Modification of the Privacy Policy
    </Subtitle>
    <Text>
      This privacy policy is subject to change at any time. Any significant changes will be communicated to you.
      In case of change, the fact that you continue to use the features of the site www.snouki.fr is equivalent to
      the acceptance of the latter.
    </Text>

    <Subtitle>
      5. Use of collected data
    </Subtitle>
    <Text>
      Snouki uses the collected data for several purposes :
    </Text>
    <Ul>
      <Li>
        Communicate to you the most suitable offer possible according to the elements in our possession
      </Li>
      <Li>
        Improve our content in order to offer you the best possible navigation on the site
        {' '}
        <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      </Li>
      <Li>
        Answer as best as possible the questions you ask us (depending on your store for example)
      </Li>
    </Ul>

    <Subtitle>
      6. Cookies
    </Subtitle>
    <Text>
      Your browsing on
      {' '}
      <Link href="http://www.snouki.fr" blank>www.snouki.fr</Link>
      {' '}
      may give rise to the use of cookies. These are small volumes installed by your
      browser on your hard drive. Once recognized, they allow us to inform us about the characteristics of your
      visit to our site. By default, most browsers accept cookies. However, you can change this choice so that your
      visit is not counted in our audience measurement tools. If necessary, this does not interfere with navigation
      on the site and all the features of the latter remain accessible. You will find more information on the
      CNIL website.
    </Text>
    <Text>
      Snouki is required to use 3 types of cookies :
    </Text>
    <Ul>
      <Li>
        <b>Session or preference cookies: </b>
        these cookies are essential for navigation and the proper functioning
        of the site. Therefore, you can not object to their use.
      </Li>
      <Li>
        <b>Audience measurement cookies: </b>
        these cookies allow you to track your navigation on the site for statistical
        purposes and to allow Snouki to better know its audience to improve its service, you can object to their use.
      </Li>
      <Li>
        <b>Third party cookies: </b>
        these cookies are placed by partners in commercial or advertising partnerships. You can object to their use.
      </Li>
    </Ul>


    <Title>
      ARTICLE 3 : DEFINITIONS
    </Title>
    <Text>
      The purpose of this clause is to define the various essential terms of the general conditions of use:
    </Text>
    <Ul>
      <Li>
        User: this term refers to any person who uses the site or any of the services offered by the site.
      </Li>
      <Li>
        User Content: these are the data transmitted by the user within the site.
      </Li>
      <Li>
        Member: the user becomes a member when he is identified on the site.
      </Li>
      <Li>
        Login and password: this is all the information necessary to identify a user on the site.
        The username and password allow the user to access services reserved for members of the site.
        The password is confidential
      </Li>
    </Ul>

    <Title>
      ARTICLE 4: ACCESS TO SERVICES
    </Title>
    <Text>
      The site allows the user access to the following information:
    </Text>
    <Ul>
      <Li>
        Geographical locations on a given site
      </Li>
      <Li>
        Connecting companies
      </Li>
      <Li>
        Speakers on a given site
      </Li>
      <Li>
        Contracts (lease, insurance, electricity …)
      </Li>
      <Li>
        ...
      </Li>
    </Ul>
    <Text>
      The site is accessible at no extra cost in any place to any user with access to the Internet.
      All costs incurred by the user to access the service (computer hardware, software, Internet connection, etc.)
      are borne by the user.
    </Text>
    <Text>
      The non-member user does not have access to MEMBER-ONLY services. For this, he must identify himself
      using his username and password.
    </Text>
    <Text>
      Any event due to a case of force majeure, or any cause outside the scope of intervention of &lt;user_name&gt;,
      resulting in particular a malfunction of the network or server does not engage the responsibility of &lt;user_name&gt;.
    </Text>
    <Text>
      Access to the services of the site may at any time be subject to interruption, suspension, modification without
      notice for maintenance. In this case, the User undertakes not to claim any compensation following the
      interruption, suspension or modification of the service.
    </Text>


    <Title>
      ARTICLE 5: INTELLECTUAL PROPERTY
    </Title>
    <Text>
      Trademarks, logos, signs and any other content of the site are protected by the Code of intellectual
      property and more particularly by copyright.
    </Text>
    <Text>
      The user requests the prior authorization of the site for any reproduction, publication, copy of the various
      contents.
    </Text>
    <Text>
      The User undertakes to use the contents of the site in a strictly private setting. Any use of
      the content for other purposes, including commercial purposes, is strictly prohibited.
    </Text>


    <Title>
      ARTICLE 6: PERSONAL DATA
    </Title>
    <Text>
      The information requested at the registration to the site is necessary and mandatory for the creation of
      the user&apos;s account. In particular, the email address may be used by the site for the administration,
      management and animation of the service.
    </Text>
    <Text>
      The site ensures the user a collection and processing of personal information in compliance with the GDPR that
      came into force on May 25. The user has a right of consent.
    </Text>
    <Text>
      The user has the right to access, rectify, delete and oppose his personal data. The user exercises this right:
    </Text>
    <Ul>
      <Li>
        By mail at
        {' '}
        <Link href="mailto:contact@snouki.fr">contact@snouki.fr</Link>
      </Li>
    </Ul>


    <Title>
      ARTICLE 7: LIABILITY AND FORCE MAJEURE
    </Title>
    <Text>
      The sources of the information disseminated on the site are deemed reliable. However, for data that is not
      directly produced by &lt;user_name&gt;, the site reserves the right to a non-guarantee of the reliability of the
      sources. This information given on the site is for informational purposes only. Thus, the user assumes sole
      responsibility for the use of this information and content of this site.
    </Text>
    <Text>
      The user makes sure to keep his password secret. Any disclosure of the password, in any form, is prohibited.
      The user assumes the risks associated with the use of his username and password. The site declines all
      responsibility.
    </Text>
    <Text>
      The responsibility of the site can not be engaged in the event of force majeure or the unpredictable and
      insurmountable fact of a third party or the fact of an intervenor outside &lt;user_name&gt;.
    </Text>


    <Title>
      ARTICLE 8: EVOLUTION OF THE GENERAL CONDITIONS OF USE
    </Title>
    <Text>
      The site reserves at any time the right to modify the clauses stipulated in these general conditions of Use.
    </Text>


    <Title>
      ARTICLE 9: DURATION
    </Title>
    <Text>
      The duration of these Terms and Conditions of use is defined by the agreement between SNOUKI and its users.
      These general conditions of use have their effects with respect to the user from the use of the service.
    </Text>


    <Title>
      ARTICLE 10: APPLICABLE LAW AND COMPETENT JURISDICTION
    </Title>
    <Text>
      French legislation applies to these general conditions of Use.
    </Text>
  </Wrap>
)
