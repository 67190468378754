import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import AvatarPlaceholderImg from 'Public/assets/img/avatar-placeholder.svg'

import { themeVar } from '@/ui'
import { $isAuth, $userInfo } from '@/features/app'
import { logout } from '@/features/autorization'
import { onCloseMenu } from '@/features/main-menu'


export const MenuHeader = () => {
  const { t } = useTranslation('main-menu')
  const isAuth = useStore($isAuth)

  const userInfo = useStore($userInfo)

  const onClick = () => {
    logout()
    onCloseMenu()
  }

  if (!isAuth || !userInfo) return null

  return (
    <Wrap>
      <Name>
        {t('welcome', { firstName: userInfo.firstName, lastName: userInfo.lastName })}
      </Name>

      <AvatarPlaceholderImg />

      <LogoutBtn onClick={onClick}>
        {t('logOut')}
      </LogoutBtn>
    </Wrap>
  )
}


const Wrap = styled.div`
  display: flex;
  width: 245px;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 0 auto 10px;
`

const Name = styled.div`
  width: 105px;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  font-weight: 300;
`

const LogoutBtn = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${themeVar('logoutButtonColor')};
  cursor: pointer;
`
