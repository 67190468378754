import { PersonalFileTabEmunITabEmunI, UserTabEmunI } from '@/features/app'
import { personalFile } from './domain'

export const setPersonFileStep = personalFile.event<PersonalFileTabEmunITabEmunI>()
export const personalFileCompleteStep = personalFile.event<PersonalFileTabEmunITabEmunI>()
export const showPersonFileCreatedModal = personalFile.event()
export const hidePersonFileCreatedModal = personalFile.event()
export const setUserStep = personalFile.event<UserTabEmunI>()
export const userCompleteStep = personalFile.event<UserTabEmunI>()
export const showSiteSearchModal = personalFile.event()
export const hideSiteSearchModal = personalFile.event()
