import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  InputField,
  PrimaryButton,
  Row,
  Col,
  SubTitle,
  themeVar,
  Select,
  InputDateTime,
  Icon,
  SmallText,
  AccentText,
  RadioButtonsList,
  InputTextArea,
} from '@/ui'
import { DocumentForm } from '../../model/document'

export const AddDocumentForm = () => {
  const { t } = useTranslation('tenant/main')
  const { fields } = useForm(DocumentForm)


  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  const fileRef = React.useRef<HTMLInputElement>(null)

  const uploadHandler = (filesList: FileList | null) => {
    // TODO: потому что работы с файлами нет
    // if (filesList) fields.content.onChange(filesList[0])
  }

  return (
    <BlockWrap>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
        <>
          <SubTitle>
            1.
            {' '}
            {t('documentName')}
            <sup>*</sup>
          </SubTitle>
          <InputField
            value={fields.name.value}
            onChangeValue={fields.name.onChange}
            errorText={fields.name.errorText()}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('version')}
          </SubTitle>
          <InputField
            value={fields.version.value}
            onChangeValue={fields.version.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('validityEndDate')}
          </SubTitle>
          <InputDateTime
            fill
            onlyDate
            value={fields.validityEndDate.value}
            onChange={(val) => fields.validityEndDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <Col width="100%" align="flex-start" noMargin noPadding>
            <>
              <SubTitle>
                4.
                {' '}
                {t('documentType')}
              </SubTitle>
              <InputField
                value={fields.documentType.value}
                onChangeValue={fields.documentType.onChange}
              />
            </>
          </Col>
          <Col width="100%" align="flex-start" noMargin noPadding>
            <>
              <SubTitle>
                5.
                {' '}
                {t('active')}
              </SubTitle>
              <RadioButtonsList
                name="active"
                items={YesNoItems}
                value={fields.active.value}
                onChange={fields.active.onChange}
              />
            </>
          </Col>
        </>
      </Col>
      <Col md="calc(100% / 3 * 2)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            6.
            {' '}
            {t('description')}
          </SubTitle>
          <InputTextArea
            value={fields.description.value}
            onChangeValue={fields.description.onChange}
          />
        </>
      </Col>
      <Row>
        <Col width="100%">
          <>
            <HiddenFileInput
              ref={fileRef}
              type="file"
              onChange={(e) => uploadHandler(e.currentTarget.files)}
            />

            <PrimaryButton
              size="large"
              type="inverse"
              appendIcon="upload"
              onClick={() => { fileRef?.current?.click() }}
            >
              {t('content')}
              <sup>
                <AccentText>
                  *
                </AccentText>
              </sup>
            </PrimaryButton>
          </>
        </Col>
      </Row>
      <Col width="100%" noPadding noMargin>
        <Row>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              outline
              fluid
              type="warning"
              appendIcon="close"
            >
              {t('clear')}
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="180px" noMargin>
            <PrimaryButton
              fluid
              type="inverse"
              appendIcon="plus"
            >
              {t('add')}
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </BlockWrap>
  )
}


const BlockWrap = styled.form<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 34px;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
  & [class*="ErrorText"] {
    color: white !important;
  }
`

const HiddenFileInput = styled.input`
  display:none;
`
