import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const NormalizeCss = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    overflow: hidden;
  }
  html, body, #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  body {
    display: flex;
    width: 100%;
  }
  .open-modal{
    overflow: hidden !important;
  }
  a {
    text-decoration: none;
  }
  ${normalize}
`
