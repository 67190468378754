import * as React from 'react'
import styled, { css } from 'styled-components'

import { Card, PrimaryButton } from '@/ui'

interface Props {
  disabled?: boolean;
  onClick: () => any;
}

export const CardMe = ({ disabled, onClick }: Props) => (
  <Wrap>
    <Title>
      REAL ESTATE PROFESSIONAL
    </Title>
    <Desc>
      This offer concerns trustees, asset managers, les architects… want to manage their property assets.
    </Desc>
    <ImageAndPriceWrap>
      <Image src="/assets/img/plan-ME.svg" />

      <PricesWrap>
        <PriceBlock>
          <PriceBg src="/assets/img/price-orange.svg" />
          <PriceContent>
            <PricePrice>
              €4000
            </PricePrice>
            <PriceDesc>
              Per year for 1000 premises
            </PriceDesc>
          </PriceContent>
        </PriceBlock>
      </PricesWrap>
    </ImageAndPriceWrap>

    <FeaturesList>
      <Feature>
        <FeatureText>Put an intervention demand</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Consult a provider’s quotation</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Relationship between providers and tenants on a site</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Put a storage of his documents </FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Launch tenders</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>A dedicated hotline and an interlocutor</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Tutorial videos</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Assistance and advice</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>A template library (rental contract, receipt …)</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Manage intervention demands</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureText>Purchase of supplies</FeatureText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>

      <Feature>
        <ComingSoonText>
          coming soon
        </ComingSoonText>
      </Feature>

      <Feature>
        <FeatureSoonText>Make reports in any format</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Manage invoicing</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Meter reading</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Draw up an inventary</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Manage regulatory control</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Manage contracts (property deeds, insurances, leases, charges, services) and deadlines</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Put financial provision to plan work</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>
          Breakfast and tray meals orders associated
          with reservation room
        </FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>
          Comply equipment
        </FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>Book a meeting room</FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>
      <Feature>
        <FeatureSoonText>
          Equipment and works, and stock
          management
        </FeatureSoonText>
        <img src="/assets/img/check.svg" alt="" />
      </Feature>

      <ChooseButton
        disabled={disabled}
        onClick={onClick}
      >
        choose this plan
      </ChooseButton>
    </FeaturesList>
  </Wrap>
)

const Wrap = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 336px;
  padding-bottom: 40px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 20px 30px 10px;
`

const Desc = styled.div`
  width: 250px;
  color: #A1A1A1;
  font-size: 12px;
  line-height: 14px;
  margin: 0 30px;
`

const ImageAndPriceWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 195px;
  margin-top: 20px;
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: -40px;
  left: 0;
  user-select: none;
  pointer-events: none;
`

const PricesWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 168px;
`

const PriceBlock = styled.div`
  position: relative;
  width: 100%;
  color: white;
  padding: 10px 28px 10px 21px;
  margin-top: 30px;
`

const PriceBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  z-index: 1;
`

const PriceContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const PriceDesc = styled.div`
  font-size: 8px;
  line-height: 10px;
  margin-top: 5px;
`

const PricePrice = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
`

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Feature = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px 49px 10px 29px;
  background-color: #FBFBFB;
  color: black;
  font-size: 12px;
  line-height: 15px;
  &:nth-of-type(2n) {
   background-color: transparent; 
  }
`

const FeatureText = styled.div`
  margin-left: 7px;
  text-align: right;
`

const FeatureSoonText = styled(FeatureText)`
  color: #A1A1A1;
`

const ComingSoonText = styled.div`
  color: #FB8C5D;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
`

const ChooseButton = styled(PrimaryButton)`
  margin: 40px 32px 0;
`

const IconsWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 6px;
`
