import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  InputField,
  Select,
  InputDateTime,
  RadioButtonsList,
  InputTextArea,
  SubTitle,
  AccentText,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import { ImagePicker } from '../../parts'
import { personalFileCompleteStep, setPersonFileStep } from '../../../model'

export const Person = () => {
  const [personImage, setPersonImage] = React.useState<File | null>(null)
  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [entity, setEntity] = React.useState<string>('')
  const [organisationLocation, setOrganisationLocation] = React.useState<string>('')
  const [title, setTitle] = React.useState<string>('')
  const [marriedLastName, setMarriedLastName] = React.useState<string>('')
  const [lastNameOfUse, setLastNameOfUse] = React.useState<string>('')
  const [firstNameOfUse, setFirstNameOfUse] = React.useState<string>('')
  const [dateOfBirth, setDateOfBirth] = React.useState<string | null>(new Date().toISOString())
  const [additionalCode, setAdditionalCode] = React.useState<string>('')
  const [status, setStatus] = React.useState<string>('')
  const [vip, setVip] = React.useState<boolean>(false)
  const [description, setDescription] = React.useState<string>('')

  const locationItems = getMock().selects.newEmployee.location
  const statusItems = getMock().selects.newEmployee.status
  const vipItems = getMock().selects.newEmployee.vip

  const confirmFormAction = () => {
    personalFileCompleteStep('person')
    setPersonFileStep('location')
  }

  return (
    <Wrap>
      <>
        <Row>
          <ImagePicker
            file={personImage}
            onFile={setPersonImage}
          />
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                1. first name
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={firstName}
                onChangeValue={setFirstName}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                2. last name
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={lastName}
                onChangeValue={setLastName}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                3. email
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={email}
                onChangeValue={setEmail}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                4. entity
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={entity}
                onChangeValue={setEntity}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                5. organisation location
                <AccentText>*</AccentText>
              </SubTitle>
              <Select
                name="organisation location"
                value={organisationLocation}
                items={locationItems}
                onChange={setOrganisationLocation}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                6. title
              </SubTitle>
              <InputField
                value={title}
                onChangeValue={setTitle}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                7. married last name
              </SubTitle>
              <InputField
                value={marriedLastName}
                onChangeValue={setMarriedLastName}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                8. last name of use
              </SubTitle>
              <InputField
                value={lastNameOfUse}
                onChangeValue={setLastNameOfUse}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                9. first name of use
              </SubTitle>
              <InputField
                value={firstNameOfUse}
                onChangeValue={setFirstNameOfUse}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                10. date of birth
              </SubTitle>
              <InputDateTime
                value={dateOfBirth}
                onChange={setDateOfBirth}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                11. additional code
              </SubTitle>
              <InputField
                value={additionalCode}
                onChangeValue={setAdditionalCode}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                12. status
              </SubTitle>
              <Select
                name="status"
                value={status}
                items={statusItems}
                onChange={setStatus}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                13. vip
              </SubTitle>
              <RadioButtonsList
                name="vip"
                value={vip}
                items={vipItems}
                onChange={setVip}
              />
            </>
          </Col>
          <Col xs="100%" md={2} align="flex-start">
            <>
              <SubTitle>
                14. description
              </SubTitle>
              <InputTextArea
                value={description}
                onChangeValue={setDescription}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md="180px">
            <PrimaryButton appendIcon="rightChevron" onClick={() => confirmFormAction()}>
              next step
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}

const Wrap = styled.div`
    padding: 20px 40px 20px 18px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 40px;
    `}
`
