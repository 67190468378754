import { consultation } from './domain'

export const showCreateConsultationDialog = consultation.event()
export const hideCreateConsultationDialog = consultation.event()
export const createConsultation = consultation.event()
export const hideConsultationCreatedModal = consultation.event()
export const showConsultationInfoDialog = consultation.event()
export const hideConsultationInfoDialog = consultation.event()
export const showConsultationValidateDialog = consultation.event()
export const hideConsultationValidateDialog = consultation.event()
export const validateConsultation = consultation.event()
export const hideValidatedConsultationDialog = consultation.event()
export const showCreateParticipantDialog = consultation.event()
export const hideCreateParticipantDialog = consultation.event()
export const createParticipant = consultation.event()
export const hideParticipantValidateDialog = consultation.event()
export const approveParticipant = consultation.event()
export const showParticipantValidateDialog = consultation.event()
export const hideParticipantInfoDialog = consultation.event()
export const showParticipantInfoDialog = consultation.event()
