import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  Icon, IconName,
} from '../../icons'
import { themeVar, ThemedStyledProps, useTheme } from '../../theming'

type FilterProps = {
  children: React.ReactChild;
  title?: string;
  count?: number;
}

export const Filter = ({ children, count, title }: FilterProps) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <FilterWrap>
      <Activator onClick={() => setOpen(!open)}>
        sort by
        <IconWrap
          style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
        >
          <Icon
            icon="arrow"
            fill={theme.dataTableMobileFilterColor}
            width={15}
          />
        </IconWrap>
      </Activator>
      <Count>
        {count}
        {' '}
        entries
      </Count>
      <HiddenContent open={open}>
        <FilterTitle>
          {title}
        </FilterTitle>
        {children}
      </HiddenContent>
    </FilterWrap>
  )
}

const FilterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${themeVar('dataTableMobileFilterColor')};
  background: ${themeVar('dataTableMobileFilterBg')};
  font-family: ${themeVar('baseFont')};
  width: 100%;
  padding: 20px 25px;
`
type HiddenContentProps = {
  open?: boolean;
} & ThemedStyledProps

const HiddenContent = styled.div<HiddenContentProps>`
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  ${({ open }) => open && css`
    display: flex;
    margin-top: 20px;
  `}
`

const Activator = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
`
const Count = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  font-size: 14px;
`

const IconWrap = styled.div`
    margin-left: 3px;
    display: flex;
    align-items: center;
    transition: transform .3s;
`

const FilterTitle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
`
