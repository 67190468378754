import * as React from 'react'
import styled from 'styled-components'
import {
  Col,
  InputField,
  Select,
  SubTitle,
  RadioButtonsList,
  WrapField,
} from '@/ui'
import { getMock } from '@/features/app'

export const ExternalForm = () => {
  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [entity, setEntity] = React.useState<string | number | boolean | null>(null)
  const [phoneNumber, setPhoneNumber] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [identity, setIdentity] = React.useState<string | number | boolean | null>(null)
  const [parking, setParking] = React.useState<string | number | boolean | null>(null)
  const [plateNumber, setPlateNumber] = React.useState<string>('')
  const [luggageNumber, setLuggageNumber] = React.useState<string>('')
  const [cloakroom, setCloakroom] = React.useState<string>('')
  const [badge, setBadge] = React.useState<string>('')
  const SelectEntityItems = (name: string) => [
    { value: 1, text: `${name} Uno` },
    { value: 2, text: `${name} Dos` },
    { value: 3, text: `${name} Tres` },
  ]

  const YesNo = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ]
  return (
    <FormWrap>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>1. person</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="First name"
            value={firstName}
            onChangeValue={(val) => setFirstName(val)}
          />
        </WrapField>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="Last name"
            value={lastName}
            onChangeValue={(val) => setLastName(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>2. entity</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose entity"
            items={getMock().selects.preRegistration.entity}
            value={entity}
            onChange={(val) => setEntity(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>3. phone number</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            name="phone number"
            placeholder="+7"
            value={phoneNumber}
            onChangeValue={(val) => setPhoneNumber(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>4. email</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="Your email"
            value={email}
            onChangeValue={(val) => setEmail(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>5. identity</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <Select
            name="Choose identity"
            items={getMock().selects.preRegistration.identity}
            value={identity}
            onChange={(val) => setIdentity(val)}
          />
        </WrapField>
      </Col>
      <Col width="50%" noPadding>
        <>
          <Col width="100%" noPadding align="flex-start">
            <SubTitle>6. parking</SubTitle>
          </Col>
          <Col noPadding width="100%">
            <WrapField>
              <RadioButtonsList
                name="cloakroom"
                items={YesNo}
                value={parking}
                onChange={(val) => setParking(val)}
              />
            </WrapField>
          </Col>
        </>
      </Col>
      <Col width="50%" noPadding>
        <>
          <Col width="100%" noPadding align="flex-start">
            <SubTitle>plate number</SubTitle>
          </Col>
          <Col noPadding width="100%">
            <WrapField>
              <InputField
                placeholder="Plate number"
                value={plateNumber}
                onChangeValue={(val) => setPlateNumber(val)}
              />
            </WrapField>
          </Col>
        </>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>7. luggage number</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="Enter luggage number"
            value={luggageNumber}
            onChangeValue={(val) => setLuggageNumber(val)}
          />
        </WrapField>
      </Col>
      <Col width="100%" noPadding align="flex-start">
        <SubTitle>8. cloakroom </SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="Enter cloaroom"
            value={cloakroom}
            onChangeValue={(val) => setCloakroom(val)}
          />
        </WrapField>
      </Col>

      <Col width="100%" noPadding align="flex-start">
        <SubTitle>9. badge</SubTitle>
      </Col>
      <Col noPadding width="100%">
        <WrapField>
          <InputField
            placeholder="Enter badge"
            value={badge}
            onChangeValue={(val) => setBadge(val)}
          />
        </WrapField>
      </Col>

    </FormWrap>
  )
}
const FormWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
