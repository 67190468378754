import * as React from 'react'
import { useField } from 'effector-forms'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  ContractFormTitle,
  SubTitle,
  NumberInput,
  RadioButtonsList,
  Select,
  InputDateTime,
} from '@/ui'
import { GeneralForm } from '../../../model/general'

export const Dates = () => {
  const { t } = useTranslation('tenant/main')
  const effectiveDate = useField(GeneralForm.fields.effectiveDate)
  const dateOfSigning = useField(GeneralForm.fields.dateOfSigning)
  const movingInToPremisesDate = useField(GeneralForm.fields.movingInToPremisesDate)
  const leaseDurationMonths = useField(GeneralForm.fields.leaseDurationMonths)
  const leaseDurationYears = useField(GeneralForm.fields.leaseDurationYears)
  const firm = useField(GeneralForm.fields.firm)
  const expirationDate = useField(GeneralForm.fields.expirationDate)
  const deadlineToDenunciationDate = useField(GeneralForm.fields.deadlineToDenunciationDate)
  const leaseDurationType = useField(GeneralForm.fields.leaseDurationType)

  const leaseDurationTypeItems = [
    { text: t('standart'), value: true },
    { text: t('nonStandart'), value: false },
  ]
  const leaseDurationItems: {text: string; value: '3' | '6' | '9' | '10' | '12' | ''}[] = [
    { text: t('notSelected'), value: '' },
    { text: t('3years'), value: '3' },
    { text: t('6years'), value: '6' },
    { text: t('9years'), value: '9' },
    { text: t('10years'), value: '10' },
    { text: t('12years'), value: '12' },
  ]
  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  return (
    <Row align="flex-start">
      <Col width="100%" align="flex-start" noMargin>
        <ContractFormTitle>{t('dates')}</ContractFormTitle>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            1.
            {' '}
            {t('effectiveDate')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={effectiveDate.value}
            onChange={(val) => effectiveDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            2.
            {' '}
            {t('dateOfSigning')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={dateOfSigning.value}
            onChange={(val) => dateOfSigning.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('dateOfMovingInToPremises')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={movingInToPremisesDate.value}
            onChange={(val) => movingInToPremisesDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            4.
            {' '}
            {t('leaseDuration')}
          </SubTitle>
          <RadioButtonsList
            name="leaseDurationType"
            items={leaseDurationTypeItems}
            value={leaseDurationType.value}
            onChange={leaseDurationType.onChange}
          />
        </>
      </Col>
      {leaseDurationType.value && (
      <Col md="calc(100% / 3)" xs="100%" vAlign="flex-end" align="flex-start">
        <>
          <Select
            name="Select duration"
            items={leaseDurationItems}
            value={leaseDurationYears.value}
            onChange={leaseDurationYears.onChange}
          />

        </>
      </Col>
      )}
      {!leaseDurationType.value && (
        <>
          <Col md="calc(100% / 3)" xs="100%" vAlign="flex-start" align="flex-start">
            <>

              <SubTitle>
                {t('leaseDurationYears')}
              </SubTitle>
              <NumberInput
                value={leaseDurationYears.value}
                onChangeValue={leaseDurationYears.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" xs="100%" vAlign="flex-start" align="flex-start">
            <>

              <SubTitle>
                {t('leaseDurationMonth')}
              </SubTitle>
              <NumberInput
                value={leaseDurationMonths.value}
                onChangeValue={leaseDurationMonths.onChange}
              />
            </>
          </Col>
        </>
      )}
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            5.
            {' '}
            {t('firm')}
          </SubTitle>
          <RadioButtonsList
            name="firm"
            items={YesNoItems}
            value={firm.value}
            onChange={firm.onChange}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            6.
            {' '}
            {t('expiryDate')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={expirationDate.value}
            onChange={(val) => expirationDate.onChange(val || '')}
          />
        </>
      </Col>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            7.
            {' '}
            {t('deadlineForDenunciation')}
          </SubTitle>
          <InputDateTime
            onlyDate
            value={deadlineToDenunciationDate.value}
            onChange={(val) => deadlineToDenunciationDate.onChange(val || '')}
          />
        </>
      </Col>
    </Row>

  )
}
