import { report } from './domain'

export const showCreateOrderDialog = report.event()
export const hideCreateOrderDialog = report.event()
export const createOrder = report.event()
export const hideOrderCreatedModal = report.event()
export const showOrderInfoDialog = report.event()
export const hideOrderInfoDialog = report.event()
export const showAdminReportInfoDialog = report.event()
export const hideAdminReportInfoDialog = report.event()
