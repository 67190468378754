import {
  $isCreateConsultationDialogShown,
  $isConsultationCreatedDialogShown,
  $isConsultationInfoDialogShown,
  $isValidateConsultationDialogShown,
  $isConsultationValidatedDialogShown,
  $isCreateParticipantDialogShown,
  $isParticipantInfoDialogShown,
  $isParticipantValidateDialogShown,
} from './state'
import {
  showCreateConsultationDialog,
  hideCreateConsultationDialog,
  createConsultation,
  hideConsultationCreatedModal,
  hideConsultationInfoDialog,
  showConsultationInfoDialog,
  showConsultationValidateDialog,
  hideConsultationValidateDialog,
  validateConsultation,
  hideValidatedConsultationDialog,
  createParticipant,
  hideCreateParticipantDialog,
  showCreateParticipantDialog,
  showParticipantInfoDialog,
  approveParticipant,
  hideParticipantInfoDialog,
  hideParticipantValidateDialog,
  showParticipantValidateDialog,
} from './events'


$isCreateConsultationDialogShown
  .on(showCreateConsultationDialog, () => true)
  .on(hideCreateConsultationDialog, () => false)
  .on(createConsultation, () => false)

$isConsultationCreatedDialogShown
  .on(createConsultation, () => true)
  .on(hideConsultationCreatedModal, () => false)

$isConsultationInfoDialogShown
  .on(showConsultationInfoDialog, () => true)
  .on(hideConsultationInfoDialog, () => false)

$isValidateConsultationDialogShown
  .on(showConsultationValidateDialog, () => true)
  .on(hideConsultationValidateDialog, () => false)
  .on(validateConsultation, () => false)

$isConsultationValidatedDialogShown
  .on(validateConsultation, () => true)
  .on(hideValidatedConsultationDialog, () => false)

$isCreateParticipantDialogShown
  .on(showCreateParticipantDialog, () => true)
  .on(hideCreateParticipantDialog, () => false)
  .on(createParticipant, () => false)

$isParticipantValidateDialogShown
  .on(showParticipantValidateDialog, () => true)
  .on(hideParticipantValidateDialog, () => false)
  .on(approveParticipant, () => false)

$isParticipantInfoDialogShown
  .on(showParticipantInfoDialog, () => true)
  .on(hideParticipantInfoDialog, () => false)
