import { createGlobalStyle } from 'styled-components'


function fontFace(name: string, src: string, fontWeight: number | 'normal' = 'normal', fontStyle: 'normal' | 'italic' = 'normal') {
  return `
      @font-face{
          font-family: "${name}";
          src: 
            url('/assets/fonts/${src}.woff2') format('woff2'),
            url('/assets/fonts/${src}.woff') format('woff'),
            url('/assets/fonts/${src}.otf') format('otf'),
            url('/assets/fonts/${src}.ttf') format('ttf');
  
          font-style: ${fontStyle};
          font-weight: ${fontWeight};
      }
  `
}


export const Fonts = createGlobalStyle`
  ${fontFace('Cocogoose Classic', 'cocogoose-classic-thin', 100, 'normal')}
  ${fontFace('Cocogoose Classic', 'cocogoose-classic-light', 300, 'normal')}
  ${fontFace('Cocogoose Classic', 'cocogoose-classic-regular', 400, 'normal')}
  ${fontFace('Cocogoose Classic', 'cocogoose-classic-bold', 600, 'normal')}
  ${fontFace('Cocogoose Classic', 'cocogoose-classic-extra-bold', 900, 'normal')}
  
  body{
    font-family: 'Cocogoose Classic';
  }
`
