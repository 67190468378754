import * as React from 'react'
import {
  Switch, Route,
} from 'react-router'
import { history } from '@/lib/history'


export type RouteConfig = {
  path: string;
  component: React.FC | typeof React.Component;
  type: 'private' | 'public' | 'guest';
  routes?: RouteConfig[];
}

export type RouterConfig = {
  routes: RouteConfig[];
  guestFallbackPath?: string;
  privateFallbackPath?: string;
}

type RouteGuardProps = {
  isAuth: boolean;
  route: RouteConfig;
  guestFallbackPath?: string;
  privateFallbackPath?: string;
}

const RouteGuard: React.FC<RouteGuardProps> = (props) => {
  const {
    children,
    route,
    isAuth,
    guestFallbackPath,
    privateFallbackPath,
  } = props

  React.useEffect(() => {
    if (isAuth && route.type === 'guest' && guestFallbackPath) {
      history.replace(guestFallbackPath)
    }
    if (!isAuth && route.type === 'private' && privateFallbackPath) {
      history.replace(privateFallbackPath)
    }
  }, [isAuth])

  if (isAuth && route.type === 'guest') {
    return null
  }

  if (!isAuth && route.type === 'private') {
    return null
  }

  const Component = route.component
  return (<Component>{children}</Component>)
}


const renderRoutes = (config: RouterConfig, isAuth: boolean) => {
  const renderRoutesTree = (routes: RouteConfig[], path: string) => routes.map((route) => {
    const innerPath = path + route.path

    return (
      <Route key={route.path} path={innerPath} exact={!route.routes?.length}>
        <RouteGuard
          route={route}
          isAuth={isAuth}
          guestFallbackPath={config.guestFallbackPath}
          privateFallbackPath={config.privateFallbackPath}
        >
          {
              route.routes && route.routes.length && (
                <Switch>
                  { renderRoutesTree(route.routes, innerPath) }
                </Switch>
              )
            }
        </RouteGuard>
      </Route>
    )
  })

  return renderRoutesTree(config.routes, '')
}

export type RoutesProps = {
  config: RouterConfig;
  isAuth: boolean;
}

export const Routes = ({ config, isAuth }: RoutesProps) => (
  <Switch>
    {renderRoutes(config, isAuth)}
  </Switch>
)
