import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { Row } from './Row'
import { themeVar } from '../theming'

type Props = {
    children: (open: boolean) => React.ReactChild;
    hiddenContent: React.ReactChild;
    hiddenContentActions?: React.ReactChild;
    open?: boolean;
    colorized?: boolean;
    onClick?: () => void;
    noChangeColor?: boolean;
    className?: string;
}

export const ExpandedRow = ({
  children,
  hiddenContent,
  hiddenContentActions,
  open = false,
  colorized = false,
  onClick = () => {},
  noChangeColor,
  className,
}: Props) => {
  const [showHiddenContent, setShowHiddenContent] = React.useState<boolean>(open)
  return (
    <ExpandedRowWrap
      noChangeColor={noChangeColor}
      colorized={colorized}
      open={showHiddenContent}
      onClick={() => onClick()}
      className={className}
    >
      <ExpandedRowContent
        onClick={() => setShowHiddenContent(!showHiddenContent)}
        noChangeColor={noChangeColor}
      >
        {children(showHiddenContent)}
      </ExpandedRowContent>
      <HiddenContent noChangeColor={noChangeColor} show={showHiddenContent}>
        {hiddenContent}
      </HiddenContent>
      <HiddenContentActions show={showHiddenContent}>
        {hiddenContentActions}
      </HiddenContentActions>
    </ExpandedRowWrap>
  )
}
const ExpandedRowContent = styled(Row)<{noChangeColor?: boolean}>`
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba(255, 245, 237, 0.25);
  ${({ noChangeColor }) => noChangeColor && css`
    color: white !important;
    fill: white !important;
  `}
`

const ExpandedRowWrap = styled.div<{open: boolean;colorized?: boolean; noChangeColor?: boolean}>`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    ${({ colorized }) => colorized && css`
      &:nth-child(2n+1){
        background: ${themeVar('brandOrangePale2xColor')};
      }
    `}
    ${({ open, noChangeColor }) => !noChangeColor && open && css`
      background: ${themeVar('brandOrangeColor')} !important;
      color: white !important;
      fill: white !important;
      & ${ExpandedRowContent} *{
        color: white !important;
        fill: white !important;
      }
    `}
    
    ${breakpoint('mobile', 'tablet')`
      padding: 15px;
      border-radius: 4px;
      margin-top: 20px;
    `}
    ${({ noChangeColor }) => noChangeColor && css`
      background: ${themeVar('brandOrangeColor')} !important;
      ${breakpoint('mobile', 'tablet')`
        padding: 0;
      `}
    `}
`


const HiddenContent = styled.div<{show: boolean; noChangeColor?: boolean}>`
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
    ${({ noChangeColor }) => !noChangeColor && css`
      & * {
        color: white !important;
        fill: white !important;
      }
    `}
`

const HiddenContentActions = styled.div<{show: boolean}>`
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
`
