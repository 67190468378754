import {
  $isPreRegistrationModalShown,
} from './state'
import {
  hidePreRegistrationDialog,
  registrate,
  showPreRegistrationDialog,
} from './events'

$isPreRegistrationModalShown
  .on(showPreRegistrationDialog, () => true)
  .on(hidePreRegistrationDialog, () => false)
  .on(registrate, () => false)
