import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { IconName } from '@/ui/icons'
import { useInView } from 'react-intersection-observer'
import { themeVar, ThemedStyledProps } from '../theming'
import { DataTableRow } from './DataTableRow'
import { SortHeader } from './data-table-content/SortHeader'

type Props<Item> = {
  columns: Column[];
  data: Item[];
  extractKey: (item: Item) => {};
  actions?: Action<Item>[];
  children: (item: Item) => React.ReactChild;
  bottomAction?: React.ReactChild;
  onRowClick?: (item: Item) => void;
  oversizeRow?: boolean;
  onLoadNextPage?: () => void;
  expandedMode?: boolean;
  noMarginTop?: boolean;
}

type Column = {
  name: string;
  renderHeader: React.ReactChild;
  width?: number | string;
}

export type Action<Item> = {
  color: 'warning' | 'green' | 'primary';
  icon: IconName;
  text?: string;
  action: (item: Item) => void;
}


export function DataTable<Item>({
  columns,
  data,
  actions,
  extractKey,
  children,
  bottomAction,
  onRowClick,
  oversizeRow,
  onLoadNextPage,
  expandedMode,
  noMarginTop,
}: Props<Item>) {
  const { ref, inView } = useInView({
    threshold: 1,
  })

  React.useEffect(() => {
    if (inView && onLoadNextPage) {
      onLoadNextPage()
    }
  }, [inView])
  return (
    <DataTableWrap noMarginBottom={noMarginTop}>
      <Wrap noMarginTop={noMarginTop}>
        <HeaderRow inverse={expandedMode}>
          {columns.map(({ renderHeader, width, name }) => (
            <Col
              key={name}
              width={width || 1}
            >
              {renderHeader}
            </Col>
          ))}
        </HeaderRow>
        {data.map((item) => (
          <DataTableRow
            marginMode={expandedMode}
            oversizeRow={Boolean(oversizeRow)}
            item={item}
            actions={actions}
            onRowClick={() => onRowClick && onRowClick(item)}
            key={`${extractKey(item)}row`}
          >
            { children(item) }
          </DataTableRow>
        ))}
        <DataTableLoader ref={ref}> </DataTableLoader>
      </Wrap>
      {bottomAction && (
        <StikyWrap>
          <StickyContent>
            {bottomAction}
          </StickyContent>
        </StikyWrap>
      )}
    </DataTableWrap>
  )
}

const Wrap = styled.div<{noMarginTop?: boolean}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  overflow: hidden;
  font-family: 'Cocogoose Classic';
  margin-top: 21px; 
  border-radius: 4px;
  justify-content: center;
  ${({ noMarginTop }) => noMarginTop && css`
    margin-top: 0;
  `}
`

type StyledColumnProps = {
    width: number | string;
} & ThemedStyledProps

const Col = styled.div<StyledColumnProps>`
  ${({ width }) => (Number.isInteger(width) && css`
      flex: ${width};
    `) || css`
      width: ${width};
    `
}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child){
    padding-right: 15px;
  }
`

const HeaderRow = styled.div<{inverse?: boolean}>`
  width: 100%;
  display: flex;
  padding: 15px;
  ${breakpoint('mobile', 'tablet')`
    display: none;
  `}
  background: ${themeVar('dataTableHeaderBg')};
  ${({ inverse }) => inverse && css`
    color: white;
    fill: white !important;
    background: none;
    * svg {
      fill: white !important;
    }
  `}
`

type StyledActionProps = {
  color: string;
} & ThemedStyledProps

const Action = styled.div<StyledActionProps>`
  flex: 1;
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ color }) => {
    switch (color) {
      case 'warning':
        return (css` background: ${themeVar('dataTableActionBgWarning')};`)
      case 'green':
        return (css` background: ${themeVar('dataTableActionBgGreen')};`)
      default:
        return (css` background: ${themeVar('dataTableActionBgPrimary')};`)
    }
  }}
`

const StikyWrap = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    margin: auto;
    left: 0;
    width: 100%;
    justify-content: center;
    pointer-events: none;
    z-index: 40;
    height: 100%;
    
`
const StickyContent = styled.div`
    position: sticky;
    bottom: 0px;
    display: flex;
    margin: auto auto 0px;
    left: 0;
    justify-content: center;
    pointer-events: none;
    
    z-index: 100;
    ${breakpoint('tablet')`
      box-shadow: 0px 20px 55px rgba(213, 76, 46, 0.45);
      margin-bottom: 40px;
      bottom: 40px;
    `}
    ${breakpoint('mobile', 'tablet')`
      width:100%;
      padding: 53px 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    `}
`

const DataTableWrap = styled.div<{noMarginBottom?: boolean}>`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${({ noMarginBottom }) => !noMarginBottom && css`
    ${breakpoint('mobile', 'tablet')`
      padding-bottom: 120px;
    `}
  `}
`
const DataTableLoader = styled.div`
  width: 100%;
  height: 1px;
`
