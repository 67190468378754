import { forward, sample } from 'effector'

import { $selectedSiteId } from '../main/units'
import {
  leases,
  $leasesList,
  reset,
  $showContractDetailsModal,
  $showLeasesDetailsModal,
  showContractDetailsModal,
  hideContractDetailsModal,
  hideLeasesDetailsModal,
  showLeasesDetailsModal,
  resetLeases,
  $insuranceContractList,
  $serviceProviderList,
  insurance,
  serviceProvider,
} from './units'


$leasesList
  .on(leases.fetchDataFx.doneData, (state, r) => [...state, ...r.items])
  .reset(resetLeases)

$serviceProviderList
  .on(serviceProvider.fetchDataFx.doneData, (state, r) => [...state, ...r.items])
  .reset(resetLeases)

$insuranceContractList
  .on(insurance.fetchDataFx.doneData, (state, r) => [...state, ...r.items])
  .reset(resetLeases)

forward({
  from: insurance.nextPage,
  to: insurance.fetchDataFx,
})

forward({
  from: serviceProvider.nextPage,
  to: serviceProvider.fetchDataFx,
})

forward({
  from: leases.nextPage,
  to: leases.fetchDataFx,
})

forward({
  from: reset,
  to: [leases.resetPagination, serviceProvider.resetPagination, insurance.resetPagination],
})

$showContractDetailsModal
  .on(showContractDetailsModal, () => true)
  .on(hideContractDetailsModal, () => false)

$showLeasesDetailsModal
  .on(showLeasesDetailsModal, () => true)
  .on(hideLeasesDetailsModal, () => false)
