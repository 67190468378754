import { createDomain } from 'effector'
import { createPagination } from '@/lib/effector-pagination'
import {
  getNewsArticlesListReqFx,
  NewsArticle,
} from '@/dal'

export const mainPageDomain = createDomain('site')

export const $selectedSiteId = mainPageDomain.store('siteId')
export const $newsList = mainPageDomain.store<NewsArticle[]>([])

export const updateNewsList = mainPageDomain.event<NewsArticle[]>()

export const newsPagination = createPagination<void, NewsArticle>({
  domain: mainPageDomain,
  effect: getNewsArticlesListReqFx,
  perPage: 3,
})
