import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'

import { CreateContractEndorsementRes } from '@/dal/contracts.interfaces'
import { showConfirmModal } from '@/features/app/hooks'
import {
  DataTable, TableRow, SubTitle, Action, Col, AccentText, Visible,
} from '@/ui'
import { dateSlash } from '@/lib/dates'
import { $list, deleteItem } from '../../model/endorsements'

export const EndorsementList = () => {
  const { t } = useTranslation('tenant/main')
  const endorsementList = useStore($list)
  const dataTableTemplate = [
    {
      name: 'description',
      renderHeader: (
        <SubTitle>
          {t('description')}
        </SubTitle>
      ),
      width: 2,
    },
    {
      name: 'effectiveDate',
      renderHeader: (
        <SubTitle>
          {t('effectiveDate')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'dateOfSigning',
      renderHeader: (
        <SubTitle>
          {t('dateOfSigning')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'content',
      renderHeader: (
        <SubTitle>
          <AccentText>
            {t('content')}
          </AccentText>
        </SubTitle>
      ),
      width: 2,
    },
  ]
  const dataTableActions: Action<CreateContractEndorsementRes>[] = [
    {
      color: 'warning',
      icon: 'trash',
      action: (i) => {
        showConfirmModal({
          title: t('deleteEndorsementTile'),
          text: t('deleteEndorsementText'),
          action: () => {
            deleteItem(i)
          },
        })
      },
    },
  ]
  return (
    <DataTable
      noMarginTop
      columns={dataTableTemplate}
      actions={dataTableActions}
      extractKey={(item) => `${item.id}cadastralReference`}
      data={endorsementList}
    >
      {(item) => (
        <TableRow smallText oversizeRow>
          <Visible tablet>
            <Col width={2}>
              {item.description}
            </Col>
            <Col width={1}>
              {item.effectiveDate ? dateSlash(item.effectiveDate) : ''}
            </Col>
            <Col width={1}>
              {item.dateOfSigning ? dateSlash(item.dateOfSigning) : ''}
            </Col>
            <Col width={2}>
              {item.content}
            </Col>
          </Visible>
          <Visible mobile>
            <Col width="100%" name={t('description')} align="flex-start">
              {item.description}
            </Col>
            <Col width="50%" name={t('effectiveDate')} align="flex-start">
              {item.effectiveDate ? dateSlash(item.effectiveDate) : ''}
            </Col>
            <Col width="50%" name={t('dateOfSigning')} align="flex-start">
              {item.dateOfSigning ? dateSlash(item.dateOfSigning) : ''}
            </Col>
            <Col width="5100%" name={t('content')} align="flex-start">
              {item.content}
            </Col>
          </Visible>
        </TableRow>
      )}
    </DataTable>
  )
}
