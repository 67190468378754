import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'
import { CreateContractClauseRes, ContractClauseType } from '@/dal/contracts.interfaces'
import {
  createContractClauseReqFx,
  deleteContractClauseReqFx,
  attachContractClauseToTenantLeaseReqFx,
  detachContractClauseFromTenantLeaseReqFx,
} from '@/dal/contracts'
import { rules } from '@/lib/validators'

const d = createDomain('d')

export const $list = d.createStore<CreateContractClauseRes[]>([])

export const loadList = d.createEvent<CreateContractClauseRes[]>()
export const addItem = d.createEvent<CreateContractClauseRes>()
export const deleteItem = d.createEvent<CreateContractClauseRes>()
export const dropItem = d.createEvent<CreateContractClauseRes['id']>()

export const ContractClausesForm = createForm({
  domain: d,
  fields: {
    type: {
      init: '' as ContractClauseType,
      rules: [rules.required()],
    },
    name: {
      init: '',
    },
    page: {
      init: '',
    },
    clause: {
      init: '',
    },
    description: {
      init: '',
    },
    active: {
      init: true,
    },
    share: {
      init: '' as string,
    },
    amount: {
      init: '' as string,
    },
  },
})

export const createItemFx = attach({ effect: createContractClauseReqFx })
export const attachItemToTenantLeaseFx = attach({ effect: attachContractClauseToTenantLeaseReqFx })
export const deleteItemFx = attach({ effect: deleteContractClauseReqFx })
export const detachItemFromTenantLeaseFx = attach({ effect: detachContractClauseFromTenantLeaseReqFx })
