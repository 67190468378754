import * as React from 'react'
import styled, { css } from 'styled-components'

import { useClickOutside } from '@/lib/click-outside'
import {
  InputField,
} from '@/ui'

import { themeVar, ThemedStyledProps } from '../theming'


type Props<Item extends object> = {
  placeholder?: string;
  inputValue: string;
  items: Item[];
  maxItemsInView?: number;
  value?: Item | null;
  extractKey?: (item: Item) => string;
  extractLabel: (item: Item) => string;
  activeField?: React.ReactChild;
  onInputChange: (val: string) => void;
  onChange: (val: Item) => void;
  errorText?: string;
}

export function Autocomplete<Item extends object>(props: Props<Item>) {
  const {
    items,
    value,
    inputValue,
    placeholder,
    extractLabel,
    extractKey = (x) => (x as any).id,
    onChange,
    onInputChange,
    activeField,
    errorText,
    maxItemsInView = 7,
  } = props

  const [open, setOpen] = React.useState(false)

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    setOpen(false)
  })

  React.useEffect(() => {
    onInputChange(value ? extractLabel(value) : '')
  }, [value])

  const onClickItem = (item: Item) => {
    onChange(item)
    setOpen(!open)
  }

  const onBlue = () => {
    if (value && inputValue !== extractLabel(value)) {
      onInputChange(extractLabel(value))
    }
  }

  const hasError = Boolean(errorText)

  const listItems = items.slice(0, maxItemsInView).map((item, index) => (
    <ListItem
      key={extractKey(item)}
      selected={!!value && (extractKey(value) === extractKey(item))}
      onClick={() => onClickItem(item)}
    >
      {extractLabel(item)}
    </ListItem>
  ))

  const mainContent = (
    <MainValue onClick={() => setOpen(!open)}>
      <InputField
        placeholder={placeholder}
        value={inputValue}
        appendIcon="lense"
        innerIcon
        onBlur={onBlue}
        onChangeValue={onInputChange}
      />
      <List open={open}>
        {listItems}
      </List>
    </MainValue>
  )

  return (
    <Wrap ref={ref}>
      {open && activeField}
      {(!open || !activeField) && mainContent}


      {hasError && (
        <ErrorText>{errorText}</ErrorText>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  
  font-size: 16px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputSelectTextColor')};
`

type StyledListProps = {
  open: boolean;
} & ThemedStyledProps

const List = styled.div<StyledListProps>`
  position: absolute;
  width:100%;
  top: calc(100% - 4px);
  left: 0;
  flex-wrap: wrap;
  background: ${themeVar('inputSelectMainColor')};
  padding-top: 4px;
  display: none;
  ${({ open }) => open && css`
    display: block;
  `};
  z-index: 10;
  max-height: 192px;
  overflow-y: auto;
`
type StyledItemProps = {
  selected: boolean;
} & ThemedStyledProps

const ListItem = styled.div<StyledItemProps>`
  display: flex;
  padding: 7px 13px;
  width: 100%;
  text-align: left;
  min-height: 32px;
  fill: ${themeVar('inputSelectSelectedColor')};
  ${({ selected, theme }) => selected && css`
    background: ${theme.inputSelectSelectedColor};
    color: ${theme.inputSelectSelectedTextColor};
    fill: ${theme.inputSelectSelectedTextColor};
  `};
  
  &:hover {
    background: ${themeVar('inputSelectSelectedColor')};
    color: ${themeVar('inputSelectSelectedTextColor')};
    fill: ${themeVar('inputSelectSelectedTextColor')};
  }
`

const MainValue = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const ErrorText = styled.div<ThemedStyledProps>`
  font-family: ${themeVar('baseFont')};
  color: ${themeVar('inputErrorColor')};
  line-height: 10px;
  font-size: 10px;
  padding-top: 3px;
  width: 100%;
`
