import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'


i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    whitelist: ['en', 'fr'],
    load: 'languageOnly',
    ns: 'translation',
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    partialBundledLanguages: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
    },
    detection: {
      order: ['querystring', 'localStorage'],
      caches: ['localStorage'],
      lookupQuerystring: 'language',
      lookupLocalStorage: 'language',
      cookieMinutes: 999999999,
    },
    react: {
      useSuspense: true,
    },
  })
  .then(() => {
    if (!i18n.language) {
      i18n.changeLanguage('en')
    }
  })

export default i18n
