import * as React from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Heading,
  Text,
  Col,
  Row,
  WrapField,
} from '@/ui'
import styled from 'styled-components'
import {
  $isThanksModalShown,
  $isThanksModalText,
  hideThanksModal,
} from '../../model'

export const ThanksModal = () => {
  const isThanksModalShown = useStore($isThanksModalShown)
  const isThanksModalText = useStore($isThanksModalText)
  const { t } = useTranslation('autorization/translation')
  return (
    <Modal
      type="login"
      show={isThanksModalShown}
      onClose={() => hideThanksModal()}
    >
      <ContentWrap>
        <Row>
          <Col noPadding width="100%">
            <WrapField>
              <Heading>{t('thanks')}</Heading>
            </WrapField>
          </Col>
          <Col noPadding width="100%">
            <Text align="center" style={{ fontWeight: 500 }}>
              {isThanksModalText}
            </Text>
          </Col>
        </Row>
      </ContentWrap>
    </Modal>
  )
}


const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
