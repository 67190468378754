import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { showConfirmModal } from '@/features/app/hooks'
import { CreateBankSecurityRes } from '@/dal/contracts.interfaces'
import {
  DataTable, TableRow, SubTitle, Action, Col, AccentText, Visible,
} from '@/ui'
import { dateSlash } from '@/lib/dates'
import { convertToEuroCurrency } from '@/lib/field-handlers'
import { $list, deleteItem } from '../../model/bankSecurity'

export const BankSecurityList = () => {
  const { t } = useTranslation('tenant/main')
  const bankSecurityList = useStore($list)
  const dataTableTemplate = [
    {
      name: 'type',
      renderHeader: (
        <SubTitle>
          {t('type')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'date',
      renderHeader: (
        <SubTitle>
          {t('date')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'deposit',
      renderHeader: (
        <SubTitle>
          <AccentText>
            {t('deposit')}
          </AccentText>
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'amount',
      renderHeader: (
        <SubTitle>
          {t('amount')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'lastReview',
      renderHeader: (
        <SubTitle>
          {t('lastReview')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'guarantee',
      renderHeader: (
        <SubTitle>
          {t('guarantee')}
        </SubTitle>
      ),
      width: 1,
    },
    {
      name: 'remarks',
      renderHeader: (
        <SubTitle>
          {t('remarks')}
        </SubTitle>
      ),
      width: 3,
    },
  ]
  const dataTableActions: Action<CreateBankSecurityRes>[] = [
    {
      color: 'warning',
      icon: 'trash',
      action: (i) => {
        showConfirmModal({
          title: t('deleteDepositTile'),
          text: t('deleteDepositText'),
          action: () => {
            deleteItem(i)
          },
        })
      },
    },
  ]
  return (
    <DataTable
      noMarginTop
      columns={dataTableTemplate}
      actions={dataTableActions}
      extractKey={(item) => `${item.id}cadastralReference`}
      data={bankSecurityList}
    >
      {(item) => (
        <TableRow smallText oversizeRow>
          <Visible tablet>
            <Col width={1}>
              {item.type}
            </Col>
            <Col width={1}>
              {dateSlash(item.date)}
            </Col>
            <Col width={1}>
              <AccentText>
                €
                {' '}
                {convertToEuroCurrency(item.amount)}
              </AccentText>
            </Col>
            <Col width={1}>
              {item.amountRent}
            </Col>
            <Col width={1}>
              {dateSlash(item.lastReviewDate)}
            </Col>
            <Col width={1}>
              {item.typeOfGuarantee}
            </Col>
            <Col width={3}>
              {item.remarks}
            </Col>
          </Visible>
          <Visible mobile>
            <Col width="50%" name={t('type')} align="flex-start">
              {item.type}
            </Col>
            <Col width="50%" name={t('date')} align="flex-start">
              {dateSlash(item.date)}
            </Col>
            <Col width="50%" name={t('deposit')} align="flex-start">
              <AccentText>
                €
                {' '}
                {item.amount}
              </AccentText>
            </Col>
            <Col width="50%" name={t('amount')} align="flex-start">
              {item.amountRent}
            </Col>
            <Col width="50%" name={t('lastReview')} align="flex-start">
              {dateSlash(item.lastReviewDate)}
            </Col>
            <Col width="50%" name={t('guarantee')} align="flex-start">
              {item.typeOfGuarantee}
            </Col>
            <Col width="100%" name={t('remarks')} align="flex-start">
              {item.remarks}
            </Col>
          </Visible>
        </TableRow>
      )}
    </DataTable>
  )
}
