import * as React from 'react'
import { useField } from 'effector-forms'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CounterpartyWithAllContacts } from '@/dal'
import { useAutocomplete } from '@/lib/effector-autocomplete'
import {
  DespecialisationIndemnityPaymentTerms, Taxation, PaymentTerms,
} from '@/dal/contracts.interfaces'
import {
  Row,
  Col,
  InputField,
  NumberInput,
  SubTitle,
  RadioButtonsList,
  Select,
  Autocomplete,
  AccentText,
} from '@/ui'

import { PaymentForm, paymentToAutocomplete } from '../../../model/payment'
import { GeneralForm } from '../../../model/general'

export const MainForm = () => {
  const { t } = useTranslation('tenant/main')
  const paymentPeriod = useField(PaymentForm.fields.paymentPeriod)
  const joiningFee = useField(PaymentForm.fields.joiningFee)
  const joiningFeeAmount = useField(PaymentForm.fields.joiningFeeAmount)
  const despecialisationIndemnity = useField(PaymentForm.fields.despecialisationIndemnity)
  const despecialisationIndemnityAmount = useField(PaymentForm.fields.despecialisationIndemnityAmount)
  const despecialisationIndemnityPaymentTerms = useField(PaymentForm.fields.despecialisationIndemnityPaymentTerms)
  const paymentTo = useField(PaymentForm.fields.paymentTo)
  const taxation = useField(PaymentForm.fields.taxation)
  const paymentTerms = useField(PaymentForm.fields.paymentTerms)
  const maximumRent = useField(PaymentForm.fields.maximumRent)
  const paymentAmount = useField(PaymentForm.fields.paymentAmount)
  const transferOfBusiness = useField(PaymentForm.fields.transferOfBusiness)
  const transferOfBusinessAmount = useField(PaymentForm.fields.transferOfBusinessAmount)
  const repurchasingOfShares = useField(PaymentForm.fields.repurchasingOfShares)
  const repurchasingOfSharesAmount = useField(PaymentForm.fields.repurchasingOfSharesAmount)
  const despecialisationIndemnityPaymentToMyOrganization = useField(PaymentForm.fields.despecialisationIndemnityPaymentToMyOrganization)
  const type = useField(GeneralForm.fields.type)

  const paymentToAuto = useAutocomplete(paymentToAutocomplete)

  const leaseDurationItems: {text: string; value: 1 | 2 | 3 | 6 | 12 | 18}[] = [
    { text: t('1month'), value: 1 },
    { text: t('3month'), value: 3 },
    { text: t('6month'), value: 6 },
    { text: t('12month'), value: 12 },
    { text: t('18month'), value: 18 },
  ]

  const despecialisationIndemnityPaymentTermsItems: {text: string; value: DespecialisationIndemnityPaymentTerms}[] = [
    { value: 'check', text: t('check') },
    { value: 'check-or-bank-withdrawal', text: t('checkOrBankWithdrawal') },
    { value: 'check-or-wire-transfer', text: t('checkOrWireTransfer') },
  ]

  const YesNoItems = [
    { text: t('yes'), value: true },
    { text: t('no'), value: false },
  ]

  const taxationItems: {text: string; value: Taxation | null}[] = [
    { text: t('notSelected'), value: null },
    { value: '1-2-rental-income-tax', text: t('12RentalIncomeTax') },
    { value: 'rental-income-tax', text: t('rentalIncomeTax') },
    { value: 'VAT', text: t('VAT') },
  ]

  const paymentTermsItems: {text: string; value: PaymentTerms | null}[] = [
    { text: t('notSelected'), value: null },
    { value: 'become-due', text: t('becomeDue') },
    { value: 'overdue', text: t('overdue') },
  ]
  return (
    <Row align="flex-start">
      <BlockRow>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
          <>
            <SubTitle>
              1.
              {' '}
              {t('paymentPeriod')}
            </SubTitle>
            <Select
              name={t('paymentPeriod')}
              items={leaseDurationItems}
              value={paymentPeriod.value}
              onChange={paymentPeriod.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
          <>
            <SubTitle>
              2.
              {' '}
              {t('joiningFee')}
            </SubTitle>
            <RadioButtonsList
              name="joiningFee"
              items={YesNoItems}
              value={joiningFee.value}
              onChange={joiningFee.onChange}
            />
          </>
        </Col>
        {joiningFee.value && (
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('amount')}
                <AccentText>*</AccentText>
              </SubTitle>
              <NumberInput
                isFloat
                value={joiningFeeAmount.value}
                onChangeValue={joiningFeeAmount.onChange}
                errorText={joiningFeeAmount.errorText()}
              />
            </>
          </Col>
        )}
      </BlockRow>
      <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
        <>
          <SubTitle>
            3.
            {' '}
            {t('despecialisationIndemnity')}
          </SubTitle>
          <RadioButtonsList
            name="status"
            value={despecialisationIndemnity.value}
            items={YesNoItems}
            onChange={despecialisationIndemnity.onChange}
          />
        </>
      </Col>
      {despecialisationIndemnity.value && (
        <>
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('amount')}
                <AccentText>*</AccentText>
              </SubTitle>
              <NumberInput
                isFloat
                value={despecialisationIndemnityAmount.value}
                onChangeValue={despecialisationIndemnityAmount.onChange}
                errorText={despecialisationIndemnityAmount.errorText()}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('paymentTerms')}
              </SubTitle>
              <Select
                name={t('paymentPeriod')}
                items={despecialisationIndemnityPaymentTermsItems}
                value={despecialisationIndemnityPaymentTerms.value}
                onChange={despecialisationIndemnityPaymentTerms.onChange}
              />
            </>
          </Col>
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('paymentToMyOrganization')}
              </SubTitle>
              <RadioButtonsList
                name="status"
                value={despecialisationIndemnityPaymentToMyOrganization.value}
                items={YesNoItems}
                onChange={despecialisationIndemnityPaymentToMyOrganization.onChange}
              />
            </>
          </Col>
        </>
      )}

      {
        !despecialisationIndemnityPaymentToMyOrganization.value && despecialisationIndemnity.value && (
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('paymentTo')}
              </SubTitle>
              <Autocomplete<CounterpartyWithAllContacts>
                value={paymentToAuto.value}
                inputValue={paymentToAuto.searchValue}
                extractLabel={(item) => item.conterparty.companyName || item.conterparty.email || `${item.localContact.contact.firstName} ${item.localContact.contact.lastName}`}
                extractKey={(item) => item.conterparty.id}
                placeholder={t('paymentTo')}
                errorText={paymentTo.errorText()}
                items={paymentToAuto.items}
                onInputChange={(val) => paymentToAuto.onSearchChange(val)}
                onChange={(val) => paymentToAuto.onChange(val)}
              />
            </>
          </Col>
        )
      }


      <BlockRow>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
          <>
            <SubTitle>
              4.
              {' '}
              {t('taxation')}
            </SubTitle>
            <Select
              name={t('taxation')}
              items={taxationItems}
              value={taxation.value}
              onChange={taxation.onChange}
            />
          </>
        </Col>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
          <>
            <SubTitle>
              5.
              {' '}
              {t('maximumRent')}
            </SubTitle>
            <RadioButtonsList
              name="maximumRent"
              value={maximumRent.value}
              items={YesNoItems}
              onChange={maximumRent.onChange}
            />
          </>
        </Col>
      </BlockRow>
      <BlockRow>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
          <>
            <SubTitle>
              6.
              {' '}
              {t('paymentTerms')}
            </SubTitle>
            <Select
              name={t('paymentTerms')}
              items={paymentTermsItems}
              value={paymentTerms.value}
              onChange={paymentTerms.onChange}
            />
          </>
        </Col>
        {type.value === 'lease' && (
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('amount')}
                <AccentText>*</AccentText>
              </SubTitle>
              <NumberInput
                isFloat
                value={paymentAmount.value}
                onChangeValue={paymentAmount.onChange}
                errorText={paymentAmount.errorText()}
              />
            </>
          </Col>
        )}
      </BlockRow>
      <BlockRow>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
          <>
            <SubTitle>
              7.
              {' '}
              {t('transferOfBusiness')}
            </SubTitle>
            <Select
              name={t('transferOfBusiness')}
              items={YesNoItems}
              value={transferOfBusiness.value}
              onChange={transferOfBusiness.onChange}
            />
          </>
        </Col>
        {transferOfBusiness.value && (
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('amount')}
                <AccentText>*</AccentText>
              </SubTitle>
              <NumberInput
                isFloat
                value={transferOfBusinessAmount.value}
                onChangeValue={transferOfBusinessAmount.onChange}
                errorText={transferOfBusinessAmount.errorText()}
              />
            </>
          </Col>
        )}
      </BlockRow>
      <BlockRow>
        <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start" noMargin>
          <>
            <SubTitle>
              8.
              {' '}
              {t('repurchasingOfShares')}
            </SubTitle>
            <Select
              name={t('repurchasingOfShares')}
              items={YesNoItems}
              value={repurchasingOfShares.value}
              onChange={repurchasingOfShares.onChange}
            />
          </>
        </Col>
        {repurchasingOfShares.value && (
          <Col md="calc(100% / 3)" xs="100%" align="flex-start" vAlign="flex-start">
            <>
              <SubTitle>
                {t('amount')}
                <AccentText>*</AccentText>
              </SubTitle>
              <NumberInput
                isFloat
                value={repurchasingOfSharesAmount.value}
                onChangeValue={repurchasingOfSharesAmount.onChange}
                errorText={repurchasingOfSharesAmount.errorText()}
              />
            </>
          </Col>
        )}
      </BlockRow>
    </Row>

  )
}


const BlockRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
