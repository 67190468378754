import { forward, guard, sample } from 'effector'

import { Surface } from '@/dal'

import { reloadSitesList } from '../list/unit'
import {
  hideModal,
  showModal,
  newLocality,
  $isShowModal,
  $selectedSurfaceId,
  createLocalityFx,
  attachLocalityToSurfaceFx,
} from './unit'

$isShowModal
  .on(showModal, () => true)
  .reset(hideModal)
  .reset(createLocalityFx.done)

$selectedSurfaceId
  .on(showModal, (_, x) => x)
  .reset(hideModal)

forward({
  from: newLocality.formValidated.map((x) => ({ ...x, localities: [] })),
  to: createLocalityFx,
})

// attach surface to site
sample({
  source: $selectedSurfaceId,
  clock: guard({
    source: createLocalityFx.doneData,
    filter: $selectedSurfaceId.map((x) => !!x),
  }),
  fn: (surfaceId, data) => ({
    id: surfaceId as Surface['id'],
    itemsID: [data.id],
  }),
  target: attachLocalityToSurfaceFx,
})

forward({
  from: attachLocalityToSurfaceFx.done,
  to: reloadSitesList,
})

forward({
  from: hideModal,
  to: newLocality.reset,
})
