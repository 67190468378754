import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../../theming'


type StyledRowProps = {
  type?: 'warning'|'gray'| 'success'| string;
  smallText?: boolean;
  oversizeRow?: boolean;
} & ThemedStyledProps


export const TableRow = styled.div<StyledRowProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: ${({ smallText }) => (smallText ? '12px' : '16px')};
  ${({ type = null }) => {
    switch (type) {
      case 'warning':
        return css`
            background: ${themeVar('dataTableRowWarningBg')};
            color: ${themeVar('dataTableRowWarningText')};
          `
      case 'gray':
        return css`
            background: ${themeVar('dataTableRowGrayBg')};
            color: ${themeVar('dataTableRowGrayText')};
          `
      case 'success':
        return css`
            background: ${themeVar('dataTableRowSuccessBg')};
            color: ${themeVar('dataTableRowSuccessText')};
          `
      default:
        return css`
          background: ${themeVar('dataTableRowDefaultBg')};
        `
    }
  }}
  
  ${({ oversizeRow }) => oversizeRow && css`
    padding: 10px 0;
  `}
  ${breakpoint('tablet')`
    padding-right: 22px;
  `}
  ${breakpoint('mobile', 'tablet')`
    padding: ${themeVar('dataTableRowPaddingMobile')};
    padding-right: 0;
    padding-top: 0;
  `}
  `
