import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Row,
  Modal,
  PrimaryButton,
  Title,
  SubTitle,
  Col,
  InputField,
  WrapField,
  AccentText,
  ErrorText,
} from '@/ui'
import {
  $isShowModal, hideModal, passwordForm, $serverError,
} from '../../model/change-password'


export const ChangePasswordForm = () => {
  const { t } = useTranslation('settings/change-password')
  const authTrans = useTranslation('autorization/translation')
  const isShowModal = useStore($isShowModal)
  const serverError = useStore($serverError)
  const { submit, fields } = useForm(passwordForm)

  const saveForm = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Modal show={isShowModal} onClose={() => hideModal()}>
      <Form onSubmit={saveForm}>
        <Col width="100%" noPadding>
          <Title>{t('title')}</Title>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              {t('fields.oldPassword')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              type="password"
              placeholder={t('fields.oldPassword')}
              value={fields.oldPassword.value}
              onChangeValue={fields.oldPassword.onChange}
              errorText={fields.oldPassword.errorText()}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              {t('fields.newPassword')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              type="password"
              placeholder={t('fields.newPassword')}
              value={fields.password.value}
              onChangeValue={fields.password.onChange}
              errorText={fields.password.errorText()}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              {t('fields.confirmPassword')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              type="password"
              placeholder={t('fields.confirmPassword')}
              value={fields.repeatPassword.value}
              onChangeValue={fields.repeatPassword.onChange}
              errorText={fields.repeatPassword.errorText()}
            />
          </>
        </Col>
        { serverError && (
        <Row>
          <Col noPadding width="80%">
            <ErrorText>{authTrans.t(`errors.${serverError}`)}</ErrorText>
          </Col>
        </Row>
        )}
        <Row>
          <Col width="100%" md="80%" noPadding>
            <WrapField>
              <PrimaryButton
                fluid
                htmlType="submit"
              >
                {t('buttons.changePassword')}
              </PrimaryButton>
            </WrapField>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
  `
