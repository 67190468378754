import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/validators'
import { registerOwnerReqFx } from '@/dal'

export const d = createDomain('signupDomain')

export const $serverError = d.store<string>('')

export const mounted = d.event<void>()
export const reset = d.event<void>()
export const resetServerError = d.event<void>()

export const registerOwnerFx = attach({ effect: registerOwnerReqFx })

export const form = createForm({
  domain: d,
  filter: $serverError.map((x) => !x),
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.isEmail()],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
    passwordRepeat: {
      init: '',
      rules: [rules.required(), rules.isConfirmed('password')],
    },
  },
})
