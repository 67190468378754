import * as React from 'react'
import {
  Filter, Col, SortHeader,
} from '@/ui'

import styled from 'styled-components'

export const MobileFilter = () => {
  const [tmpSortBy, setTmpSortBy] = React.useState<string | null>('number')
  const [tmpSortDirection, setTmpSortDirection] = React.useState<'ASC'|'DESC'>('ASC')
  const setSort = (name: string) => {
    if (tmpSortBy !== name) {
      setTmpSortBy(name)
      setTmpSortDirection('ASC')
      return
    }
    if (tmpSortDirection === 'ASC') {
      setTmpSortDirection('DESC')
      return
    }
    setTmpSortBy(null)
  }

  return (
    <Wrap>
      <Filter count={36}>
        <>
          <Col width={1} noPadding vAlign="flex-start" align="center">
            <SortWrap>
              <SortHeader
                direction={tmpSortBy === 'name' ? tmpSortDirection : undefined}
                onChange={() => setSort('name')}
              >
                Name
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'jobTitle' ? tmpSortDirection : undefined}
                onChange={() => setSort('jobTitle')}
              >
                Job Title
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'location' ? tmpSortDirection : undefined}
                onChange={() => setSort('location')}
              >
                Location
              </SortHeader>
            </SortWrap>
          </Col>
          <Col width={1} noPadding vAlign="flex-start" align="center">
            <SortWrap>
              <SortHeader
                direction={tmpSortBy === 'site' ? tmpSortDirection : undefined}
                onChange={() => setSort('site')}
              >
                Site
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'date' ? tmpSortDirection : undefined}
                onChange={() => setSort('date')}
              >
                Date
              </SortHeader>
              <SortHeader
                direction={tmpSortBy === 'phone' ? tmpSortDirection : undefined}
                onChange={() => setSort('phone')}
              >
                Phone
              </SortHeader>
            </SortWrap>
          </Col>
        </>
      </Filter>
    </Wrap>
  )
}

const SortWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 6px;
	align-items: flex-start;
`

const Wrap = styled.div`
	margin-bottom: 15px;
`
