import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Select, Col, PrimaryButton } from '@/ui'
import { getMock } from '@/features/app'

import {
  $sitesListForSelect, $selectedSiteId, setSiteId, fetchSitesListFx,
} from '../../model/main'

export const SiteSelect = () => {
  const { t } = useTranslation('site/common')

  const selectedSiteId = useStore($selectedSiteId)
  const selectItems = useStore($sitesListForSelect)

  React.useEffect(() => {
    fetchSitesListFx()
  }, [])

  return (
    <SiteSelectWrap>
      <Col md="calc(100% / 3)" xs="100%" noMargin>
        <Select
          name={t('siteSelect.placeholder')}
          items={selectItems}
          value={selectedSiteId}
          onChange={(val) => setSiteId(val)}
        />
      </Col>
      <Col md="calc(100% / 5)" xs="100%" noMargin>
        <NavLink to="/sites">
          <PrimaryButton type="white" fluid>
            {t('manageSites')}
          </PrimaryButton>
        </NavLink>
      </Col>
    </SiteSelectWrap>
  )
}

const SiteSelectWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 26px 0;
    ${breakpoint('mobile', 'tablet')`
        margin: 26px;
    `}
`
