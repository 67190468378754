import { forward, sample, guard } from 'effector'

import { history } from '@/lib/history'

import {
  setNewPasswordForm,
  $serverError,
  $token,
  $modalShown,
  setToken,
  setServerError,
  resetServerError,
  showModal,
  closeModal,
  setNewPasswordFx,
} from './units'

$serverError
  .on(setServerError, (_, error) => error)
  .reset(resetServerError)

$token
  .on(setToken, (_, t) => t)

$modalShown
  .on(showModal, () => true)
  .reset(closeModal)

$modalShown.updates.watch((val) => {
  if (!val) {
    setImmediate(() => {
      history.replace('/')
    })
  }
})

guard({
  source: sample({
    source: [setNewPasswordForm.$values, $token],
    clock: setNewPasswordForm.formValidated,
    fn: ([form, token]) => ({
      password: form.password,
      token,
    }),
  }),
  filter: $token.map(Boolean),
  target: setNewPasswordFx,
})


forward({
  from: setNewPasswordFx.failData,
  to: setServerError,
})

setNewPasswordFx.done.watch(() => {
  closeModal()
  resetServerError()
  setNewPasswordForm.reset()
})
