import {
  forward, guard, sample,
} from 'effector'

import { $selectedSiteId } from '../main/units'
import {
  filters,
  searchFilters,
  contactsPagination,
  searchPagination,
  $contactsList,
  $searchContactsList,
  $searchValue,
  $currentSearchValue,
  $isSearchActive,
  $selectedContact,
  $showContactDetailsModal,
  reset,
  mounted,
  startSearch,
  endSearch,
  addContactsToList,
  addContactsToSearchList,
  setCurrentSearchValue,
  updateSearchValue,
  setSearchActive,
  updateFilters,
  selectContact,
  showContactDetailsModal,
  hideContactDetailsModal,
} from './units'

$contactsList
  .on(addContactsToList, (state, items) => [...state, ...items])

$searchContactsList
  .on(addContactsToSearchList, (_, items) => [...items])
  .reset(contactsPagination.resetPagination)

$searchValue
  .on(updateSearchValue, (_, s) => s)

$currentSearchValue
  .on(setCurrentSearchValue, (_, s) => s)

$isSearchActive
  .on(setSearchActive, (_, isActive) => isActive)
  .on(startSearch, () => true)
  .on(endSearch, () => false)

$selectedContact
  .on(selectContact, (_, c) => c)

$showContactDetailsModal
  .on(showContactDetailsModal, () => true)
  .on(hideContactDetailsModal, () => false)

forward({
  from: searchPagination.fetchDataFx.doneData.map((r) => r.items),
  to: addContactsToSearchList,
})

forward({
  from: contactsPagination.fetchDataFx.doneData.map((r) => r.items),
  to: addContactsToList,
})

const searchStarted = guard({
  source: sample([$searchValue, $currentSearchValue], startSearch),
  filter: ([searchValue, currentSearchValue]) => searchValue && searchValue !== currentSearchValue,
})

forward({
  from: searchStarted.map(([v]) => v),
  to: setCurrentSearchValue,
})

forward({
  from: searchStarted.map(() => undefined),
  to: [updateFilters, searchPagination.resetPagination],
})

forward({
  from: reset,
  to: [contactsPagination.resetPagination, searchPagination.resetPagination],
})

sample({
  source: $currentSearchValue,
  clock: updateFilters,
}).watch((v) => {
  searchPagination.resetPagination()
  // searchFilters.filters['contact.email'].setValue(v)
  searchFilters.filters['contact.firstName'].setValue(v)
  searchFilters.filters['contact.lastName'].setValue(v)
  searchPagination.fetchDataFx()
})
/*

sample({
  source: $selectedSiteId,
  clock: [mounted, $selectedSiteId.updates],
  target: filters.filters.elemID.setValue,
})
*/

forward({
  from: mounted,
  to: contactsPagination.fetchDataFx,
})
