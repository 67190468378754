import * as React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

import { NewsArticle } from '@/dal'
import { Heading, PrimaryButton } from '@/ui'
import { showModal, setNewsArticleData } from '@/features/news-modal'

import { NewsItem } from '../parts'
import { $newsList, newsPagination } from '../../model/index'

export const NewsList = () => {
  const { t } = useTranslation('start-page')
  const newsList = useStore($newsList)
  const hasNextPage = useStore(newsPagination.$hasNext)

  React.useEffect(() => {
    newsPagination.fetchDataFx()
  }, [])

  const showArticle = (article: NewsArticle) => {
    setNewsArticleData(article)
    showModal()
  }

  const newsItemList = newsList.map(
    (el) => (
      <NewsItem
        more={() => showArticle(el)}
        date={el.createdAt}
        text={el.snippet}
        title={el.title}
        key={el.id}
      />
    ),
  )
  return (
    <Wrap>
      <Title>
        <Heading>{t('news.title')}</Heading>
      </Title>
      <h3 style={{ textAlign: 'center', width: '100%' }}>
        News block is under development
      </h3>
      { newsItemList }
      { hasNextPage && (
        <Action>
          <PrimaryButton fluid type="gray">
            {t('news.seeAll')}
          </PrimaryButton>
        </Action>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
    z-index: 2;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 35px 55px rgba(213, 76, 46, 0.28);
    margin-bottom: 135px;
    padding: 50px 100px;
    ${breakpoint('mobile', 'tablet')`
        padding: 30px 18px;
        margin: 35px 6px;
    `}
`

const Title = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 35px;
`

const Action = styled.div`
    display: flex;
    width: 50%;
    margin: auto;
    ${breakpoint('mobile', 'tablet')`
        width: 100%;
    `}
`
