import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  fetchSitesListReqFx,
  Locality,
  Site,
  Surface,
  CadastralReference,

} from '@/dal'
import { TenantLease } from '@/dal/contracts.interfaces'

export interface SelectedObjects {
  sites: Site['id'][];
  surfaces: Surface['id'][];
  localities: Locality['id'][];
}
export type LocalityWithParent = Locality & {parent: SurfaceWithParent; itemType: 'locality'}
export type SurfaceWithParent = Omit<Surface, 'localities'> & {parent: SiteWithParent; children: LocalityWithParent[]; itemType: 'surface'}
export type SiteWithParent = Omit<Site, 'surfaces'> & {children: SurfaceWithParent[]; itemType: 'site'}


const d = createDomain('d')

export const $sitesList = d.store<Site[]>([])
export const $cadastralReferencesList = d.store<CadastralReference[]>([])
export const $selectedObjects = d.store<SelectedObjects>({
  sites: [],
  surfaces: [],
  localities: [],
})
export const $selectedObjectsForBackend = d.store<SelectedObjects>({
  sites: [],
  surfaces: [],
  localities: [],
})

export const mounted = d.event()
export const loadSelectedObjectsData = d.event<TenantLease>()
export const calculateTree = d.event<SiteWithParent | SurfaceWithParent | LocalityWithParent>()
export const loadTree = d.event<SelectedObjects>()

export const toggleSite = d.event<SiteWithParent>()
export const toggleSurface = d.event<SurfaceWithParent>()
export const toggleLocality = d.event<LocalityWithParent>()

// linking parents over structure
export const $sitesListWithParentLinks = $sitesList.map((sites) => sites.map(({ surfaces, ...site }) => {
  const resultSite: SiteWithParent = { ...site, itemType: 'site', children: [] }
  surfaces.forEach(({ localities, ...surface }) => {
    const resultSurface: SurfaceWithParent = {
      ...surface, itemType: 'surface', parent: resultSite, children: [],
    }
    localities.forEach((locality) => {
      resultSurface.children.push({ ...locality, itemType: 'locality', parent: resultSurface })
    })
    resultSite.children.push(resultSurface)
  })
  return resultSite
}))

export const cadastralForm = createForm({
  domain: d,
  fields: {
    site: {
      init: '',
    },
    section: {
      init: '',
    },
    number: {
      init: 0,
    },
    location: {
      init: '',
    },
    surfaceArea: {
      init: '',
    },
  },
})

export const fetchSitesListFx = attach({
  effect: fetchSitesListReqFx,
  mapParams: () => ({
    limit: 9999,
    offset: 0,
  }),
})
