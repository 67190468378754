import { forward, sample } from 'effector'

import { history } from '@/lib/history'

import {
  signinForm,
  signinFx,
  fetchMyUserFx,
  closeSigninModal,
  setServerError,
  resetServerError,
  $serverError,
} from './units'

$serverError
  .on(setServerError, (_, error) => error)
  .reset(resetServerError)

sample({
  source: signinForm.$values,
  clock: signinForm.formValidated,
  target: signinFx,
})

forward({
  from: signinFx.failData,
  to: setServerError,
})

forward({
  from: signinFx.done,
  to: fetchMyUserFx,
})

fetchMyUserFx.done.watch(() => {
  history.push('/home')
  closeSigninModal()
  resetServerError()
  signinForm.reset()
})
