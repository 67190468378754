import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar, ThemedStyledProps } from '../../theming'

export const NewLabel = styled.div`
position: absolute;
left: 0px;
writing-mode: vertical-lr;
transform: scaleY(-1) scaleX(-1);
background: ${themeVar('dataTableNewLabelBg')};
color: ${themeVar('dataTableNewLabelText')};
font-weight: bold;
font-size: 8px;
padding: 3px 2px;
top: calc(50% - 14px);
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
${breakpoint('mobile', 'tablet')`
    transform: none;
    background: transparent;
    top: 6px;
    right: 6px;
    left: auto;
    writing-mode: lr;
    color: ${themeVar('dataTableNewLabelBg')};
    margin: 0;
    padding: 0;
  `}
`
