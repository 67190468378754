import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { setNewPasswordReqFx, ResetSetNewPasswordParams } from '@/dal'
import { rules } from '@/lib/validators'
import { CustomError } from '@/lib/errors'

const setNewPasswordDomain = createDomain()

export const setNewPasswordForm = createForm({
  domain: setNewPasswordDomain,
  fields: {
    password: {
      init: '',
      rules: [rules.required()],
    },
    repeatPassword: {
      init: '',
      rules: [rules.required(), rules.isConfirmed('password')],
    },
  },
})

export const $serverError = setNewPasswordDomain.store<CustomError | null>(null)
export const $token = setNewPasswordDomain.store<string| null>(null)
export const $modalShown = setNewPasswordDomain.store(false)

export const setServerError = setNewPasswordDomain.event<CustomError>()
export const resetServerError = setNewPasswordDomain.event<void>()
export const setToken = setNewPasswordDomain.event<string>()
export const showModal = setNewPasswordDomain.event<void>()
export const closeModal = setNewPasswordDomain.event<void>()

export const setNewPasswordFx = attach({ effect: setNewPasswordReqFx })
