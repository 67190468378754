import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import { useTranslation } from 'react-i18next'

import {
  AccentText,
  Modal,
  PrimaryButton,
  InputField,
  Checkbox,
  Heading,
  Text,
  Col,
  Row,
  Title,
  WrapField,
} from '@/ui'
import styled from 'styled-components'

import { PrivacyBlock } from '../parts'
import {
  signinForm,
  $isAutorizationDialogShown,
  $serverError,
  hideAutorizationDialog,
  showChangePasswordDialog,
  signinFx,
  showSignUpDialog,
} from '../../model'

export const SignIn = () => {
  const { t } = useTranslation('autorization/translation')
  const isAutorizationDialogShown = useStore($isAutorizationDialogShown)
  const serverError = useStore($serverError)
  const {
    submit, fields, hasError, reset,
  } = useForm(signinForm)
  const [rememberOnDevice, setRememberOnDevice] = React.useState<boolean>(true)

  const pending = useStore(signinFx.pending)
  const formHasError = hasError()

  const goToPasswordReset = () => {
    hideAutorizationDialog()
    showChangePasswordDialog()
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  const closeModal = () => {
    reset()
    hideAutorizationDialog()
  }

  return (
    <Modal
      show={isAutorizationDialogShown}
      onClose={() => closeModal()}
    >
      <>
        <ContentWrap onSubmit={onSubmit}>
          <Row>
            <Col noPadding width="100%">
              <WrapField>
                <Heading>{t('signInTitle')}</Heading>
              </WrapField>
            </Col>
            <Col noPadding width="90%">
              <Title align="center" style={{ textTransform: 'none', fontWeight: 500 }}>
                {t('signInText')}
              </Title>
            </Col>
          </Row>
          {serverError && (
          <Col noPadding width="80%">
            <WrapField>
              <Text align="center">
                <AccentText warning>
                  {t(`errors.${serverError}`)}
                </AccentText>
              </Text>
            </WrapField>
          </Col>
          )}
          <Col noPadding width="80%">
            <WrapField>
              <InputField
                name="Email"
                placeholder={t('email')}
                errorText={fields.email.errorText({
                  isEmail: t('invalidEmailFormat'),
                  required: t('requiredFiled'),
                })}
                value={fields.email.value}
                onChangeValue={fields.email.onChange}
              />
            </WrapField>
          </Col>
          <Col noPadding width="80%">
            <WrapField>
              <InputField
                type="password"
                placeholder={t('password')}
                name="Password"
                errorText={fields.password.errorText({
                  required: t('requiredFiled'),
                })}
                value={fields.password.value}
                onChangeValue={fields.password.onChange}
              />
            </WrapField>
          </Col>
          <Col noPadding width="100%">
            <Text align="center">
              <ResetPasswordLink onClick={goToPasswordReset}>
                <AccentText>
                  {t('fogorPassword')}
                </AccentText>
              </ResetPasswordLink>
            </Text>
          </Col>
          <Row>
            <WrapField>
              <Col noPadding width="80%">
                <PrimaryButton
                  fluid
                  size="large"
                  htmlType="submit"
                  disabled={formHasError || pending}
                >
                  {t('signIn')}
                </PrimaryButton>
              </Col>
            </WrapField>
            <Col noPadding width="80%">
              <PrimaryButton
                fluid
                outline
                type="gray"
                size="large"
                appendIcon="rightChevron"
                onClick={showSignUpDialog}
              >
                {t('signUp')}
              </PrimaryButton>
            </Col>
          </Row>

          <Col noPadding md="65%" xs="70%">
            <Checkbox
              checked={rememberOnDevice}
              onChange={(val) => setRememberOnDevice(val)}
            >
              <span>
                {t('rememberOnDevice')}
              </span>
            </Checkbox>
          </Col>
        </ContentWrap>
      </>
    </Modal>
  )
}


const ContentWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ResetPasswordLink = styled.a`
  text-decoration: none;
  color: inherit;  
  cursor: pointer;
`
