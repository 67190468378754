import * as React from 'react'
import { useStore } from 'effector-react'
import {
  Modal,
  Heading,
  Text,
  Col,
} from '@/ui'
import styled from 'styled-components'
import { $personFileCreatedModal, hidePersonFileCreatedModal } from '../../../model'

export const CreatedModal = () => {
  const personFileCreatedModal = useStore($personFileCreatedModal)

  return (
    <Modal show={personFileCreatedModal} onClose={() => hidePersonFileCreatedModal()} type="info">
      <>
        <Col width="100%" noPadding align="center">
          <HeadingWrap>
            <Heading>
              almost done!
            </Heading>
          </HeadingWrap>
        </Col>
        <Col width="100%" noPadding align="center">
          <Text align="center">
            Now we will now review your application, verify that the data is correct, and then send a confirmation of registration to the email you specified.
          </Text>
        </Col>
      </>
    </Modal>
  )
}


const HeadingWrap = styled.div`
  padding-bottom: 15px;
`
