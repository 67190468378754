import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'

import {
  Row,
  Col,
  ContractFormTitle,
  themeVar,
  PrimaryButton,
} from '@/ui'
import { history } from '@/lib/history'

import { DocumentList } from './DocumentList'
import { AddSignatureForm } from './AddSignature'
import { AddDocumentForm } from './AddDocument'
import { SignatureList } from './SignatureList'
import { setTab } from '../../model/main'
import { AddEndorsementForm } from './AddEndorsement'
import { EndorsementList } from './EndorsementList'

export const Documents = () => {
  const { t } = useTranslation('tenant/main')

  return (
    <Wrap>
      <Row>
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('signatures')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <SignatureList />
        <AddSignatureForm />
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('endorsements')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <EndorsementList />
        <AddEndorsementForm />
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('documents')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <DocumentList />
        <AddDocumentForm />

        <BlockWrap>
          <Row>
            <Col xs="100%" md="180px" noMargin>
              <PrimaryButton
                outline
                fluid
                type="gray"
                prependIcon="leftChevron"
                onClick={() => setTab('general')}
              >
                {t('prevStep')}
              </PrimaryButton>
            </Col>
            <Col xs="100%" md="180px">
              <PrimaryButton
                fluid
                appendIcon="rightChevron"
                onClick={() => history.push('/site')}
              >
                {t('submit')}
              </PrimaryButton>
            </Col>
          </Row>
        </BlockWrap>
      </Row>
    </Wrap>
  )
}

const Wrap = styled.div`
`

const BlockWrap = styled.div<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 34px;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
`
