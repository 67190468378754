import * as React from 'react'
import styled from 'styled-components'
import { themeVar, ThemedStyledProps } from '../theming'

type Props<Value> = {
    text: string;
    value: Value;
    defaultValue: Value;
    onChange: (val: Value) => void;
    children: (
        val: Value,
        onChange: (val: Value) => void
        ) => void;
  }


export function RadioInput<Value>({
  text, value, onChange, children, defaultValue,
}: Props<Value>) {
  return (
    <Wrap>
      <ItemWrap
        onClick={() => onChange(defaultValue)}
      >
        <RadioIcon>
          {defaultValue === value && (
          <RadioIconCenter />
          )}

        </RadioIcon>
        {text}
      </ItemWrap>
      <ItemWrap>
        <RadioIcon>
          {defaultValue !== value && (
          <RadioIconCenter />
          )}

        </RadioIcon>
        {children(value, onChange)}
      </ItemWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: ${themeVar('baseFont')};
`

const RadioIcon = styled.div<ThemedStyledProps>`
    border: 2px solid ${themeVar('inputRadioIconBorder')};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 7px;
    justify-content: center;
`

const RadioIconCenter = styled.div<ThemedStyledProps>`
    border: none;
    border-radius: 50%;
    margin: 3px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    background: ${themeVar('inputRadioIconBackground')};
`

const ItemWrap = styled.div`
    display: flex;
    margin-right: 16px;
    align-items: center;
    margin-top: 10px;
    width: 100%;
`
