import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { themeVar } from '../../theming'

export const Heading = styled.div`
font-family: ${themeVar('baseFont')};
font-weight: 600;
font-size: 36px;
line-height: 40px;
width: 100%;
text-transform: uppercase; 
text-align: center;
${breakpoint('mobile', 'tablet')` 
    font-size: 14px;
    line-height: 14px;
  `}
`
