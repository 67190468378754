import {
  forward, split, sample, combine,
} from 'effector'

import { history } from '@/lib/history'
import { $lang } from '@/features/app'

import {
  $serverError,
  $token,
  $modalShown,
  setToken,
  setServerError,
  showModal,
  closeModal,
  checkTokenReqFx,
  getNewTokenReqFx,
} from './units'


$serverError
  .on(setServerError, (_, error) => error)
  .reset(checkTokenReqFx.done)

$token
  .on(setToken, (_, t) => t)

$modalShown
  .on(showModal, () => true)
  .reset(closeModal)

$modalShown.updates.watch((val) => {
  if (!val) {
    setImmediate(() => {
      history.replace('/')
    })
  }
})

sample({
  source: $lang,
  clock: setToken,
  fn: (language, emailToken) => ({
    emailToken,
    language,
  }),
  target: checkTokenReqFx,
})

const errorSplit = split(checkTokenReqFx.fail, {
  tokenExpired: (r) => r.error.name === 'VerifyEmailTokenExpired',
})

sample({
  source: combine($token, $lang, (emailToken, language) => ({
    emailToken: emailToken || '',
    language,
  })),
  clock: errorSplit.tokenExpired,
  target: getNewTokenReqFx,
})

forward({
  from: getNewTokenReqFx.done.map(() => 'VerifyEmailTokenExpired'),
  to: setServerError,
})
