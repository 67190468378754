import {
  forward,
  sample,
  combine,
  split,
  merge,
} from 'effector'
import { condition } from 'patronum'


import { CounterpartyProfile, CounterpartyWithAllContacts } from '@/dal'
import {
  d,
  $isCreationModalOpen,
  openCreationModal,
  closeCreationModal,
  $isEmailChecked,
  $emailCheckMessage,
  reset,
  emailForm,
  mainForm,
  $foundCounterparty,
  counterpartyFound,
  createNewCounterparty,
  createAllCounterpartyContacts,
  $formError,
  setEmailCheckMessage,
  fetchCounterpartyListFx,
  createCounterpartyFx,
  createAllCounterpartyContactsFx,
} from './units'

d.onCreateStore((x) => {
  x.reset(reset)
})

$isCreationModalOpen
  .on(openCreationModal, () => true)
  .on(closeCreationModal, () => false)

$emailCheckMessage
  .on(setEmailCheckMessage, (_, x) => x)

forward({
  from: closeCreationModal,
  to: [mainForm.reset, emailForm.reset],
})

// find counterparty
forward({
  from: emailForm.formValidated,
  to: fetchCounterpartyListFx,
})


const counterpartyRequested = split(fetchCounterpartyListFx.doneData, {
  alreadyAdded: (x) => !!x.items.length && !!x.items[0].localContact,
  notFound: (x) => !x.items.length,
  found: (x) => !!x.items.length,
})

counterpartyRequested.found.watch((x) => {
  setEmailCheckMessage('Counterparty was found')
  counterpartyFound(x.items[0])
})
counterpartyRequested.notFound.watch(() => {
  setEmailCheckMessage('Counterparty with this email don`t found. New counterpart will be created')
})
counterpartyRequested.alreadyAdded.watch(() => {
  setEmailCheckMessage('This counterparty already added')
})

forward({
  from: [
    counterpartyRequested.found.map(() => true),
    counterpartyRequested.notFound.map(() => true),
    counterpartyRequested.alreadyAdded.map(() => false),
  ],
  to: $isEmailChecked,
})

// counterparty was found
forward({
  from: counterpartyFound,
  to: mainForm.setForm.prepend((x) => ({
    ...x.conterparty,
    ...x.conterparty.contact,
    phones: x.conterparty.contact.phones.filter((_, i) => i !== 0),
  })),
})

forward({
  from: counterpartyFound,
  to: $foundCounterparty,
})


// create counterparty or local contact
condition({
  source: mainForm.formValidated,
  if: $foundCounterparty.map((x) => !!x),
  then: createAllCounterpartyContacts,
  else: createNewCounterparty,
})

sample({
  source: combine(emailForm.$values, mainForm.$values),
  clock: createNewCounterparty,
  fn: ([emailF, mainF]) => ({
    ...mainF,
    phones: [mainF.defaultPhone, ...mainF.phones],
    email: emailF.email,
  }),
  target: createCounterpartyFx,
})

const onCreateNewContacts = merge([
  createAllCounterpartyContacts.map(() => '' as string),
  createCounterpartyFx.doneData.map((x) => x.id),
])
sample({
  source: combine($foundCounterparty, mainForm.$values),
  clock: onCreateNewContacts,
  fn: ([fc, mainF], justCreatedId) => ({
    conterpartyID: justCreatedId || (fc as CounterpartyWithAllContacts).conterparty.id,
    contact: {
      address: mainF.address,
      fax: mainF.fax,
      firstName: mainF.firstName,
      lastName: mainF.lastName,
      webSite: mainF.webSite,
      defaultPhone: mainF.defaultPhone,
      phones: [mainF.defaultPhone, ...mainF.phones],
    },
  }),
  target: createAllCounterpartyContactsFx,
})

forward({
  from: createAllCounterpartyContactsFx.done,
  to: [reset, closeCreationModal],
})
