import axiosLib, { AxiosInstance, AxiosResponse } from 'axios'
import {
  resetAuthState,
  requestFx,
  authServiceRequestFx,
  Request,
} from './units'

axiosLib.defaults.withCredentials = true

const axios = axiosLib.create({
  baseURL: process.env.REST_API_BASE_URL,
})
const authServiceAxios = axiosLib.create({
  baseURL: process.env.REST_API_AUTH_URL,
})

axios.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.data.code === 401) {
    resetAuthState()
  }
  throw error
})

authServiceAxios.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.data.code === 401) {
    resetAuthState()
  }
  throw error
})

const createRequestHandler = (axiosInstance: AxiosInstance) => (params: Request): Promise<AxiosResponse<any>> => {
  if (params.fake) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (params.fake && params.fake.success) {
          return resolve()
        }
        return reject()
      }, 200)
    })
  }
  return axiosInstance.request({
    method: params.method,
    url: params.url,
    params: params.query,
    data: params.body,
  })
}

requestFx.use(createRequestHandler(axios))
authServiceRequestFx.use(createRequestHandler(authServiceAxios))
