import { useStore } from 'effector-react'
import { Account, User } from '@/dal'
import { $isAdmin, $accountInfo, $user } from '../model'


type UserInfo = {
    isAdmin: boolean;
    account: Account | null;
    user: User | null;
}

export const useUserInfo = (): UserInfo => ({
  isAdmin: useStore($isAdmin),
  account: useStore($accountInfo),
  user: useStore($user),
})
