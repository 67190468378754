import { forward, sample } from 'effector'
import { getTenentLeaseFx, $tenantContract } from '../main'
import {
  $list,
  DocumentForm,
  addItem,
  attachItemToTenantLeaseFx,
  createItemFx,
  deleteItem,
  deleteItemFx,
  detachItemFromTenantLeaseFx,
  dropItem,
  loadList,
} from './unit'

$list
  .on(loadList, (_, r) => r)
  .on(addItem, (_, r) => [..._, r])
  .on(dropItem, (_, r) => _.filter((el) => el.id === r))

forward({
  from: getTenentLeaseFx.doneData.map((r) => r.tenantLease.documents),
  to: loadList,
})

sample({
  clock: DocumentForm.formValidated,
  source: DocumentForm.$values,
  fn: (form) => ({
    ...form,
    type: form.documentType,
    content: {
      id: 'test',
      name: 'test',
      link: 'google.com',
    },
  }),
  target: createItemFx,
})

forward({
  from: createItemFx.doneData,
  to: addItem,
})

sample({
  clock: createItemFx.doneData,
  source: $tenantContract,
  fn: (contract, period) => ({
    id: contract?.id as string,
    itemsID: [period.id],
  }),
  target: attachItemToTenantLeaseFx,
})

forward({
  from: attachItemToTenantLeaseFx.done,
  to: DocumentForm.reset,
})

forward({
  from: deleteItem.map((i) => ({ id: i.id, items: [i.id] })),
  to: detachItemFromTenantLeaseFx,
})

forward({
  from: [
    detachItemFromTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0] : '')),
    attachItemToTenantLeaseFx.fail.map((r) => (r.params.items ? r.params.items[0]?.id : '')),
    detachItemFromTenantLeaseFx.done.map((r) => (r.params.items ? r.params.items[0] : '')),
  ],
  to: dropItem,
})


forward({
  from: deleteItem.map((i) => ({ id: i.id })),
  to: deleteItemFx,
})
