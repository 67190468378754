import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { themeVar } from '@/ui'
import { dateToHuman } from '@/lib/dates'

type Props = {
    date: string;
    title: string;
    author: string;
    text: string;
    more: () => void;
}
export const NewsItem = ({
  date, author, title, text, more,
}: Props) => (
  <NewsWrap>
    <NewsDate>
      {dateToHuman(date)}
    </NewsDate>
    <NewsAuthor>
      by
      {' '}
      {author}
    </NewsAuthor>
    <NewsTitle>
      {title}
    </NewsTitle>
    <NewsText>
      {text}
    </NewsText>
    <NewsMore onClick={() => more()}>
      More
    </NewsMore>
  </NewsWrap>
)

const NewsWrap = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px; 
    ${breakpoint('mobile', 'tablet')`
      padding: 15px; 
      border-radius: 4px;
      border: 1px solid ${themeVar('brandOrangePale2xColor')};
    `}
    margin-bottom: 40px;
`

const NewsDate = styled.div`
    display: flex;
    width: 50%;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: ${themeVar('newsDateColor')};
    font-size: 10px;
`

const NewsAuthor = styled.div`
    display: flex;
    width: 50%;
    justify-content: flex-end;
    margin-bottom: 20px;
    color: ${themeVar('newsAuthotColor')};
    font-size: 10px;
`

const NewsTitle = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    color: ${themeVar('newsTextColor')};
    font-size: 16px;
    font-weight: 600;
`

const NewsText = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    color: ${themeVar('newsTextColor')};
    font-size: 12px;
`

const NewsMore = styled.div`
    display: flex;
    margin-bottom: 15px;
    color: ${themeVar('newsMoreBtnColor')};
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    cursor: pointer;
`
