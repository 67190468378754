import { createDomain, attach } from 'effector'
import { TenantLease } from '@/dal/contracts.interfaces'
import { getTenentLeaseReqFx } from '@/dal/contracts'

const d = createDomain('d')

export const $activeTab = d.createStore<string>('general')
export const $tenantContract = d.createStore<TenantLease | null>(null)

export const setTab = d.createEvent<string>()
export const loadContract = d.createEvent<string>()
export const setContract = d.createEvent<TenantLease>()

export const getTenentLeaseFx = attach({ effect: getTenentLeaseReqFx })
