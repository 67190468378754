import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  ContractFormTitle,
  themeVar,
  PrimaryButton,
} from '@/ui'
import { BankSecurityList } from './BankSecurityList'
import { AddSecurityDepositForm } from './AddSecurityDeposit'
import { AddBankSecurityForm } from './AddBankSecurity'
import { SecurityDepositList } from './SecurityDepositList'
import { setTab } from '../../model/main'

export const Security = () => {
  const { t } = useTranslation('tenant/main')


  return (
    <Wrap>
      <Row>
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('securityDeposit')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <SecurityDepositList />
        <AddSecurityDepositForm />
        <BlockWrap noBorder>
          <Row align="flex-start">
            <Col width="100%" align="flex-start" noMargin>
              <ContractFormTitle>{t('bankSecurity')}</ContractFormTitle>
            </Col>
          </Row>
        </BlockWrap>
        <BankSecurityList />
        <AddBankSecurityForm />
        <BlockWrap noBorder>
          <Row>
            <Col xs="100%" md="180px" noMargin>
              <PrimaryButton
                outline
                fluid
                type="gray"
                prependIcon="leftChevron"
                onClick={() => setTab('payment')}
              >
                {t('prevStep')}
              </PrimaryButton>
            </Col>
            <Col xs="100%" md="180px">
              <PrimaryButton
                fluid
                appendIcon="rightChevron"
                onClick={() => setTab('documents')}
              >
                {t('nextStep')}
              </PrimaryButton>
            </Col>
          </Row>
        </BlockWrap>
      </Row>
    </Wrap>
  )
}

const Wrap = styled.div`
`

const BlockWrap = styled.div<{noBorder?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 34px;
  ${breakpoint('mobile', 'tablet')`
    padding: 0 0 0 15px;
  `}
  ${({ noBorder }) => !noBorder && css`
    border-bottom: 1px solid ${themeVar('brandGrayPaleColor')};
  `}
`
