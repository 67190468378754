import * as React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HeaderLayout } from '../parts'

export const Header = () => {
  const { t } = useTranslation('header')
  const match = useRouteMatch(useLocation().pathname)
  const PageName = (): string => {
    switch (match?.path) {
      case '/demands':
        return t('interventions demands')
      case '/orders':
        return t('orders')
      case '/consultations':
        return t('consultations')
      case '/consultations/bids':
        return t('consultations')
      case '/reports':
        return t('reports')
      case '/settings':
        return t('settings')
      case '/arrival':
        return t('arrival')
      case '/commands/new':
        return t('commands')
      case '/commands':
        return t('commands')
      case '/':
        return ''
      default:
        return 'Snouki'
    }
  }

  return (
    <HeaderLayout title={PageName()} />
  )
}
