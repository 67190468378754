import * as React from 'react'
import { TabControl } from '@/ui'
import { useStore } from 'effector-react'
import { UserTabEmunI } from '@/features/app'
import { $createUserStep, setUserStep, $createUserCompletedSteps } from '../../model'
import {
  Access, User,
} from './user'

export const CreateUserForm = () => {
  const createUserStep = useStore($createUserStep)
  const createUserCompletedSteps = useStore($createUserCompletedSteps)

  type StepsI = {value: UserTabEmunI; name: string}[]

  const steps: StepsI = [
    { value: 'user', name: '1. User' },
    { value: 'access', name: '2. Access restricted by site ' },
  ]

  const CommandFormContent = (stepName: UserTabEmunI) => {
    switch (stepName) {
      case 'user':
        return (<User />)
      case 'access':
        return (<Access />)
      default:
        return null
    }
  }

  const changeStep = (step: UserTabEmunI) => {
    setUserStep(step)
  }

  return (
    <>
      <TabControl
        items={steps}
        value={createUserStep}
        onItemClick={(val) => changeStep(val.value)}
        checkedIcon="check"
        checkedSteps={createUserCompletedSteps}
      />
      {CommandFormContent(createUserStep)}

    </>
  )
}
