import React from 'react'
import {
  Checkbox,
  themeVar,
} from '@/ui'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { useTranslation } from 'react-i18next'

interface Props {
    checked: boolean;
    onchange: (val: boolean) => void;
}

export const PrivacyBlock = ({ checked, onchange }: Props) => {
  const { t } = useTranslation('autorization/translation')
  return (
    <PolicyBlock>
      <Checkbox
        whiteMode
        checked={checked}
        onChange={onchange}
      >
        <PolicyText>
          {t('policyText.0')}
          <Link href="/tos">
            {t('policyText.1')}
          </Link>
          {t('policyText.2')}
          <Link href="/gdpr">
            {t('policyText.3')}
          </Link>
        </PolicyText>
      </Checkbox>
    </PolicyBlock>
  )
}

const PolicyBlock = styled.div`
  position: relative;
  background: ${themeVar('brandOrangeColor')};
  color: white;
  fill: white;
  padding: 24px 20px 20px;
  bottom: -42px;
  left: 0;
  margin: 0 -70px;
  ${breakpoint('mobile', 'tablet')`
    padding: 20px 70px 35px;
  `}
  &::before{
    position: absolute;
    left: 0;
    top: -8px;
    height: 16px;
    width: 100%;
    content: '';
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const PolicyText = styled.span`
  font-size: 12px;
  font-weight: 100;
  line-height: 12px;
`

interface LinkProps {
  href: string;
}

const Link: React.FC<LinkProps> = ({ children, href }) => (
  <StyledLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </StyledLink>
)

const StyledLink = styled.a`
  text-decoration: underline !important;
  font-weight: 700;  
  color: unset;
`
