import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  InputField,
  Select,
  SubTitle,
  AccentText,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import { personalFileCompleteStep, setPersonFileStep } from '../../../model'

export const NewEntry = () => {
  const [card, setCard] = React.useState<string>('')
  const [continent, setContinent] = React.useState<string>('')
  const [country, setCountry] = React.useState<string>('')
  const [region, setRegion] = React.useState<string>('')
  const [state, setState] = React.useState<string>('')
  const [postCode, setPostCode] = React.useState<string>('')
  const [town, setTown] = React.useState<string>('')
  const [district, setDistrict] = React.useState<string>('')
  const [address, setAddress] = React.useState<string>('')
  const [address2, setAddress2] = React.useState<string>('')
  const [address3, setAddress3] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [phone2, setPhone2] = React.useState<string>('')
  const [fax, setFax] = React.useState<string>('')
  const [mobile, setMobile] = React.useState<string>('')
  const [webSite, setWebSite] = React.useState<string>('')
  const [switchboard, setSwitchboard] = React.useState<string>('')

  const cardItems = getMock().selects.newEmployee.card
  const continentItems = getMock().selects.newEmployee.continent
  const countryItems = getMock().selects.newEmployee.country
  const regionItems = getMock().selects.newEmployee.region
  const stateItems = getMock().selects.newEmployee.state
  const postCodeItems = getMock().selects.newEmployee.postCode
  const townItems = getMock().selects.newEmployee.town
  const districtItems = getMock().selects.newEmployee.district
  const addresItems = getMock().selects.newEmployee.addres

  const confirmFormAction = () => {
    personalFileCompleteStep('contact')
    setPersonFileStep('details')
  }

  return (
    <Wrap>
      <>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                1. Card
                <AccentText>*</AccentText>
              </SubTitle>
              <Select
                name="Choose number"
                items={cardItems}
                value={card}
                onChange={setCard}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                2. Continent
              </SubTitle>
              <Select
                name="Choose continent"
                items={continentItems}
                value={continent}
                onChange={setContinent}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                3. country
              </SubTitle>
              <Select
                name="Choose country"
                items={countryItems}
                value={country}
                onChange={setCountry}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                4. region
              </SubTitle>
              <Select
                name="Choose region"
                items={regionItems}
                value={region}
                onChange={setRegion}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                5. State/county
              </SubTitle>
              <Select
                name="Choose state"
                items={stateItems}
                value={state}
                onChange={setState}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                6. post code
              </SubTitle>
              <Select
                name="Choose post code"
                items={postCodeItems}
                value={postCode}
                onChange={setPostCode}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                7. town
              </SubTitle>
              <Select
                name="Choose town"
                items={townItems}
                value={town}
                onChange={setTown}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                8. District
              </SubTitle>
              <Select
                name="Choose district"
                items={districtItems}
                value={district}
                onChange={setDistrict}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                9. address
              </SubTitle>
              <Select
                name="Choose addres"
                items={addresItems}
                value={address}
                onChange={setAddress}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                10. address 2
              </SubTitle>
              <Select
                name="Choose addres"
                items={addresItems}
                value={address2}
                onChange={setAddress2}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                11. address 3
              </SubTitle>
              <Select
                name="Choose addres"
                items={addresItems}
                value={address3}
                onChange={setAddress3}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                12. email
              </SubTitle>
              <InputField
                value={email}
                onChangeValue={setEmail}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                13. phone
              </SubTitle>
              <InputField
                value={phone}
                onChangeValue={setPhone}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                14. phone 2
              </SubTitle>
              <InputField
                value={phone2}
                onChangeValue={setPhone2}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                15. fax
              </SubTitle>
              <InputField
                value={fax}
                onChangeValue={setFax}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                16. mobile
              </SubTitle>
              <InputField
                value={mobile}
                onChangeValue={setMobile}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                17. web site
              </SubTitle>
              <InputField
                value={webSite}
                onChangeValue={setWebSite}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                18. switchboard
              </SubTitle>
              <InputField
                value={switchboard}
                onChangeValue={setSwitchboard}
              />
            </>
          </Col>
        </Row>

        <Row>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => setPersonFileStep('location')}
            >
              back
            </PrimaryButton>
          </Col>
          <Col xs="100%" md="150px">
            <PrimaryButton
              fluid
              appendIcon="rightChevron"
              onClick={() => confirmFormAction()}
            >
              next step
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}


const Wrap = styled.div`
    padding: 20px 40px 20px 18px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 40px;
    `}
`
