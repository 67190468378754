import { createDomain, attach } from 'effector'

import { verifyEmailReqFx, verifyEmailResendReqFx } from '@/dal'
import { CustomError } from '@/lib/errors'

const d = createDomain()


export const $serverError = d.store<string | null>(null)
export const $token = d.store<string| null>(null)
export const $modalShown = d.store(false)

export const setServerError = d.event<string>()

export const setToken = d.event<string>()
export const showModal = d.event<void>()
export const closeModal = d.event<void>()

export const checkTokenReqFx = attach({ effect: verifyEmailReqFx })

export const getNewTokenReqFx = attach({ effect: verifyEmailResendReqFx })
