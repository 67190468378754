import { createDomain } from 'effector'

import { createPagination } from '@/lib/effector-pagination'
import {
  getNewsArticlesListReqFx,
  NewsArticle,
} from '@/dal'
import { createFilters } from '@/lib/effector-filters'

export const siteNewsDomain = createDomain()

export const updateNewsList = siteNewsDomain.event<NewsArticle[]>()
export const addNewsToList = siteNewsDomain.event<NewsArticle[]>()
export const addNewsToSearchList = siteNewsDomain.event<NewsArticle[]>()
export const updateSearchValue = siteNewsDomain.event<string>()
export const updateSearchDate = siteNewsDomain.event<string| null>()
export const setCurrentSearchValue = siteNewsDomain.event<string>()
export const setSearchActive = siteNewsDomain.event<boolean>()
export const startSearch = siteNewsDomain.event<void>()
export const endSearch = siteNewsDomain.event<void>()
export const updateFilters = siteNewsDomain.event<void>()
export const reset = siteNewsDomain.event<void>()
export const resetLists = siteNewsDomain.event<void>()
export const loadNextPage = siteNewsDomain.event()
export const loadNextSearchPage = siteNewsDomain.event()


export const $newsList = siteNewsDomain.store<NewsArticle[]>([])
export const $searchNewsList = siteNewsDomain.store<NewsArticle[]>([])
export const $searchValue = siteNewsDomain.store('')
export const $searchDate = siteNewsDomain.store<string| null>('')
export const $currentSearchValue = siteNewsDomain.store('')
export const $currentSearchDate = siteNewsDomain.store<string| null>('')
export const $isSearchActive = siteNewsDomain.store(false)

siteNewsDomain.onCreateStore(($store) => {
  $store.reset(reset)
})

export const filters = createFilters({
  domain: siteNewsDomain,
  filters: {
    elemID: {
      include: true,
      value: '',
    },
  },
})

export const searchFilters = createFilters({
  domain: siteNewsDomain,
  logicFilter: true,
  filters: {
    elemID: {
      include: true,
      value: '',
    },
    article: {
      include: true,
      value: '',
    },
    title: {
      include: true,
      value: '',
    },
    createdAt: {
      include: true,
      value: null as string | null,
    },
  },
})

/*
export const searchPagination = createPagination({
  domain: siteNewsDomain,
  effect: getNewsArticlesListReqFx,
  params: searchFilters.$result,
  perPage: 12,
})

export const newsPagination = createPagination({
  domain: siteNewsDomain,
  effect: getNewsArticlesListReqFx,
  params: filters.$result,
  perPage: 12,
})
*/
