import * as React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

import {
  PrimaryButton,
  Row,
  Col,
  AccentText,
  ExtraSmallText,
  Title,
  InputField,
  themeVar,
} from '@/ui'
import { GetAllCounterpartyContact } from '@/dal'

import {
  $isSearchActive,
  $searchValue,
  $currentSearchValue,
  $searchContactsList,
  $contactsList,
  reset,
  startSearch,
  endSearch,
  updateSearchValue,
  selectContact,
  showContactDetailsModal,
  contactsPagination,
  mounted,
} from '../../model/contacts/units'
import { ContactDetailsModal } from '../containers/ContactDetails'

export const ContactList = () => {
  const { t } = useTranslation('site/contacts')

  const isSearchActive = useStore($isSearchActive)
  const searchValue = useStore($searchValue)
  const currentSearchValue = useStore($currentSearchValue)
  const searchContactsList = useStore($searchContactsList)
  const contactsList = useStore($contactsList)

  React.useEffect(() => {
    mounted()
    return () => reset()
  }, [])

  const contacts = isSearchActive ? searchContactsList : contactsList

  const openModal = (contact: GetAllCounterpartyContact) => {
    selectContact(contact)
    showContactDetailsModal()
  }

  const renderContacts = (dataItems: GetAllCounterpartyContact[]) => dataItems.map((item, index) => (
    <TableRow
      key={`${item.contact.firstName}${item.id}`}
      colorized
      onClick={() => openModal(item)}
    >
      <>
        <Col md={1} xs="50%">
          <ColItem>
            <ExtraSmallText align="left">
              <AccentText>
                {t('list.cols.firstName')}
              </AccentText>
            </ExtraSmallText>
            {item.contact.firstName}
          </ColItem>
        </Col>
        <Col md={1} xs="50%">
          <ColItem>
            <ExtraSmallText align="left">
              <AccentText>
                {t('list.cols.lastName')}
              </AccentText>
            </ExtraSmallText>
            {item.contact.lastName}
          </ColItem>
        </Col>
        <Col md={1} xs="100%">
          <ColItem>
            <ExtraSmallText align="left">
              <AccentText>
                {t('list.cols.phone')}
              </AccentText>
            </ExtraSmallText>
            {item.contact.defaultPhone}
          </ColItem>
        </Col>
      </>
    </TableRow>
  ))

  return (
    <Wrap>
      <SearchRow>
        {isSearchActive && (
          <>
            <Col md="25%" xs="50%" noMargin noPadding align="flex-start">
              <PrimaryButton
                onClick={() => endSearch()}
                prependIcon="leftChevron"
                outline
              >
                {t('list.buttons.back')}
              </PrimaryButton>
            </Col>
            <Col md="50%" xs="100%" noMargin noPadding>
              <Row>
                <Title>
                  <AccentText>
                    {t('list.search.results', { value: currentSearchValue })}
                  </AccentText>
                </Title>
              </Row>
            </Col>
          </>
        )}
        <Col width="100%" md={isSearchActive ? '25%' : '50%'} noMargin noPadding>
          <InputField
            value={searchValue}
            placeholder={t('list.search.fieldPlaceholder')}
            onChangeValue={updateSearchValue}
            appendIcon="lense"
            innerIcon
            onAppendIconClick={() => startSearch()}
          />
        </Col>
      </SearchRow>
      {renderContacts(contacts)}
      <ContactDetailsModal />
    </Wrap>
  )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    ${breakpoint('mobile', 'tablet')`
        padding: 0px 25px;
    `}
`
const SearchRow = styled(Row)`
  padding-left: 20px;
  padding-right: 20px;
`

const TableRow = styled(Row)`
  border-bottom: 1px solid ${themeVar('brandGreenPaleColor')};
  ${(prop) => prop.onClick && css`
    cursor: pointer;
  `}
  &:after {
    background: ${themeVar('brandOrangePale2xColor')};
  }
  &:nth-child(2n+1){
    &:after{
      background: white;
    }
  }
  ${breakpoint('mobile', 'tablet')`
      background: white;
      border: 2px solid ${themeVar('brandOrangePale2xColor')};
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 0 15px 15px;
      &:after{
        display: none;
      }
  `}
`

const ColItem = styled.div`
  width: 100%;
  font-size: 12px;
`
