import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import {
  Row,
  Col,
  InputField,
  Select,
  InputDateTime,
  RadioButtonsList,
  SubTitle,
  AccentText,
  PrimaryButton,
} from '@/ui'
import { getMock } from '@/features/app'
import { userCompleteStep, setUserStep } from '../../../model'

export const User = () => {
  const [login, setLogin] = React.useState<string>('')
  const [status, setStatus] = React.useState<boolean>(true)
  const [securityRole, setSecurityRole] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>('')
  const [category, setCategory] = React.useState<string>('')
  const [interfaceLanguage, setInterfaceLanguage] = React.useState<string>('')
  const [portalIsioservice, setPortalIsioservice] = React.useState<string>('')
  const [portalIsioref, setPortalIsioref] = React.useState<string>('')
  const [lastConeection, setLastConeection] = React.useState<string | null>(new Date().toISOString())
  const [role, setRole] = React.useState<string>('')

  const selectItems = getMock().selects.newEmployee.status
  const statusItems = getMock().selects.newEmployee.statusBoolean

  const confirmFormAction = () => {
    userCompleteStep('user')
    setUserStep('access')
  }

  return (
    <Wrap>
      <>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                1. Login
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                value={login}
                onChangeValue={setLogin}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                2. status
              </SubTitle>
              <RadioButtonsList
                name="status"
                items={statusItems}
                value={status}
                onChange={setStatus}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                3. security role
              </SubTitle>
              <Select
                name="Choose role"
                items={selectItems}
                value={securityRole}
                onChange={setSecurityRole}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                4. password
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                type="password"
                value={password}
                onChangeValue={setPassword}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                5. password confirmation*
                <AccentText>*</AccentText>
              </SubTitle>
              <InputField
                type="password"
                value={passwordConfirm}
                onChangeValue={setPasswordConfirm}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                6. category
              </SubTitle>
              <Select
                name="Choose category"
                items={selectItems}
                value={category}
                onChange={setCategory}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                7. interface language
              </SubTitle>
              <Select
                name="Choose language"
                items={selectItems}
                value={interfaceLanguage}
                onChange={setInterfaceLanguage}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                8. portal IsioService
              </SubTitle>
              <Select
                name="Choose portal"
                items={selectItems}
                value={portalIsioservice}
                onChange={setPortalIsioservice}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                9. portal IsioRef
              </SubTitle>
              <Select
                name="Choose portal"
                items={selectItems}
                value={portalIsioref}
                onChange={setPortalIsioref}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                10. date of last connection
              </SubTitle>
              <InputDateTime
                value={lastConeection}
                onChange={setLastConeection}
              />
            </>
          </Col>
          <Col xs="100%" md={1} align="flex-start">
            <>
              <SubTitle>
                11. role
              </SubTitle>
              <Select
                name="Choose role"
                items={selectItems}
                value={role}
                onChange={setRole}
              />
            </>
          </Col>
          <Col xs="100%" md={1} />
        </Row>
        <Row>
          <Col xs="100%" md="180px">
            <PrimaryButton appendIcon="rightChevron" onClick={() => confirmFormAction()}>
              next step
            </PrimaryButton>
          </Col>
        </Row>
      </>
    </Wrap>
  )
}

const Wrap = styled.div`
    padding: 20px 40px 20px 18px;
    ${breakpoint('mobile', 'tablet')`
        padding: 20px 40px;
    `}
`
