import { forward, sample } from 'effector'
import i18n from 'i18next'

import { $lang } from '@/features/app'

import { showThanksModal, hideSignUpDialog } from '../units'
import {
  d,
  $serverError,
  mounted,
  reset,
  resetServerError,
  form,
  registerOwnerFx,
} from './units'

d.onCreateStore((x) => {
  x.reset(reset)
})

$serverError
  .reset(resetServerError)

sample({
  source: $lang,
  clock: form.formValidated,
  fn: (language, f) => ({
    email: f.email,
    password: f.password,
    language,
  }),
  target: registerOwnerFx,
})

registerOwnerFx.done
  .watch(() => {
    hideSignUpDialog()
    setImmediate(() => {
      showThanksModal(i18n.t('autorization/translation:modals.successModal.text'))
      reset()
    })
  })

forward({
  from: registerOwnerFx.failData.map((x) => x.name),
  to: $serverError,
})

forward({
  from: form.$values.updates,
  to: resetServerError,
})
