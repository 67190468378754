import { createDomain, attach } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/validators'
import { createCompanyReqFx } from '@/dal'

export const d = createDomain('d')

export const useUserData = d.event<void>()
export const mounted = d.event<void>()
export const reset = d.event<void>()

export const form = createForm({
  domain: d,
  fields: {
    name: {
      init: '' as string,
      rules: [rules.required()],
    },
    description: {
      init: '' as string,
      rules: [rules.required()],
    },
    code: {
      init: '' as string,
      rules: [rules.required()],
    },
    address: {
      init: '' as string,
      rules: [rules.required()],
    },
    email: {
      init: '' as string,
      rules: [rules.required(), rules.isEmail()],
    },
    phones: {
      init: [] as string[],
    },
    defaultPhone: {
      init: '' as string,
      rules: [rules.required()],
    },
    webSite: {
      init: '' as string,
      rules: [rules.isURL()],
    },
    fax: {
      init: '' as string,
    },
  },
})

export const createCompanyFx = attach({ effect: createCompanyReqFx })
