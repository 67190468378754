import * as React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  PrimaryButton,
  Title,
  SubTitle,
  Col,
  InputField,
  Select,
  InputTextArea,
  Text,
  SliderField,
  WrapField,
  AccentText,
} from '@/ui'
import { $isShowModal, newLocality, hideModal } from '../../model/new-locality'
import {
  useLocalityAssignmentList,
  useLocalityAvailabilityList,
  useLocalityLoadList,
  useLocalityTypeList,
} from '../../helper/GetSelectItems'

export const NewLocality = () => {
  const { t } = useTranslation('site-creation')
  const isShowModal = useStore($isShowModal)
  const { submit, fields, reset } = useForm(newLocality)
  const localityLoadItemsList = useLocalityLoadList()
  const localityAssignmentItemsList = useLocalityAssignmentList()
  const localityTypeItemsList = useLocalityTypeList()
  const localityAvailabilityItemsList = useLocalityAvailabilityList()

  const saveForm = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Modal show={isShowModal} onClose={() => hideModal()}>
      <Form onSubmit={saveForm}>
        <Col width="100%" noPadding>
          <Title>{t('newLocality')}</Title>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              1.
              {' '}
              {t('name')}
              <AccentText>*</AccentText>
            </SubTitle>
            <InputField
              placeholder={t('localityPlaceholder')}
              value={fields.name.value}
              onChangeValue={fields.name.onChange}
              errorText={fields.name.errorText()}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              2.
              {' '}
              {t('description')}
            </SubTitle>
            <InputTextArea
              maxLength={150}
              value={fields.description.value}
              onChangeValue={fields.description.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              3.
              {' '}
              {t('assigment')}
            </SubTitle>
            <Select
              name={t('chooseAssigment')}
              value={fields.assigment.value}
              items={localityAssignmentItemsList}
              onChange={fields.assigment.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              4.
              {' '}
              {t('type')}
            </SubTitle>
            <Select
              name={t('chooseType')}
              value={fields.type.value}
              items={localityTypeItemsList}
              onChange={fields.type.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              5.
              {' '}
              {t('availibility')}
            </SubTitle>
            <Select
              name={t('chooseAvailibility')}
              value={fields.availibility.value}
              items={localityAvailabilityItemsList}
              onChange={fields.availibility.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              3.
              {' '}
              {t('typeOfLoad')}
            </SubTitle>
            <Select
              name={t('chooseTypeOfLoad')}
              value={fields.typeOfLoad.value}
              items={localityLoadItemsList}
              onChange={fields.typeOfLoad.onChange}
            />
          </>
        </Col>
        <Col width="100%" md="80%" noPadding align="flex-start">
          <>
            <SubTitle>
              8.
              {' '}
              {t('area')}
            </SubTitle>
            <WrapField>
              <Text>
                {t('surfaceSquare')}
                <sup>2</sup>
              </Text>
              <InputField
                placeholder={t('surface')}
                value={fields.surface.value}
                onChangeValue={fields.surface.onChange}
              />
            </WrapField>
            <WrapField>
              <Text>
                {t('perimeter')}
              </Text>
              <InputField
                placeholder={t('perimeter')}
                value={fields.perimetr.value}
                onChangeValue={fields.perimetr.onChange}
              />
            </WrapField>
            <WrapField>
              <Text>
                {t('volume')}
                <sup>3</sup>
              </Text>
              <InputField
                placeholder={t('volume')}
                value={fields.volume.value}
                onChangeValue={fields.volume.onChange}
              />
            </WrapField>
          </>
        </Col>

        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              htmlType="submit"
            >
              {t('save')}
            </PrimaryButton>
          </WrapField>
        </Col>
        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              outline
              type="warning"
              appendIcon="close"
              onClick={() => reset()}
            >
              {t('clear')}
            </PrimaryButton>
          </WrapField>
        </Col>
        <Col width="100%" md="80%" noPadding>
          <WrapField>
            <PrimaryButton
              fluid
              outline
              type="gray"
              prependIcon="leftChevron"
              onClick={() => hideModal()}
            >
              {t('return')}
            </PrimaryButton>
          </WrapField>
        </Col>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
  `
