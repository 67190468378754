import styled from 'styled-components'

import { SubTitle } from '@/ui'

export const GridCol = styled.div<{ gridArea: string; vAlign?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  align-items: ${({ vAlign = 'center' }) => vAlign};
  grid-area: ${({ gridArea }) => gridArea};
`

export const StyledSubTitle = styled(SubTitle)`
  margin: 0;
`
