import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { TosEN, TosFR } from '../containers'

export const ToSPage = () => {
  const { i18n } = useTranslation()

  if (i18n.language === 'fr') {
    return (
      <TosFR />
    )
  }

  return <TosEN />
}
